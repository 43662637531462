import { Button, FormControl, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Controller } from 'react-hook-form';

import { UseFocus, useMountEffect } from '../../../hooks';
// import { useCustomStore } from '../../../hooks';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

type notificationFormPropTypes = {
  classes: any;
  notificationController: any;
  notificationSubmit: any;
  filter: any;
  resetFilter: any;
  searchPage: any;
  setFilters: (src: any) => void;
  submitNotificationFilter: any;
};

const NotificationSearchForm: React.FC<any> = (
  props: notificationFormPropTypes,
) => {
  // const { notificationStore } = useCustomStore();
  const {
    classes,
    notificationController,
    notificationSubmit,
    filter,
    resetFilter,
    searchPage,
    setFilters,
    submitNotificationFilter,
  } = props;

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  return (
    <form
      onSubmit={notificationSubmit(submitNotificationFilter)}
      id={searchPage}>
      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Title
        </Typography>

        <Grid className={classes.borderBottom}>
          <Typography component={'div'} className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={notificationController}
                defaultValue=""
                name="title"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={input1Ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.title}
                      value={filter.title}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          title: e,
                        }));
                        // notificationStore.setNotificationSearchDetails('title',e);
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </Grid>

        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Description
        </Typography>
        <Grid className={classes.borderBottom}>
          <Typography component={'div'} className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={notificationController}
                defaultValue=""
                name="description"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.description}
                      value={filter.description}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          description: e,
                        }));
                        // notificationStore.setNotificationSearchDetails('description',e);
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </Grid>

        {/* <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Next Link
        </Typography>
        <Grid className={classes.borderBottom}>
          <Typography component={'div'} className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={notificationController}
                defaultValue=""
                name="nextLink"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={ref}
                      name={name}
                      isRefreshValue={true}
                      defaultValue={filter.nextLink}
                      value={filter.nextLink}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          nextLink: e,
                        }));
                        // notificationStore.setNotificationSearchDetails('nextLink',e);
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </Grid> */}

        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Status
        </Typography>
        <Grid className={classes.borderBottom}>
          <Typography component={'div'} className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={notificationController}
                defaultValue=""
                name="status"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.status}
                      value={filter.status}
                      inputRefObj={ref}
                      size={'small'}
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          status: e,
                        }));
                        // notificationStore.setNotificationSearchDetails('status',e);
                      }}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={
                        <>
                          <option
                            value=""
                            // disabled
                            className={classes.optionsClassName}>
                            Select
                          </option>
                          <option
                            value="scheduled"
                            className={classes.optionsClassName}>
                            Scheduled
                          </option>
                          <option
                            value="active"
                            className={classes.optionsClassName}>
                            Active
                          </option>
                          <option
                            value="inactive"
                            className={classes.optionsClassName}>
                            Inactive
                          </option>
                          <option
                            value="deleted"
                            className={classes.optionsClassName}>
                            Deleted
                          </option>
                        </>
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </Grid>
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              disabled={
                // !(
                //   (
                //   // filter.nextLink ||
                //   filter.title ||
                //   filter.description
                //   )
                //   ||
                //   (
                //     filter.status
                //   )
                // )
                false
              }
              tabIndex={0}
              type="button"
              onClick={resetFilter}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            disabled={
              // !(
              //   (
              //   filter.title ||
              //   filter.description
              //   )
              //   ||
              //   (
              //     filter.status
              //   )
              // )
              false
            }
            className={classes.filterBtn}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(NotificationSearchForm);
