import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useCustomStore } from '../../../../hooks';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import MappingHeader from './MappingHeader';
import MappingList from './MappingList';
import useStyles from './mappingStyle';

const Mapping: React.FC = () => {
  const classes = useStyles();
  const { videoStore } = useCustomStore();

  const { enqueueSnackbar } = useSnackbar();

  const [moreViolationMappings, setMoreViolationMappings] =
    useState<boolean>(false);

  const getViolationMappings = useCallback(async () => {
    videoStore.setViolationMappingsLoader(true);
    const resp = await videoStore.getInitialTrainingViolationMappings();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Violation Mappings',
        SnackBarConfig('e'),
      );
    }
    videoStore.setViolationMappingsLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMoreViolationMappings = useCallback(async () => {
    setMoreViolationMappings(true);
    const groupsResponse = await videoStore.getTrainingViolationMappings();
    if (groupsResponse.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Violation Mappings',
        SnackBarConfig('e'),
      );
    }
    setMoreViolationMappings(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getViolationMappings();
  }, [getViolationMappings]);

  const violationMappingSortBy = (columnName: string) => {
    videoStore.setViolationMappingFilters({ sortBy: columnName });
    videoStore.setViolationMappingFilters({
      sortDirection:
        videoStore.ViolationMappingFilters.sortDirection === 'asc'
          ? 'desc'
          : 'asc',
    });
    getViolationMappings();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        px: 6,
      }}>
      {videoStore.ViolationMappingsLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          className={classes.scroll}
          container
          spacing={2}
          justifyContent="space-between">
          <TableContainer
            sx={{ maxHeight: '85vh' }}
            id="training-mapping-table">
            <InfiniteScroll
              dataLength={videoStore.ViolationMappings.length}
              style={{ overflow: 'unset' }}
              next={getMoreViolationMappings}
              hasMore={videoStore.HasMoreViolationMappings}
              scrollableTarget={'training-mapping-table'}
              loader={
                moreViolationMappings && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                className={classes.table}
                stickyHeader
                sx={{
                  borderCollapse: 'separate !important',
                  borderSpacing: '0px 5px !important',
                }}
                aria-label="sticky table">
                <TableHead>
                  <MappingHeader
                    violationMappingSortBy={violationMappingSortBy}
                    videoStore={videoStore}
                  />
                </TableHead>
                <TableBody>
                  {videoStore.ViolationMappings.length > 0 ? (
                    <MappingList />
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={8}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
    </Box>
  );
};

export default observer(Mapping);
