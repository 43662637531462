import Button, { ButtonProps } from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './VideoArchiveDialogStyles';

const ArchiveButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#A70D0D',
  },
  backgroundColor: '#A70D0D',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#A70D0D'),
  height: '48px',
  width: '170px',
}));

type VideoArchiveDialogProps = {
  isOpen: boolean;
  closeDialog: (response: boolean, id?: string) => void;
};

const VideoArchiveDialog: React.FC<any> = (props: VideoArchiveDialogProps) => {
  const classes = useStyles();
  const { isOpen, closeDialog } = props;

  return (
    <DialogMigrate
      aria-labelledby="video-archive-dialog"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {
        closeDialog(false);
      }}>
      <DialogTitle>
        <div className={classes.title}>
          Are you sure you want to archive this video?
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.subTitle}>
          This will be removed from videos. You will not be able to undo this
          once submitted.
        </div>
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.dialogAction}>
        <Link
          className={classes.cancelLink}
          color="inherit"
          underline="always"
          onClick={() => {
            closeDialog(false);
          }}>
          NO, CANCEL
        </Link>
        <ArchiveButton
          type={'button'}
          variant="contained"
          onClick={() => {
            closeDialog(true);
          }}>
          <Typography className={classes.archiveBtn}>YES, ARCHIVE</Typography>
        </ArchiveButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(VideoArchiveDialog);
