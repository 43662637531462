import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import SortingIcon from '../../../components/SortingIcon/SortingIcon';

const UsersHeader: React.FC<{
  userHeader: any;
  adminUsersStore: any;
}> = ({ userHeader, adminUsersStore }) => {
  const sortBY = async (e: any) => {
    adminUsersStore.setSortBy(e);
    adminUsersStore.setSortDirection(
      adminUsersStore.SortDirection === 'desc' ? 'asc' : 'desc',
    );
    adminUsersStore.setShowLoader(true);
    await adminUsersStore.fetchAdminsBySorting();
    adminUsersStore.setShowLoader(false);
  };

  return (
    <TableRow style={{ backgroundColor: '#f5f5f5' }}>
      {userHeader.map(
        (header: any, index: number) =>
          header.show && (
            <TableCell key={index} style={{ backgroundColor: '#f5f5f5' }}>
              <Grid item xs={12}>
                <Typography
                  component={'div'}
                  noWrap
                  style={{ alignItems: 'center', display: 'flex' }}>
                  <Typography
                    component={'div'}
                    sx={{
                      color: header.name === 'name' ? '#241A2E' : '#979598',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '12px',
                      letterSpacing: 0,
                      lineHeight: '14px',
                    }}>
                    {header.lable}
                  </Typography>

                  {header !== '' && header.name !== '' && (
                    <SortingIcon
                      {...{
                        sortBY,
                        sortByName: header.name,
                        store: adminUsersStore,
                      }}
                    />
                  )}
                </Typography>
              </Grid>
            </TableCell>
          ),
      )}
      <TableCell style={{ backgroundColor: '#f5f5f5' }} />
      <TableCell style={{ backgroundColor: '#f5f5f5' }} />
    </TableRow>
  );
};

export default observer(UsersHeader);
