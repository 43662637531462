import '@react-pdf-viewer/core/lib/styles/index.css';

import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import packageJson from '../../../package.json';
import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './VideoStreamDialogStyles';

type VideoStreamDialogProps = {
  isOpen: boolean;
  videoUrl: string;
  videoTitle: string;
  hasDocument: boolean;
  closeDialog: () => void;
};

const VideoStreamDialog: React.FC<VideoStreamDialogProps> = (
  props: VideoStreamDialogProps,
) => {
  const classes = useStyles();
  const { isOpen, closeDialog, videoUrl, videoTitle, hasDocument } = props;

  const pdfJsVersion: string = packageJson.dependencies['pdfjs-dist'];

  return (
    <DialogMigrate
      aria-labelledby="video-stream-dialog"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={false}
      onClose={() => {
        closeDialog();
      }}>
      <DialogTitle className={classes.dialogHeader}>
        <div className="title">{videoTitle || ''}</div>
        <IconButton
          size="small"
          aria-label="close"
          onClick={() => closeDialog()}>
          <CloseIcon className="close-icon" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={clsx({
          [classes.dialogContent]: true,
          [classes.documentContent]: hasDocument,
        })}>
        {hasDocument ? (
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfJsVersion}/build/pdf.worker.min.js`}>
            <Viewer
              fileUrl={videoUrl}
              defaultScale={SpecialZoomLevel.PageWidth}
            />
          </Worker>
        ) : (
          <div className="video">
            <video src={videoUrl} controls width="100%" />
          </div>
        )}
      </DialogContent>
    </DialogMigrate>
  );
};

export default observer(VideoStreamDialog);
