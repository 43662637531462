import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import SortingIcon from '../../../components/SortingIcon/SortingIcon';

const CompanyHeader: React.FC<{
  companyStore: any;
}> = ({ companyStore }) => {
  const sortBY = async (e: any) => {
    companyStore.setShowLoader(true);
    companyStore.setSortBy(e);
    companyStore.setSortDirection(
      companyStore.SortDirection === 'desc' ? 'asc' : 'desc',
    );
    await companyStore.fetchCompaniesBySorting();
    companyStore.setShowLoader(false);
  };

  return (
    <TableRow>
      <TableCell style={{ backgroundColor: '#f5f5f5' }}>
        <Grid item xs={12}>
          <Typography
            component={'div'}
            noWrap
            style={{ alignItems: 'center', display: 'flex' }}>
            <Typography
              component={'div'}
              sx={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Regular',
                fontSize: '12px',
                letterSpacing: 0,
                lineHeight: '14px',
              }}>
              Company
            </Typography>
            <SortingIcon
              {...{ sortBY, sortByName: 'name', store: companyStore }}
            />
          </Typography>
        </Grid>
      </TableCell>
      <TableCell style={{ backgroundColor: '#f5f5f5' }}>
        <Grid item xs={12}>
          <Typography
            component={'div'}
            noWrap
            style={{ alignItems: 'center', display: 'flex' }}>
            <Typography
              sx={{
                color: '#979598',
                fontFamily: 'FiraSans-Regular',
                fontSize: '12px',
                letterSpacing: 0,
                lineHeight: '14px',
              }}>
              Simplex ID
            </Typography>
            <SortingIcon
              {...{ sortBY, sortByName: 'simplexId', store: companyStore }}
            />
          </Typography>
        </Grid>
      </TableCell>
      <TableCell style={{ backgroundColor: '#f5f5f5' }}>
        <Grid item xs={12}>
          <Typography
            component={'div'}
            noWrap
            style={{ alignItems: 'center', display: 'flex' }}>
            <Typography
              component={'div'}
              sx={{
                color: '#979598',
                fontFamily: 'FiraSans-Regular',
                fontSize: '12px',
                letterSpacing: 0,
                lineHeight: '14px',
              }}>
              DOT #
            </Typography>
            <SortingIcon
              {...{ sortBY, sortByName: 'dotNumber', store: companyStore }}
            />
          </Typography>
        </Grid>
      </TableCell>
      <TableCell style={{ backgroundColor: '#f5f5f5' }}>
        <Grid item xs={12}>
          <Typography
            component={'div'}
            noWrap
            style={{ alignItems: 'center', display: 'flex' }}>
            <Typography
              component={'div'}
              sx={{
                color: '#979598',
                fontFamily: 'FiraSans-Regular',
                fontSize: '12px',
                letterSpacing: 0,
                lineHeight: '14px',
              }}>
              # Active Drivers
            </Typography>
            <SortingIcon
              {...{
                sortBY,
                sortByName: 'totalActiveDrivers',
                store: companyStore,
              }}
            />
          </Typography>
        </Grid>
      </TableCell>
      <TableCell style={{ backgroundColor: '#f5f5f5' }}>
        <Grid item xs={12}>
          <Typography
            component={'div'}
            noWrap
            style={{ alignItems: 'center', display: 'flex' }}>
            <Typography
              component={'div'}
              sx={{
                color: '#979598',
                fontFamily: 'FiraSans-Regular',
                fontSize: '12px',
                letterSpacing: 0,
                lineHeight: '14px',
              }}>
              # Active Units
            </Typography>
            <SortingIcon
              {...{
                sortBY,
                sortByName: 'totalActiveDrivers',
                store: companyStore,
              }}
            />
          </Typography>
        </Grid>
      </TableCell>
      <TableCell style={{ backgroundColor: '#f5f5f5' }}>
        <Grid item xs={12} />
      </TableCell>
      <TableCell style={{ backgroundColor: '#f5f5f5' }}>
        <Grid item xs={12} />
      </TableCell>
    </TableRow>
  );
};

export default observer(CompanyHeader);
