import { makeAutoObservable, toJS } from 'mobx';

import { API_LIMIT } from '../config';
import { QuizDetails, QuizQuestions, TrainingQuizzesFilters } from '../types';
import { RootStore } from '.';

type TrainingQuizzesDTO = {
  id: string;
  title: string;
  correctAnswersPercentage: number;
  status: 'active' | 'archived' | 'draft';
  questions: QuizQuestions[];
  videoTitle: string;
};

export class QuizzesStore {
  private _status: 'active' | 'archived' = 'active';
  private _quizzesList: any[] = [];
  private _quizzesCount: number | undefined = 0;
  private _showLoader = false;
  private _hasMoreResults = false;
  private _nextLink = '';
  private _quizFilters: TrainingQuizzesFilters = {
    limit: String(API_LIMIT),
    nextLink: this.NextLink,
    quizTitle: '',
    sortBy: this.SortBy,
    sortDirection: this.SortDirection,
    status: 'active',
    videoTitle: '',
  };
  private _sortBy: 'title' = 'title';
  private _sortDirection: 'asc' | 'desc' = 'asc';
  private _selectedQuiz: TrainingQuizzesDTO = {
    correctAnswersPercentage: 0,
    id: '',
    questions: [
      {
        answerOptions: [
          {
            id: '',
            isCorrect: false,
            option: '',
            optionOrder: 1,
          },
          {
            id: '',
            isCorrect: false,
            option: '',
            optionOrder: 2,
          },
          {
            id: '',
            isCorrect: true,
            option: '',
            optionOrder: 3,
          },
          {
            id: '',
            isCorrect: false,
            option: '',
            optionOrder: 4,
          },
        ],
        id: '',
        question: '',
        questionOrder: 1,
        questionTitle: '',
      },
    ],
    status: 'draft',
    title: '',
    videoTitle: 'Video Name',
  };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setQuizFilters(data: TrainingQuizzesFilters) {
    this._quizFilters = {
      ...this._quizFilters,
      ...data,
    };
  }
  resetQuizFilters() {
    this.setQuizFilters({
      quizTitle: '',
      sortBy: 'title',
      sortDirection: 'asc',
      status: 'active',
      videoTitle: '',
    });
    this.setSortBy('title');
    this.setSortDirection('asc');
  }

  setQuizzesList(data: Array<any>) {
    this._quizzesList = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setStatus(value: 'active' | 'archived') {
    this._status = value;
  }

  setSortBy(data: 'title') {
    this._sortBy = data;
  }

  setSortDirection(data: 'asc' | 'desc') {
    this._sortDirection = data;
  }

  setCount(count: number | undefined) {
    this._quizzesCount = count;
  }

  setSelectedQuiz(data: TrainingQuizzesDTO) {
    this._selectedQuiz = data;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get Filters() {
    return this._quizFilters;
  }

  get QuizzesList() {
    return this._quizzesList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get Status() {
    return this._status;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get Count() {
    return this._quizzesCount;
  }

  get SelectedQuiz() {
    return { ...toJS(this._selectedQuiz) };
  }

  setSelectedQuizReset() {
    this._selectedQuiz = {
      correctAnswersPercentage: 0,
      id: '',
      questions: [
        {
          answerOptions: [
            {
              id: '',
              isCorrect: false,
              option: '',
              optionOrder: 1,
            },
            {
              id: '',
              isCorrect: false,
              option: '',
              optionOrder: 2,
            },
            {
              id: '',
              isCorrect: true,
              option: '',
              optionOrder: 3,
            },
            {
              id: '',
              isCorrect: false,
              option: '',
              optionOrder: 4,
            },
          ],
          id: '',
          question: '',
          questionOrder: 1,
          questionTitle: '',
        },
      ],
      status: 'draft',
      title: '',
      videoTitle: 'Video Name',
    };
  }

  setQuizApiReset() {
    this.setNextLink('');
    this.setHasMoreResults(false);
    this.setQuizzesList([]);
  }

  async createQuiz(payload: QuizDetails): Promise<any> {
    return await this.rootStore.apiStore.quizzesApi.createQuiz(payload);
  }

  async getQuizzesBySorting() {
    this.setQuizApiReset();
    return await this.getAllQuizzes();
  }

  async getAllQuizzes(): Promise<any> {
    const params: TrainingQuizzesFilters = {
      ...this._quizFilters,
      nextLink: this.NextLink,
      sortBy: this.SortBy,
      sortDirection: this.SortDirection,
    };

    const response = await this.rootStore.apiStore.quizzesApi.getAllQuizzes(
      params,
    );
    if (response.isOk()) {
      const { response: results, count, nextLink } = response.value;
      const fullResult = this._quizzesList.concat(results);

      this.setQuizzesList(fullResult);
      this.setCount(count);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async getQuizDetails(quizId: string): Promise<any> {
    const response = await this.rootStore.apiStore.quizzesApi.getQuizDetails(
      quizId,
    );
    return response;
  }

  async updateQuiz(quizId: string, payload: QuizDetails): Promise<any> {
    return await this.rootStore.apiStore.quizzesApi.updateQuiz(quizId, payload);
  }

  async archiveQuiz(quizId: string): Promise<any> {
    return await this.rootStore.apiStore.quizzesApi.archiveQuiz(quizId);
  }
}
