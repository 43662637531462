import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import React from 'react';

const SortingIcon: React.FC<{
  store: any;
  sortBY: (src: any) => void;
  sortByName: string;
}> = ({ store, sortBY, sortByName }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <ArrowDropUp
        fontSize="small"
        style={{
          color:
            store.SortBy === sortByName && store.SortDirection == 'desc'
              ? '#DEC330'
              : '#D8D8D8',
          cursor: 'pointer',
          marginBottom: '-14px',
        }}
        onClick={() => sortBY(sortByName)}
      />
      <ArrowDropDown
        fontSize="small"
        style={{
          color:
            store.SortBy == sortByName && store.SortDirection == 'asc'
              ? '#DEC330'
              : '#D8D8D8',
          cursor: 'pointer',
        }}
        onClick={() => sortBY(sortByName)}
      />
    </div>
  );
};

export default observer(SortingIcon);
