import { Grid, Typography } from '@mui/material';
import React from 'react';

const ComingSoon: React.FC = () => {
  return (
    <Grid container justifyContent={'center'} mt="40px">
      <Grid item md={1}>
        <Typography
          component={'span'}
          color={'#979598'}
          fontSize={'14px'}
          fontFamily={'FiraSans-Medium'}
          fontStyle={'italic'}
          fontWeight={500}>
          Coming soon
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ComingSoon;
