import { CircularProgress } from '@mui/material';
import { ClassNameMap, makeStyles } from '@mui/styles';
import React from 'react';

type LoaderProps = {
  className?: string;
  classes?: ClassNameMap;
  color?: 'inherit' | 'primary' | 'secondary';
  isDisableShrink?: boolean;
  size?: number | string;
  thickness?: number;
  value?: number;
  variant?: 'determinate' | 'indeterminate';
  style?: any;
};

const Loader = (props: LoaderProps): React.ReactElement => {
  const {
    className,
    classes,
    isDisableShrink,
    size,
    thickness,
    value,
    variant,
    style,
  } = props;
  const styleClasses = useStyles();

  return (
    <div className={[styleClasses.loader, className].join(' ')} style={style}>
      <CircularProgress
        classes={classes}
        // color={color}
        disableShrink={isDisableShrink}
        size={size}
        thickness={thickness}
        value={value}
        variant={variant}
        sx={{ color: '#DEC330' }}
      />
    </div>
  );
};

const useStyles = makeStyles({
  loader: {
    display: 'flex',
    margin: '0 auto',
    width: 'fit-content',
  },
});

export default Loader;
