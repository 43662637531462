import MultiSelectUnstyled, {
  MultiSelectUnstyledProps,
} from '@mui/base/MultiSelectUnstyled';
import OptionUnstyled, {
  optionUnstyledClasses,
} from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import { styled } from '@mui/system';
import * as React from 'react';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 320px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 320px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `,
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

export const CustomMultiSelect = React.forwardRef(function CustomMultiSelect(
  props: MultiSelectUnstyledProps<number>,
  ref: React.ForwardedRef<any>,
) {
  const components: MultiSelectUnstyledProps<number>['components'] = {
    Listbox: StyledListbox,
    Popper: StyledPopper,
    Root: StyledButton,
    ...props.components,
  };

  return <MultiSelectUnstyled {...props} ref={ref} components={components} />;
});

type MultiSelectDropdownProps = {
  options: any[];
  isAutoFocus?: boolean;
  isDefaultListboxOpen?: boolean;
  defaultValue?: any[];
  isDisabled?: boolean;
  onChange: (src: any) => void;
  value: any[];
  ref?: any;
};

export const MultiSelectDropdown = (
  props: MultiSelectDropdownProps,
): React.ReactElement => {
  const {
    options = [],
    isAutoFocus = false,
    isDefaultListboxOpen = false,
    defaultValue = [],
    isDisabled = false,
    onChange,
    value = [],
    ref = null,
  } = props;

  return (
    <CustomMultiSelect
      defaultValue={defaultValue}
      value={value}
      disabled={isDisabled}
      onChange={(event: any) => onChange(event)}
      defaultListboxOpen={isDefaultListboxOpen}
      autoFocus={isAutoFocus}
      ref={ref}>
      {options.map((list: any, index: number) => {
        return (
          <StyledOption key={index} value={list.value}>
            {list.label}
          </StyledOption>
        );
      })}
    </CustomMultiSelect>
  );
};

// export default function UnstyledSelectsMultiple() {
//   return (
//     <CustomMultiSelect defaultValue={[10, 20]}>
//       <StyledOption value={10}>Ten</StyledOption>
//       <StyledOption value={20}>Twenty</StyledOption>
//       <StyledOption value={30}>Thirty</StyledOption>
//       <StyledOption value={40}>Forty</StyledOption>
//       <StyledOption value={50}>Fifty</StyledOption>
//     </CustomMultiSelect>
//   );
// }
