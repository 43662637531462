import { makeAutoObservable } from 'mobx';

import { API_LIMIT } from '../config';
import { CompanySearchFilters, CompanySearchFormInputs } from '../types';
import { RootStore } from '.';

export class CompanyStore {
  private _limit = API_LIMIT;
  private _companyList: Array<any> = [];
  private _companyUsersList: Array<any> = [];
  private _showLoader = false;
  private _showUserLoader = false;
  private _hasMoreResults = false;
  private _hasMoreCompanyUsers = false;
  private _nextLink = '';
  private _nextLinkForCompanyUsers = '';
  private _filter: CompanySearchFilters = {
    dotNumber: '',
    limit: API_LIMIT,
    name: '',
    nextLink: this.NextLink,
    simplexId: '',
  };
  private _sortBy = 'name';
  private _sortDirection = 'asc';

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setHasMoreCompanyUsers(hasMore: boolean) {
    this._hasMoreCompanyUsers = hasMore;
  }

  setFilters(data: CompanySearchFilters) {
    this._filter = data;
  }

  setCompanyList(data: Array<any>) {
    this._companyList = data;
  }

  setCompanyUsersList(data: Array<any>) {
    this._companyUsersList = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setShowUserLoader(value: boolean) {
    this._showUserLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setNextLinkForCompanyUsers(link: string) {
    this._nextLinkForCompanyUsers = link;
  }

  setCompanyApiReset() {
    this.setNextLink('');
    this.setHasMoreResults(false);
    this.setCompanyList([]);
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get ShowUserLoader() {
    return this._showUserLoader;
  }

  get Filters() {
    return this._filter;
  }

  get CompanyList() {
    return this._companyList;
  }

  get CompanyUsersList() {
    return this._companyUsersList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get NextLinkForCompanyUsers() {
    return this._nextLinkForCompanyUsers;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get hasMoreCompanyUsers() {
    return this._hasMoreCompanyUsers;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  async fetchCompaniesBySorting() {
    this.setCompanyApiReset();
    return await this.fetchCompanies();
  }

  resetCompanyUsers = () => {
    this.setCompanyUsersList([]);
    this.setNextLinkForCompanyUsers('');
  };

  getUsersByCompanyId = (companyId = '') =>
    this.rootStore.apiStore.companyApi.getUsersByCompanyId(companyId);

  sendEmailToUsers = (companyId = '') =>
    this.rootStore.apiStore.companyApi.sendEmailToUsersByCompanyId(companyId);

  async fetchCompanies() {
    const params: any = {};
    this.Filters.name != '' ? (params['name'] = this.Filters.name) : null;
    this.Filters.simplexId != ''
      ? (params['simplexId'] = this.Filters.simplexId)
      : null;
    this.Filters.dotNumber != ''
      ? (params['dotNumber'] = this.Filters.dotNumber)
      : null;

    params['limit'] = this._limit;
    params['nextLink'] = this._nextLink;
    params['sortBy'] = this.SortBy;
    params['sortDirection'] = this.SortDirection;

    const response = await this.rootStore.apiStore.companyApi.getCompanyList(
      params,
    );
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._companyList.concat(results);

      this.setCompanyList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchCompaniesBySearchBased(searchVal: CompanySearchFormInputs) {
    const params: any = {
      ...searchVal,
    };

    params['limit'] = this._limit;
    params['nextLink'] = this._nextLink;
    params['sortBy'] = this.SortBy;
    params['sortDirection'] = this.SortDirection;

    const response =
      await this.rootStore.apiStore.companyApi.getCompaniesBySearch(params);
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      this.setCompanyList(results);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchCompanyUsers(companyId: string) {
    const params: any = {
      companyId: companyId,
    };
    if (this._nextLinkForCompanyUsers) {
      params['nextLink'] = this._nextLinkForCompanyUsers;
    } else {
      this.setCompanyUsersList([]);
    }
    const response =
      await this.rootStore.apiStore.companyApi.getCompanyUsersList(params);
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._companyUsersList.concat(results);

      this.setCompanyUsersList(fullResult);
      this.setNextLinkForCompanyUsers(nextLink);
      this.setHasMoreCompanyUsers(nextLink ? true : false);
    }
    return response;
  }
}
