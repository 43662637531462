import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { CompanyOrDriverDTO } from '../../types';
import { RootStore } from '..';

export class CompanyApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getCompanyList = (params: {
    limit: number;
    nextLink: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      GetListBySearch: { Endpoint, Method },
    } = ApiRoutes.Company;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<CompanyOrDriverDTO>({
      method: Method,
      params: { ...restParams },
      url: Endpoint,
    });
  };

  sendEmailToUsersByCompanyId = (companyId: string) => {
    const {
      SendEmail: { Endpoint, Method },
    } = ApiRoutes.Company;
    return this.rootStore.apiStore.call({
      method: Method,
      params: { companyId },
      url: Endpoint,
    });
  };

  getUsersByCompanyId = (companyId: string) => {
    const {
      GetUsers: { Endpoint, Method },
    } = ApiRoutes.Company;
    return this.rootStore.apiStore.call({
      method: Method,
      params: { companyId },
      url: Endpoint,
    });
  };

  getCompanyUsersList = (params: { companyId: string }) => {
    const {
      GetUserList: { Endpoint, Method },
    } = ApiRoutes.Company;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<CompanyOrDriverDTO>({
      method: Method,
      params: { ...restParams },
      url: Endpoint,
    });
  };

  getCompaniesBySearch = (params: {
    limit: number;
    nextLink: string;
    name: string;
    simplexId: string;
    dotNumber: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      GetListBySearch: { Endpoint, Method },
    } = ApiRoutes.Company;

    const restParams = this.objClean({ ...params });

    // This is static response once you have the api remove this code and uncomment the commented code
    return this.rootStore.apiStore.call<CompanyOrDriverDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  objClean(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  }
}
