import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  borderBottom: {
    borderBottom: '1px solid #403849',
    marginBottom: '1rem !important',
  },
  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  filterBtn: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    border: 'unset !important',
    borderRadius: '4px',
    color: '#fff !important',
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    justifyContent: 'center',
    textTransform: 'uppercase',
    width: '100%',
  },
  filterIcon: {
    width: '18px',
  },
  filterLabel: {
    color: '#FFFFFF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '15px',
    fontWeight: '500',
    height: '22px',
    letterSpacing: '1px',
    lineHeight: '22px',
    textTransform: 'capitalize',
  },
  inputF: {
    '& .MuiFilledInput-root': {
      color: '#FFFFFF !important',
      fontFamily: 'FiraSans-Medium !important',
    },
    '& Mui-error': {
      height: 0,
      paddingLeft: '5px',
    },
    '& input': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      padding: '11px',
    },

    '&::placeholder': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      opacity: '0.43',
    },
    '&:focusVisible': {
      outline: 'unset !important',
    },
    background: 'unset !important',
    border: 'unset !important',
    color: '#ffff !important',
    width: '100%',
  },
  optionsClassName: {
    // backgroundColor: theme.palette.common.white + '!important',
    backgroundColor: '#241A2E !important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  resetF: {
    '& .Mui-disabled': {
      border: 'unset !important',
    },
    alignItems: 'center',
    backgroundColor: 'unset !important',
    color: '#777777 !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Bold',
    fontSize: '12px !important',
    height: '48px !important',
    justifyContent: 'center !important',
    letterSpacing: '0.8px',
    textDecoration: 'underline solid #777777',
  },
  searcBox: {
    '& .date-picker': {
      '& .MuiInputBase-input': {
        color: '#FFFFFF',
      },
    },
    alignItems: 'center',
    backgroundColor: '#403849',
    borderRadius: '7px',
    display: 'flex',
    height: '43px',
    marginBottom: '1rem !important',
  },
  selectClassName: {
    borderRadius: '7px !important',
    color: '#FFFFFF !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
  },
  submited: {
    boxShadow: 'unset',
  },
}));

export default useStyles;
