import './TopAppBar.scss';

import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import filterIcon from '../../assets/images/filter.svg';
import { API_LIMIT } from '../../config';
import { useCustomStore } from '../../hooks';
import {
  CompanySearchFilters,
  DriverSearchFilters,
  NotificationSearchParams,
  SearchPages,
  UsersSearchFilters,
} from '../../types';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import AdminDialog from '../AdminDialog/AdminDialog';
import SearchFilter from '../SearchFilter/SearchFilter';
import search from './../../assets/images/h-search.svg';
import useStyles from './TopAppBarStyles';

const TopAppBar: React.FC = () => {
  const classes = useStyles();
  const {
    authStore,
    companyStore,
    driverStore,
    notificationStore,
    adminUsersStore,
  } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchPage, setSearchPage] = useState('');
  const [trainingTab, setTrainingTab] = useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();

  const [filter, setFilters] = useState<CompanySearchFilters>({
    dotNumber: '',
    limit: API_LIMIT,
    name: '',
    nextLink: '',
    simplexId: '',
  });

  const [driverFilter, setDriverFilters] = useState<DriverSearchFilters>({
    companyName: '',
    driverType: '',
    limit: API_LIMIT,
    name: '',
    nextLink: '',
    simplexId: '',
  });

  const [notificationFilter, setNotificationFilter] =
    useState<NotificationSearchParams>({
      description: '',
      // nextLink: '',
      status: '',
      title: '',
    });

  const [adminFilter, setAdminFilters] = useState<UsersSearchFilters>({
    limit: API_LIMIT,
    name: '',
    nextLink: '',
  });

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (searchPage === SearchPages.Drivers) {
      setDriverFilters((pre) => ({
        ...pre,
        ...driverStore.Filters,
      }));
    }
    if (searchPage === SearchPages.Users) {
      setAdminFilters((pre) => ({
        ...pre,
        ...adminUsersStore.Filters,
      }));
    }
    if (searchPage === SearchPages.Notifications) {
      setNotificationFilter((pre: any) => ({
        ...pre,
        ...notificationStore.getNotificationAllSearchParamDetails,
      }));
    }
    if (searchPage === SearchPages.Companies) {
      setFilters((pre) => ({
        ...pre,
        ...companyStore.Filters,
      }));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDriversListsWithStatus = useCallback(
    async (status: string) => {
      if (searchPage === SearchPages.Drivers) {
        driverStore.setStatus(status);
        driverStore.setDriverApiReset();
        driverStore.setShowLoader(true);
        const getDriversResp = await driverStore.fetchDrivers();

        if (getDriversResp.isErr()) {
          enqueueSnackbar(
            String(getDriversResp.error.message),
            SnackBarConfig('e'),
          );
        }
        driverStore.setShowLoader(false);
      }

      if (searchPage === SearchPages.Users) {
        adminUsersStore.setStatus(status);
        adminUsersStore.setAdminApiReset();
        adminUsersStore.setShowLoader(true);
        const getAdminsResp = await adminUsersStore.fetchAdmins();

        if (getAdminsResp.isErr()) {
          enqueueSnackbar(
            String(getAdminsResp.error.message),
            SnackBarConfig('e'),
          );
        }
        adminUsersStore.setShowLoader(false);
      }
    },
    [adminUsersStore, driverStore, enqueueSnackbar, searchPage],
  );

  const gotToTrainingRoute = (name: any) => {
    switch (name.toLowerCase()) {
      case 'trainings':
        navigate('/training/trainings');
        break;
      case 'quizzes':
        navigate('/training/quizzes');
        break;
      case 'mapping':
        navigate('/training/mapping');
        break;
      default:
        navigate('/training/trainings');
    }
  };
  const setSearchIcons = (path: string) => {
    switch (path.toLowerCase()) {
      case 'companies':
        setSearchPage(SearchPages.Companies);
        break;
      case 'drivers':
        setSearchPage(SearchPages.Drivers);
        break;
      case 'notifications':
        setSearchPage(SearchPages.Notifications);
        break;
      case 'settings':
        setSearchPage(SearchPages.Settings);
        break;
      case 'users':
        setSearchPage(SearchPages.Users);
        break;
      case 'profile':
        setSearchPage(SearchPages.Profiles);
        break;
      case 'training/trainings':
        setTrainingTab('trainings');
        setSearchPage(SearchPages.Training);
        break;
      case 'training/quizzes':
        setTrainingTab('quizzes');
        setSearchPage(SearchPages.Training);
        break;
      case 'training/mapping':
        setTrainingTab('mapping');
        setSearchPage(SearchPages.Training);
        break;
      default:
        setSearchPage(SearchPages.Companies);
        break;
    }
    if (
      path.toLowerCase().includes('training/trainings/add') ||
      path.toLowerCase().includes('training/trainings/edit') ||
      path.toLowerCase().includes('training/quizzes/add') ||
      path.toLowerCase().includes('training/quizzes/edit') ||
      path.toLowerCase().includes('training/quizzes/view')
    ) {
      setTrainingTab('trainingAddEdit');
    }
  };

  useEffect(() => {
    setSearchIcons(pathname.substring(1));
    return () => {
      adminUsersStore.setStatus('Users');
    };
  }, [adminUsersStore, pathname]);

  const filterDesider = () => {
    if (searchPage === SearchPages.Drivers) {
      return driverFilter;
    }

    if (searchPage === SearchPages.Users) {
      return adminFilter;
    }

    if (searchPage === SearchPages.Notifications) {
      return notificationFilter;
    }

    return filter;
  };

  const filterSetFnDesider = () => {
    if (searchPage === SearchPages.Drivers) {
      return setDriverFilters;
    }

    if (searchPage === SearchPages.Users) {
      return setAdminFilters;
    }

    if (searchPage === SearchPages.Notifications) {
      return setNotificationFilter;
    }

    return setFilters;
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const goToTrainingAddScreens = (addRoute: string) => {
    switch (addRoute) {
      case 'trainings':
        navigate('/training/trainings/add');
        break;
      case 'quizzes':
        navigate('/training/quizzes/add');
        break;
      default:
        navigate('/training/trainings');
    }
  };

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.hTitle}>
          {pathname.split('/')[1]}
        </Typography>
        {['training'].includes(searchPage) && (
          <Typography component={'div'} className={classes.dflexJA}>
            <Typography
              className={
                trainingTab === 'trainings'
                  ? classes.AtrainingTabs
                  : classes.NtrainingTabs
              }
              onClick={() => gotToTrainingRoute('trainings')}>
              {'TRAININGS'}
            </Typography>
            <Typography
              className={
                trainingTab === 'quizzes'
                  ? classes.AtrainingTabs
                  : classes.NtrainingTabs
              }
              onClick={() => gotToTrainingRoute('quizzes')}>
              {'QUIZZES'}
            </Typography>
            <Typography
              className={
                trainingTab === 'mapping'
                  ? classes.AtrainingTabs
                  : classes.NtrainingTabs
              }
              onClick={() => gotToTrainingRoute('mapping')}>
              {'MAPPING'}
            </Typography>
          </Typography>
        )}
        {['driver-search', 'user-search'].includes(searchPage) && (
          <Typography component={'div'} className={classes.dflexJA}>
            <Typography
              className={
                searchPage === SearchPages.Drivers &&
                driverStore.Status === 'Active'
                  ? classes.Adriver
                  : searchPage === SearchPages.Users &&
                    adminUsersStore.Status === 'Users'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              onClick={() =>
                getDriversListsWithStatus(
                  searchPage === SearchPages.Users ? 'Users' : 'Active',
                )
              }>
              {searchPage === SearchPages.Users ? 'Users' : 'Active'}
            </Typography>
            <Typography
              className={
                searchPage === SearchPages.Drivers &&
                driverStore.Status === 'Terminated'
                  ? classes.Adriver
                  : searchPage === SearchPages.Users &&
                    adminUsersStore.Status === 'Roles'
                  ? classes.Adriver
                  : classes.Ndriver
              }
              style={{ marginLeft: '0.5rem', padding: '0 1rem' }}
              onClick={() =>
                getDriversListsWithStatus(
                  searchPage === SearchPages.Users ? 'Roles' : 'Terminated',
                )
              }>
              {searchPage === SearchPages.Users ? 'Roles' : 'Terminated'}
            </Typography>
          </Typography>
        )}

        <Typography component={'div'} gutterBottom className={classes.dflexA}>
          {searchPage !== 'setting-search' &&
            searchPage !== 'profile-search' &&
            adminUsersStore.Status !== 'Roles' &&
            !['trainingAddEdit', 'mapping'].includes(trainingTab) && (
              <Typography
                className={classes.hIcon}
                aria-controls={open ? searchPage : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => handleClick(event)}>
                <img className={classes.hIcon} src={search} alt="search" />
              </Typography>
            )}
          {/* {searchPage === 'notification-search' && (
          <Typography
            className={classes.fIcon}
            aria-controls={open ? searchPage : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <img className={classes.ficonz} src={filterIcon} alt="filter" />
          </Typography>
        )} */}
          {authStore.pageAccess &&
            authStore.pageAccess.admins &&
            searchPage === SearchPages.Training &&
            trainingTab === 'trainings' && (
              <Typography component={'div'} style={{ padding: '1.5rem' }}>
                <Typography
                  component={'div'}
                  className={classes.createBtn}
                  onClick={() => goToTrainingAddScreens('trainings')}>
                  {'Add Training'}
                </Typography>
              </Typography>
            )}
          {authStore.pageAccess &&
            authStore.pageAccess.admins &&
            searchPage === SearchPages.Training &&
            trainingTab === 'quizzes' && (
              <Typography component={'div'} style={{ padding: '1.5rem' }}>
                <Typography
                  component={'div'}
                  className={classes.createBtn}
                  onClick={() => goToTrainingAddScreens('quizzes')}>
                  {'Add Quiz'}
                </Typography>
              </Typography>
            )}
          {authStore.pageAccess &&
            authStore.pageAccess.admins &&
            searchPage === SearchPages.Training &&
            trainingTab === 'mapping' && (
              <Typography component={'div'} style={{ padding: '1.5rem' }}>
                <Typography
                  component={'div'}
                  className={classes.createBtn}
                  style={{ visibility: 'hidden' }}>
                  {'Add Mapping'}
                </Typography>
              </Typography>
            )}
          {searchPage !== 'setting-search' &&
            searchPage !== 'profile-search' &&
            adminUsersStore.Status !== 'Roles' &&
            trainingTab === 'mapping' && (
              <Typography
                className={classes.hIcon}
                aria-controls={open ? searchPage : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => handleClick(event)}>
                <img className={classes.hIcon} src={search} alt="search" />
              </Typography>
            )}
          {authStore.pageAccess &&
            authStore.pageAccess.admins &&
            searchPage === SearchPages.Users && (
              <Typography component={'div'} style={{ padding: '1.5rem' }}>
                <Typography
                  component={'div'}
                  className={classes.createBtn}
                  onClick={() => setIsOpen(true)}>
                  {adminUsersStore.Status === 'Users' ? 'Add User' : 'Add Role'}
                </Typography>
              </Typography>
            )}
          <SearchFilter
            {...{
              anchorEl,
              filter: filterDesider(),
              handleClose,
              open,
              searchPage,
              setFilters: filterSetFnDesider(),
            }}
          />
        </Typography>
      </div>
      <Grid>
        {isOpen && <AdminDialog isOpen={isOpen} closeDialog={closeDialog} />}
      </Grid>
    </>
  );
};

export default observer(TopAppBar);
