import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Grid,
  IconButton,
  Popover,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthStore } from '../../../store/auth';
import { DriverStore } from '../../../store/driver';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
// import { GransPortalAccessDialog } from './DriverGrantPortalAccessDialog';
import useStyles from './driverStyle';

type ListPropsTypes = {
  driverLists: any;
  authStore: AuthStore;
  driverStore: DriverStore;
  enqueueSnackbar: any;
  Status: any;
};

const DriverLists = (props: ListPropsTypes): React.ReactElement => {
  const { driverLists, authStore, driverStore, enqueueSnackbar, Status } =
    props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  // const [dialog, setDialog] = useState<{ open: boolean; driver: any }>({
  //   driver: null,
  //   open: false,
  // });

  const handleDriverOption = (event: any, contactId: string) => {
    const obj: any = {};

    if (tooltipIsOpen[contactId]) {
      obj[contactId] = false;
      setAnchorEl(null);
    } else {
      obj[contactId] = true;
      setAnchorEl(event.currentTarget);
    }

    setTooltipIsOpen(obj);
  };

  const sendPortalInvite = async (contactId: string) => {
    const inviteResponse = await driverStore.sendPortalInvite(contactId);
    if (inviteResponse.isErr()) {
      enqueueSnackbar(
        String(inviteResponse.error.message),
        SnackBarConfig('e'),
      );
    }
    if (inviteResponse.isOk()) {
      enqueueSnackbar(
        String(inviteResponse.value.message),
        SnackBarConfig('e'),
      );
    }
  };

  const loginAs = async (driver: any) => {
    const bodyData = {
      contactId: driver.contactId,
      name: driver.name,
    };
    const tokenResponse = await authStore.impersonateClientToken(bodyData);
    if (tokenResponse.isErr()) {
      enqueueSnackbar(String(tokenResponse.error.message), SnackBarConfig('e'));
    }
  };

  // const closeDialog = () => {
  //   setDialog((pre) => ({
  //     ...pre,
  //     driver: null,
  //     open: false,
  //   }));
  // };

  return (
    <React.Fragment>
      {driverLists.map((driver: any, index: number) => (
        <TableRow
          key={index}
          className={classes.rowHeight}
          style={{ minHeight: '80px' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                noWrap
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Semibold',
                  fontSize: '16px',
                  fontWeight: 600,
                  height: '23px',
                  letterSpacing: 0,
                  lineHeight: '23px',
                }}>
                {driver.name || '-'}
              </Typography>
              <Typography
                noWrap
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '16px',
                  height: '19px',
                  letterSpacing: 0,
                  lineHeight: '19px',
                }}>
                {driver.simplexId || '-'}
              </Typography>
            </Grid>
          </TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                noWrap
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Semibold',
                  fontSize: '16px',
                  fontWeight: 600,
                  height: '23px',
                  letterSpacing: 0,
                  lineHeight: '23px',
                }}>
                {driver.companyName || '-'}
              </Typography>
              <Typography
                noWrap
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '16px',
                  height: '19px',
                  letterSpacing: 0,
                  lineHeight: '19px',
                }}>
                {driver.companySimplexId || '-'}
              </Typography>
            </Grid>
          </TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                noWrap
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '16px',
                  height: '19px',
                  letterSpacing: 0,
                  lineHeight: '19px',
                }}>
                {driver.driverType || '-'}
              </Typography>
            </Grid>
          </TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                noWrap
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '16px',
                  height: '19px',
                  letterSpacing: 0,
                  lineHeight: '19px',
                }}>
                {driver.hireDate
                  ? moment(driver.hireDate).format('M/DD/YYYY')
                  : '-'}
              </Typography>
            </Grid>
          </TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                noWrap
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '16px',
                  height: '19px',
                  letterSpacing: 0,
                  lineHeight: '19px',
                }}>
                {driver.terminationDate
                  ? moment(driver.terminationDate).format('M/DD/YYYY')
                  : '-'}
              </Typography>
            </Grid>
          </TableCell>
          {Status !== 'Terminated' && (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
              <Grid item>
                <IconButton
                  onClick={(event) =>
                    handleDriverOption(event, driver.contactId)
                  }>
                  <MoreVertIcon />
                </IconButton>
                <Popover
                  className="driver-popclass"
                  open={tooltipIsOpen[driver.contactId]}
                  onClose={(event) =>
                    handleDriverOption(event, driver.contactId)
                  }
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                  }}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}>
                  <Box
                    sx={{
                      backgroundColor: '#241A2E',
                      borderRadius: '8px',
                      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
                      padding: '12px 0px',
                      // height: '100.9px',
                      position: 'relative',
                      width: '207px',
                    }}>
                    <div className="arrow">.</div>
                    <Typography
                      className="n-pop-btn"
                      style={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                      }}
                      onClick={() => {
                        const contactIdString: string = driver.contactId || '';
                        navigate('/driver-profile/' + contactIdString);
                        // enqueueSnackbar('Coming Soon', SnackBarConfig('i'));
                      }}>
                      View Profile
                    </Typography>
                    <Typography
                      className="n-pop-btn"
                      onClick={(e) => {
                        window.open('mailto:no-reply@example.com');
                        e.preventDefault();
                      }}>
                      Email
                    </Typography>
                    {!driver.hasPortalAccess && (
                      <Typography
                        className="n-pop-btn"
                        // onClick={() => {
                        //   setTooltipIsOpen({});
                        //   setDialog((pre) => ({
                        //     ...pre,
                        //     driver,
                        //     open: true,
                        //   }));
                        // }}>
                        onClick={() => sendPortalInvite(driver.contactId)}>
                        Send Portal Invite
                      </Typography>
                    )}
                    {authStore.pageAccess &&
                    authStore.pageAccess?.impersonateDrivers ? (
                      <Typography
                        className="n-pop-btn"
                        onClick={() => loginAs(driver)}>
                        Log In As Driver
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                </Popover>
              </Grid>
            </TableCell>
          )}
        </TableRow>
      ))}
      {/* {dialog.open ? (
        <GransPortalAccessDialog
          {...{ closeDialog, driverInfo: dialog.driver }}
        />
      ) : null} */}
    </React.Fragment>
  );
};

export default observer(DriverLists);
