class NavRoutes {
  static PUBLIC = {
    CREATEPASSWORD: 'create-password',
    FORGOTPASSWORD: 'forgot-password',
    LOGIN: 'login',
    RESETPASSWORD: 'reset-password',
  };

  static PRIVATE = {
    COMPANIES: 'companies',
    DRIVERPROFILE: 'driver-profile',
    DRIVERS: 'drivers',
    HOME: 'home',
    LEFT_NAV: {
      PROFILE: 'profile',
      SERVICEREQUEST: 'service-request',
    },
    MAPPING: 'mapping',
    NOTIFICATIONS: 'notifications',
    SERVICEREQUEST: {
      UCR: 'ucr',
    },
    SETTINGS: 'settings',
    TRAINING: {
      ADD: 'add',
      EDIT: 'edit',
      MAPPING: 'mapping',
      QUIZZES: 'quizzes',
      TRAINING: 'training',
      TRAININGS: 'trainings',
      VIEW: 'view',
    },
    USERS: 'users',
  };
}
export default NavRoutes;
