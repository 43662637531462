import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardBox: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    height: '296px',
    width: '782px',
  },
  editBtn: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '16px !important',
    textAlign: 'right',
    textDecoration: 'underline solid #0061ff',
  },
  infoTitle: {
    color: '#241A2E !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },
  infodis: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
  },
  labels: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  logoutLink: {
    color: '#A70D0D !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-SemiBold !important',
    fontSize: '16px !important',
    fontWeight: '600',
    letterSpacing: 0,
    lineHeight: '23px',
    paddingTop: '.5rem',
    textDecoration: 'underline solid #A70D0D !important',
  },
  profileTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '20px !important',
  },
  saveBtn: {
    background: 'none !important',
    border: 'none',
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '16px !important',
    textAlign: 'right',
    textDecoration: 'underline solid #0061ff',
  },
  selectClassName: {
    backgroundColor: '#F8F8FA !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
    textTransform: 'capitalize',
    // opacity: '0.43 !important',
  },
  submited: {
    boxShadow: 'unset',
  },
}));

export default useStyles;
