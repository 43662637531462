import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { QuizDetails, TrainingQuizzesFilters } from '../../types';
import { objectClean } from '../../utils/helper';
import { RootStore } from '..';

export class QuizzesApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  createQuiz = (payload: QuizDetails) => {
    const {
      CreateQuizzes: { Endpoint, Method },
    } = ApiRoutes.Quizzes;
    return this.rootStore.apiStore.call<any>({
      data: payload,
      method: Method,
      url: Endpoint,
    });
  };

  updateQuiz = (quizId: string, payload: QuizDetails) => {
    const {
      UpdateQuizDetails: { Endpoint, Method },
    } = ApiRoutes.Quizzes;
    return this.rootStore.apiStore.call<any>({
      data: payload,
      method: Method,
      url: Endpoint.concat(quizId),
    });
  };

  getAllQuizzes = (filters: TrainingQuizzesFilters) => {
    const {
      GetAllQuizzes: { Endpoint, Method },
    } = ApiRoutes.Quizzes;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean({ ...filters }),
      },
      url: Endpoint,
    });
  };

  getQuizDetails = (quizId: string) => {
    const {
      GetQuizDetails: { Endpoint, Method },
    } = ApiRoutes.Quizzes;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint.concat(quizId),
    });
  };

  archiveQuiz = (quizId: string) => {
    const {
      ArchiveQuizzes: { Endpoint, Method },
    } = ApiRoutes.Quizzes;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint.concat(quizId),
    });
  };
}
