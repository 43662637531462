import './style/style.scss';

import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import Loader from './components/Loader/Loader';
import PrivateRoute from './config/routes/PrivateRoute';
import PublicRoute from './config/routes/PublicRoute';
import { useCustomStore } from './hooks';

const App: React.FC = () => {
  const { authStore } = useCustomStore();
  const [isReady, setReady] = useState<boolean | null>(false);
  const [oneTimeCall, setOneTimeCall] = useState<boolean | null>(false);

  const handleAppEntry = useCallback(async () => {
    await authStore.fetchTokens();
    setReady(true);
  }, [authStore]);
  const portalAccess = useCallback(async () => {
    setReady(false);
    await authStore.pagesAccess();
    setReady(true);
    setOneTimeCall(false);
  }, [authStore]);

  useEffect(() => {
    handleAppEntry();
  }, [handleAppEntry]);
  useEffect(() => {
    if (authStore.tokens && !oneTimeCall && !authStore.pageAccess) {
      setOneTimeCall(true);
      portalAccess();
    }
  }, [authStore.tokens, authStore.pageAccess, portalAccess, oneTimeCall]);
  useEffect(() => {
    window.onload = () => {
      const documentiframe: any = document.body.querySelector('IFRAME');
      if (documentiframe) {
        documentiframe.style.display = 'none';
      }
    };
  }, []);

  if (isReady) {
    const hasTokenAndUserId = !!authStore.tokens;
    return hasTokenAndUserId ? <PrivateRoute /> : <PublicRoute />;
  }
  return (
    <Typography
      component={'div'}
      sx={{
        left: '50%',
        position: 'fixed',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
      <Loader {...{ size: 80 }} />
    </Typography>
  );
};

export default observer(App);
