import ClearIcon from '@mui/icons-material/Clear';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useCustomStore } from '../../../../hooks';
import useStyles from './VideoListStyle';

type ReasonMappingListProps = {
  hasDocument: boolean;
};

const ReasonMappingList = (
  props: ReasonMappingListProps,
): React.ReactElement => {
  const classes = useStyles();
  const { videoStore } = useCustomStore();

  const { hasDocument } = props;

  const reasonMappingsHeader: any = [{ label: 'Reason Name', name: 'name' }];

  return (
    <Grid
      className={classes.scroll}
      container
      spacing={2}
      style={{ padding: '0', paddingLeft: '10px' }}
      justifyContent="space-between">
      <Table
        className={classes.table}
        sx={{
          borderCollapse: 'separate !important',
          borderSpacing: '0px 5px !important',
          minWidth: 650,
        }}
        aria-label="simple table">
        <TableHead>
          <TableRow>
            {reasonMappingsHeader.map((header: any, index: number) => (
              <TableCell key={index}>
                <Grid item xs={12}>
                  <Typography
                    component={'div'}
                    noWrap
                    style={{ alignItems: 'center', display: 'flex' }}>
                    <Typography
                      component={'div'}
                      sx={{
                        color: '#979598',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '12px',
                        letterSpacing: 0,
                        lineHeight: '14px',
                      }}>
                      {header.label}
                    </Typography>
                  </Typography>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {videoStore.SelectedReasons.length > 0 ? (
            videoStore.SelectedReasons.map((reason: any, index: number) => (
              <TableRow
                key={index}
                className={classes.rowHeight}
                style={{ minHeight: '80px' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Bold',
                        fontSize: '16px',
                        fontWeight: 600,
                        height: '23px',
                        letterSpacing: 0,
                        lineHeight: '23px',
                      }}>
                      {reason.name || '-'}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Bold',
                        fontSize: '16px',
                        fontWeight: 600,
                        height: '23px',
                        letterSpacing: 0,
                        lineHeight: '23px',
                      }}
                    />
                  </Grid>
                </TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#979598',
                        cursor: 'pointer',
                        fontFamily: 'FiraSans-Light',
                        fontSize: '16px',
                        height: '19px',
                        letterSpacing: 0,
                        lineHeight: '19px',
                        textAlign: 'end',
                      }}
                      onClick={() => {
                        videoStore.removeSelectedReason(reason.id);
                      }}>
                      {<ClearIcon />}
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              className={classes.rowHeight}
              style={{ minHeight: '80px' }}>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                align="center"
                colSpan={5}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#241A2E',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '16px',
                      height: '23px',
                      letterSpacing: 0,
                      lineHeight: '23px',
                      textAlign: 'center',
                    }}>
                    {hasDocument ? 'Document' : 'Video'} not mapped to any
                    Reason(s)
                  </Typography>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default observer(ReasonMappingList);
