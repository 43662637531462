import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { UsersDTO } from '../../types';
import { RootStore } from '..';

export class AdminUsersApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getAdminList = (
    params: {
      name: string;
      limit: number;
      nextLink: string;
      sortBy: string;
      sortDirection: string;
    },
    status: string,
  ) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Admins;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<UsersDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: status === 'Users' ? Endpoint : Endpoint.concat('/roles'),
    });
  };

  getAdminUsersList = (params: { limit: number; nextLink: string }) => {
    const {
      GetAdminUsersList: { Endpoint, Method },
    } = ApiRoutes.Admins;

    return this.rootStore.apiStore.call<UsersDTO>({
      method: Method,
      params,
      url: Endpoint,
    });
  };

  getAdminRolesList = (params: { limit: number; nextLink: string }) => {
    const {
      GetAdminRolesList: { Endpoint, Method },
    } = ApiRoutes.Admins;

    return this.rootStore.apiStore.call<UsersDTO>({
      method: Method,
      params,
      url: Endpoint,
    });
  };

  getAdminsBySearch = (params: {
    limit: number;
    nextLink: string;
    name: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Admins;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<UsersDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  getAdminsUsersBySearch = (params: {
    limit: number;
    nextLink: string;
    firstName: string;
    lastName: string;
    email: string;
  }) => {
    const {
      GetAdminUsersList: { Endpoint, Method },
    } = ApiRoutes.Admins;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<UsersDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  createAdminUsers = (selectedList: any) => {
    const {
      CreateAdminUsers: { Endpoint, Method },
    } = ApiRoutes.Admins;

    return this.rootStore.apiStore.call<any>({
      data: selectedList,
      method: Method,
      url: Endpoint,
    });
  };

  deleteAdmin = (id: string, status: string) => {
    const {
      DeleteAdmins: { Endpoint, Method },
    } = ApiRoutes.Admins;
    const deleteAdminsUrl: string =
      status === 'Users' ? Endpoint.concat(id) : Endpoint.concat(`role/${id}`);

    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: deleteAdminsUrl,
    });
  };

  updateAdmin = (user: any) => {
    const {
      UpdateAdmins: { Endpoint, Method },
    } = ApiRoutes.Admins;
    const editAdminUserUrl: string = Endpoint.concat(user.userId);

    return this.rootStore.apiStore.call<any>({
      data: { email: user.email, roleId: user.roleId },
      method: Method,
      url: editAdminUserUrl,
    });
  };

  createAdminRoles = (data: any) => {
    const {
      CreateAdminRoles: { Endpoint, Method },
    } = ApiRoutes.Admins;

    return this.rootStore.apiStore.call<any>({
      data: {
        addNotifications: data.addNotifications === 'true' ? true : false,
        impersonateAdmin: data.impersonateAdmin === 'true' ? true : false,
        impersonateDrivers: data.impersonateDrivers === 'true' ? true : false,
        manageAdmins: false,
        manageTrainings: data.manageTrainings === 'true' ? true : false,
        name: data.name,
      },
      method: Method,
      url: Endpoint,
    });
  };

  updateRole = (role: any, roleId: string) => {
    const {
      UpdateRole: { Endpoint, Method },
    } = ApiRoutes.Admins;
    const editAdminUserUrl: string = Endpoint.concat(roleId);

    return this.rootStore.apiStore.call<any>({
      data: {
        addNotifications: role.addNotifications === 'true' ? true : false,
        impersonateAdmin: role.impersonateAdmin === 'true' ? true : false,
        impersonateDrivers: role.impersonateDrivers === 'true' ? true : false,
        manageTrainings: role.manageTrainings === 'true' ? true : false,
        name: role.name,
        // manageAdmins: false,
      },
      method: Method,
      url: editAdminUserUrl,
    });
  };

  objClean(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  }
}
