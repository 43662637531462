import { Config } from './index';

export const baseUrl = Config.BASE_URL;

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const ApiRoutes = {
  Admins: {
    CreateAdminRoles: {
      Endpoint: '/simplex-admins/role',
      Method: HttpMethod.POST,
    },
    CreateAdminUsers: {
      Endpoint: '/simplex-admins',
      Method: HttpMethod.POST,
    },
    DeleteAdmins: {
      Endpoint: '/simplex-admins/',
      Method: HttpMethod.DELETE,
    },
    GetAdminRolesList: {
      Endpoint: '/simplex-admins/roles',
      Method: HttpMethod.GET,
    },
    GetAdminUsersList: {
      Endpoint: '/simplex-admins/users',
      Method: HttpMethod.GET,
    },
    GetList: {
      Endpoint: '/simplex-admins',
      Method: HttpMethod.GET,
    },
    UpdateAdmins: {
      Endpoint: '/simplex-admins/',
      Method: HttpMethod.PUT,
    },
    UpdateRole: {
      Endpoint: '/simplex-admins/role/',
      Method: HttpMethod.PUT,
    },
  },
  Auth: {
    Login: {
      Endpoint: '/admins/sign-in',
      Method: HttpMethod.POST,
    },
    LoginASImpersonate: {
      Endpoint: 'impersonate/client',
      Method: HttpMethod.POST,
    },
    TokenXchange: {
      Endpoint: '/admins/exchange',
      Method: HttpMethod.POST,
    },
    pageAccess: {
      Endpoint: 'admins/profile-access',
      Method: HttpMethod.GET,
    },
  },
  Company: {
    GetList: {
      Endpoint: '/companies',
      Method: HttpMethod.GET,
    },
    GetListBySearch: {
      Endpoint: '/companies/search',
      Method: HttpMethod.GET,
    },
    GetUserList: {
      Endpoint: '/companies/users',
      Method: HttpMethod.GET,
    },
    GetUsers: {
      Endpoint: '/companies/user-list',
      Method: HttpMethod.GET,
    },
    SendEmail: {
      Endpoint: '/companies/invite',
      Method: HttpMethod.GET,
    },
  },
  Driver: {
    GetDriverDetailById: {
      Endpoint: '/drivers/',
      Method: HttpMethod.GET,
    },
    GetList: {
      Endpoint: '/drivers/all',
      Method: HttpMethod.GET,
    },
    GetListBySearch: {
      Endpoint: '/drivers/all/search',
      Method: HttpMethod.GET,
    },
    GrantPortalAccess: {
      Endpoint: '/drivers/add-portal-access',
      Method: HttpMethod.POST,
    },
    SendPortalInvite: {
      Endpoint: '/drivers/send-portal-invite',
      Method: HttpMethod.GET,
    },
  },
  Notification: {
    CreateNotification: {
      Endpoint: '/notifications',
      Method: HttpMethod.POST,
    },
    DeleteNotification: {
      Endpoint: '/notifications/',
      Method: HttpMethod.DELETE,
    },
    GetList: {
      Endpoint: '/notifications',
      Method: HttpMethod.GET,
    },
    GetListBySearch: {
      Endpoint: '/notifications/search',
      Method: HttpMethod.GET,
    },
    UpdateNotification: {
      Endpoint: '/notifications/',
      Method: HttpMethod.PUT,
    },
  },
  Quizzes: {
    ArchiveQuizzes: {
      Endpoint: '/quizzes/',
      Method: HttpMethod.DELETE,
    },
    CreateQuizzes: {
      Endpoint: '/quizzes',
      Method: HttpMethod.POST,
    },
    GetAllQuizzes: {
      Endpoint: '/quizzes/all',
      Method: HttpMethod.GET,
    },
    GetQuizDetails: {
      Endpoint: '/quizzes/',
      Method: HttpMethod.GET,
    },
    UpdateQuizDetails: {
      Endpoint: '/quizzes/',
      Method: HttpMethod.PUT,
    },
  },
  States: {
    GetList: {
      Endpoint: '/options/states',
      Method: HttpMethod.GET,
    },
  },
  User: {
    ChangePassword: {
      Endpoint: '/admins/change-password',
      Method: HttpMethod.POST,
    },
    CreatePassword: {
      Endpoint: '/admins/create-password',
      Method: HttpMethod.POST,
    },
    ForgotPassword: {
      Endpoint: '/admins/forgot-password',
      Method: HttpMethod.POST,
    },
    ProfileInfo: {
      Endpoint: '/admins/profile-info',
      Method: HttpMethod.GET,
    },
    ResetPassword: {
      Endpoint: '/admins/reset-password',
      Method: HttpMethod.POST,
    },
  },
  Video: {
    ArchiveTraining: {
      Endpoint: '/trainings/',
      Method: HttpMethod.DELETE,
    },
    CreateTraining: {
      Endpoint: '/trainings',
      Method: HttpMethod.POST,
    },
    GetCertificateTypes: {
      Endpoint: '/trainings/certificate-types',
      Method: HttpMethod.GET,
    },
    GetQuizLookList: {
      Endpoint: '/quizzes/lookup',
      Method: HttpMethod.GET,
    },
    GetTraining: {
      Endpoint: '/trainings/',
      Method: HttpMethod.GET,
    },
    GetTrainingAssignmentReasons: {
      Endpoint: '/training-assignments/list-reasons',
      Method: HttpMethod.GET,
    },
    GetTrainingViolationBasicCategories: {
      Endpoint: '/options/basic',
      Method: HttpMethod.GET,
    },
    GetTrainingViolationCodes: {
      Endpoint: '/training-violations/violation-codes',
      Method: HttpMethod.GET,
    },
    GetTrainingViolationGroups: {
      Endpoint: '/training-violations/violation-groups',
      Method: HttpMethod.GET,
    },
    GetTrainingViolationMappings: {
      Endpoint: '/training-violations/violation-mappings',
      Method: HttpMethod.GET,
    },
    GetTrainings: {
      Endpoint: '/trainings/all',
      Method: HttpMethod.GET,
    },
    UpdateTraining: {
      Endpoint: '/trainings/',
      Method: HttpMethod.PUT,
    },
    UploadTrainingFile: {
      Endpoint: '/files/training-upload',
      Method: HttpMethod.POST,
    },
  },
};
