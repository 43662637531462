import NavRoutes from './routes/NavRoutes';

export const Config = {
  API_URL: `${process.env.REACT_APP_BASE_URL!}/v1`,
  APP_DECRYPT_SECRET: 'kYp3s6v9y$B&E)H@McQfThWmZq4t7w!z',
  BASE_URL: process.env.REACT_APP_BASE_URL!,
  CLIENT_PORTAL: process.env.REACT_APP_CLIENT_PORTAL_URL!,
};

export const API_LIMIT = 20;
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const DASHBOARD_DATE_FORMAT = 'MMM D';

export const PublicRoutes: any = {
  LOGIN: `/${NavRoutes.PUBLIC.LOGIN}`,
};

export const PrivateRoutes: any = {
  PROFILE: `/${NavRoutes.PRIVATE.LEFT_NAV.PROFILE}`,
  SERVICEREQUEST: {
    UCR: `/${NavRoutes.PRIVATE.LEFT_NAV.SERVICEREQUEST}/${NavRoutes.PRIVATE.SERVICEREQUEST.UCR}`,
  },
};
