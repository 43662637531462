import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  Adriver: {
    alignItems: 'center',
    borderBottom: '5px solid #DEC330',
    color: '#241A2E',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '15px',
    height: '80px',
    justifyContent: 'center',
    minWidth: '80px',
    textTransform: 'uppercase',
  },
  AtrainingTabs: {
    alignItems: 'center',
    borderBottom: '5px solid #DEC330',
    color: '#241A2E',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '15px',
    height: '80px',
    justifyContent: 'center',
    minWidth: '80px',
    textTransform: 'uppercase',
  },
  Ndriver: {
    alignItems: 'center',
    color: '#241A2E',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '15px',
    height: '80px',
    justifyContent: 'center',
    minWidth: '80px',
    textTransform: 'uppercase',
  },
  NtrainingTabs: {
    alignItems: 'center',
    color: '#241A2E',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '15px',
    height: '80px',
    justifyContent: 'center',
    minWidth: '80px',
    textTransform: 'uppercase',
  },
  borderBottom: {
    borderBottom: '1px solid #403849',
    marginBottom: '1rem !important',
  },
  createBtn: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '4px',
    color: '#FFFFFF !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '48px',
    justifyContent: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    width: '171px',
  },
  dflexA: {
    alignItems: 'center',
    display: 'flex',
  },
  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  fIcon: {
    alignItems: 'center',
    border: '2px solid #E7E7E7',
    borderRadius: '6px',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    width: '40px',
  },
  ficonz: {
    height: '23px',
    width: '28px',
  },
  hIcon: {
    cursor: 'pointer',
    display: 'flex',
    paddingRight: '0.5rem',
  },
  hTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '20px !important',
    fontWeight: 'bold !important',
    letterSpacing: 0,
    lineHeight: '29px !important',
    textTransform: 'capitalize',
  },
  header: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ededed',
    borderTop: '5px solid #DEC330',
    display: 'flex',
    height: '80px',
    justifyContent: 'space-between',
    left: '245px',
    padding: '0 1.5rem 0 2rem',
    position: 'fixed',
    right: '0',
    top: '0',
    zIndex: '100',
  },
}));

export default useStyles;
