import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  '& .MuiGrid-root': {
    padding: '0 16px !important',
  },
  '& .MuiPaper-root': {
    '.MuiGrid-container': {
      padding: '0px 16px !important',
    },
    padding: '16px 0px !important',
  },
  '&.MuiTable-root': {
    backgroundColor: '#fafafb',
  },
  bottomAction: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px !important',
    padding: '0 10px',
    width: '100%',
  },
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  combinedTooltip: {
    backgroundColor: '#241A2E',
    borderRadius: '8px',
    boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
    height: '122.9px',
    width: '207px',
  },
  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: 'px',
      // boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      // height: 'auto',
    },
  },
  infoTitle: {
    color: '#241A2E !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },
  infodis: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
  },
  inputStyles: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#f3f3f3 !important',
      borderRadius: '7px !important',
      color: '#241A2E !important',
      fontFamily: 'FiraSans-Medium !important',
      fontSize: '14px !important',
      fontWeight: ' 500 !important',
      letterSpacing: '0 !important',
      // opacity: '0.43 !important',
    },
    '& .MuiFilledInput-root:after': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:before': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:hover': {
      border: 'none',
      outline: 'none',
    },
    '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& Mui-error': {
      height: 0,
    },
    '& input': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      padding: '11px',
    },
    '& label': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: 12,
      height: 24,
      letterSpacing: 0,
    },
  },
  loginAsAdmin: {
    color: '#2573BF',
    fontFamily: 'FiraSans-Bold',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '22px',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'right',
  },
  optionsClassName: {
    backgroundColor: '#241A2E !important',
    color: '#FFFFFF',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  pxy: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  rectangleTooltip: {
    backgroundColor: '#37414F',
    height: '32px',
    width: '207px',
  },
  rightSidebtn: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rowHeight: {
    height: '80px',
  },
  scroll: {
    overflow: 'hidden',
    position: 'relative',
  },
  selectClassName: {
    '& .MuiInput-root': {
      height: '44px !important',
    },
    backgroundColor: '#F8F8FA',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    boxSizing: 'border-box',
    marginBottom: '6px',
    paddingLeft: '10px !important',
  },
  submited: {
    boxShadow: 'unset',
  },
  table: {
    minWidth: 650,
  },
}));

export default useStyles;
