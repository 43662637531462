import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import {
  ChangePasswordInput,
  CreatePasswordFormInputs,
  ForgotPasswordFormInputs,
  GetUsersProfileInfoDTO,
  ResetPasswordFormInputs,
} from '../../types';
import { RootStore } from '..';

export type UserApiInstance = InstanceType<typeof UserApiStore>;

export class UserApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getUsersProfileInfo = () => {
    const {
      ProfileInfo: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<GetUsersProfileInfoDTO>({
      method: Method,
      url: Endpoint,
    });
  };

  changePassword = (values: ChangePasswordInput) => {
    const {
      ChangePassword: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<void>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };

  resetPassword = (values: ResetPasswordFormInputs) => {
    const {
      ResetPassword: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<any>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };

  createPassword = (values: CreatePasswordFormInputs) => {
    const {
      CreatePassword: { Endpoint, Method },
    } = ApiRoutes.User;
    return this.rootStore.apiStore.call<any>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };

  forgotPassword = (values: ForgotPasswordFormInputs) => {
    const {
      ForgotPassword: { Endpoint, Method },
    } = ApiRoutes.User;

    return this.rootStore.apiStore.call<any>({
      data: values,
      method: Method,
      url: Endpoint,
    });
  };
}
