import * as CryptoJS from 'crypto-js';

import { Config } from '../config';

export const delay = (time: number) =>
  new Promise((r) =>
    setTimeout(() => {
      r(null);
    }, time),
  );

export const trimLeadingZeros = (val: string | number) => {
  return `${val}`.replace(/\b0+\B/, '');
};

export const objectClean = (obj: any) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
      delete obj[key];
    }
  }
  return obj;
};

export const decryptFileUrl = (value: string | null): string => {
  try {
    const key = Config.APP_DECRYPT_SECRET;
    const decryptValue = value
      ? CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8)
      : '';
    return decryptValue;
  } catch (e) {
    return 'failed to decrypt';
  }
};
