import { makeAutoObservable } from 'mobx';

import { RootStore } from '.';

export class UserStore {
  private _userData: any = {};
  private _showLoader = false;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setUserData(data: any) {
    this._userData = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get UserData() {
    return this._userData;
  }

  async fetchAdminUser() {
    const response =
      await this.rootStore.apiStore.userApi.getUsersProfileInfo();
    if (response.isOk()) {
      const { value } = response;
      this.setUserData(value);
    }
    return response;
  }
}
