import { Button, FormControl, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

type SearchFormProps = {
  classes: any;
  onClose: () => void;
};

const TrainingViolationMappingsSearchForm: React.FC<any> = (
  props: SearchFormProps,
) => {
  const { classes, onClose } = props;
  const { videoStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const [initialFocus, setInitialFocus] = UseFocus();

  const [basicCategories, setBasicCategories] = useState<any[]>([]);

  const { control, handleSubmit } = useForm<{
    violationCode: string;
    description: string;
    violationGroup: string;
    videoTitle: string;
    basicCategoryId: string;
  }>({
    defaultValues: {
      basicCategoryId: videoStore.ViolationMappingFilters.basicCategoryId,
      description: videoStore.ViolationMappingFilters.description,
      videoTitle: videoStore.ViolationMappingFilters.videoTitle,
      violationCode: videoStore.ViolationMappingFilters.violationCode,
      violationGroup: videoStore.ViolationMappingFilters.violationGroup,
    },
  });

  const getBasicCategories = useCallback(async () => {
    videoStore.setViolationBasicCategoriesLoader(true);
    const resp = await videoStore.getTrainingViolationBasicCategories();
    if (resp.isErr()) {
      enqueueSnackbar('Unable to fetch BASIC Categories', SnackBarConfig('e'));
    } else {
      setBasicCategories(resp.value.data);
    }
    videoStore.setViolationBasicCategoriesLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBasicCategories();
  }, [getBasicCategories]);

  useMountEffect(setInitialFocus);

  const onFiltersSubmit = useCallback(
    async (values) => {
      videoStore.setViolationMappingFilters(values);
      videoStore.setViolationMappingsLoader(true);
      const resp = await videoStore.getInitialTrainingViolationMappings();
      if (resp.isErr()) {
        enqueueSnackbar(
          'Unable to fetch Violation Mappings',
          SnackBarConfig('e'),
        );
      }
      videoStore.setViolationMappingsLoader(false);
      onClose();
    },
    [enqueueSnackbar, onClose, videoStore],
  );

  const resetFilters = useCallback(async () => {
    videoStore.resetViolationMappingFilters();
    videoStore.setViolationMappingsLoader(true);
    const resp = await videoStore.getInitialTrainingViolationMappings();
    if (resp.isErr()) {
      enqueueSnackbar(
        'Unable to fetch Violation Mappings',
        SnackBarConfig('e'),
      );
    }
    videoStore.setViolationMappingsLoader(false);
    onClose();
  }, [enqueueSnackbar, onClose, videoStore]);

  return (
    <form
      onSubmit={handleSubmit(onFiltersSubmit)}
      id="training-violation-mappings-search-form">
      <FormControl error={false} fullWidth>
        <Typography component={'h3'} className={classes.filterLabel}>
          BASIC Category
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                name="basicCategoryId"
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      size={'small'}
                      onChangeText={onChange}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={
                        <>
                          <option
                            value=""
                            key="basic-category-select"
                            disabled={true}
                            className={classes.optionsClassName}>
                            Select
                          </option>
                          {basicCategories.map((c: any) => (
                            <option
                              key={c.key}
                              value={c.key}
                              className={classes.optionsClassName}>
                              {c.value}
                            </option>
                          ))}
                        </>
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography component={'h3'} className={classes.filterLabel}>
          Violation Group
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="violationGroup"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={initialFocus}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography component={'h3'} className={classes.filterLabel}>
          Violation Code
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="violationCode"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography component={'h3'} className={classes.filterLabel}>
          Description
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="description"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography component={'h3'} className={classes.filterLabel}>
          Training Name
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="videoTitle"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              type="button"
              onClick={resetFilters}
              disabled={videoStore.ViolationMappingsLoader}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            className={classes.filterBtn}
            type="submit"
            disabled={videoStore.ViolationMappingsLoader}>
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(TrainingViolationMappingsSearchForm);
