import { Dialog } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

function DialogMigrate({
  children,
  className,
  maxWidth,
  open,
  disableBackdropClick,
  disableEscapeKeyDown,
  onClose,
  ...rest
}: {
  children: React.ReactNode;
  className: string;
  maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  open: boolean;
  disableBackdropClick: boolean;
  disableEscapeKeyDown: boolean;
  onClose: () => void;
}) {
  const handleClose = (event: any, reason: string) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false;
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      className={className}
      maxWidth={maxWidth}
      fullWidth={true}
      onClose={handleClose}
      {...rest}>
      {children}
    </Dialog>
  );
}

export default observer(DialogMigrate);
