import { FormHelperText, Select } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';

type FormInputTextProps = {
  errors?: string;
  defaultValue?: string;
  value: any;
  tabIndexVal?: number;
  onChangeText?: (src: string) => void;
  isEditable?: boolean;
  name: string;
  isRefreshValue?: boolean;
  isFormSubmittedOnce?: boolean;
  inputRefObj?: React.Ref<any>;
  optionsList: any;
  selectClassName?: any;
  iconsClassName?: string;
  placeHolder?: string;
  size?: 'small' | 'medium';
};

export const SelectInput = (props: FormInputTextProps): ReactElement => {
  const {
    errors,
    defaultValue,
    value,
    tabIndexVal = 0,
    onChangeText,
    isEditable = false,
    name,
    isRefreshValue = false,
    isFormSubmittedOnce,
    inputRefObj,
    optionsList,
    selectClassName,
    iconsClassName,
    placeHolder,
    size = 'medium',
  } = props;

  const classes = useStyles();
  const [inputVal, setInputVal] = React.useState(value);
  const [showLocalError, setShowLocalError] = React.useState(true);

  const onValueChange = React.useCallback(
    (val: string) => {
      setInputVal(val);
      setShowLocalError(!val);
      onChangeText && onChangeText(val);
    },
    [onChangeText],
  );

  const onBlur = () => {
    setShowLocalError(true);
  };

  React.useEffect(() => {
    if (isRefreshValue && inputVal !== value) {
      setInputVal(value);
      setShowLocalError(!value);
    }
  }, [inputVal, value, isRefreshValue]);

  const showError = isFormSubmittedOnce || showLocalError;

  return (
    <>
      <Select
        value={inputVal}
        name={name}
        size={size ? size : undefined}
        placeholder={placeHolder ? placeHolder : 'Select'}
        classes={iconsClassName ? { icon: iconsClassName } : {}}
        className={selectClassName}
        defaultValue={defaultValue}
        inputProps={{
          tabIndex: tabIndexVal,
        }}
        inputRef={inputRefObj}
        disabled={isEditable}
        error={showError ? (errors ? true : false) : false}
        fullWidth
        onBlur={() => {
          onBlur();
        }}
        onChange={(event: any) => {
          onValueChange(event.target.value);
        }}
        native>
        {optionsList}
      </Select>
      {errors && (
        <FormHelperText className={classes.helperText}>{errors}</FormHelperText>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    helperText: {
      color: theme.palette.warning.main,
    },
  }),
);
