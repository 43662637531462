import { makeAutoObservable, toJS } from 'mobx';

import { RootStore } from '.';

export class GlobalStore {
  private _stateDetails: any = [];
  private _currentRoute = '';
  private _menuOpen = false;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setMenu(menu: boolean) {
    this._menuOpen = menu;
  }

  setCurrentRoute(route: string) {
    this._currentRoute = route;
  }

  get getStateDetails() {
    return [...toJS(this._stateDetails)];
  }

  get getCurrentRoute() {
    return this._currentRoute;
  }

  get showMenu() {
    return this._menuOpen;
  }

  setStateDetails(data: any = []) {
    this._stateDetails = data;
  }

  setApiStateDetails = async () => {
    const response =
      await this.rootStore.apiStore.globalApiStore.getStateList();
    if (response.isOk()) {
      this.setStateDetails([...response.value]);
    } else if (response.isErr()) {
      this.setStateDetails();
    }
    return response;
  };
}
