import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { CompanyOrDriverDTO } from '../../types';
import { RootStore } from '..';

export class DriverApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getDriverList = (params: {
    limit: number;
    nextLink: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      GetListBySearch: { Endpoint, Method },
    } = ApiRoutes.Driver;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<CompanyOrDriverDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  getDriversBySearch = (params: {
    limit: number;
    nextLink: string;
    status: string;
    name: string;
    companyName: string;
    driverType: string;
    simplexId: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    const {
      GetListBySearch: { Endpoint, Method },
    } = ApiRoutes.Driver;

    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<CompanyOrDriverDTO>({
      method: Method,
      params: {
        ...restParams,
      },
      url: Endpoint,
    });
  };

  getIndividualDriverDetail = (params: { id: any }) => {
    const { id }: any = params;
    const {
      GetDriverDetailById: { Endpoint, Method },
    } = ApiRoutes.Driver;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {},
      url: Endpoint.concat(id),
    });
  };

  GrantPortalAccess = (bodyData: any) => {
    const {
      GrantPortalAccess: { Endpoint, Method },
    } = ApiRoutes.Driver;

    return this.rootStore.apiStore.call<any>({
      data: bodyData,
      method: Method,
      params: {},
      url: Endpoint,
    });
  };

  sendPortalInvite = (params: { contactId: string }) => {
    const {
      SendPortalInvite: { Endpoint, Method },
    } = ApiRoutes.Driver;
    const restParams = this.objClean({ ...params });

    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: { ...restParams },
      url: Endpoint,
    });
  };

  objClean(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  }
}
