import { Button, FormControl, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { InputText } from '../../Forms/InputText';
import { SelectInput } from '../../Forms/selectInput';
import search from './../../../assets/images/h-search.svg';

type SearchFormProps = {
  classes: any;
  onClose: () => void;
};

const TrainingQuizzesSearchForm: React.FC<any> = (props: SearchFormProps) => {
  const { classes, onClose } = props;
  const { quizzesStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const [initialFocus, setInitialFocus] = UseFocus();

  const { control, handleSubmit } = useForm<{
    quizTitle: string;
    status: 'active' | 'archived' | 'draft';
    videoTitle: string;
  }>({
    defaultValues: {
      quizTitle: quizzesStore.Filters.quizTitle,
      status: quizzesStore.Filters.status,
      videoTitle: quizzesStore.Filters.videoTitle,
    },
  });

  useMountEffect(setInitialFocus);

  const onFiltersSubmit = useCallback(
    async (values) => {
      quizzesStore.setQuizFilters(values);
      quizzesStore.setShowLoader(true);
      const resp = await quizzesStore.getQuizzesBySorting();
      if (resp.isErr()) {
        enqueueSnackbar('Unable to fetch Quizzes', SnackBarConfig('e'));
      }
      quizzesStore.setShowLoader(false);
      onClose();
    },
    [enqueueSnackbar, onClose, quizzesStore],
  );

  const resetFilters = useCallback(async () => {
    quizzesStore.resetQuizFilters();
    quizzesStore.setShowLoader(true);
    const resp = await quizzesStore.getQuizzesBySorting();
    if (resp.isErr()) {
      enqueueSnackbar('Unable to fetch Quizzes', SnackBarConfig('e'));
    }
    quizzesStore.setShowLoader(false);
    onClose();
  }, [enqueueSnackbar, onClose, quizzesStore]);

  return (
    <form
      onSubmit={handleSubmit(onFiltersSubmit)}
      id="training-quiz-search-form">
      <FormControl error={false} fullWidth>
        <Typography component={'h3'} className={classes.filterLabel}>
          Quiz Status
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue="active"
                name="status"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      size={'small'}
                      onChangeText={onChange}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      placeHolder={'Select'}
                      optionsList={
                        <>
                          <option
                            value="active"
                            className={classes.optionsClassName}>
                            Active
                          </option>
                          <option
                            value="archived"
                            className={classes.optionsClassName}>
                            Archived
                          </option>
                          <option
                            value="draft"
                            className={classes.optionsClassName}>
                            Draft
                          </option>
                        </>
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography component={'h3'} className={classes.filterLabel}>
          Quiz Title
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="quizTitle"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={initialFocus}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography component={'h3'} className={classes.filterLabel}>
          Training Name
        </Typography>
        <Grid className={classes.borderBottom}>
          <Grid container className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={control}
                defaultValue=""
                name="videoTitle"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      placeholder="Search"
                      onChangeText={onChange}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              type="button"
              onClick={resetFilters}
              disabled={quizzesStore.ShowLoader}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            className={classes.filterBtn}
            type="submit"
            disabled={quizzesStore.ShowLoader}>
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(TrainingQuizzesSearchForm);
