import { makeAutoObservable, toJS } from 'mobx';

import { API_LIMIT } from '../config';
import {
  CreateTrainingInputs,
  TrainingAssignmentReasonFilters,
  TrainingType,
  TrainingVideoFilters,
  TrainingViolationCodeInputs,
  TrainingViolationFilters,
  TrainingViolationGroupInputs,
  TrainingViolationMappingFilters,
  TrainingViolationMappingInputs,
  UpdateTrainingInputs,
} from '../types';
import { RootStore } from '.';

type TrainingVideoDTO = {
  id: string;
  title: string;
  thumbnailUrl: string;
  thumbnailName: string;
  thumbnailPath: string;
  fileName: string;
  fileUrl: string;
  filePath: string;
  hasQuiz: boolean;
  quizId: string | null;
  quizTitle: string | null;
  status: 'active' | 'archived' | 'draft';
  hasViolationMappings: boolean;
  violationMappings: any[];
  isAccidentsRelated: boolean;
  hasReasonMappings: boolean;
  reasonMappings: any[];
  hasDocument: boolean;
  documentTypeId: string;
};

export class VideoStore {
  private _sortBy: 'title' = 'title';
  private _sortDirection: 'asc' | 'desc' = 'asc';

  private _videosList: any[] = [];
  private _violationBasicCategories: any[] = [];
  private _violationGroups: any[] = [];
  private _violationCodes: any[] = [];
  private _violationMappings: any[] = [];
  private _assignmentReasons: any[] = [];
  private _trainingCertificateTypes: any[] = [];

  private _showLoader = false;
  private _violationBasicCategoriesLoader = false;
  private _violationGroupsLoader = false;
  private _violationCodesLoader = false;
  private _violationMappingsLoader = false;
  private _assignmentReasonsLoader = false;

  private _nextLink = '';
  private _violationGroupsNextLink = '';
  private _violationCodesNextLink = '';
  private _violationMappingsNextLink = '';
  private _assignmentReasonsNextLink = '';

  private _hasMoreResults = false;
  private _hasMoreViolationGroups = false;
  private _hasMoreViolationCodes = false;
  private _hasMoreViolationMappings = false;
  private _hasMoreAssignmentReasons = false;

  private _videoFilters: TrainingVideoFilters = {
    limit: String(API_LIMIT),
    nextLink: this.NextLink,
    quizTitle: '',
    sortBy: this.SortBy,
    sortDirection: this.SortDirection,
    status: 'active',
    trainingType: TrainingType.ALL,
    videoTitle: '',
    violationCode: '',
  };
  private _violationGroupFilters: TrainingViolationFilters = {
    limit: '1000',
    nextLink: '',
    searchText: '',
    sortBy: 'name',
    sortDirection: 'asc',
  };
  private _violationCodeFilters: TrainingViolationFilters = {
    limit: '1000',
    nextLink: '',
    searchText: '',
    sortBy: 'code',
    sortDirection: 'asc',
  };
  private _violationMappingFilters: TrainingViolationMappingFilters = {
    basicCategoryId: '',
    description: '',
    limit: String(API_LIMIT),
    nextLink: '',
    sortBy: 'code',
    sortDirection: 'asc',
    videoTitle: '',
    violationCode: '',
    violationGroup: '',
  };
  private _assignmentReasonFilters: TrainingAssignmentReasonFilters = {
    limit: '10',
    nextLink: '',
    searchText: '',
    sortBy: 'code',
    sortDirection: 'asc',
  };

  private _selectedVideo: TrainingVideoDTO = {
    documentTypeId: '',
    fileName: '',
    filePath: '',
    fileUrl: '',
    hasDocument: false,
    hasQuiz: true,
    hasReasonMappings: false,
    hasViolationMappings: false,
    id: '',
    isAccidentsRelated: false,
    quizId: null,
    quizTitle: null,
    reasonMappings: [],
    status: 'draft',
    thumbnailName: '',
    thumbnailPath: '',
    thumbnailUrl: '',
    title: '',
    violationMappings: [],
  };
  private _selectedBasicCategoryId = '';
  private _selectedViolationGroupId = '';
  private _selectedViolationCodes: any[] = [];
  private _selectedReasons: any[] = [];

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setSortBy(data: 'title') {
    this._sortBy = data;
  }

  setSortDirection(data: 'asc' | 'desc') {
    this._sortDirection = data;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  setVideosList(data: Array<any>) {
    this._videosList = data;
  }
  setViolationBasicCategories(data: any[]) {
    this._violationBasicCategories = data;
  }
  setViolationGroups(data: any[]) {
    this._violationGroups = data;
  }
  setViolationCodes(data: any[]) {
    this._violationCodes = data;
  }
  setViolationMappings(data: any[]) {
    this._violationMappings = data;
  }
  setAssignmentReasons(data: any[]) {
    this._assignmentReasons = data;
  }
  setTrainingCertificateTypes(data: any[]) {
    this._trainingCertificateTypes = data;
  }
  get VideosList() {
    return this._videosList;
  }
  get ViolationBasicCategories() {
    return this._violationBasicCategories;
  }
  get ViolationGroups() {
    return this._violationGroups;
  }
  get ViolationCodes() {
    return this._violationCodes;
  }
  get ViolationMappings() {
    return this._violationMappings;
  }
  get AssignmentReasons() {
    return this._assignmentReasons;
  }
  get TrainingCertificateTypes() {
    return this._trainingCertificateTypes;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }
  setViolationBasicCategoriesLoader(value: boolean) {
    this._violationBasicCategoriesLoader = value;
  }
  setViolationGroupsLoader(value: boolean) {
    this._violationGroupsLoader = value;
  }
  setViolationCodesLoader(value: boolean) {
    this._violationCodesLoader = value;
  }
  setViolationMappingsLoader(value: boolean) {
    this._violationMappingsLoader = value;
  }
  setAssignmentReasonsLoader(value: boolean) {
    this._assignmentReasonsLoader = value;
  }
  get ShowLoader() {
    return this._showLoader;
  }
  get ViolationBasicCategoriesLoader() {
    return this._violationBasicCategoriesLoader;
  }
  get ViolationGroupsLoader() {
    return this._violationGroupsLoader;
  }
  get ViolationCodesLoader() {
    return this._violationCodesLoader;
  }
  get ViolationMappingsLoader() {
    return this._violationMappingsLoader;
  }
  get AssignmentReasonsLoader() {
    return this._assignmentReasonsLoader;
  }

  setHasMoreVideos(hasMore: boolean) {
    this._hasMoreResults = hasMore;
  }
  setHasMoreViolationGroups(hasMore: boolean) {
    this._hasMoreViolationGroups = hasMore;
  }
  setHasMoreViolationCodes(hasMore: boolean) {
    this._hasMoreViolationCodes = hasMore;
  }
  setHasMoreViolationMappings(hasMore: boolean) {
    this._hasMoreViolationMappings = hasMore;
  }
  setHasMoreAssignmentReasons(hasMore: boolean) {
    this._hasMoreAssignmentReasons = hasMore;
  }
  get HasMoreVideos() {
    return this._hasMoreResults;
  }
  get HasMoreViolationGroups() {
    return this._hasMoreViolationGroups;
  }
  get HasMoreViolationCodes() {
    return this._hasMoreViolationCodes;
  }
  get HasMoreViolationMappings() {
    return this._hasMoreViolationMappings;
  }
  get HasMoreAssignmentReasons() {
    return this._hasMoreAssignmentReasons;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }
  setViolationGroupsNextLink(link: string) {
    this._violationGroupsNextLink = link;
  }
  setViolationCodesNextLink(link: string) {
    this._violationCodesNextLink = link;
  }
  setViolationMappingsNextLink(link: string) {
    this._violationMappingsNextLink = link;
  }
  setAssignmentReasonsNextLink(link: string) {
    this._assignmentReasonsNextLink = link;
  }
  get NextLink() {
    return this._nextLink;
  }
  get ViolationGroupsNextLink() {
    return this._violationGroupsNextLink;
  }
  get ViolationCodesNextLink() {
    return this._violationCodesNextLink;
  }
  get ViolationMappingsNextLink() {
    return this._violationMappingsNextLink;
  }
  get AssignmentReasonsNextLink() {
    return this._assignmentReasonsNextLink;
  }

  setVideoFilters(filters: TrainingVideoFilters) {
    this._videoFilters = {
      ...this._videoFilters,
      ...filters,
    };
  }
  setViolationGroupFilters(filters: TrainingViolationFilters) {
    this._violationGroupFilters = {
      ...this._violationGroupFilters,
      ...filters,
    };
  }
  setViolationCodeFilters(filters: TrainingViolationFilters) {
    this._violationCodeFilters = {
      ...this._violationCodeFilters,
      ...filters,
    };
  }

  resetViolationGroupFilters() {
    this._violationGroupFilters = {
      limit: '1000',
      nextLink: '',
      searchText: '',
      sortBy: 'name',
      sortDirection: 'asc',
    };
  }

  resetViolationCodeFilters() {
    this._violationCodeFilters = {
      limit: '1000',
      nextLink: '',
      searchText: '',
      sortBy: 'code',
      sortDirection: 'asc',
    };
  }

  setViolationMappingFilters(filters: TrainingViolationMappingFilters) {
    this._violationMappingFilters = {
      ...this._violationMappingFilters,
      ...filters,
    };
  }
  setAssignmentReasonFilters(filters: TrainingAssignmentReasonFilters) {
    this._assignmentReasonFilters = {
      ...this._assignmentReasonFilters,
      ...filters,
    };
  }
  get VideoFilters() {
    return this._videoFilters;
  }
  get ViolationGroupFilters() {
    return this._violationGroupFilters;
  }
  get ViolationCodeFilters() {
    return this._violationCodeFilters;
  }
  get ViolationMappingFilters() {
    return this._violationMappingFilters;
  }
  get AssignmentReasonFilters() {
    return this._assignmentReasonFilters;
  }
  resetVideoFilters() {
    this.setVideoFilters({
      quizTitle: '',
      sortBy: 'title',
      sortDirection: 'asc',
      status: 'active',
      trainingType: TrainingType.ALL,
      videoTitle: '',
      violationCode: '',
    });
  }
  resetViolationMappingFilters() {
    this.setViolationMappingFilters({
      basicCategoryId: '',
      description: '',
      sortBy: 'code',
      sortDirection: 'asc',
      videoTitle: '',
      violationCode: '',
      violationGroup: '',
    });
  }

  setSelectedVideo(data: TrainingVideoDTO) {
    this._selectedVideo = {
      ...this._selectedVideo,
      ...data,
    };
  }
  setSelectedBasicCategoryId(value: string) {
    this._selectedBasicCategoryId = value;
  }
  setSelectedViolationGroupId(value: string) {
    this._selectedViolationGroupId = value;
  }
  get SelectedVideo() {
    return { ...toJS(this._selectedVideo) };
  }
  get SelectedBasicCategoryId() {
    return this._selectedBasicCategoryId;
  }
  get SelectedViolationGroupId() {
    return this._selectedViolationGroupId;
  }
  setSelectedViolationCodes(codes: any[], concat = true) {
    this._selectedViolationCodes = concat
      ? codes.concat(this._selectedViolationCodes)
      : codes;
  }
  setSelectedReasons(reasons: any[], concat = true) {
    this._selectedReasons = concat
      ? reasons.concat(this._selectedReasons)
      : reasons;
  }
  removeSelectedViolationCode(codeId: string) {
    this._selectedViolationCodes = this.SelectedViolationCodes.filter(
      (v) => v.id !== codeId,
    );
  }
  removeSelectedReason(reasonId: string) {
    this._selectedReasons = this.SelectedReasons.filter(
      (v) => v.id !== reasonId,
    );
  }
  resetSelectedViolationCodes() {
    this._selectedViolationCodes = [];
  }
  resetSelectedReasons() {
    this._selectedReasons = [];
  }
  get SelectedViolationCodes() {
    return this._selectedViolationCodes;
  }
  get SelectedReasons() {
    return this._selectedReasons;
  }
  get SelectedViolationCodeIds(): string[] {
    return this.SelectedViolationCodes.map((v) => v.id);
  }
  get SelectedReasonIds(): string[] {
    return this.SelectedReasons.map((v) => v.id);
  }

  setSelectedVideoReset() {
    this.setSelectedVideo({
      documentTypeId: '',
      fileName: '',
      filePath: '',
      fileUrl: '',
      hasDocument: false,
      hasQuiz: true,
      hasReasonMappings: false,
      hasViolationMappings: false,
      id: '',
      isAccidentsRelated: false,
      quizId: null,
      quizTitle: null,
      reasonMappings: [],
      status: 'draft',
      thumbnailName: '',
      thumbnailPath: '',
      thumbnailUrl: '',
      title: '',
      violationMappings: [],
    });
  }

  async uploadThumbnailImage(formData: FormData): Promise<any> {
    return await this.rootStore.apiStore.videoApi.uploadTrainingFile({
      file: formData,
      type: 'thumbnail',
    });
  }

  async uploadTrainingVideo(
    formData: FormData,
    type: 'document' | 'video',
  ): Promise<any> {
    return await this.rootStore.apiStore.videoApi.uploadTrainingFile({
      file: formData,
      type,
    });
  }

  async getQuizLookupList(): Promise<any> {
    return await this.rootStore.apiStore.videoApi.getQuizLookupList();
  }

  async getCertificateTypes(): Promise<any> {
    const resp = await this.rootStore.apiStore.videoApi.getCertificateTypes();
    if (resp.isOk()) {
      this.setTrainingCertificateTypes(resp.value.response);
    }
    return resp;
  }

  async createTraining(payload: CreateTrainingInputs): Promise<any> {
    return await this.rootStore.apiStore.videoApi.createTraining(payload);
  }

  async getTraining(trainingId: string): Promise<any> {
    const response = await this.rootStore.apiStore.videoApi.getTraining(
      trainingId,
    );
    return response;
  }

  async updateTraining(
    trainingId: string,
    payload: UpdateTrainingInputs,
  ): Promise<any> {
    return await this.rootStore.apiStore.videoApi.updateTraining(
      trainingId,
      payload,
    );
  }

  async archiveTraining(trainingId: string): Promise<any> {
    return await this.rootStore.apiStore.videoApi.archiveTraining(trainingId);
  }

  setTrainingApiReset() {
    this.setNextLink('');
    this.setHasMoreVideos(false);
    this.setVideosList([]);
  }

  setViolationGroupsApiReset() {
    this.setViolationGroupsNextLink('');
    this.setHasMoreViolationGroups(false);
    this.setViolationGroups([]);
  }
  setViolationCodesApiReset() {
    this.setViolationCodesNextLink('');
    this.setHasMoreViolationCodes(false);
    this.setViolationCodes([]);
  }
  setViolationMappingsApiReset() {
    this.setViolationMappingsNextLink('');
    this.setHasMoreViolationMappings(false);
    this.setViolationMappings([]);
  }
  setAssignmentReasonsApiReset() {
    this.setAssignmentReasonsNextLink('');
    this.setHasMoreAssignmentReasons(false);
    this.setAssignmentReasons([]);
  }

  async getTrainings(): Promise<any> {
    const params: TrainingVideoFilters = {
      ...this._videoFilters,
      nextLink: this.NextLink,
      sortBy: this.SortBy,
      sortDirection: this.SortDirection,
    };
    const response = await this.rootStore.apiStore.videoApi.getAllTrainings(
      params,
    );
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._videosList.concat(results);

      this.setVideosList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreVideos(nextLink ? true : false);
    }
    return response;
  }

  async getTrainingViolationBasicCategories(): Promise<any> {
    const response =
      await this.rootStore.apiStore.videoApi.getTrainingViolationBasicCategories();
    if (response.isOk()) {
      const { data } = response.value;
      this.setViolationBasicCategories(data);
    }
    return response;
  }

  async getTrainingViolationGroups(): Promise<any> {
    const params: TrainingViolationGroupInputs = {
      basicCategoryId: this.SelectedBasicCategoryId,
      ...this._violationGroupFilters,
      nextLink: this.ViolationGroupsNextLink,
    };
    const response =
      await this.rootStore.apiStore.videoApi.getTrainingViolationGroups(params);
    if (response.isOk()) {
      const { response: data, nextLink } = response.value;
      const fullResult = this._violationGroups.concat(data);
      this.setViolationGroups(fullResult);
      this.setViolationGroupsNextLink(nextLink);
      this.setHasMoreViolationGroups(nextLink ? true : false);
    }
    return response;
  }

  async getTrainingViolationCodes(): Promise<any> {
    const params: TrainingViolationCodeInputs = {
      violationGroupId: this.SelectedViolationGroupId,
      ...this._violationCodeFilters,
      nextLink: this.ViolationCodesNextLink,
    };
    const response =
      await this.rootStore.apiStore.videoApi.getTrainingViolationCodes(params);
    if (response.isOk()) {
      const { response: data, nextLink } = response.value;
      const fullResult = this._violationCodes.concat(data);
      this.setViolationCodes(fullResult);
      this.setViolationCodesNextLink(nextLink);
      this.setHasMoreViolationCodes(nextLink ? true : false);
    }
    return response;
  }

  async getTrainingViolationMappings(): Promise<any> {
    const params: TrainingViolationMappingInputs = {
      ...this._violationMappingFilters,
      nextLink: this.ViolationMappingsNextLink,
    };
    const response =
      await this.rootStore.apiStore.videoApi.getTrainingViolationMappings(
        params,
      );
    if (response.isOk()) {
      const { response: data, nextLink } = response.value;
      const fullResult = this._violationMappings.concat(data);
      this.setViolationMappings(fullResult);
      this.setViolationMappingsNextLink(nextLink);
      this.setHasMoreViolationMappings(nextLink ? true : false);
    }
    return response;
  }

  async getInitialTrainings() {
    this.setTrainingApiReset();
    return await this.getTrainings();
  }

  async getInitialTrainingViolationGroups() {
    this.setViolationGroupsApiReset();
    return await this.getTrainingViolationGroups();
  }

  async getInitialTrainingViolationCodes() {
    this.setViolationCodesApiReset();
    return await this.getTrainingViolationCodes();
  }

  async getInitialTrainingViolationMappings() {
    this.setViolationMappingsApiReset();
    return await this.getTrainingViolationMappings();
  }

  async getReasonList() {
    this.setAssignmentReasonsApiReset();
    const resp =
      await this.rootStore.apiStore.videoApi.getTrainingAssignmentReasons({
        showSystemReason: false,
      });
    if (resp.isOk()) {
      this.setAssignmentReasons(resp.value.response);
    } else {
      this.setAssignmentReasons([]);
    }
  }
}
