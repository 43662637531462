// import './LeftNav.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import DownImg from '../../assets/images/down.svg';
import Logo from '../../assets/images/logoNav.svg';
import NavTruck from '../../assets/images/navAdmin.svg';
import { PrivateRoutes } from '../../config';
import { useCustomStore } from '../../hooks';
import { AppPage } from '../../types';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import { AdminPages } from './AppPages';
import useStyles from './LeftNavStyles';

const profileRoute: any = {
  icon: '/assets/images/n-dashboard.svg',
  route: PrivateRoutes.PROFILE,
  title: 'Profile',
  whiteIcon: '',
};

const LeftNav: React.FC = (props) => {
  const { authStore, globalStore, userStore } = useCustomStore();
  const useNavigator = useNavigate();
  const history = useLocation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loginUser, setLoginUser] = useState<any>({
    name: '',
  });

  useEffect(() => {
    getUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!globalStore.getCurrentRoute) {
      globalStore.setCurrentRoute(history.pathname);
    }
  }, [globalStore, history]);

  const getUserDetail = useCallback(async () => {
    const userDetailResponse = await userStore.fetchAdminUser();
    if (userDetailResponse.isOk()) {
      const { value } = userDetailResponse;
      setLoginUser({ ...value });
    }

    if (userDetailResponse.isErr()) {
      const {
        error: { message = null },
      }: any = userDetailResponse;
      if (typeof message === 'string') {
        enqueueSnackbar(
          message || 'Error while processing data',
          SnackBarConfig('e'),
        );
      } else if (typeof message === 'object') {
        const messages: any = {};
        Object.keys(message).forEach((key) => {
          messages[key] = message[key].message;
        });

        Object.keys(messages).forEach((msg: string) => {
          const key: string = msg;
          const value: string = messages[msg];

          enqueueSnackbar(`${key} : ${value}`, SnackBarConfig('e'));
        });
      } else {
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let appPages: AppPage[] = React.useMemo(() => [], []);

  // if(!!authStore.tokens){
  if (authStore.tokens) {
    appPages = AdminPages;
  }

  const Navigateroute = (route: AppPage) => {
    useNavigator(route.route);
    globalStore.setCurrentRoute(route.route);
  };

  const checkForTrainingRoute = (appRoute: any, currentRoute: any) => {
    if (
      appRoute.includes('/training/trainings') &&
      currentRoute.includes('/training/') &&
      (currentRoute.includes('/training/trainings/add') ||
        currentRoute.includes('/training/trainings/edit') ||
        currentRoute.includes('/training/quizzes') ||
        currentRoute.includes('/training/mapping'))
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    // <div className="sidebar" style={{ backgroundImage: `url(${NavTruck})` }}>
    //   <img className="logo" src={Logo} alt="logo" />
    //   <div className="portal">Company Portal</div>
    //   <div className="menu-box">
    //     {appPages.map((route, index) => {
    //       return (
    //         <React.Fragment key={index}>
    //           <div className="menu-item" onClick={() => Navigateroute(route)}>
    //             <img className="menu-icon" src={route.icon} alt="icon" />
    //             <div className="menu-text">{route.title}</div>
    //           </div>
    //         </React.Fragment>
    //       );
    //     })}
    //   </div>
    // </div>

    <div
      className={classes.sidebar}
      style={{ backgroundImage: `url(${NavTruck})` }}>
      <img className={classes.logo} src={Logo} alt="logo" />
      <div className={classes.portal}>Admin</div>
      <div className={classes.menuBox}>
        {appPages.map((route, index) => {
          let hignLightClassName = classes.menuItem;
          let icons: any = route.icon;
          if (
            (route.route && route.route === globalStore.getCurrentRoute) ||
            checkForTrainingRoute(route.route, globalStore.getCurrentRoute)
          ) {
            hignLightClassName = clsx(classes.activeItem, classes.menuItem);
            icons = route.aicon;
          }
          return (
            <React.Fragment key={index}>
              <div
                className={hignLightClassName}
                onClick={() => Navigateroute(route)}>
                <img className="menu-icon" src={icons} alt="icon" />
                <div className={classes.menuText}>{route.title}</div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div
        className={classes.profileBox}
        onClick={() => Navigateroute(profileRoute)}>
        <div className={classes.w90}>
          <div className={classes.name}>{loginUser.name || ''}</div>
        </div>
        <div className={classes.w10}>
          <img className={classes.profileImg} src={DownImg} alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default observer(LeftNav);
