import { yupResolver } from '@hookform/resolvers/yup';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import DialogMigrate from '../../../components/Dialog/DialogMigrate';
import { InputText } from '../../../components/Forms/InputText';
import { SelectInput } from '../../../components/Forms/selectInput';
import { AuthStore } from '../../../store/auth';
import { AdminRoleFormInputs, RoleFormInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import {
  AdminRoleSchema,
  AdminRolesSchema,
} from '../../../utils/ValidatorSchema';
import useStyles from './UsersStyle';

type ListPropsTypes = {
  authStore: AuthStore;
  usersLists: any;
  adminUsersStore: any;
  getAdminsListCall: (reset: boolean) => void;
};

const UsersLists = (props: ListPropsTypes): React.ReactElement => {
  const { authStore, usersLists, adminUsersStore, getAdminsListCall } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [editUser, setEditUser] = useState<any>({
    email: '',
    name: '',
    role: '',
    roleId: '',
    userId: '',
  });
  const [editRole, setEditRole] = useState<any>({
    addNotifications: false,
    impersonateAdmin: false,
    impersonateDrivers: false,
    manageAdmins: false,
    manageTrainings: false,
    name: '',
    roleId: '',
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RoleFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      roleId: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(AdminRoleSchema),
  });

  const {
    control: rolControl,
    handleSubmit: handleRoleSubmit,
    formState: { errors: roleErrors },
    setValue: setRoleValue,
  } = useForm<AdminRoleFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      addNotifications: editRole.addNotifications,
      impersonateAdmin: editRole.impersonateAdmin,
      impersonateDrivers: editRole.impersonateDrivers,
      manageAdmins: editRole.manageAdmins,
      manageTrainings: editRole.manageTrainings,
      name: editRole.name,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(AdminRolesSchema),
  });

  const closeDialog = (status: string) => {
    setIsOpen(false);
    setEditUser({ email: '', name: '', role: '', roleId: '', userId: '' });
    setEditRole({
      addNotifications: false,
      impersonateAdmin: false,
      impersonateDrivers: false,
      manageAdmins: false,
      manageTrainings: false,
      name: '',
      roleId: '',
    });
  };

  const handleAdminOption = (event: any, id: string) => {
    const obj: any = {};

    if (tooltipIsOpen[id]) {
      obj[id] = false;
      setAnchorEl(null);
    } else {
      obj[id] = true;
      setAnchorEl(event.currentTarget);
    }

    setTooltipIsOpen(obj);
  };

  const deleteAdminOrRole = async (id: string) => {
    adminUsersStore.setShowLoader(true);
    const deleteresponse: any = await adminUsersStore.deleteAdmin(id);
    if (deleteresponse && deleteresponse.isOk()) {
      getAdminsListCall(false);
      enqueueSnackbar(String(deleteresponse.value.value), SnackBarConfig('s'));
    }

    if (deleteresponse && deleteresponse.isErr()) {
      adminUsersStore.setShowLoader(false);
      enqueueSnackbar(
        String(deleteresponse.error.message),
        SnackBarConfig('e'),
      );
    }
  };

  const getAdminUser = useCallback(
    async (user: any) => {
      setIsOpen(true);
      adminUsersStore.setAdminUsersShowLoader(true);
      const getAdminRolesResp = await adminUsersStore.fetchAdminRoles();
      if (getAdminRolesResp.isErr()) {
        enqueueSnackbar(
          String(getAdminRolesResp.error.message),
          SnackBarConfig('e'),
        );
      }
      setValue('roleId', user.roleId ? user.roleId : '');
      setEditUser({
        email: user.email,
        name: user.name,
        role: user.role,
        roleId: user.roleId ? user.roleId : '',
        userId: user.userId,
      });
      adminUsersStore.setAdminUsersShowLoader(false);
    },
    [adminUsersStore, enqueueSnackbar, setValue],
  );

  const submitUpdateUser = async (data: any) => {
    if (adminUsersStore.Status === 'Users') {
      adminUsersStore.setShowLoader(true);
      const updateResponse: any = await adminUsersStore.updateAdmin(editUser);
      if (updateResponse && updateResponse.isOk()) {
        closeDialog(adminUsersStore.Status);
        getAdminsListCall(false);
        enqueueSnackbar(String(updateResponse.value), SnackBarConfig('s'));
      }

      if (updateResponse && updateResponse.isErr()) {
        adminUsersStore.setShowLoader(false);
        enqueueSnackbar(
          String(updateResponse.error.message),
          SnackBarConfig('e'),
        );
      }
    } else {
      adminUsersStore.setShowLoader(true);
      const updateRoleResponse: any = await adminUsersStore.updateRole(
        data,
        editRole.roleId,
      );
      if (updateRoleResponse && updateRoleResponse.isOk()) {
        closeDialog(adminUsersStore.Status);
        getAdminsListCall(false);
        enqueueSnackbar(String(updateRoleResponse.value), SnackBarConfig('s'));
      }

      if (updateRoleResponse && updateRoleResponse.isErr()) {
        adminUsersStore.setShowLoader(false);
        enqueueSnackbar(
          String(updateRoleResponse.error.message),
          SnackBarConfig('e'),
        );
      }
    }
  };

  const onSelectUser = (userId: string, roleId: string) => {
    setEditUser({
      ...editUser,
      roleId: roleId,
    });
  };

  const showAdminCheckbox = useCallback((checked: boolean, isShow: boolean) => {
    return (
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography
            noWrap
            sx={{
              color: '#241A2E',
              fontFamily: 'FiraSans-Regular',
              fontSize: '16px',
            }}>
            <Checkbox
              className={classes.checkBoxDisabled}
              color={'success'}
              size={'medium'}
              checked={checked}
              tabIndex={0}
              disabled={isShow}
            />
          </Typography>
        </Grid>
      </TableCell>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showDataOfContent = useCallback((text: string | null) => {
    return text ? (
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          height: '19px',
          letterSpacing: 0,
          lineHeight: '19px',
        }}>
        {text}
      </Typography>
    ) : (
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          height: '19px',
          letterSpacing: 0,
          lineHeight: '19px',
          textAlign: 'center',
        }}>
        -
      </Typography>
    );
  }, []);

  const resendInvite = useCallback((e: any, user: any) => {
    alert(user?.email);
  }, []);

  return (
    <>
      {usersLists.map((user: any, index: number) => (
        <TableRow
          key={index}
          className={classes.rowHeight}
          style={{ minHeight: '80px' }}>
          {adminUsersStore.Status === 'Users' ? (
            <>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#241A2E',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      letterSpacing: 0,
                      lineHeight: '23px',
                    }}>
                    {user.name}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#241A2E',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '16px',
                      height: '19px',
                      letterSpacing: 0,
                      lineHeight: '19px',
                    }}>
                    {showDataOfContent(user.role)}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  {!user.hasPortalAccess ? (
                    <Button
                      color="secondary"
                      onClick={() => resendInvite(event, user)}>
                      Resend Portal Invitation
                    </Button>
                  ) : (
                    ''
                  )}
                </Grid>
              </TableCell>
              {authStore.pageAccess && authStore.pageAccess.admins && (
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Grid item style={{ textAlign: 'right' }}>
                    <IconButton
                      onClick={(event) =>
                        handleAdminOption(event, user.userId)
                      }>
                      <MoreVertIcon />
                    </IconButton>
                    <Popover
                      className="admin-popclass"
                      open={tooltipIsOpen[user.userId]}
                      onClose={(event) => handleAdminOption(event, user.userId)}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom',
                      }}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                      }}>
                      <Box
                        sx={{
                          backgroundColor: '#241A2E',
                          borderRadius: '8px',
                          boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
                          minHeight: '43px',
                          padding: '0.5rem 0',
                          position: 'relative',
                          width: '207px',
                        }}>
                        <div className="arrow">.</div>
                        <Typography
                          component={'div'}
                          className="n-pop-btn"
                          onClick={() => getAdminUser(user)}>
                          Edit
                        </Typography>
                      </Box>
                    </Popover>
                  </Grid>
                </TableCell>
              )}
            </>
          ) : (
            <>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#241A2E',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      letterSpacing: 0,
                      lineHeight: '23px',
                    }}>
                    {user.name}
                  </Typography>
                </Grid>
              </TableCell>
              {showAdminCheckbox(
                user.impersonateAdmin,
                !(authStore.pageAccess && authStore.pageAccess.admins) || false,
              )}
              {showAdminCheckbox(
                user.impersonateDrivers,
                !(authStore.pageAccess && authStore.pageAccess.admins) || false,
              )}
              {showAdminCheckbox(
                user.addNotifications,
                !(authStore.pageAccess && authStore.pageAccess.admins) || false,
              )}
              {showAdminCheckbox(
                user.manageAdmins,
                !(authStore.pageAccess && authStore.pageAccess.admins) || true,
              )}
              {showAdminCheckbox(
                user.manageTrainings,
                !(authStore.pageAccess && authStore.pageAccess.admins) || true,
              )}
              {authStore.pageAccess && authStore.pageAccess.admins && (
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Grid item>
                    <IconButton
                      onClick={(event) =>
                        handleAdminOption(event, user.roleId)
                      }>
                      <MoreVertIcon />
                    </IconButton>
                    <Popover
                      className="role-popclass"
                      open={tooltipIsOpen[user.roleId]}
                      onClose={(event) => handleAdminOption(event, user.roleId)}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'bottom',
                      }}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top',
                      }}>
                      <Box
                        sx={{
                          backgroundColor: '#241A2E',
                          borderRadius: '8px',
                          boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
                          minHeight: '43px',
                          padding: '0.5rem 0',
                          position: 'relative',
                          width: '207px',
                        }}>
                        <div className="arrow">.</div>
                        <Typography
                          component={'div'}
                          className="n-pop-btn"
                          onClick={() => {
                            setRoleValue(
                              'addNotifications',
                              user.addNotifications,
                            );
                            setRoleValue(
                              'impersonateAdmin',
                              user.impersonateAdmin,
                            );
                            setRoleValue(
                              'impersonateDrivers',
                              user.impersonateDrivers,
                            );
                            setRoleValue(
                              'manageTrainings',
                              user.manageTrainings,
                            );
                            setRoleValue('name', user.name);

                            setEditRole({
                              addNotifications: user.addNotifications,
                              impersonateAdmin: user.impersonateAdmin,
                              impersonateDrivers: user.impersonateDrivers,
                              manageAdmins: user.manageAdmins,
                              manageTrainings: user.manageTrainings,
                              name: user.name,
                              roleId: user.roleId,
                            });
                            setIsOpen(true);
                          }}>
                          Edit
                        </Typography>
                      </Box>
                    </Popover>
                  </Grid>
                </TableCell>
              )}
            </>
          )}
        </TableRow>
      ))}
      <Grid>
        {isOpen && (
          <DialogMigrate
            className={classes.dialog}
            maxWidth="sm"
            open={isOpen}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            onClose={() => closeDialog(adminUsersStore.Status)}>
            <DialogTitle>
              <Typography
                component={'div'}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 10px',
                }}>
                <Typography variant="h4">
                  <b>
                    Edit {adminUsersStore.Status === 'Users' ? 'USER' : 'ROLE'}
                  </b>
                </Typography>

                <Grid>
                  <Button
                    type="button"
                    variant="text"
                    onClick={() =>
                      deleteAdminOrRole(
                        adminUsersStore.Status === 'Users'
                          ? editUser.userId
                          : editRole.roleId,
                      )
                    }
                    className={classes.primaryText}>
                    REMOVE{' '}
                    {adminUsersStore.Status === 'Users' ? 'USER' : 'ROLE'}
                  </Button>
                </Grid>
              </Typography>
            </DialogTitle>

            <DialogContent>
              {adminUsersStore.AdminUsersShowLoader ? (
                <div className={classes.loader}>
                  <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                </div>
              ) : adminUsersStore.Status === 'Users' ? (
                <form
                  style={{
                    width: '100%',
                  }}
                  id="admin-update-form"
                  onSubmit={handleSubmit(submitUpdateUser)}>
                  <Grid container className={classes.pxy}>
                    <Grid item md={6}>
                      <Typography className={classes.infoTitle}>
                        Full Name
                      </Typography>
                      <Typography className={classes.infodis}>
                        {editUser.name}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        component={'div'}
                        className={classes.infoTitle}>
                        Email
                      </Typography>
                      <Typography component={'div'} className={classes.infodis}>
                        {editUser.email}
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Typography className={classes.infoTitle}>
                        Select Role *
                      </Typography>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="roleId"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <SelectInput
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.roleId?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={editUser.roleId}
                              size={'medium'}
                              onChangeText={(e: any) => {
                                onChange(e);
                                onSelectUser(editUser.userId, e);
                              }}
                              isEditable={false}
                              selectClassName={classes.selectClassName}
                              placeHolder={'Select'}
                              optionsList={
                                <>
                                  <option
                                    value="Select Role"
                                    disabled
                                    className={classes.optionsClassName}>
                                    Select Role
                                  </option>
                                  {adminUsersStore.AdminRolesList.length &&
                                    adminUsersStore.AdminRolesList.map(
                                      (val: any, index: number) => (
                                        <option
                                          value={val.roleId}
                                          key={index}
                                          disabled={!val}
                                          className={classes.optionsClassName}>
                                          {val.name}
                                        </option>
                                      ),
                                    )}
                                </>
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              ) : (
                <form
                  style={{
                    width: '100%',
                  }}
                  id="role-update-form"
                  onSubmit={handleRoleSubmit(submitUpdateUser)}>
                  <Grid
                    container
                    sx={{ paddingLeft: 'unset !important' }}
                    style={{
                      marginTop: '20px',
                      paddingLeft: 'unset !important',
                    }}>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Box className={classes.formLabelClass}>Role Name *</Box>
                      <Controller
                        control={rolControl}
                        defaultValue={''}
                        name="name"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              inputRefObj={ref}
                              errors={roleErrors.name?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                                adminUsersStore.setNewRoleDetails('name', val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className={classes.pzcenter}>
                      <Box className={classes.formLabelClass}>
                        Impersonate Admin
                      </Box>
                      <Controller
                        control={rolControl}
                        defaultValue={false}
                        name="impersonateAdmin"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <Checkbox
                              inputRef={ref}
                              disabled={false}
                              checked={value}
                              onChange={(ev) => {
                                onChange(ev.target.checked);
                                adminUsersStore.setNewRoleDetails(
                                  'impersonateAdmin',
                                  ev.target.checked,
                                );
                              }}
                              name={name}
                              value={value}
                              color="success"
                              tabIndex={0}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className={classes.pzcenter}>
                      <Box className={classes.formLabelClass}>
                        Impersonate Drivers
                      </Box>
                      <Controller
                        control={rolControl}
                        defaultValue={false}
                        name="impersonateDrivers"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <Checkbox
                              inputRef={ref}
                              disabled={false}
                              checked={value}
                              onChange={(ev) => {
                                onChange(ev.target.checked);
                                adminUsersStore.setNewRoleDetails(
                                  'impersonateDrivers',
                                  ev.target.checked,
                                );
                              }}
                              name={name}
                              value={value}
                              color="success"
                              tabIndex={0}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className={classes.pzcenter}>
                      <Box className={classes.formLabelClass}>
                        Add Notifications
                      </Box>
                      <Controller
                        control={rolControl}
                        defaultValue={false}
                        name="addNotifications"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <Checkbox
                              inputRef={ref}
                              disabled={false}
                              checked={value}
                              onChange={(ev) => {
                                onChange(ev.target.checked);
                                adminUsersStore.setNewRoleDetails(
                                  'addNotifications',
                                  ev.target.checked,
                                );
                              }}
                              name={name}
                              value={value}
                              color="success"
                              tabIndex={0}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      className={classes.pzcenter}>
                      <Box className={classes.formLabelClass}>
                        Manage Trainingss
                      </Box>
                      <Controller
                        control={rolControl}
                        defaultValue={false}
                        name="manageTrainings"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <Checkbox
                              inputRef={ref}
                              disabled={false}
                              checked={value}
                              onChange={(ev) => {
                                onChange(ev.target.checked);
                                adminUsersStore.setNewRoleDetails(
                                  'manageTrainings',
                                  ev.target.checked,
                                );
                              }}
                              name={name}
                              value={value}
                              color="success"
                              tabIndex={0}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </DialogContent>

            <DialogActions>
              <Typography
                component={'div'}
                className={classes.bottomAction}
                sx={{ padding: '0 15px !important' }}>
                <Typography component={'span'}>
                  <Button
                    // disabled={disabled}
                    variant={'text'}
                    className={'d-flex-ja '.concat(classes.cancelBtn)}
                    onClick={() => closeDialog(adminUsersStore.Status)}>
                    Cancel
                  </Button>
                </Typography>
                <Typography component={'div'} className={classes.rightSidebtn}>
                  <Button
                    form={
                      adminUsersStore.Status === 'Users'
                        ? 'admin-update-form'
                        : 'role-update-form'
                    }
                    // disabled={disabled}
                    type="submit"
                    variant="contained"
                    // onClick={() => submitUpdateUser()}
                    className={'add-btn d-flex-ja '.concat(classes.submited)}>
                    Save
                  </Button>
                </Typography>
              </Typography>
            </DialogActions>
          </DialogMigrate>
        )}
      </Grid>
    </>
  );
};

export default observer(UsersLists);
