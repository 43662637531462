import { makeAutoObservable, toJS } from 'mobx';

import { API_LIMIT } from '../config';
import {
  DriverProfileDetails,
  DriverSearchFilters,
  DriverSearchFormInputs,
} from '../types';
import { RootStore } from '.';

export class DriverStore {
  private _limit = API_LIMIT;
  private _status = 'Active';
  private _driverList: Array<any> = [];
  private _driversCount: number | undefined = 0;
  private _showLoader = false;
  private _hasMoreResults = false;
  private _nextLink = '';
  private _filter: DriverSearchFilters = {
    companyName: '',
    driverType: '',
    limit: API_LIMIT,
    name: '',
    nextLink: this.NextLink,
    simplexId: '',
  };
  private _individualDriverDetail: any = null;
  private _sortBy = 'name';
  private _sortDirection = 'asc';

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setFilters(data: DriverSearchFilters) {
    this._filter = data;
  }

  setDriverList(data: Array<any>) {
    this._driverList = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setStatus(value: string) {
    this._status = value;
  }

  setDriverApiReset() {
    this.setNextLink('');
    this.setHasMoreResults(false);
    this.setDriverList([]);
    this.setCount(0);
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  setIndividualDriverDetail(data: any = null) {
    this._individualDriverDetail = data;
  }

  setCount(count: number | undefined) {
    this._driversCount = count;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get Filters() {
    return this._filter;
  }

  get DriverList() {
    return this._driverList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get Status() {
    return this._status;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get getIndividualDriverDetail() {
    if (this._individualDriverDetail) {
      return { ...toJS(this._individualDriverDetail) };
    }
    return null;
  }

  get Count() {
    return this._driversCount;
  }

  async fetchDriversBySorting() {
    this.setDriverApiReset();
    return await this.fetchDrivers();
  }

  async fetchDrivers() {
    const params: any = {};
    this.Filters.name != '' ? (params['name'] = this.Filters.name) : null;
    this.Filters.driverType != ''
      ? (params['driverType'] = this.Filters.driverType)
      : null;
    this.Filters.companyName != ''
      ? (params['companyName'] = this.Filters.companyName)
      : null;
    this.Filters.simplexId != ''
      ? (params['simplexId'] = this.Filters.simplexId)
      : null;

    params['limit'] = this._limit;
    params['nextLink'] = this._nextLink;
    params['status'] = this._status;
    params['sortBy'] = this.SortBy;
    params['sortDirection'] = this.SortDirection;

    const response = await this.rootStore.apiStore.driverApi.getDriverList(
      params,
    );
    if (response.isOk()) {
      const { response: results, nextLink, count } = response.value;
      const fullResult = this._driverList.concat(results);

      this.setDriverList(fullResult);
      this.setCount(count);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async fetchDriversBySearchBased(searchVal: DriverSearchFormInputs) {
    // this.setDriverApiReset(true);
    const params: any = {
      ...searchVal,
    };

    params['limit'] = this._limit;
    params['nextLink'] = this._nextLink;
    params['status'] = this._status;
    params['sortBy'] = this.SortBy;
    params['sortDirection'] = this.SortDirection;

    const response = await this.rootStore.apiStore.driverApi.getDriversBySearch(
      params,
    );
    if (response.isOk()) {
      const { response: results, count, nextLink } = response.value;
      this.setDriverList(results);
      this.setCount(count);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
    }
    return response;
  }

  async getDriverDetailByIndividual(
    id: any,
  ): Promise<{ value: DriverProfileDetails }> {
    this.setIndividualDriverDetail();
    const response: any =
      await this.rootStore.apiStore.driverApi.getIndividualDriverDetail({
        id,
      });
    if (response.isOk()) {
      const { value } = response;
      this.setIndividualDriverDetail(value);
    }
    return response;
  }

  async GrantPortalAccess(bodyData: any): Promise<any> {
    return await this.rootStore.apiStore.driverApi.GrantPortalAccess(bodyData);
  }

  async sendPortalInvite(contactId: string): Promise<any> {
    return await this.rootStore.apiStore.driverApi.sendPortalInvite({
      contactId,
    });
  }
}
