import jwt_decode from 'jwt-decode';
import { makeAutoObservable } from 'mobx';

import { Config, PublicRoutes } from '../config';
import { AuthTokenType, PageAccess } from '../types';
import Storage from '../utils/Storage';
import { RootStore } from '.';

export class AuthStore {
  private _authCount = 0;

  private _tokens: AuthTokenType | null = null;

  _logoutPromise: Promise<any> | null = null;

  _exchangePromise: Promise<any> | null = null;

  private _pageAccess: PageAccess | null = null;

  private _tokenTimer: number | undefined;

  private _idleForLongtimeWarning = false;

  private _idleTime = 0;
  private _callback: any;
  private _idleInterval: NodeJS.Timer | null = null;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  __init() {
    if (!this._callback) {
      setTimeout(() => {
        this.__init();
      }, 200);
      return;
    }
    this.clearTimer();
    if (this._tokens?.accessToken) {
      this.manageRefreshToken();
      if (!this._idleInterval) {
        this._idleInterval = this._callback();
      }
    }
  }

  __unset() {
    this.clearTimer();
    if (this._idleInterval) clearInterval(this._idleInterval);
    this._idleInterval = null;
  }

  manageRefreshToken() {
    const detailsFromToken: { exp: number } = this.getTokenDetail;
    if (!detailsFromToken) return;
    this.clearTimer();
    this._tokenTimer = window.setTimeout(() => {
      this.exchangeAccessToken();
    }, detailsFromToken.exp - Date.now());
  }

  clearTimer() {
    clearTimeout(this._tokenTimer);
  }

  setIdleForLongtimeWarning(shouldShow: boolean) {
    this._idleForLongtimeWarning = shouldShow;
  }

  setCallback(cb: any) {
    this._callback = cb;
  }

  setIdleTime(duration = 0) {
    this._idleTime = duration;
  }

  get idleTime() {
    return this._idleTime;
  }

  get idleForLongtimeWarning() {
    return this._idleForLongtimeWarning;
  }

  setLogoutPromise(promise: any | null) {
    this._logoutPromise = promise;
  }

  setExchangePromise(promise: Promise<any> | null) {
    this._exchangePromise = promise;
  }

  setTokens(tokens: AuthTokenType | null) {
    this._authCount++;
    this._tokens = tokens;
    if (tokens) {
      this._onTokenSet();
    } else {
      this._onTokenUnSet();
    }
  }

  get authCount() {
    return this._authCount;
  }

  get tokens() {
    return this._tokens ? { ...this._tokens } : null;
  }
  get getTokenDetail(): any {
    const tokenDetail: any = this.tokens;
    if (tokenDetail) {
      const decoded = jwt_decode(tokenDetail.accessToken);
      return decoded;
    }
    return null;
  }
  get pageAccess() {
    return this._pageAccess;
  }

  SetPageAccess(access: PageAccess | null) {
    this._pageAccess = access;
  }

  async fetchTokens() {
    const appAuth = await Storage.getTokens();
    this.setTokens(appAuth);
  }

  initTokens(tokens: AuthTokenType) {
    if (tokens) {
      Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokens));
    } else {
      Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
    }
    this.setTokens(tokens);
  }

  // NOTE: Add in memory data cache to be cleared here
  private _clearStoresCache() {
    for (const store of Object.values(this.rootStore)) {
      store?.__reset?.();
    }
  }

  // NOTE: can be called multiple times
  // Use for login state
  private _onTokenSet() {
    for (const store of Object.values(this.rootStore)) {
      store?.__init?.();
    }
  }

  private _onTokenUnSet() {
    for (const store of Object.values(this.rootStore)) {
      store?.__unset?.();
    }
  }

  async logout() {
    if (this._logoutPromise) {
      return this._logoutPromise;
    }
    this.SetPageAccess(null);
    try {
      this.setLogoutPromise(this._authLogout());
      await this._logoutPromise!;
      // window.location.href = PublicRoutes.LOGIN;
      window.history.pushState('', '', PublicRoutes.LOGIN);
    } catch (e) {
      // console.error(e);
    } finally {
      this.setLogoutPromise(null);
      this.setTokens(null);
    }
  }

  private _authLogout() {
    if (this.isLoggedIn) {
      // TODO: API CALL TO LOGOUT
      this._clearStoresCache();
      this.removeTokens();
    }
  }

  get isLoggedIn() {
    return !!this._tokens;
  }

  async exchangeAccessToken() {
    const accessToken = this.tokens?.accessToken || '';
    const id = this.tokens?.userId || '';

    const data = await this.rootStore.apiStore.authApi.exchangeToken({
      accessToken,
      id,
    });
    const refreshToken = this.tokens?.refreshToken || '';
    if (data.isOk()) {
      const {
        value: { accessToken, id },
      } = data;
      this.initTokens({
        accessToken,
        refreshToken,
        userId: id,
      });
    }
    if (data.isErr()) this.logout();
    // return data;
  }

  removeTokens() {
    Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
    this.setTokens(null);
  }

  async exchangeOnlyOnce() {
    if (this._exchangePromise) {
      return this._exchangePromise;
    }

    try {
      this.setExchangePromise(this._exchangeToken());
      await this._exchangePromise!;
    } finally {
      this.setExchangePromise(null);
    }
  }

  private async _exchangeToken() {
    const refreshToken = this.tokens?.refreshToken;

    const { data } = await this.rootStore.apiStore.exchangeTokenApiCall(
      refreshToken || null,
    );
    this.removeTokens();
    this.initTokens({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      userId: data.id,
    });
    return data;
  }

  async login(options: { email: string; password: string }) {
    const loginResp = await this.rootStore.apiStore.authApi.login(options);

    if (loginResp.isOk()) {
      const { accessToken, refreshToken, id } = loginResp.value;
      this.initTokens({
        accessToken,
        refreshToken,
        userId: id,
      });
    }
    return loginResp;
  }

  async impersonateClientToken(bodyData: { contactId: string; name: string }) {
    const tokenReponse = await this.rootStore.apiStore.authApi.directLoginToken(
      bodyData,
    );
    if (tokenReponse.isOk()) {
      const { name, accessToken } = tokenReponse.value;
      window.open(
        Config.CLIENT_PORTAL?.concat(
          `/impersonate?name=${name}&accessToken=${accessToken}`,
        ),
      );
    }
    return tokenReponse;
  }

  async pagesAccess() {
    const accessResponse = await this.rootStore.apiStore.authApi.pagesAccess();
    if (accessResponse.isOk()) {
      const { portalAccess } = accessResponse.value;
      this.SetPageAccess({ ...portalAccess });
    } else {
      this._authLogout();
    }
    return accessResponse;
  }
}
