import {
  AuthErrorType,
  DateErrorType,
  ErrorResponseActions,
  FileErrorType,
  GeneralErrorType,
  ServerErrorType,
  UserErrorType,
} from './backendError.types';

export type themeObj = {
  common: {
    black: string;
    white: string;
  };
  primary: {
    main: string;
    dark: string;
    light: string;
    contrastText: string;
  };
  secondary: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  success: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  info: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  warning: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  error: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  text: {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
  };
  background: {
    default: string;
    paper: string;
  };
  icon: string;
  divider: string;
  border: string;
};

export type AppPage = {
  title: string;
  icon: string;
  whiteIcon: string;
  route: string;
  aicon?: string;
  view?: boolean;
};

export type customAppStyles = {
  icon: string;
  whiteIcon: string;
  index: number;
};

export type LoginFormInputs = {
  email: string;
  password: string;
};

export type LoginFormEmailInputs = {
  email: string;
};

export type UcrFormInputs = {
  type: string;
};

export type AuthTokenType = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

export type AppDataType = {
  appId: string;
  clientApiKey: string;
  publisherAppId: string;
  appName: string;
  publisherClientApiKey: string;
};

export type DriverProfileDetails = {
  accessType: string;
  accountName: string;
  address: string;
  city: string;
  contactId: string;
  dob: string;
  email: string;
  firstName: string;
  lastName: string;
  licenseEndorsements: string;
  licenseExpirationDate: string;
  licenseNumber: string;
  licenseOnFile: boolean;
  licenseState: string;
  licenseType: string;
  medicalCardExpiryDate: string;
  medicalCardIssueDate: string;
  medicalCardOnFile: boolean;
  medicalExaminerName: string | null;
  middleName: string | null;
  name: string;
  nationalRegistryNumber: string | null;
  phoneNumber: string | null;
  ssn: string;
  state: string;
  zip: string;
};

export type LoginParams =
  | {
      authType: 'emailSignup';
      params: {
        email: string;
        password: string;
        referralCode?: string;
      };
    }
  | { authType: 'emailLogin'; params: { email: string; password: string } }
  | {
      authType: 'apple';
      params: {
        identityToken: string;
        authorizationCode: string;
      };
    }
  | { authType: 'google'; params: { idToken: string } }
  | { authType: 'facebook'; params: { fbAccessToken: string } };

export enum UserRole {
  Owner = 'owner',
  Admin = 'admin',
}

export type LoginDTO = {
  accessToken: string;
  refreshToken: string;
  id: string;
};

export type PageAccess = {
  companies: boolean;
  impersonateAdmin: boolean;
  drivers: boolean;
  impersonateDrivers: boolean;
  notifications: boolean;
  admins: boolean;
};
export type ForgotPasswordFormInputs = {
  email: string;
};

export type ChangePasswordInput = {
  currentPassword: string;
  newPassword: string;
};

export type GetUsersProfileInfoDTO = {
  contactId: string;
  name: string;
  email: string;
};
export type VerifyOtpFormInputs = {
  otp: string;
};

export type CreatePasswordFormInputs = {
  newPassword: string;
  token?: string;
};

export type CreatePasswordValidationInput = {
  password: string;
};

export type ResetPasswordFormInputs = {
  confirmPassword: string;
  newPassword: string;
  resetToken?: string;
};

export type ResetPasswordValidationInput = {
  password: string;
  confirmPassword: string;
};

export type AddUserFormInput = {
  first_name: string;
  last_name: string;
  phoneNumber?: string;
  role?: UserRole;
  email: string;
  notes?: string;
};

export type AddUserFormInput2 = {
  userName: string;
  email: string;
};

export type AddUserDTO = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  deletedAt: string;
  role: UserRole;
  signupComplete: boolean;
  active: boolean;
};

export type ApiErrorType =
  | AuthErrorType
  | FileErrorType
  | UserErrorType
  | ServerErrorType
  | GeneralErrorType
  | DateErrorType;

export class ApiError {
  message: string;
  status?: number;
  type?: ApiErrorType;
  actions: ErrorResponseActions;

  constructor({
    message,
    type,
    status,
    actions = {},
  }: {
    message: string;
    type?: ApiErrorType;
    status?: number;
    actions: ErrorResponseActions;
  }) {
    this.message = message;
    this.status = status;
    this.type = type;
    this.actions = actions;
  }
}

export interface GetUsersDataItem {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  deletedAt: string;
  signupComplete: boolean;
  active: boolean;
  phoneNumber: string;
  notes: string;
}

export type GetUsersData = Array<GetUsersDataItem>;

export type UpdateAdminInput = {
  name: string;
  email: string;
};

export type AddTextDataItem = {
  type: string;
  value: string;
  isDraft: boolean;
};

export type GetTextInput = {
  type: string;
  isDraft: boolean;
};

export interface GetTextDataItem {
  type: string;
  value: string;
  isDraft: boolean;
}

export type UserStatusInput = {
  id: string;
  status: string;
};

export type CompanyOrDriverDTO = {
  response: Array<any>;
  nextLink: string;
  count: number | undefined;
};

export type CompanySearchFormInputs = {
  name: string;
  simplexId: string;
  dotNumber: string;
};

export type CompanySearchFilters = {
  name: string;
  simplexId: string;
  dotNumber: string;
  limit: number;
  nextLink: string;
};

export type DriverSearchFormInputs = {
  name: string;
  companyName: string;
  driverType: string;
  simplexId: string;
};

export type DriverSearchFilters = {
  name: string;
  companyName: string;
  driverType: string;
  simplexId: string;
  limit: number;
  nextLink: string;
};

export type NotificationSearchParams = {
  title: string;
  description: string;
  status: string;
  limit?: string;
  nextLink?: string;
};

export type NotificationSearchFormTypes = {
  title: string;
  description: string;
  status: string;
};

export type NotificationGetParams = {
  limit: string;
  nextLink?: string;
};

export type Notification = {
  title: string;
  description: string;
  hyperLink: string;
  hyperLinkText: string;
  isSplashNotification: true;
  isPushNotification: true;
  recipients: string[];
  unitsLowerLimit?: number;
  unitsUpperLimit?: number;
  yearsLowerLimit?: number;
  yearsUpperLimit?: number;
  startTime: Date;
  endTime: Date;
};

export type UsersSearchFilters = {
  name: string;
  limit: number;
  nextLink: string;
};

export type UsersSearchFormInputs = {
  name: string;
};

export type UsersDTO = {
  response: Array<any>;
  nextLink: string;
};

export enum SearchPages {
  Companies = 'company-search',
  Drivers = 'driver-search',
  Notifications = 'notification-search',
  Users = 'user-search',
  Settings = 'setting-search',
  Profiles = 'profile-search',
  Training = 'training',
}

export type AdminUsersSearchFilters = {
  firstName: string;
  lastName: string;
  email: string;
  limit: number;
  nextLink: string;
};

export type AdminUsersSearchFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
};

export type RoleFormInputs = {
  roleId: string;
};

export type AdminRoleFormInputs = {
  name: string;
  impersonateAdmin: boolean;
  impersonateDrivers: boolean;
  addNotifications: boolean;
  manageAdmins: boolean;
  manageTrainings: boolean;
};

export type GrantPortalAccess = {
  password: string;
  roleId: string;
  simplexId: string;
};

export type TrainingFileInputs = {
  type: string;
  file: FormData;
};

export type CreateTrainingInputs = {
  title: string;
  thumbnailUrl: string;
  fileUrl: string;
  hasQuiz: boolean;
  quizId: string | null;
  status: 'active' | 'draft';
  hasViolationMappings: boolean;
  violationIds: string[];
  thumbnailName: string;
  fileName: string;
  isAccidentsRelated: boolean;
  hasReasonMappings: boolean;
  reasonIds: string[];
  hasDocument: boolean;
  documentTypeId: string;
};

export type UpdateTrainingInputs = {
  title: string;
  thumbnailUrl: string;
  fileUrl: string;
  hasQuiz: boolean;
  quizId: string | null;
  status: 'active' | 'draft' | 'archived';
  hasViolationMappings: boolean;
  createViolationIds: string[];
  deleteViolationMappingIds: string[];
  thumbnailName: string;
  fileName: string;
  isAccidentsRelated: boolean;
  hasReasonMappings: boolean;
  createReasonIds: string[];
  deleteReasonMappingIds: string[];
  hasDocument: boolean;
  documentTypeId: string;
};

export enum TrainingType {
  ALL = 'all',
  VIDEO = 'video',
  DOCUMENT = 'document',
}

export type TrainingVideoFilters = {
  status: 'archived' | 'active' | 'draft';
  limit?: string;
  nextLink?: string;
  sortBy?: 'title';
  sortDirection?: 'asc' | 'desc';
  videoTitle?: string;
  quizTitle?: string;
  violationCode?: string;
  trainingType?: TrainingType;
};

export type TrainingViolationGroupInputs = {
  basicCategoryId: string;
  limit?: string;
  nextLink?: string;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  searchText?: string;
};

export type TrainingViolationCodeInputs = {
  violationGroupId: string;
  limit?: string;
  nextLink?: string;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  searchText?: string;
};

export type TrainingViolationMappingInputs = {
  limit?: string;
  nextLink?: string;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
};

export type TrainingViolationFilters = {
  limit?: string;
  nextLink?: string;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  searchText?: string;
};

export type TrainingViolationMappingFilters = {
  limit?: string;
  nextLink?: string;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  violationCode?: string;
  description?: string;
  violationGroup?: string;
  videoTitle?: string;
  basicCategoryId?: string;
};

export type TrainingAssignmentReasonFilters = {
  limit?: string;
  nextLink?: string;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  searchText?: string;
};

export type QuizQuestionAnswerOptions = {
  _uiid?: string | number | null;
  id?: string | number | null;
  option: string;
  isCorrect: boolean;
  optionOrder: number;
};

export type QuizQuestions = {
  _uiid?: string | number | null;
  id?: string | number | null;
  questionTitle: string;
  question: string;
  questionOrder: number;
  answerOptions: QuizQuestionAnswerOptions[];
};

export type TrainingQuizzesFilters = {
  status: 'archived' | 'active' | 'draft';
  limit?: string;
  nextLink?: string;
  sortBy?: 'title';
  sortDirection?: 'asc' | 'desc';
  quizTitle?: string;
  videoTitle?: string;
};

export type QuizDetails = {
  id?: string | null;
  title: string;
  correctAnswersPercentage: number;
  status?: 'active' | 'draft' | 'archived';
  questions: QuizQuestions[];
  videoTitle?: string | null;
  deleteQuestionIds?: null | string[];
  deleteAnswerOptionIds?: null | string[];
};
