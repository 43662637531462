import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import {
  Notification,
  NotificationGetParams,
  NotificationSearchParams,
} from '../../types';
import { RootStore } from '..';

export class NotificationApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getInitialApiNotification = (params: NotificationGetParams) => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.Notification;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      params,
      url: Endpoint,
    });
  };

  getNotificationBySearch = (params: NotificationSearchParams) => {
    const {
      GetListBySearch: { Endpoint, Method },
    } = ApiRoutes.Notification;

    const apiParams: any = { ...params };
    if (!params.status) {
      delete apiParams.status;
    }

    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: { ...apiParams },
      url: Endpoint,
    });
  };

  createNotifications = (body: Notification) => {
    const {
      CreateNotification: { Endpoint, Method },
    } = ApiRoutes.Notification;

    return this.rootStore.apiStore.call<any>({
      data: body,
      method: Method,
      url: Endpoint,
    });
  };

  updateNotification = (id: string, body: Notification) => {
    const {
      UpdateNotification: { Endpoint, Method },
    } = ApiRoutes.Notification;
    const editNotificationUrl: string = Endpoint.concat(id);

    return this.rootStore.apiStore.call<any>({
      data: body,
      method: Method,
      url: editNotificationUrl,
    });
  };

  deleteNotification = (id: string) => {
    const {
      DeleteNotification: { Endpoint, Method },
    } = ApiRoutes.Notification;
    const deleteNotificationUrl: string = Endpoint.concat(id);

    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: deleteNotificationUrl,
    });
  };
}
