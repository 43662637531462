import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import logo from './../../../../../assets/images/n-todo.svg';
import { Button, ContentDiv, Img, Label, Values } from './CardlistStyles';

const TaxPermitsCardList: React.FC = () => {
  return (
    <Box sx={{ minWidth: 275 }}>
      {[
        'MVR - Pre Employment',
        'PSP',
        'Criminal Background',
        'Drug Test',
        'New Driver Set-Up',
        'Physical Exam Location',
      ].map((val, index) => (
        <Card
          key={index}
          style={{
            backgroundColor: '#fff',
            borderBottom: '1px solid #d8d8d8',
            borderRadius: 'unset',
            boxShadow: 'none',
          }}>
          <CardContent>
            <Grid container spacing={0.5} minHeight={'100%'}>
              <Grid
                item
                md={1}
                height={'32px'}
                width={'32px'}
                borderRadius={'8px'}
                style={{ backgroundColor: '#f8f3d6' }}>
                <Img alt="complex" src={logo} />
              </Grid>
              <Grid item md={7} paddingTop={'5px'}>
                <Typography
                  variant="h5"
                  component="span"
                  fontSize={'16px'}
                  fontWeight={600}
                  color={'#241a2e'}
                  paddingLeft={'4px'}
                  paddingTop={'4px'}
                  paddingBottom={'2px'}>
                  {val}
                </Typography>

                <ContentDiv>
                  <Grid container spacing={0.5} minHeight={'100%'}>
                    <Grid item md={6}>
                      <Label> Driver:</Label>
                    </Grid>
                    <Grid item md={6}>
                      {' '}
                      <Values> John Miler</Values>
                    </Grid>
                  </Grid>
                </ContentDiv>

                <ContentDiv>
                  <Grid container spacing={0.5} minHeight={'100%'}>
                    <Grid item md={6}>
                      <Label> Submited:</Label>
                    </Grid>
                    <Grid item md={6}>
                      {' '}
                      <Values>12/10/22</Values>
                    </Grid>
                  </Grid>
                </ContentDiv>

                <ContentDiv>
                  <Grid container spacing={0.5} minHeight={'100%'}>
                    <Grid item md={6}>
                      <Label> Submited By: </Label>
                    </Grid>
                    <Grid item md={6}>
                      {' '}
                      <Values> Brad Miler</Values>
                    </Grid>
                  </Grid>
                </ContentDiv>
              </Grid>
              <Grid item md={4}>
                <Button>Pending</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default observer(TaxPermitsCardList);
