// import { useNavigate } from 'react-router-dom';
// import { useCustomStore } from '../../../hooks';
import './taxandpermits.scss';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Grid } from '@mui/material';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import TaxPermitsCardList from './CardsList/CardLists';
import TaxPermitsNavBarList from './NavTabs/NavTabs';
import useStyles from './TaxsandpermitsStyles';
import TaxPermitsUcr from './UCR/Ucr';

const TaxPermits: React.FC = () => {
  /* const { authStore } = useCustomStore();
  const navigate = useNavigate(); */

  /*  const logout = useCallback(async () => {
     await authStore.logout();
     navigate('/');
   }, [authStore, navigate]); */
  const classes = useStyles();
  const [value, setValue] = React.useState('1');

  return (
    <Grid container spacing={0.5} minHeight={'100%'}>
      {/* left Side content */}
      <Grid
        item
        xs={12}
        md={8}
        sm={12}
        borderRight={'1px solid #d8d8d8'}
        paddingLeft={'20px !important'}>
        <TabContext value={value}>
          <TaxPermitsNavBarList setValue={setValue} value={value} />
          <TabPanel value="1" className={classes.tabPanel}>
            <TaxPermitsUcr />
          </TabPanel>
          <TabPanel value="2" className={classes.tabPanel}>
            Item Two
          </TabPanel>
          <TabPanel value="3" className={classes.tabPanel}>
            Item Three
          </TabPanel>
          <TabPanel value="4" className={classes.tabPanel}>
            Item Four
          </TabPanel>
          <TabPanel value="5" className={classes.tabPanel}>
            Item Five
          </TabPanel>
          <TabPanel value="6" className={classes.tabPanel}>
            Item Six
          </TabPanel>
          <TabPanel value="7" className={classes.tabPanel}>
            Item Seven
          </TabPanel>
          <TabPanel value="8" className={classes.tabPanel}>
            Item Eight
          </TabPanel>
        </TabContext>
      </Grid>

      {/* Right Side content */}
      <Grid item xs={12} md={4} sm={12}>
        <Typography
          component={'div'}
          display={'inline-flex'}
          flexWrap={'wrap'}
          width={'100%'}
          style={{ backgroundColor: 'rgb(234 234 234 / 38%)' }}>
          <Typography
            component={'span'}
            display={'flex'}
            fontSize={'16px'}
            fontWeight={600}
            height={'50px'}
            alignItems={'center'}
            padding={'14px'}
            // sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom>
            Activity List
          </Typography>
          <Typography
            component={'span'}
            display={'flex'}
            fontSize={'16px'}
            fontWeight={'500 !important'}
            height={'50px'}
            alignItems={'center'}
            fontStyle={'italic'}
            padding={'14px'}
            // sx={{ fontSize: 14 }}
            color="#A0A0A0"
            gutterBottom>
            3 Not Complete
          </Typography>
        </Typography>
        <TaxPermitsCardList />
      </Grid>
    </Grid>
  );
};

export default observer(TaxPermits);
