import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  PaLeft: {
    paddingLeft: '0.25rem !important',
  },
  PaRight: {
    paddingRight: '0.25rem !important',
  },
  addTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-bold !important',
    fontSize: '1.125rem !important',
    paddingLeft: '1rem',
  },
  btnBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancel: {
    color: '#777777 !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.75rem !important',
    letterSpacing: '0.8px',
    textDecoration: 'underline solid #777777',
    textTransform: 'uppercase',
  },
  createBtn: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '4px',
    color: '#FFFFFF !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '44px',
    justifyContent: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  customBox: {
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.08)',
    marginBottom: '1rem !important',
    marginLeft: '1rem !important',
    minHheight: '232px',
    paddingBottom: '1rem !important',
    paddingRight: '1rem !important',
  },
  customCheck: {
    '& .MuiSvgIcon-MuiPaper-elevation': {
      marginLeft: '0.5rem !important',
    },
    height: '35px',
    paddingLeft: 'unset !important',
    width: '35px',
  },
  customDate: {
    '& .MuiPickersDay-root': {
      backgroundColor: 'unset',
      fontFamily: 'FiraSans-Medium !important',
      fontSize: '1rem !important',
    },
    '& .MuiTypography-caption': {
      color: '#241A2E !important',
      fontFamily: 'FiraSans-Bold !important',
      fontSize: '1rem !important',
      opacity: '0.43 !important',
    },
  },
  customDatetitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1.12rem !important',
    padding: '1rem 1.5rem 0.5rem 0rem !important',
  },
  datetime: {
    '& .MuiIconButton-edgeEnd': {
      marginRight: '-17px !important',
      padding: '8px 6px 8px 0px !important',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: '0px !important',
    },
    '& .MuiInputBase-adornedEnd': {
      paddingRight: '17px !important',
    },
    '& svg': {
      width: '20px',
    },
    backgroundColor: '#F8F8FA',
    borderRadius: '7px',
    height: '43px',
    marginRight: '.5rem !important',
  },
  delete: {
    color: '#db2426 !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.75rem !important',
    letterSpacing: '0.8px',
    marginRight: '1rem !important',
    textDecoration: 'underline solid #db2426',
    textTransform: 'uppercase',
  },
  formBox: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.08)',
    marginBottom: '1rem !important',
    minHeight: '400px',
    padding: '1.5rem',
  },
  formLabelClass: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },
  imgBox: {
    backgroundColor: '#F8F8FA',
    border: '1px solid #E0E0E0',
    height: '222px',
    marginTop: '1rem !important',
    position: 'relative',
    width: '222px',
  },
  imgnot: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  leftBox: {
    backgroundColor: '#ffffff',
  },
  leftR: {
    height: 'calc(100vh - 80px)',
    overflowY: 'auto',
  },
  marY: {
    margin: '1rem 0 !important',
  },
  minmax: {
    '& .Mui-disabled': {
      borderRadius: '7px',
      height: '43px',
    },
    '& .MuiInput-input': {
      height: '43px !important',
      paddingLeft: '.5rem !important',
      paddingTop: '.5rem !important',
    },
    backgroundColor: '#F8F8FA',
    borderRadius: '7px',
    height: '43px',
  },
  notActive: {
    backgroundColor: '#EEF5FF',
    borderBottom: '1px solid #EAEAEA',
    cursor: 'pointer',
    padding: '1.5rem',
  },
  notDate: {
    color: '#0061FF !important',
    fontSize: '0.87rem !important',
    marginTop: '1rem !important',
  },
  notDateGreen: {
    color: '#0DA728 !important',
    fontSize: '0.87rem !important',
    marginTop: '1rem !important',
  },
  notDetails: {
    borderBottom: '1px solid #EAEAEA',
    cursor: 'pointer',
    padding: '1.5rem',
  },
  notDisable: {
    backgroundColor: '#F8F8FA !important',
    borderBottom: '1px solid #EAEAEA',
    cursor: 'pointer',
    padding: '1.5rem',
  },
  notDisableDate: {
    color: '#777777 !important',
    fontSize: '0.87rem !important',
    marginTop: '1rem !important',
  },
  notDisc: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Light !important',
    fontSize: '1rem !important',
    lineHeight: '19px',
    marginBottom: '1rem !important',
    marginTop: '0.5rem !important',
  },
  notLink: {
    color: '#0061FF !important',
    fontSize: '0.87rem !important',
    marginTop: '1rem !important',
    textDecoration: 'underline solid #0061FF',
    wordBreak: 'break-all',
  },
  notTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1.12rem !important',
  },
  notactiveDate: {
    color: ' #0DA728 !important',
    fontSize: '0.87rem !important',
    marginTop: '1rem !important',
  },
  option: {
    color: '#979598 !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '0.87rem !important',
  },
  period: {
    alignItems: 'center',
    backgroundColor: '#eeeeef',
    borderRadius: '7px',
    display: 'flex',
    height: '29.03px',
    marginTop: '2rem !important',
    padding: '0 3px',
  },
  periodActive: {
    alignItems: 'center',
    backgroundColor: '#ffffff !important',
    borderRadius: '7px',
    cursor: 'pointer',
    display: 'flex',
    flex: '1',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.87rem !important',
    height: '27px',
    justifyContent: 'center',
    lineHeight: 'normal !important',
    textTransform: 'uppercase',
  },
  periodNormal: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flex: '1',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.87rem !important',
    height: '29.03px',
    justifyContent: 'center',
    lineHeight: 'normal !important',
    textTransform: 'uppercase',
  },
  reciadd: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1rem !important',
    margin: '.5rem 0 !important',
    textDecoration: 'underline solid #0061FF !important',
  },
  reciaddbox: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  reciadditem: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '20px',
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
    marginBottom: '0.5rem !important',
    marginRight: '.5rem !important',
    minWidth: '96px',
    padding: '1rem',
  },
  reciclose: {
    color: '#000000',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '14px !important',
    opacity: '0.2',
    paddingLeft: '.5rem',
    transform: 'scale(1.2)',
  },
  recipent: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.08)',
    marginBottom: '2rem !important',
    marginLeft: '1rem !important',
    minHeight: '274px',
    padding: '1rem 1.5rem',
  },
  recitext: {
    color: '#FFFFFF !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1rem !important',
  },
  recititle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1.125rem !important',
    marginBottom: '.6rem !important',
    marginTop: '1rem !important',
  },
  remove: {
    alignItems: 'center',
    backgroundColor: '#D8D8D8',
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
    color: '#979797 !important',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '8px !important',
    height: '20px',
    justifyContent: 'center',
    position: 'absolute',
    right: '-.5rem',
    top: '-.5rem',
    width: '20px',
  },
  scheduleStatusColor: {
    backgroundColor: 'green',
  },
  scrollB: {
    overflowY: 'auto',
  },
  selectClassName: {
    backgroundColor: '#F8F8FA !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
  },
  selectIn: {
    '& .Mui-disabled': {
      borderRadius: '7px',
      height: '43px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#F8F8FA !important',
      borderRadius: '7px !important',
      color: '#241A2E !important',
      fontFamily: 'FiraSans-Medium !important',
      fontSize: '0.875rem !important',
      fontWeight: ' 500 !important',
      height: '43px',
      letterSpacing: '0 !important',
    },
  },
  selectText: {
    '& .Mui-disabled': {
      borderRadius: '7px',
    },
    '& .MuiInputBase-inputMultiline': {
      overflow: 'auto',
      resize: 'vertical',
    },
    backgroundColor: '#F8F8FA !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.875rem !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
  },
  selectW: {
    '& .MuiSelectUnstyled-popper': {
      left: '-10% !important',
    },
    '& .MuiSelectUnstyled-root': {
      margin: 'unset !important',
      minWidth: '100% !important',
    },
  },
  splas: {
    alignItems: 'center',
    backgroundColor: '#0DA728',
    borderRadius: '32px',
    color: '#fff !important',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '12px !important',
    height: '28px',
    justifyContent: 'center',
    lineHeight: 'normal !important',
    marginTop: '.5rem !important',
    padding: '0 .6rem',
    width: '148px',
  },
  submit: {
    alignItems: 'center',
    backgroundColor: '#241A2E',
    borderRadius: '4px',
    color: '#ffffff !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '48px',
    justifyContent: 'center',
    letterSpacing: '0.94px',
    marginLeft: '1rem !important',
    minWidth: '100px',
    padding: '0 1rem !important',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
