import {
  CircularProgress,
  Divider,
  Grid,
  TableContainer,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

import { useCustomStore } from '../../../hooks';
import { AuthStore } from '../../../store/auth';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import useStyles from './companyStyle';

type ListPropsTypes = {
  usersList: any[];
  isLast: any;
  enqueueSnackbar: any;
  authStore: AuthStore;
  companyId: string;
  getMoreCompanyUsers: (companyId: string) => void;
};

const CompanyUserList = (props: ListPropsTypes): React.ReactElement => {
  const {
    authStore,
    isLast,
    usersList,
    enqueueSnackbar,
    getMoreCompanyUsers,
    companyId,
  } = props;
  const classes = useStyles();

  const { companyStore } = useCustomStore();

  const loginAs = async (user: any) => {
    const bodyData = {
      contactId: user.contactId,
      name: user.name,
    };
    const tokenResponse = await authStore.impersonateClientToken(bodyData);
    if (tokenResponse.isErr()) {
      enqueueSnackbar(String(tokenResponse.error.message), SnackBarConfig('e'));
    }
  };

  return (
    <TableContainer sx={{ maxHeight: '50vh' }} id="companies-user-list-table">
      <InfiniteScroll
        next={() => getMoreCompanyUsers(companyId)}
        hasMore={companyStore.hasMoreCompanyUsers}
        scrollableTarget={'companies-user-list-table'}
        style={{ overflow: 'unset' }}
        loader={
          <div style={{ textAlign: 'center', width: '100%' }}>
            <CircularProgress
              size={30}
              sx={{ color: '#DEC330', marginTop: 5 }}
            />
          </div>
        }
        dataLength={usersList.length}>
        {usersList.map((user, index) => {
          return (
            <Grid key={index} container paddingBottom={'23px'}>
              <Grid item xs={2} md={2} lg={2}>
                <div className="d-flex-a">
                  <Grid
                    style={{
                      backgroundColor: '#0DA728',
                      borderRadius: '50%',
                      height: '8px',
                      width: '8px',
                    }}
                  />
                  <Typography
                    noWrap
                    sx={{
                      color: '#241A2E',
                      flex: '1',
                      float: 'left',
                      fontFamily: 'FiraSans-Bold',
                      fontSize: '15px',
                      fontWeight: 500,
                      letterSpacing: 0,
                      lineHeight: '22px',
                      paddingLeft: '0.5rem',
                      width: '100px',
                    }}>
                    {user.name}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} md={2} lg={2}>
                <Typography
                  noWrap
                  sx={{
                    color: '#241A2E',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '15px',
                    fontWeight: 500,
                    height: '22px',
                    letterSpacing: 0,
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}>
                  {user.role}
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Typography
                  noWrap
                  sx={{
                    color: '#241A2E',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '15px',
                    fontWeight: 500,
                    height: '22px',
                    letterSpacing: 0,
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}>
                  {user.email}
                </Typography>
              </Grid>

              <Grid item xs={2} md={2} lg={2}>
                <Typography
                  noWrap
                  sx={{
                    color: '#241A2E',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '15px',
                    fontWeight: 500,
                    height: '22px',
                    letterSpacing: 0,
                    lineHeight: '22px',
                    textAlign: 'center',
                  }}>
                  {user.phone}
                </Typography>
              </Grid>
              {authStore.pageAccess &&
              authStore.pageAccess?.impersonateAdmin ? (
                <Grid item xs={2} md={2} lg={2}>
                  <Typography noWrap textAlign={'right'}>
                    <Link
                      to="#"
                      className={classes.loginAsAdmin}
                      onClick={() => loginAs(user)}>
                      Login as admin
                    </Link>
                  </Typography>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          );
        })}
        {!isLast ? (
          <Divider
            sx={{
              backgroundColor: '#D8D8D8',
              height: '1px',
            }}
          />
        ) : (
          <Divider
            sx={{
              backgroundColor: '#FFFFFF',
              height: '1px',
            }}
          />
        )}
      </InfiniteScroll>
    </TableContainer>
  );
};

export default observer(CompanyUserList);
