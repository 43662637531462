import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  activeItem: {
    '& div': {
      color: '#ffffff !important',
    },
    background:
      'linear-gradient(90deg,#52442e 0%,#52442e 68.69%, #40332e 100%)',
    borderRadius: '8px 0 0 8px',
    // alignItems: 'center',
    // cursor: 'pointer',
    // display: 'flex',
    // height: '48px',
    // padding: '0em 1rem',
  },
  company: {
    color: '#DEC330',
    fontSize: '14px',
    lineHeight: '17px',
  },
  logo: {
    padding: '0 0 0 1.5rem',
  },
  menuBox: {
    height: '78vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingLeft: '0.3rem',
    paddingTop: '1rem',
  },
  menuItem: {
    '&:hover': {
      '& div': {
        color: '#ffffff !important',
      },
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '48px',
    padding: '0em 1rem',
  },
  menuText: {
    color: '#979598',
    fontSize: '15px',
    fontWeight: '500',
    paddingLeft: '0.5rem',
  },
  name: {
    color: '#FFFFFF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '16px',
    lineHeight: '23px',
  },
  portal: {
    color: '#979598',
    fontSize: '10px',
    fontWeight: '500',
    letterSpacing: '3.33px',
    padding: ' 0.3rem 0 0 1.5rem',
    textTransform: 'uppercase',
  },
  profileBox: {
    alignItems: 'center',
    borderTop: ' 1px solid #323232',
    bottom: '0',
    cursor: 'pointer',
    display: 'flex',
    left: '0',
    padding: '1rem 0 1rem 1.2rem',
    position: 'fixed',
    width: '245px',
    zIndex: '101',
  },
  profileImg: {
    transform: 'rotate(270deg)',
  },
  sidebar: {
    backgroundColor: '#000000',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    bottom: '0',
    height: '100vh',
    left: '0',
    maxHeight: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '1rem 0',
    position: 'fixed',
    top: '0',
    width: '245px',
    zIndex: '100',
  },
  w10: { width: '10%' },
  w90: { width: '90%' },
}));

export default useStyles;
