import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useCustomStore } from '../../hooks';
import { AdminRoleFormInputs, AdminUsersSearchFormInputs } from '../../types';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import {
  AdminRolesSchema,
  AdminUsersSearchSchema,
} from '../../utils/ValidatorSchema';
import DialogMigrate from '../Dialog/DialogMigrate';
import { InputText } from '../Forms/InputText';
import { SelectInput } from '../Forms/selectInput';
import search from './../../assets/images/h-search.svg';
import useStyles from './AdminDialogStyles';

const DarkColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
  },
  backgroundColor: '#241A2E',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#241A2E'),
  height: '44px',
}));

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

const FallbackText = styled(Typography)((props) => ({
  color: '#DB2426',
  fontSize: 12,
  fontStyle: 'italic',
  fontWeight: 'bold',
  margin: '5px 0',
}));

const AdminDialog: React.FC<any> = (props: any) => {
  const { isOpen, closeDialog } = props;
  const headers = ['Full Name', 'Email Address'];
  const classes = useStyles();
  const { adminUsersStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const { AdminUsersShowLoader, AdminUsersList, AdminRolesList } =
    adminUsersStore;
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [searchAdminUsers, setSearchAdminUsers] =
    useState<AdminUsersSearchFormInputs>({
      email: '',
      firstName: '',
      lastName: '',
    });
  const [selectList, setSelectList] = useState<Array<any>>([]);

  const {
    control,
    // handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<AdminUsersSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(AdminUsersSearchSchema),
  });

  const {
    control: rolControl,
    handleSubmit: handleRoleSubmit,
    formState: { errors: roleErrors },
  } = useForm<AdminRoleFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      addNotifications: false,
      impersonateAdmin: false,
      impersonateDrivers: false,
      manageTrainings: false,
      name: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(AdminRolesSchema),
  });

  const getAllAdminUsers = useCallback(async () => {
    adminUsersStore.setAdminUsersShowLoader(true);
    const getAdminUsersResp = await adminUsersStore.fetchAdminUsers();
    if (getAdminUsersResp.isErr()) {
      enqueueSnackbar(
        String(getAdminUsersResp.error.message),
        SnackBarConfig('e'),
      );
    }

    const getAdminRolesResp = await adminUsersStore.fetchAdminRoles();
    if (getAdminRolesResp.isErr()) {
      enqueueSnackbar(
        String(getAdminRolesResp.error.message),
        SnackBarConfig('e'),
      );
    }
    adminUsersStore.setAdminUsersShowLoader(false);
  }, [adminUsersStore, enqueueSnackbar]);

  const getSearchBasedAdminUsers = useCallback(
    async (searchParams: AdminUsersSearchFormInputs) => {
      if (
        !searchParams.firstName &&
        !searchParams.lastName &&
        !searchParams.email
      ) {
        adminUsersStore.setAdminUsersApiReset();
        getAllAdminUsers();
        return;
      }
      setGetMoreLoader(true);
      const getAdminUsersResp =
        await adminUsersStore.fetchAdminUsersBySearchBased(searchParams);
      setGetMoreLoader(false);
      adminUsersStore.setAdminUsersShowLoader(false);
      if (getAdminUsersResp.isErr()) {
        enqueueSnackbar(
          String(getAdminUsersResp.error.message),
          SnackBarConfig('e'),
        );
      }
    },
    [adminUsersStore, enqueueSnackbar, getAllAdminUsers],
  );

  const getMoreAdminUsers = useCallback(async () => {
    if (
      searchAdminUsers.firstName ||
      searchAdminUsers.lastName ||
      searchAdminUsers.email
    ) {
      getSearchBasedAdminUsers(searchAdminUsers);
      return;
    }
    setGetMoreLoader(true);
    const getAdminUsersResp = await adminUsersStore.fetchAdminUsers();
    if (getAdminUsersResp.isErr()) {
      enqueueSnackbar(
        String(getAdminUsersResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [
    adminUsersStore,
    enqueueSnackbar,
    getSearchBasedAdminUsers,
    searchAdminUsers,
  ]);

  const adminUserSearchSubmit = useCallback(
    (data: AdminUsersSearchFormInputs) => {
      if (data.firstName || data.lastName || data.email) {
        adminUsersStore.setAdminUsersShowLoader(true);
        adminUsersStore.setAdminUsersApiReset();
        setSearchAdminUsers((pre) => ({ ...pre, ...data }));
        getSearchBasedAdminUsers(data);
      }
    },
    [adminUsersStore, getSearchBasedAdminUsers],
  );

  const onCheck = (adminUser: any) => {
    let arr = selectList;
    const index = selectList.findIndex(
      (val: any) => val.systemUserId === adminUser.adminId,
    );
    if (index !== -1) {
      arr = arr.filter((val: any) => val.systemUserId !== adminUser.adminId);
    } else {
      arr.push({
        email: adminUser.email,
        name: adminUser.name,
        roleId: '',
        systemUserId: adminUser.adminId,
      });
    }
    setSelectList([...arr]);
  };

  const onSelect = (adminUser: any, role: string) => {
    const arr = selectList;

    const index = selectList.findIndex(
      (val: any) => val.systemUserId === adminUser.adminId,
    );
    if (index === -1) {
      arr.push({
        email: adminUser.email,
        name: adminUser.name,
        roleId: role,
        systemUserId: adminUser.adminId,
      });
    }

    selectList.map((val: any, index: any) => {
      if (val.systemUserId === adminUser.adminId) {
        arr[index].roleId = role;
      }
    });
    setSelectList([...arr]);
  };

  const closeDialogWindow = useCallback(() => {
    adminUsersStore.setAdminUsersApiReset();
    closeDialog && closeDialog();
  }, [adminUsersStore, closeDialog]);

  const onUserSubmit = useCallback(async () => {
    if (selectList.some((val) => val.roleId === '')) {
      return;
    }
    adminUsersStore.setAdminUsersShowLoader(true);
    const addAdminUsersResp = await adminUsersStore.addAdminUsers(selectList);
    if (addAdminUsersResp.isErr()) {
      enqueueSnackbar(
        String(addAdminUsersResp.error.message),
        SnackBarConfig('e'),
      );
    } else {
      adminUsersStore.setAdminUsersShowLoader(false);
      enqueueSnackbar(String(addAdminUsersResp.value), SnackBarConfig('s'));

      adminUsersStore.setShowLoader(true);
      adminUsersStore.setAdminApiReset();
      const getAdminsResp = await adminUsersStore.fetchAdmins();

      if (getAdminsResp.isErr()) {
        enqueueSnackbar(
          String(getAdminsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      adminUsersStore.setShowLoader(false);
      closeDialogWindow();
    }
    adminUsersStore.setAdminUsersShowLoader(false);
  }, [adminUsersStore, closeDialogWindow, enqueueSnackbar, selectList]);

  const onRoleSubmit = useCallback(async (data: any) => {
    // const data = adminUsersStore.getNewRoleDetails;
    setBtnDisable(true);
    adminUsersStore.setAdminUsersShowLoader(true);
    const addAdminRolesResp = await adminUsersStore.addAdminRoles(data);
    setBtnDisable(false);
    if (addAdminRolesResp.isErr()) {
      enqueueSnackbar(
        String(addAdminRolesResp.error.message),
        SnackBarConfig('e'),
      );
    } else {
      enqueueSnackbar(String(addAdminRolesResp.value), SnackBarConfig('s'));

      adminUsersStore.setShowLoader(true);
      adminUsersStore.setAdminApiReset();
      const getAdminsResp = await adminUsersStore.fetchAdmins();

      if (getAdminsResp.isErr()) {
        enqueueSnackbar(
          String(getAdminsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      adminUsersStore.setShowLoader(false);
      adminUsersStore.setAdminUsersShowLoader(false);
      closeDialogWindow();
    }
    adminUsersStore.setAdminUsersShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearForm = () => {
    reset();
    setSearchAdminUsers((pre) => ({
      ...pre,
      ...{
        email: '',
        firstName: '',
        lastName: '',
      },
    }));
    adminUsersStore.setAdminUsersApiReset();
    getAllAdminUsers();
  };

  const searchValue = () => {
    adminUserSearchSubmit(getValues());
  };

  useEffect(() => {
    setSelectList([]);
  }, []);

  useEffect(() => {
    getAllAdminUsers();
  }, [getAllAdminUsers]);

  return (
    <DialogMigrate
      aria-labelledby="admin-users-select"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={closeDialogWindow}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '1.375rem',
          letterSpacing: 0,
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>
            {adminUsersStore.Status === 'Users'
              ? 'Search & Select User(s)'
              : 'Add Role'}
          </div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialogWindow();
            }}>
            <ClearIcon />
          </Link>
        </div>
      </DialogTitle>
      {adminUsersStore.Status === 'Users' && (
        <form id={'admin-users-dialog'} className={classes.headerWrapper}>
          <Grid
            container
            spacing={1}
            ml={0}
            pl={'15px'}
            pr={'24px'}
            sx={{ paddingBottom: '9.5px', width: '100%' }}>
            <Grid item md={3} xs={3}>
              <FormControl>
                <Controller
                  control={control}
                  defaultValue=""
                  name="firstName"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        name={name}
                        inputRefObj={ref}
                        placeholder="First Name"
                        errors={errors.firstName?.message || ''}
                        isRefreshValue={true}
                        isEditable={false}
                        type={'text'}
                        variant="standard"
                        value={value}
                        initIcon={
                          <InputAdornment position="start">
                            <img className={classes.hIcon} src={search} />
                          </InputAdornment>
                        }
                        className={classes.selectClassName}
                        onChangeText={(val) => {
                          onChange(val);
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={3} xs={3}>
              <FormControl>
                <Controller
                  control={control}
                  defaultValue=""
                  name="lastName"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        name={name}
                        inputRefObj={ref}
                        placeholder="Last Name"
                        errors={errors.lastName?.message || ''}
                        isRefreshValue={true}
                        isEditable={false}
                        type={'text'}
                        variant="standard"
                        value={value}
                        initIcon={
                          <InputAdornment position="start">
                            <img className={classes.hIcon} src={search} />
                          </InputAdornment>
                        }
                        className={classes.selectClassName}
                        onChangeText={(val) => {
                          onChange(val);
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4} xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="email"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <InputText
                        name={name}
                        inputRefObj={ref}
                        placeholder="Email Address"
                        errors={errors.email?.message || ''}
                        isRefreshValue={true}
                        isEditable={false}
                        type={'text'}
                        variant="standard"
                        value={value}
                        initIcon={
                          <InputAdornment position="start">
                            <img className={classes.hIcon} src={search} />
                          </InputAdornment>
                        }
                        className={classes.selectClassName}
                        onChangeText={(val) => {
                          onChange(val);
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2} xs={2}>
              <div className={classes.headerActionBtn}>
                <DarkColorButton
                  type="button"
                  onClick={() => searchValue()}
                  variant="contained"
                  sx={{
                    borderRadius: '4px',
                    padding: '0 10px !important',
                    width: '100%',
                  }}>
                  <Typography
                    sx={{
                      color: '#FFFFFF',
                      fontFamily: 'FiraSans-Medium',
                      fontSize: '0.9375rem',
                      fontWeight: 500,
                      height: '22px',
                      letterSpacing: '1px',
                      lineHeight: '22px',
                      textAlign: 'center',
                    }}>
                    Search
                  </Typography>
                </DarkColorButton>

                {searchAdminUsers.firstName ||
                searchAdminUsers.lastName ||
                searchAdminUsers.email ? (
                  <Typography component={'span'} marginLeft={'12px'}>
                    <Button
                      variant={'text'}
                      className={'d-flex-ja '.concat(classes.cancelBtn)}
                      onClick={() => clearForm()}>
                      Clear
                    </Button>
                  </Typography>
                ) : (
                  ''
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      )}

      <DialogContent id="scroll">
        {adminUsersStore.Status === 'Users' ? (
          <>
            <Box
              sx={{
                marginBottom: '15px',
              }}>
              <Grid container spacing={2}>
                <Divider className={classes.divider} />
                {headers.map((header: any, index: number) => (
                  <Grid item xs={4} key={index}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#A4A4A4',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.75rem',
                        height: '14px',
                        letterSpacing: 0,
                        lineHeight: '14px',
                        width: '89px',
                      }}>
                      {header}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <InfiniteScroll
              dataLength={AdminUsersList.length}
              // height={windowHeight}
              style={{ overflow: 'unset' }}
              next={getMoreAdminUsers}
              hasMore={adminUsersStore.AdminUsersHasMoreResults}
              scrollableTarget={'scroll'}
              loader={
                getMoreLoader && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Grid
                container
                spacing={2}
                style={{
                  backgroundColor: '#FFFFFF',
                  marginBottom: '10px',
                }}>
                {AdminUsersShowLoader ? (
                  <div className={classes.loader}>
                    <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                  </div>
                ) : AdminUsersList.length > 0 ? (
                  AdminUsersList.map((adminUser: any, index: number) => (
                    <React.Fragment key={index}>
                      <Grid item xs={1}>
                        <Checkbox
                          className={classes.customCheck}
                          checked={selectList.some((val) =>
                            val.systemUserId === adminUser.adminId
                              ? true
                              : false,
                          )}
                          onChange={(event: any) => {
                            onCheck(adminUser);
                          }}
                          color="success"
                          tabIndex={0}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          noWrap
                          sx={{
                            color: '#241A2E',
                            fontFamily: 'FiraSans-Regular',
                            fontSize: '0.9375rem',
                            letterSpacing: 0,
                            paddingTop: '10px !important',
                            textTransform: 'capitalize',
                          }}>
                          {adminUser.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          noWrap
                          sx={{
                            color: '#241A2E',
                            fontFamily: 'FiraSans-Regular',
                            fontSize: '0.9375rem',
                            letterSpacing: 0,
                            paddingTop: '10px !important',
                            textTransform: 'capitalize',
                          }}>
                          {adminUser.email || (
                            <FallbackText>missing</FallbackText>
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{ paddingBottom: '10px !important' }}>
                        <SelectInput
                          name={'role'}
                          errors={
                            selectList.some(
                              (val) =>
                                val.systemUserId === adminUser.adminId &&
                                val.roleId === '',
                            )
                              ? 'Required'
                              : ''
                          }
                          isRefreshValue={true}
                          value={
                            selectList.find(
                              (val) => val.systemUserId === adminUser.adminId,
                            )?.roleId || 'Select Role'
                          }
                          inputRefObj={null}
                          size={'medium'}
                          onChangeText={(e: any) => {
                            onSelect(adminUser, e);
                          }}
                          isEditable={!adminUser.email}
                          selectClassName={classes.selectClassName}
                          placeHolder={'Select'}
                          optionsList={
                            <>
                              <option
                                value="Select Role"
                                disabled
                                className={classes.optionsClassName}>
                                Select Role
                              </option>
                              {AdminRolesList.length &&
                                AdminRolesList.map((val, index) => (
                                  <option
                                    value={val.roleId}
                                    key={index}
                                    disabled={!val}
                                    className={classes.optionsClassName}>
                                    {val.name}
                                  </option>
                                ))}
                            </>
                          }
                        />
                      </Grid>
                      <Divider className={classes.divider} />
                    </React.Fragment>
                  ))
                ) : (
                  <div className={classes.noRecordsFound}>
                    No record(s) found
                  </div>
                )}
                <Divider className={classes.divider} />
              </Grid>
            </InfiniteScroll>
          </>
        ) : (
          <form
            style={{
              width: '100%',
            }}
            id="role-create-form"
            onSubmit={handleRoleSubmit(onRoleSubmit)}>
            <Grid
              container
              sx={{ paddingLeft: 'unset !important' }}
              style={{
                marginTop: '20px',
                paddingLeft: 'unset !important',
              }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Box className={classes.formLabelClass}>Role Name *</Box>
                <Controller
                  control={rolControl}
                  defaultValue=""
                  name="name"
                  render={({
                    field: { onChange, value, name, ref, onBlur },
                    formState,
                  }) => {
                    return (
                      <>
                        <InputText
                          inputRefObj={ref}
                          name={name}
                          isRefreshValue={true}
                          value={value}
                          placeholder="Role Name"
                          onChangeText={(val) => {
                            onChange(val);
                            adminUsersStore.setNewRoleDetails('name', val);
                          }}
                          onBlurText={onBlur}
                          isEditable={false}
                          className={classes.inputF}
                          type={'text'}
                          variant="outlined"
                        />
                        <span className="custom-error">
                          {roleErrors.name?.message || ''}
                        </span>
                      </>
                    );
                  }}
                />
              </Grid>

              <Grid item lg={2} md={2} sm={2} xs={2} className={classes.center}>
                <Box className={classes.formLabelClass}>Impersonate Admin</Box>
                <Controller
                  control={rolControl}
                  defaultValue={false}
                  name="impersonateAdmin"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <Checkbox
                        inputRef={ref}
                        disabled={false}
                        checked={value}
                        onChange={(ev) => {
                          onChange(ev.target.checked);
                          adminUsersStore.setNewRoleDetails(
                            'impersonateAdmin',
                            ev.target.checked,
                          );
                        }}
                        name={name}
                        value={value}
                        color="success"
                        tabIndex={0}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item lg={2} md={2} sm={2} xs={2} className={classes.center}>
                <Box className={classes.formLabelClass}>
                  Impersonate Drivers
                </Box>
                <Controller
                  control={rolControl}
                  defaultValue={false}
                  name="impersonateDrivers"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <Checkbox
                        inputRef={ref}
                        disabled={false}
                        checked={value}
                        onChange={(ev) => {
                          onChange(ev.target.checked);
                          adminUsersStore.setNewRoleDetails(
                            'impersonateDrivers',
                            ev.target.checked,
                          );
                        }}
                        name={name}
                        value={value}
                        color="success"
                        tabIndex={0}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item lg={2} md={2} sm={2} xs={2} className={classes.center}>
                <Box className={classes.formLabelClass}>Add Notifications</Box>
                <Controller
                  control={rolControl}
                  defaultValue={false}
                  name="addNotifications"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <Checkbox
                        inputRef={ref}
                        disabled={false}
                        checked={value}
                        onChange={(ev) => {
                          onChange(ev.target.checked);
                          adminUsersStore.setNewRoleDetails(
                            'addNotifications',
                            ev.target.checked,
                          );
                        }}
                        name={name}
                        value={value}
                        color="success"
                        tabIndex={0}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item lg={2} md={2} sm={2} xs={2} className={classes.center}>
                <Box className={classes.formLabelClass}>Manage Trainings</Box>
                <Controller
                  control={rolControl}
                  defaultValue={false}
                  name="manageTrainings"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <Checkbox
                        inputRef={ref}
                        disabled={false}
                        checked={value}
                        onChange={(ev) => {
                          onChange(ev.target.checked);
                          adminUsersStore.setNewRoleDetails(
                            'manageTrainings',
                            ev.target.checked,
                          );
                        }}
                        name={name}
                        value={value}
                        color="success"
                        tabIndex={0}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
      <DialogActions className={classes.jxy}>
        {adminUsersStore.Status === 'Users' ? (
          <BottomButton
            disabled={btnDisable}
            type={'button'}
            variant="contained"
            sx={{ width: '100%' }}
            onClick={() => selectList.length && onUserSubmit()}>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontFamily: 'FiraSans-Medium',
                fontSize: '0.9375rem',
                fontWeight: '500',
                height: '22px',
                letterSpacing: '1px',
                lineHeight: '22px',
                textAlign: 'center',
              }}>
              INVITE USERS
            </Typography>
          </BottomButton>
        ) : (
          <BottomButton
            sx={{
              backgroundColor: '#DEC330',
              borderRadius: '4px',
              color: '#FFFFFF',
              fontFamily: 'FiraSans-Medium',

              fontSize: '0.9375rem',
              fontWeight: '500',
              height: '44px',
              margin: '0px 18px 10px 18px',
              textAlign: 'center',
            }}
            form={'role-create-form'}
            disabled={btnDisable}
            type="submit"
            variant="contained">
            Create Role
          </BottomButton>
        )}
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(AdminDialog);
