/* eslint-disable react/react-in-jsx-scope */
import TabList from '@mui/lab/TabList';
import { Divider } from '@mui/material';
import Tab from '@mui/material/Tab';

import logo from './../../../../../assets/images/n-todo.svg';
import { Img, useStyles } from './NavTabsStyles';

type NavBarPropsTypes = {
  setValue: any;
  value: string;
};
const TaxPermitsNavBarList = (props: NavBarPropsTypes) => {
  const classes = useStyles();
  const { setValue, value } = props;
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <>
      <TabList
        onChange={handleChange}
        aria-label="lab API tabs example"
        textColor="secondary"
        className={classes.tabs}
        variant="scrollable"
        scrollButtons={'auto'}
        allowScrollButtonsMobile={true}>
        <Tab
          icon={
            <div
              className={`${classes.tabsImage} ${
                value === '1' ? classes.tabImageHighlight : ''
              }`}>
              <Img alt="complex" src={logo} />
            </div>
          }
          label={<div className={classes.tabsHeader}>UCR</div>}
          value="1"
        />
        <Tab
          icon={
            <div
              className={`${classes.tabsImage} ${
                value === '2' ? classes.tabImageHighlight : ''
              }`}>
              <Img alt="complex" src={logo} />
            </div>
          }
          label={<div className={classes.tabsHeader}>Road Taxes /2290</div>}
          value="2"
          wrapped
        />
        <Tab
          icon={
            <div
              className={`${classes.tabsImage} ${
                value === '3' ? classes.tabImageHighlight : ''
              }`}>
              <Img alt="complex" src={logo} />
            </div>
          }
          label={<div className={classes.tabsHeader}>Annual Report</div>}
          value="3"
          wrapped
        />
        <Tab
          icon={
            <div
              className={`${classes.tabsImage} ${
                value === '4' ? classes.tabImageHighlight : ''
              }`}>
              <Img alt="complex" src={logo} />
            </div>
          }
          label={<div className={classes.tabsHeader}>IFTA</div>}
          value="4"
          wrapped
        />
        <Tab
          icon={
            <div
              className={`${classes.tabsImage} ${
                value === '5' ? classes.tabImageHighlight : ''
              }`}>
              <Img alt="complex" src={logo} />
            </div>
          }
          label={<div className={classes.tabsHeader}>State Permits</div>}
          value="5"
          wrapped
        />
        <Tab
          icon={
            <div
              className={`${classes.tabsImage} ${
                value === '6' ? classes.tabImageHighlight : ''
              }`}>
              <Img alt="complex" src={logo} />
            </div>
          }
          label={<div className={classes.tabsHeader}>IRP Service</div>}
          value="6"
          wrapped
        />
        <Tab
          icon={
            <div
              className={`${classes.tabsImage} ${
                value === '7' ? classes.tabImageHighlight : ''
              }`}>
              <Img alt="complex" src={logo} />
            </div>
          }
          label={<div className={classes.tabsHeader}>DOT Update</div>}
          value="7"
          wrapped
        />
        <Tab
          icon={
            <div
              className={`${classes.tabsImage} ${
                value === '8' ? classes.tabImageHighlight : ''
              }`}>
              <Img alt="complex" src={logo} />
            </div>
          }
          label={<div className={classes.tabsHeader}>FT Quarter Filing</div>}
          value="8"
          wrapped
        />
      </TabList>
      <Divider />
    </>
  );
};

export default TaxPermitsNavBarList;
