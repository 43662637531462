import './home.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';

import LeftNav from '../../../components/LeftNav/LeftNav';
import TopAppBar from '../../../components/TopAppBar/TopAppBar';
import { useCustomStore } from '../../../hooks';
import useStyles from './homeStyle';

const Home: React.FC = (props: any) => {
  const classes = useStyles();
  const history = useLocation();
  const { globalStore }: any = useCustomStore();

  React.useEffect(() => {
    globalStore.setCurrentRoute(history.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.pathname]);

  // return (
  //   <div className="main-container">
  //     <LeftNav />
  //     <div className="main-content">
  //       <TopAppBar />
  //       <div className="content-body">{props.children}</div>
  //     </div>
  //   </div>
  // );

  return (
    <div className={classes.mainContainer}>
      <LeftNav />
      <div className={classes.mainContent}>
        <TopAppBar />
        <div className="content-body">{props.children}</div>
      </div>
    </div>
  );
};

export default Home;
