import React, { useEffect, useState } from 'react';

import { useCustomStore } from '../hooks';

const TIMER = {
  everyMin: 1000 * 60,
  logoutThreshold: 15,
  oneMin: 1,
  warningThreshold: 14,
};
const TokenManager: React.FC<any> = (props) => {
  const { authStore } = useCustomStore();
  const [isCallbackSet] = useState(false);

  const handleInteraction = () => {
    if (!authStore.idleForLongtimeWarning) {
      authStore.setIdleTime(0);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleInteraction);
    document.addEventListener('keypress', handleInteraction);
    return () => {
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('keypress', handleInteraction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    authStore.setCallback(() => {
      const interval = setInterval(() => {
        authStore.setIdleTime(authStore.idleTime + 1);
        if (authStore.idleTime === TIMER.oneMin) {
          authStore.exchangeAccessToken();
        }
        if (authStore.idleTime === TIMER.warningThreshold) {
          authStore.setIdleForLongtimeWarning(true);
        }
        if (authStore.idleTime >= TIMER.logoutThreshold) {
          authStore.setIdleForLongtimeWarning(false);
          authStore.logout();
          localStorage.clear();
        }
      }, TIMER.everyMin);

      return interval;
    });
  }, [authStore, isCallbackSet]);
  return props.children;
};
export default TokenManager;
