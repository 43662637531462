import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import QuizArchiveDialog from '../../../../components/QuizArchiveDialog/quizArchiveDialog';
import SortingIcon from '../../../../components/SortingIcon/SortingIcon';
import { useCustomStore } from '../../../../hooks';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import useStyles from './QuizzesListStyle';

const QuizzesList = (): React.ReactElement => {
  const { quizzesStore } = useCustomStore(); // authStore
  const { enqueueSnackbar } = useSnackbar();
  const [moreQuizzesLoader, setMoreQuizzesLoader] = useState<boolean>(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] =
    useState<boolean>(false);
  const [selectedQuizId, setSelectedQuizId] = useState<string | null>(null);

  const quizHeader: any = [
    { lable: 'Quiz Title', name: 'title' },
    { lable: 'Correct % Needed', name: 'correctAnswersPercentage' },
    { lable: 'Status', name: 'status' },
    { lable: '', name: '' },
  ];

  const getAllQuizzes = useCallback(async () => {
    quizzesStore.setShowLoader(true);
    const quizzesResp = await quizzesStore.getQuizzesBySorting();
    if (quizzesResp.isErr()) {
      enqueueSnackbar(String(quizzesResp.error.message), SnackBarConfig('e'));
    }
    quizzesStore.setShowLoader(false);
  }, [quizzesStore, enqueueSnackbar]);

  const getMoreQuizzes = useCallback(async () => {
    setMoreQuizzesLoader(true);
    const quizzesResp = await quizzesStore.getAllQuizzes();
    if (quizzesResp.isErr()) {
      enqueueSnackbar(String(quizzesResp.error.message), SnackBarConfig('e'));
    }
    setMoreQuizzesLoader(false);
  }, [quizzesStore, enqueueSnackbar]);

  useEffect(() => {
    getAllQuizzes();
  }, [getAllQuizzes]);

  const sortBY = async (e: any) => {
    quizzesStore.setSortBy(e);
    quizzesStore.setSortDirection(
      quizzesStore.SortDirection === 'desc' ? 'asc' : 'desc',
    );
    quizzesStore.setShowLoader(true);
    await quizzesStore.getQuizzesBySorting();
    quizzesStore.setShowLoader(false);
  };

  const handlequizOption = (event: any, quizId: string) => {
    const obj: any = {};

    if (tooltipIsOpen[quizId]) {
      obj[quizId] = false;
      setAnchorEl(null);
    } else {
      obj[quizId] = true;
      setAnchorEl(event.currentTarget);
    }
    setTooltipIsOpen(obj);
  };

  const editQuiz = (quiz: any) => {
    const routeId: string = quiz.id;
    navigate(`/training/quizzes/edit/${routeId}`);
  };

  const archiveQuiz = async (confirm: boolean) => {
    if (!confirm || selectedQuizId === null) {
      setIsArchiveDialogOpen(false);
      selectedQuizId !== null && handlequizOption(null, selectedQuizId);
      return;
    }
    const archiveResp = await quizzesStore.archiveQuiz(selectedQuizId);
    if (archiveResp.isErr()) {
      enqueueSnackbar(String(archiveResp.error.message), SnackBarConfig('e'));
    } else if (archiveResp.isOk()) {
      getAllQuizzes();
      enqueueSnackbar(String(archiveResp.value), SnackBarConfig('s'));
    }
    setSelectedQuizId(null);
    setIsArchiveDialogOpen(false);
  };

  const viewQuiz = (quiz: any) => {
    const routeId: string = quiz.id;
    navigate(`/training/quizzes/view/${routeId}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        px: 6,
      }}>
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          letterSpacing: 0,
          lineHeight: '19px',
          padding: '1rem 1.5rem 2.5rem 1.5rem',
        }}
      />
      {/* {!quizzesStore.ShowLoader && <Grid>{getActiveUnits()}</Grid>} */}
      <Grid
        className={classes.scroll}
        container
        spacing={2}
        style={{ padding: '0 2rem' }}
        justifyContent="space-between">
        <TableContainer sx={{ maxHeight: '85vh' }} id="training-quizzes-table">
          <InfiniteScroll
            dataLength={
              quizzesStore.QuizzesList && quizzesStore.QuizzesList.length
                ? quizzesStore.QuizzesList.length
                : 0
            }
            style={{ overflow: 'unset' }}
            next={getMoreQuizzes}
            hasMore={quizzesStore.HasMoreResults} // quizzesStore.HasMoreResults
            scrollableTarget={'training-quizzes-table'}
            loader={
              moreQuizzesLoader && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#DEC330', marginTop: 20 }}
                  />
                </div>
              )
            }
            endMessage>
            {quizzesStore.ShowLoader ? (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '80px',
                  marginTop: '80px',
                  width: '100%',
                }}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            ) : (
              <Table
                className={classes.table}
                sx={{
                  borderCollapse: 'separate !important',
                  borderSpacing: '0px 5px !important',
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {quizHeader.map((header: any, index: number) => (
                      <TableCell
                        key={index}
                        style={{
                          backgroundColor: '#f5f5f5',
                          paddingLeft: header.name === 'name' ? '0px' : '16px',
                        }}
                        className={`${
                          header.name === 'name' ? classes.nameHeader : ''
                        }`}>
                        <Grid item xs={12}>
                          <Typography
                            component={'div'}
                            noWrap
                            style={{ alignItems: 'center', display: 'flex' }}>
                            <Typography
                              component={'div'}
                              sx={{
                                color: '#979598',
                                fontFamily: 'FiraSans-Regular',
                                fontSize: '12px',
                                letterSpacing: 0,
                                lineHeight: '14px',
                                paddingLeft: '0px !important',
                              }}>
                              {header.lable}
                            </Typography>

                            {header.name === 'title' && (
                              <SortingIcon
                                {...{
                                  sortBY: sortBY,
                                  sortByName: header.name,
                                  store: quizzesStore,
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quizzesStore.QuizzesList.length > 0 ? (
                    quizzesStore.QuizzesList.map((quiz: any, index: number) => (
                      <TableRow
                        key={index}
                        className={classes.rowHeight}
                        style={{ minHeight: '80px' }}>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            width: '50%',
                          }}>
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              noWrap
                              sx={{
                                color: '#241A2E',
                                cursor: 'pointer',
                                fontFamily: 'FiraSans-Bold',
                                fontSize: '16px',
                                fontWeight: 600,
                                height: '23px',
                                letterSpacing: 0,
                                lineHeight: '23px',
                              }}
                              onClick={() => {
                                viewQuiz(quiz);
                              }}>
                              {quiz.title || '-'}
                            </Typography>
                            {quiz.videoTitle && (
                              <Typography
                                noWrap
                                sx={{
                                  color: '#0061FF',
                                  fontFamily: 'FiraSans-Regular',
                                  fontSize: '13px',
                                  height: '15px',
                                  letterSpacing: 0,
                                  lineHeight: '15px',
                                }}>
                                {quiz.videoTitle || '-'}
                              </Typography>
                            )}
                            {!quiz.videoTitle && (
                              <Typography
                                noWrap
                                sx={{
                                  color: '#B1B4BE',
                                  fontFamily: 'FiraSans-Regular',
                                  fontSize: '13px',
                                  height: '15px',
                                  letterSpacing: 0,
                                  lineHeight: '15px',
                                }}>
                                {'No Training'}
                              </Typography>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            width: '27%',
                          }}>
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              noWrap
                              sx={{
                                color: '#241A2E',
                                fontFamily: 'FiraSans-Light',
                                fontSize: '16px',
                                height: '19px',
                                letterSpacing: 0,
                                lineHeight: '19px',
                              }}>
                              {String(quiz.correctAnswersPercentage) + '%' ||
                                '-'}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingRight: '80px',
                            paddingTop: 0,
                          }}>
                          <Grid item xs={12} md={12} lg={12}>
                            {quiz.status === 'active' && (
                              <Typography
                                noWrap
                                sx={{
                                  color: '#0DA728',
                                  fontFamily: 'FiraSans-Medium',
                                  fontSize: '16px',
                                  height: '23px',
                                  letterSpacing: 0,
                                  lineHeight: '23px',
                                }}>
                                {'Active'}
                              </Typography>
                            )}
                            {quiz.status === 'draft' && (
                              <Typography
                                noWrap
                                sx={{
                                  color: '#979598',
                                  fontFamily: 'FiraSans-Medium',
                                  fontSize: '16px',
                                  height: '23px',
                                  letterSpacing: 0,
                                  lineHeight: '23px',
                                }}>
                                {'Draft'}
                              </Typography>
                            )}
                            {quiz.status === 'archived' && (
                              <Typography
                                noWrap
                                sx={{
                                  color: '#241A2E',
                                  fontFamily: 'FiraSans-Medium',
                                  fontSize: '16px',
                                  height: '23px',
                                  letterSpacing: 0,
                                  lineHeight: '23px',
                                }}>
                                {'Archived'}
                              </Typography>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <Grid item style={{ textAlign: 'right' }}>
                            {quiz.status !== 'archived' && (
                              <>
                                <IconButton
                                  onClick={(event) =>
                                    handlequizOption(event, quiz.id)
                                  }>
                                  <MoreVertIcon />
                                </IconButton>
                                <Popover
                                  className="admin-popclass"
                                  open={tooltipIsOpen[quiz.id]}
                                  onClose={(event) =>
                                    handlequizOption(event, quiz.id)
                                  }
                                  anchorEl={anchorEl}
                                  anchorOrigin={{
                                    horizontal: 'center',
                                    vertical: 'bottom',
                                  }}
                                  transformOrigin={{
                                    horizontal: 'right',
                                    vertical: 'top',
                                  }}>
                                  <Box
                                    sx={{
                                      backgroundColor: '#241A2E',
                                      borderRadius: '8px',
                                      boxShadow:
                                        '0 5px 14px 0 rgba(0,0,0,0.22)',
                                      minHeight: '43px',
                                      padding: '0.5rem 0',
                                      position: 'relative',
                                      width: '207px',
                                    }}>
                                    <div className="arrow">.</div>
                                    <Typography
                                      component={'div'}
                                      className="n-pop-btn"
                                      onClick={() => editQuiz(quiz)}>
                                      Edit
                                    </Typography>
                                    <Typography
                                      component={'div'}
                                      className="n-pop-btn"
                                      onClick={() => viewQuiz(quiz)}>
                                      View
                                    </Typography>
                                    <Typography
                                      component={'div'}
                                      className="n-pop-btn"
                                      onClick={() => {
                                        setSelectedQuizId(quiz.id);
                                        setIsArchiveDialogOpen(true);
                                      }}>
                                      Archive
                                    </Typography>
                                  </Box>
                                </Popover>
                              </>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={4}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </InfiniteScroll>
        </TableContainer>
      </Grid>
      {isArchiveDialogOpen && (
        <QuizArchiveDialog
          isOpen={isArchiveDialogOpen}
          closeDialog={(resp: boolean) => archiveQuiz(resp)}
        />
      )}
    </Box>
  );
};

export default observer(QuizzesList);
