import { AxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';
import { err, ok, Result } from 'neverthrow';

import { AuthErrorType } from '../../backendError.types';
import { ApiError } from '../../types';
import { RootStore } from '..';
import { AdminUsersApiStore } from './adminusers.api';
import { AuthApiStore } from './auth.api';
import { CompanyApiStore } from './companies.api';
import { DriverApiStore } from './drivers.api';
import { GlobalApiStore } from './global.api';
import { NotificationApiStore } from './notification.api';
import { QuizzesApiStore } from './quizzes.api';
import { UserApiStore } from './user.api';
import { VideoApiStore } from './video.api';

export class ApiStore {
  authApi: AuthApiStore;
  userApi: UserApiStore;
  companyApi: CompanyApiStore;
  driverApi: DriverApiStore;
  adminUsersApi: AdminUsersApiStore;
  notificationApiStore: NotificationApiStore;
  globalApiStore: GlobalApiStore;
  videoApi: VideoApiStore;
  quizzesApi: QuizzesApiStore;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      adminUsersApi: false,
      authApi: false,
      companyApi: false,
      driverApi: false,
      notificationApiStore: false,
      quizzesApi: false,
      userApi: false,
      videoApi: false,
    });

    this.authApi = new AuthApiStore(rootStore);
    this.userApi = new UserApiStore(rootStore);
    this.companyApi = new CompanyApiStore(rootStore);
    this.driverApi = new DriverApiStore(rootStore);
    this.adminUsersApi = new AdminUsersApiStore(rootStore);
    this.notificationApiStore = new NotificationApiStore(rootStore);
    this.globalApiStore = new GlobalApiStore(rootStore);
    this.videoApi = new VideoApiStore(rootStore);
    this.quizzesApi = new QuizzesApiStore(rootStore);

    this.initialize();
  }

  initialize = () => {
    this.rootStore.axiosInstance.interceptors.request.use((config: any) => {
      const accessToken: string | undefined =
        this.rootStore.authStore.tokens?.accessToken;
      const authHeders = accessToken
        ? { Authorization: `Bearer ${accessToken}` }
        : { 'x-simplex-client-api-key': '' };

      config.headers = {
        ...config.headers,
        ...authHeders,
      };

      return config;
    });

    this.rootStore.axiosInstance.interceptors.response.use(
      undefined,
      (error: AxiosError) => {
        const { response } = error;
        throw new ApiError({
          actions: response?.data?.actions,
          message:
            response?.data?.message ?? 'Please check your internet connection!',
          status: response?.status,
          type: response?.data?.type,
        });
      },
    );
  };

  exchangeTokenApiCall(refreshToken: string | null) {
    return this.rootStore.axiosInstance({
      data: {
        refreshToken,
      },
      method: 'POST',
      url: 'tokens/exchange',
    });
  }

  async call<T = any>(options: {
    url: string;
    method: 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH';
    headers?: any;
    data?: any;
    params?: any;
  }): Promise<Result<T, ApiError>> {
    const initialAuthCount = this.rootStore.authStore.authCount;
    try {
      const { data } = await this.rootStore.axiosInstance(options);
      return ok(data as T);
    } catch (e) {
      const er = e as ApiError;
      if (er.actions.exchange) {
        try {
          // await exchange the token only once.
          initialAuthCount === this.rootStore.authStore.authCount &&
            (await this.rootStore.authStore.exchangeOnlyOnce());
          // on success - call the api again.
          return await this.call<T>(options);
        } catch (e) {
          //  logout only once
          await this.rootStore.authStore.logout();
        }
      } else if (er.type === AuthErrorType.NoAccessToUser) {
        return err(e as ApiError);
      } else if (er.actions.authFailed) {
        await this.rootStore.authStore.logout();
      }
      return err(e as ApiError);
    }
  }
}
