import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import logoNav2 from '../../../assets/images/logo-nav2.svg';
import { InputText } from '../../../components/Forms/InputText';
import Loader from '../../../components/Loader/Loader';
import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { LoginFormInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { loginSchema } from '../../../utils/ValidatorSchema';
import useStyles from '../PublicStyles';

const Login: React.FC = () => {
  const classes = useStyles();
  const { authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(loginSchema),
  });

  const submitLogin = useCallback(
    async (data: LoginFormInputs) => {
      setLoading(true);
      const loginResp = await authStore.login(data);

      if (loginResp.isErr()) {
        enqueueSnackbar(String(loginResp.error.message), SnackBarConfig('e'));
        setLoading(false);
      }
    },
    [authStore, enqueueSnackbar],
  );

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  return (
    <Grid
      container
      spacing={0}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      className={classes.sliderHome}>
      <Grid item margin={'auto'}>
        <div className={classes.rectangle}>
          <img src={logoNav2} className={classes.logoNav2} />
          <form
            style={{
              width: '100%',
            }}
            onSubmit={handleSubmit(submitLogin)}>
            <Grid
              sx={{
                marginTop: '31px',
              }}>
              <FormControl error={false} fullWidth>
                <Grid container>
                  <Box className={classes.labels}>Email</Box>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="email"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <InputText
                            isFormSubmittedOnce={!!formState.submitCount}
                            inputRefObj={input1Ref}
                            errors={errors.email?.message || ''}
                            name={name}
                            isRefreshValue={true}
                            value={value}
                            onChangeText={onChange}
                            isEditable={false}
                            className={classes.inputStyles}
                            type={'email'}
                            variant="filled"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Box
                    className={classes.labels}
                    sx={{
                      marginTop: '12px',
                    }}>
                    Password
                  </Box>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="password"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <InputText
                            isFormSubmittedOnce={!!formState.submitCount}
                            inputRefObj={ref}
                            errors={errors.password?.message || ''}
                            name={name}
                            isRefreshValue={true}
                            value={value}
                            onChangeText={onChange}
                            isSecureTextEntry={true}
                            isEditable={false}
                            className={classes.inputStyles}
                            type={'password'}
                            variant="filled"
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Button
              disabled={loading}
              tabIndex={0}
              type="submit"
              variant="contained"
              fullWidth
              className={classes.buttonStyles}>
              {loading ? (
                <Loader
                  className={'flex-centering mt-1em mb-1em'}
                  style={{ marginBottom: 16, marginTop: 16 }}
                  color="primary"
                />
              ) : (
                'Login'
              )}
            </Button>
            <Link
              to="/forgot-password"
              onClick={() => {}}
              tabIndex={0}
              className={classes.forgotPassword}>
              Forgot Password?
            </Link>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(Login);
