import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  '& .MuiGrid-root': {
    padding: '0 16px !important',
  },
  '& .MuiPaper-root': {
    '.MuiGrid-container': {
      padding: '0px 16px !important',
    },
    padding: '16px 0px !important',
  },
  '&.MuiTable-root': {
    backgroundColor: '#fafafb',
  },
  bottomAction: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px !important',
    padding: '0 10px',
    width: '100%',
  },
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  checkBoxDisabled: {
    backgroundColor: 'unset !important',
    border: 'unset !important',
  },
  combinedTooltip: {
    backgroundColor: '#241A2E',
    borderRadius: '8px',
    height: '122.9px',
    width: '207px',
  },
  compliant: {
    alignItems: 'center',
    backgroundColor: 'rgba(92,174,91,0.2)',
    borderRadius: '17px',
    color: '#5CAE5B !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-SemiBold !important',
    fontSize: '0.75em !important',
    height: '24px',
    justifyContent: 'center',
    letterSpacing: '0.75px !important',
    paddingTop: '0.2rem',
    textTransform: 'uppercase',
    width: '90px',
  },
  compliantNot: {
    alignItems: 'center',
    backgroundColor: 'rgba(167,13,13,0.2)',
    borderRadius: '17px',
    color: '#A70D0D !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-SemiBold !important',
    fontSize: '0.75em !important',
    height: '24px',
    justifyContent: 'center',
    letterSpacing: '0.75px !important',
    minWidth: '118px',
    paddingTop: '0.2rem',
    textTransform: 'uppercase',
  },
  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: 'px',
      // boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      // height: 'auto',
    },
  },
  editBtn: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '16px !important',
    textAlign: 'right',
    textDecoration: 'underline solid #0061ff',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.75rem',
    lineHeight: '24px',
  },
  infoTitle: {
    color: '#241A2E !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },
  infodis: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
  },
  inputF: {
    '&::placeholder': {
      color: '#FFFFFF',
      fontFamily: 'FiraSans-Medium',
      opacity: '0.43',
    },
    '&:focusVisible': {
      outline: 'unset !important',
    },
    background: 'unset !important',
    border: 'unset !important',
    color: '#ffff !important',
    width: '100%',
  },
  loader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '80px',
    marginTop: '80px',
    width: '100%',
  },
  loginAsAdmin: {
    color: '#2573BF',
    fontFamily: 'FiraSans-Bold',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '22px',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'right',
  },
  optionsClassName: {
    backgroundColor: '#241A2E !important',
    color: '#FFFFFF',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  primaryText: {
    color: '#A70D0D !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '12px !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #A70D0D !important',
  },
  profileTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '20px !important',
  },
  pxy: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  pzcenter: {
    marginTop: '-1.3rem !important',
    textAlign: 'center',
  },
  rectangleTooltip: {
    backgroundColor: '#37414F',
    height: '32px',
    width: '207px',
  },
  rightSidebtn: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rowHeight: {
    height: '80px',
  },
  scroll: {
    overflow: 'hidden',
    position: 'relative',
  },
  selectClassName: {
    '& .MuiInput-root': {
      height: '44px !important',
    },
    backgroundColor: '#F8F8FA !important',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    boxSizing: 'border-box',
    marginBottom: '6px',
    paddingLeft: '10px !important',
  },

  submited: {
    boxShadow: 'unset',
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 5px',
    minWidth: 650,
  },

  view: {
    color: '#0061FF !important',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '1em !important',
    textDecoration: 'underline solid #0061FF',
  },
}));

export default useStyles;
