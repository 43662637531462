import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material'; //
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import search from '../../../../../assets/images/h-search.svg';
import DialogMigrate from '../../../../../components/Dialog/DialogMigrate';
import { InputText } from '../../../../../components/Forms/InputText';
import { useCustomStore } from '../../../../../hooks';
import useStyles from './AddReasonDialogStyle';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

type AddReasonDialogType = {
  isOpen: boolean;
  closeDialog: () => void;
};

const AddReasonDialog: React.FC<any> = (props: AddReasonDialogType) => {
  const { isOpen, closeDialog } = props;
  const classes = useStyles();
  const { videoStore } = useCustomStore();

  const [selectedReasons, setSelectedReasons] = useState<any[]>([]);

  const onSelectReason = (event: any, reason: any) => {
    if (event && event.target.checked) {
      setSelectedReasons([...[reason], ...selectedReasons]);
    } else {
      const reasons = selectedReasons.filter((v) => v.id !== reason.id);
      setSelectedReasons(reasons);
    }
  };

  const onAddReason = () => {
    videoStore.setSelectedReasons(selectedReasons);
    closeDialog();
  };

  return (
    <DialogMigrate
      aria-labelledby="training-video-violations"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => closeDialog()}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '1.375rem',
          letterSpacing: 0,
          paddingLeft: '20px',
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>Search & Select Reasons</div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialog();
            }}>
            <ClearIcon />
          </Link>
        </div>
      </DialogTitle>
      <Grid
        container
        spacing={1}
        ml={0}
        pl={'15px'}
        pr={'24px'}
        className={classes.headerWrapper}
        sx={{ paddingBottom: '9.5px', width: '100%' }}>
        <Grid item md={12} xs={12} style={{ padding: '0' }}>
          <FormControl style={{ width: '98%' }}>
            <InputText
              name="search-reason"
              placeholder="Search Reason"
              isEditable={false}
              type={'text'}
              variant="standard"
              value={''}
              initIcon={
                <InputAdornment position="start">
                  <img className={classes.hIcon} src={search} />
                </InputAdornment>
              }
              className={classes.selectClassName}
            />
          </FormControl>
        </Grid>
      </Grid>
      <DialogContent id="scroll" style={{ paddingTop: '10px' }}>
        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              xs={1}
              style={{
                alignItems: 'center',
                display: 'flex',
                padding: '0 0 0 5px',
              }}
            />
            <Grid
              item
              xs={10}
              style={{
                alignItems: 'center',
                display: 'flex',
                padding: '0 0 0 5px',
              }}>
              <Typography
                noWrap
                sx={{
                  color: '#A4A4A4',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '0.75rem',
                  height: '14px',
                  letterSpacing: 0,
                  lineHeight: '14px',
                  width: '89px',
                }}>
                Reason Name
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <InfiniteScroll
          dataLength={videoStore.AssignmentReasons.length}
          style={{ overflow: 'unset' }}
          next={() => {}}
          hasMore={videoStore.HasMoreAssignmentReasons}
          scrollableTarget={'scroll'}
          loader={
            false && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#DEC330', marginTop: 20 }}
                />
              </div>
            )
          }
          endMessage>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: '#FFFFFF',
              marginBottom: '10px',
            }}>
            {videoStore.AssignmentReasonsLoader ? (
              <div className={classes.loader}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            ) : videoStore.AssignmentReasons.length > 0 ? (
              videoStore.AssignmentReasons.map((reason: any, index: number) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      paddingBottom: '10px',
                      paddingLeft: '0px',
                      paddingTop: '10px',
                    }}>
                    <Grid item xs={1}>
                      <Checkbox
                        checked={[
                          ...videoStore.SelectedViolationCodeIds,
                          ...selectedReasons.map((v) => v.id),
                        ].includes(reason.id)}
                        onChange={(event: any) => {
                          onSelectReason(event, reason);
                        }}
                        color="success"
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        noWrap
                        sx={{
                          color: '#241A2E',
                          fontFamily: 'FiraSans-Bold',
                          fontSize: '16px',
                          letterSpacing: 0,
                          lineHeight: '23px',
                          textTransform: 'capitalize',
                        }}>
                        {reason.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </React.Fragment>
              ))
            ) : (
              <div className={classes.noRecordsFound}>
                No Assignment Reason(s) found
              </div>
            )}
            <Divider className={classes.divider} />
          </Grid>
        </InfiniteScroll>
      </DialogContent>
      <DialogActions className={classes.jxy}>
        <BottomButton
          disabled={selectedReasons.length < 1}
          type={'button'}
          variant="contained"
          sx={{ width: '100%' }}
          onClick={() => onAddReason()}>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontFamily: 'FiraSans-Medium',
              fontSize: '0.9375rem',
              fontWeight: '500',
              height: '22px',
              letterSpacing: '1px',
              lineHeight: '22px',
              textAlign: 'center',
            }}>
            ADD
            {selectedReasons.length === 0
              ? ' '
              : ` (${selectedReasons.length}) `}
            Reasons
          </Typography>
        </BottomButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(AddReasonDialog);
