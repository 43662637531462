import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  '& .MuiGrid-root': {
    padding: '0 16px !important',
  },
  '& .MuiPaper-root': {
    '.MuiGrid-container': {
      padding: '0px 16px !important',
    },
    padding: '16px 0px !important',
  },
  '&.MuiTable-root': {
    backgroundColor: '#fafafb',
  },
  boldText: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Bold',
    fontSize: '16px',
    fontWeight: 600,
    height: '23px',
    letterSpacing: 0,
    lineHeight: '23px',
  },
  loginAsAdmin: {
    color: '#2573BF',
    fontFamily: 'FiraSans-Bold',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '22px',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'right',
  },
  noRecordsFound: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    paddingTop: 20,
    textAlign: 'center',
    width: '100%',
  },
  rowHeight: {
    height: '80px',
  },
  scroll: {
    overflow: 'hidden',
    padding: '2rem 2rem 0 3rem',
    position: 'relative',
  },
  table: {
    minWidth: 650,
  },
  tableHeaderBg: {
    backgroundColor: '#f5f5f5 !important',
  },
}));

export default useStyles;
