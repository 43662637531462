import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useCustomStore } from '../../../hooks';
import { CompanySearchFormInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import CompanyHeader from './CompanyHeader';
import CompanyLists from './CompanyLists';
import useStyles from './companyStyle';

const Companies: React.FC = () => {
  const { companyStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [getMoreLoader, setGetMoreLoader] = useState(false);
  const [showUserLoader, setShowUserLoader] = useState(false);

  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [showInviteAlert, setShowInviteAlert] = useState(false);
  const [inviteReqLoader, setInviteReqLoader] = useState(false);

  const [companyUsers, setCompanyUsers] = useState<any[]>();

  const [searchCompanies, setSearchCompanies] =
    useState<CompanySearchFormInputs>({
      dotNumber: companyStore.Filters.dotNumber,
      name: companyStore.Filters.name,
      simplexId: companyStore.Filters.simplexId,
    });

  const [expandState, setExpandState] = useState<any>({});
  const handleEpandRow = (event: any, companyId: any) => {
    !expandState[companyId] && getCompanyUsersLists(companyId);

    const obj: any = {};
    expandState[companyId] ? (obj[companyId] = false) : (obj[companyId] = true);
    setExpandState(obj);
    companyStore.resetCompanyUsers();
  };

  const getCompanyUsersLists = useCallback(
    async (companyId: string) => {
      setShowUserLoader(true);
      const getCompanyUsersResp = await companyStore.fetchCompanyUsers(
        companyId,
      );
      if (getCompanyUsersResp.isOk()) {
        setShowUserLoader(false);
      }
      if (getCompanyUsersResp.isErr()) {
        setShowUserLoader(false);
        enqueueSnackbar(
          String(getCompanyUsersResp.error.message),
          SnackBarConfig('e'),
        );
      }
    },
    [companyStore, enqueueSnackbar],
  );

  const getCompanyLists = useCallback(
    async (isReset: boolean) => {
      companyStore.setShowLoader(true);
      if (isReset) {
        setSearchCompanies({
          dotNumber: '',
          name: '',
          simplexId: '',
        });
        companyStore.setFilters({
          dotNumber: '',
          limit: 0,
          name: '',
          nextLink: '',
          simplexId: '',
        });
      }
      const getCompaniesResp = await companyStore.fetchCompaniesBySorting();
      if (getCompaniesResp.isOk()) {
        companyStore.setShowLoader(false);
      }
      if (getCompaniesResp.isErr()) {
        companyStore.setShowLoader(false);
        enqueueSnackbar(
          String(getCompaniesResp.error.message),
          SnackBarConfig('e'),
        );
      }
    },
    [companyStore, enqueueSnackbar],
  );

  const getSearchBasedCompanies = useCallback(
    async (searchParams: CompanySearchFormInputs) => {
      if (
        !searchParams.name &&
        !searchParams.simplexId &&
        !searchParams.dotNumber
      ) {
        companyStore.setCompanyApiReset();
        getCompanyLists(true);
        return;
      }
      setGetMoreLoader(true);
      const getEquipmentsResp = await companyStore.fetchCompaniesBySearchBased(
        searchParams,
      );
      setGetMoreLoader(false);
      companyStore.setShowLoader(false);
      if (getEquipmentsResp.isErr()) {
        enqueueSnackbar(
          String(getEquipmentsResp.error.message),
          SnackBarConfig('e'),
        );
      }
    },
    [companyStore, getCompanyLists, enqueueSnackbar],
  );

  // add more Equipments on page scroll
  const getMoreCompanies = useCallback(async () => {
    if (
      searchCompanies.name ||
      searchCompanies.simplexId ||
      searchCompanies.dotNumber
    ) {
      getSearchBasedCompanies(searchCompanies);
      return;
    }
    setGetMoreLoader(true);
    const getEquipmentsResp = await companyStore.fetchCompanies();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [companyStore, enqueueSnackbar, getSearchBasedCompanies, searchCompanies]);

  const getMoreCompanyUsers = useCallback(
    async (companyId: string) => {
      setShowUserLoader(true);
      const getEquipmentsResp = await companyStore.fetchCompanyUsers(companyId);
      if (getEquipmentsResp.isErr()) {
        enqueueSnackbar(
          String(getEquipmentsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      setShowUserLoader(false);
    },
    [companyStore, enqueueSnackbar],
  );

  useEffect(() => {
    getCompanyLists(true);
  }, [getCompanyLists]);

  const handleInvite = (companyId: string) => {
    setInviteReqLoader(true);
    setSelectedCompanyId(companyId);
    (async () => {
      const result = await companyStore.getUsersByCompanyId(companyId);
      setInviteReqLoader(false);
      if (result.isErr()) {
        enqueueSnackbar(
          result?.error?.message || 'Failed to get users',
          SnackBarConfig('e'),
        );
        return;
      }

      if (result.isOk() && result.value?.length) {
        setCompanyUsers(result.value);
        setShowInviteAlert(true);
      } else {
        enqueueSnackbar(
          'This company has no users to invite. Try another one',
          SnackBarConfig('e'),
        );
      }
    })();
  };

  const sendEmailToUsers = () => {
    setInviteReqLoader(true);
    (async () => {
      const result = await companyStore.sendEmailToUsers(selectedCompanyId);
      setInviteReqLoader(false);
      setShowInviteAlert(false);
      if (result.isErr())
        enqueueSnackbar(
          'Failed to send emails. Try again',
          SnackBarConfig('e'),
        );
      else enqueueSnackbar('Emails sent successfully', SnackBarConfig('s'));
    })();
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          padding: '4rem',
          px: 6,
        }}>
        <Grid
          className={classes.scroll}
          container
          spacing={2}
          justifyContent="space-between">
          <TableContainer sx={{ maxHeight: '85vh' }} id="companies-table">
            <InfiniteScroll
              dataLength={
                companyStore.CompanyList && companyStore.CompanyList.length
                  ? companyStore.CompanyList.length
                  : 0
              }
              // height={windowHeight}
              style={{ overflow: 'unset' }}
              next={getMoreCompanies}
              hasMore={companyStore.HasMoreResults}
              scrollableTarget={'companies-table'}
              loader={
                getMoreLoader && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              {companyStore.ShowLoader ? (
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '80px',
                    marginTop: '80px',
                    width: '100%',
                  }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#DEC330', marginTop: 20 }}
                  />
                </div>
              ) : companyStore.CompanyList &&
                companyStore.CompanyList.length > 0 ? (
                <Table
                  stickyHeader
                  sx={{
                    borderCollapse: 'separate !important',
                    borderSpacing: '0px 5px !important',
                  }}
                  aria-label="companies table">
                  <TableHead>
                    <CompanyHeader {...{ companyStore }} />
                  </TableHead>
                  <TableBody>
                    <CompanyLists
                      getMoreCompanyUsers={getMoreCompanyUsers}
                      onInvite={handleInvite}
                      {...{
                        companyLists: companyStore.CompanyList,
                        enqueueSnackbar,
                        expandState,
                        handleEpandRow,
                        showUserLoader,
                      }}
                    />
                  </TableBody>
                </Table>
              ) : (
                <div
                  style={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    paddingTop: 20,
                    textAlign: 'center',
                    width: '100%',
                  }}>
                  No record(s) found
                </div>
              )}
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      </Box>
      <Backdrop open={showInviteAlert || inviteReqLoader}>
        {inviteReqLoader ? (
          <CircularProgress color="inherit" />
        ) : (
          <Dialog
            open={showInviteAlert}
            onClose={() => {}}
            scroll="paper"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              <h2>
                Are you sure you want to send invite to all contacts of this
                company?
              </h2>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography paragraph>
                  Following users of this company will receive email to onboard
                  simplex portal.
                </Typography>
              </DialogContentText>
              <List
                sx={{
                  width: '100%',
                }}>
                {companyUsers?.map((user, index) => (
                  <>
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemText
                        primary={user.simplexId}
                        secondary={
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary">
                            {user.name} - {user.email} - {user.role}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowInviteAlert(false)}>
                Disagree
              </Button>
              <Button onClick={sendEmailToUsers} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Backdrop>
    </>
  );
};

export default observer(Companies);
