import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { LoginDTO, PageAccess } from '../../types';
import { RootStore } from '..';

export class AuthApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  login = (params: { email: string; password: string }) => {
    const { email, password } = params;
    const {
      Login: { Endpoint, Method },
    } = ApiRoutes.Auth;
    return this.rootStore.apiStore.call<LoginDTO>({
      data: {
        email,
        password,
      },
      method: Method,
      url: Endpoint,
    });
  };

  directLoginToken = (bodyData: { contactId: string; name: string }) => {
    const {
      LoginASImpersonate: { Endpoint, Method },
    } = ApiRoutes.Auth;
    return this.rootStore.apiStore.call<{ name: string; accessToken: string }>({
      data: bodyData,
      method: Method,
      url: Endpoint,
    });
  };

  pagesAccess = () => {
    const {
      pageAccess: { Endpoint, Method },
    } = ApiRoutes.Auth;
    return this.rootStore.apiStore.call<{ portalAccess: PageAccess }>({
      method: Method,
      url: Endpoint,
    });
  };

  exchangeToken(data: { id: string; accessToken: string }) {
    const {
      TokenXchange: { Endpoint, Method },
    } = ApiRoutes.Auth;
    return this.rootStore.apiStore.call<LoginDTO>({
      data,
      method: Method,
      url: Endpoint,
    });
  }
}
