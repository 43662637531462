import { Button, FormControl, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { UseFocus, useMountEffect } from '../../../hooks';
import { InputText } from '../../Forms/InputText';
import search from './../../../assets/images/h-search.svg';

type adminFormTypes = {
  classes: any;
  adminController: any;
  adminSubmit: any;
  filter: any;
  resetFilter: any;
  reset: any;
  searchPage: any;
  setFilters: (src: any) => void;
  submitAdminFilter: any;
  adminUsersStore: any;
};

const AdminSearchForm: React.FC<adminFormTypes> = (props: adminFormTypes) => {
  const {
    classes,
    adminController,
    adminSubmit,
    filter,
    resetFilter,
    reset,
    searchPage,
    setFilters,
    submitAdminFilter,
    adminUsersStore,
  } = props;

  const [input1Ref, setInput1Focus] = UseFocus();

  useEffect(() => {
    if (!adminUsersStore.Filters.name) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMountEffect(setInput1Focus);

  return (
    <form onSubmit={adminSubmit(submitAdminFilter)} id={searchPage}>
      <FormControl error={false} fullWidth>
        <Typography
          component={'h3'}
          sx={{
            color: '#FFFFFF',
            fontFamily: 'FiraSans-Medium',
            fontSize: '15px',
            fontWeight: '500',
            height: '22px',
            letterSpacing: '1px',
            lineHeight: '22px',
            textTransform: 'capitalize',
            width: '166px',
          }}>
          Name
        </Typography>

        <Grid className={classes.borderBottom}>
          <Typography component={'div'} className={classes.searcBox}>
            <Grid item md={2} className={classes.dflexJA}>
              <img className={classes.filterIcon} src={search} alt="search" />
            </Grid>
            <Grid item md={10}>
              <Controller
                control={adminController}
                defaultValue=""
                name="name"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      inputRefObj={input1Ref}
                      name={name}
                      isRefreshValue={true}
                      // defaultValue={filter.name}
                      value={filter.name}
                      placeholder="Search"
                      onChangeText={(e: any) => {
                        onChange(e);
                        setFilters((pre: any) => ({
                          ...pre,
                          name: e,
                        }));
                      }}
                      isEditable={false}
                      className={classes.inputF}
                      type={'text'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Typography>
        </Grid>
      </FormControl>
      <Grid container style={{ padding: '0px !important' }}>
        <Grid item md={6}>
          <Typography component={'div'} className={classes.resetF}>
            <Button
              variant="contained"
              className={' d-flex-ja '.concat(classes.resetF)}
              disabled={!adminUsersStore.Filters.name}
              tabIndex={0}
              type="button"
              onClick={resetFilter}>
              RESET
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            component={'button'}
            disabled={!filter.name}
            className={classes.filterBtn}
            type="submit">
            SEARCH
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(AdminSearchForm);
