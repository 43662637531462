import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  '&.MuiTable-root': {
    backgroundColor: '#fafafb',
  },

  ArrowDownIcon: {
    '&:hover': {
      backgroundColor: '#241A2E',
    },
    cursor: 'pointer',
  },

  ArrowUpIcon: {
    '&:hover': {
      backgroundColor: '#241A2E',
    },
    cursor: 'pointer',
  },

  activeSwitch: {
    marginRight: '30px',
  },

  answerOptions: {
    '.MuiFormControlLabel-labelPlacementEnd.MuiFormControlLabel-root > .MuiTypography-body1.MuiFormControlLabel-label':
      {
        marginLeft: '14px !important',
        paddingLeft: '14px !important',
      },
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
  },

  bottomAction: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px !important',
    padding: '0 10px',
    width: '100%',
  },

  cancelBtn: {
    '&:hover': {
      backgroundColor: '#D1D3D4',
      color: '#FFFFFF !important',
    },
    backgroundColor: '#D1D3D4',
    color: '#FFFFFF !important',
    cursor: 'pointer !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '15px !important',
    letterSpacing: '0.94px !important',
    lineHeight: '22px',
    marginRight: '5px',
    padding: '10px 20px 10px 20px',
    textTransform: 'uppercase',
  },

  combinedTooltip: {
    backgroundColor: '#241A2E',
    borderRadius: '8px',
    boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
    height: '122.9px',
    width: '207px',
  },
  correctOptionsMarker: {
    '&.Mui-disabled': {
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
    color: '#0062FF !important',
    opacity: '0.8',
  },

  customQuizCheck: {
    '& .MuiSvgIcon-MuiPaper-elevation': {
      marginLeft: '0.5rem !important',
    },
    borderRadius: '6px',
    height: '28px',
    paddingLeft: 'unset !important',
    width: '28px',
  },

  customViolationCheck: {
    '& .MuiSvgIcon-MuiPaper-elevation': {
      marginLeft: '0.5rem !important',
    },
    height: '35px',
    marginLeft: '12px',
    paddingLeft: 'unset !important',
    width: '35px',
  },

  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: 'px',
    },
  },

  disabledField: {
    backgroundColor: 'transparent !important',
    border: 'none',
  },

  formLabelClass: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },

  goBackLink: {
    '& .back-icon': {
      fontSize: '0.9rem',
    },
    '& span': {
      textDecoration: 'underline',
    },
    alignItems: 'center',
    color: '#777777',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium',
    fontSize: '12px',
    fontWeight: 400,
    height: '17px',
    letterSpacing: 0,
    lineHeight: '17px',
  },

  hIcon: {
    height: '16px',
    width: '16px',
  },

  infoTitle: {
    color: '#241A2E !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },

  infodis: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
  },

  inputStyles: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#f3f3f3 !important',
      borderRadius: '7px !important',
      color: '#241A2E !important',
      fontFamily: 'FiraSans-Medium !important',
      fontSize: '14px !important',
      fontWeight: ' 500 !important',
      letterSpacing: '0 !important',
      // opacity: '0.43 !important',
    },
    '& .MuiFilledInput-root:after': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:before': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:hover': {
      border: 'none',
      outline: 'none',
    },
    '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& Mui-error': {
      height: 0,
    },
    '& input': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      padding: '11px',
    },
    '& label': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: 12,
      height: 24,
      letterSpacing: 0,
    },
  },

  loginAsAdmin: {
    color: '#2573BF',
    fontFamily: 'FiraSans-Bold',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '22px',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'right',
  },

  nameHeader: {
    paddingLeft: '0 !important',
    paddingRight: '32px',
  },

  optionsClassName: {
    backgroundColor: '#241A2E !important',
    color: '#FFFFFF',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  percentageDisabledIcon: {
    height: 'auto',
    paddingLeft: '5px',
    width: 'auto',
  },
  pxy: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  questionAnswerBar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '14px',
    paddingTop: '10px',
  },
  questionTopBar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  quizDetailsCardContainer: {
    '. MuiPaper-elevation > .MuiCard-root': {
      '&.quizDetailsCard': {
        padding: '0 !important',
      },
    },
  },
  quizForm: {
    padding: '0rem 2rem',
    width: '100%',
  },
  quizInputContainer: {
    paddingLeft: '0px !important',
  },
  radioOutline: {
    '&.Mui-disabled': {
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
  },
  reciaddbox: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  reciadditem: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '20px',
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
    marginBottom: '0.5rem !important',
    marginRight: '.5rem !important',
    minWidth: '96px',
    padding: '1rem',
  },
  reciclose: {
    color: '#000000',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '14px !important',
    opacity: '0.2',
    paddingLeft: '.5rem',
    transform: 'scale(1.2)',
  },
  recitext: {
    color: '#FFFFFF !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1rem !important',
  },
  rectangleTooltip: {
    backgroundColor: '#37414F',
    height: '32px',
    width: '207px',
  },
  rightSidebtn: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rowHeight: {
    height: '80px',
  },
  saveBtn: {
    '&:hover': {
      backgroundColor: '#0DA728',
      color: '#FFFFFF !important',
    },
    backgroundColor: '#0DA728',
    color: '#FFFFFF !important',
    cursor: 'pointer !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '15px !important',
    letterSpacing: '0.94px !important',
    lineHeight: '22px',
    marginRight: '5px',
    padding: '10px 30px 10px 30px',
    textTransform: 'uppercase',
  },
  scroll: {
    overflow: 'hidden',
    position: 'relative',
  },
  selectClassName: {
    '& .MuiInput-root': {
      height: '44px !important',
    },
    backgroundColor: '#F8F8FA',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    boxSizing: 'border-box',
    marginBottom: '6px',
    paddingLeft: '10px !important',
  },
  selectIn: {
    '& .Mui-disabled': {
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
    // '.MuiInputBase-input.Mui-disabled': {
    //   backgroundColor: 'transparent !important',
    //   border: 'none !important',
    // },
    '& .MuiInputBase-root': {
      backgroundColor: '#F8F8FA !important',
      borderRadius: '7px !important',
      color: '#241A2E !important',
      fontFamily: 'FiraSans-Medium !important',
      fontSize: '0.875rem !important',
      fontWeight: ' 500 !important',
      height: '43px',
      letterSpacing: '0 !important',
    },

    '.Mui-disabled': {
      backgroundColor: 'transparent !important',
      border: 'none !important',
    },
  },

  selectQuizDropdown: {
    paddingLeft: '0px !important',
  },

  selectW: {
    '& .MuiSelectUnstyled-popper': {
      left: '-10% !important',
    },
    '& .MuiSelectUnstyled-root': {
      margin: 'unset !important',
      minWidth: '100% !important',
    },
  },

  submit: {
    '&:hover': {
      backgroundColor: '#241A2E',
      color: '#FFFFFF !important',
    },
    alignItems: 'center',
    backgroundColor: '#241A2E',
    borderRadius: '4px',
    color: '#ffffff !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '48px',
    justifyContent: 'center',
    letterSpacing: '0.94px',
    padding: '12px 27px 12px 27px',
    textAlign: 'right',
    textTransform: 'uppercase',
    width: '100%',
  },

  submited: {
    boxShadow: 'unset',
  },
  table: {
    minWidth: 650,
  },
  uploadPreviewContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  uploadThumbnail: {
    '&:hover': {
      backgroundColor: '#241A2E',
      color: '#FFFFFF !important',
    },
    alignItems: 'center',
    backgroundColor: '#241A2E',
    borderRadius: '4px',
    color: '#ffffff !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '48px',
    justifyContent: 'center',
    letterSpacing: '0.94px',
    lineHeight: '22px',
    padding: '12px 22px 12px 22px',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
