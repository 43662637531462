import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import logoNav2 from '../../../assets/images/logo-nav2.svg';
import { InputText } from '../../../components/Forms/InputText';
import Loader from '../../../components/Loader/Loader';
import { PublicRoute } from '../../../config/Constants';
import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { ForgotPasswordFormInputs, LoginFormEmailInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { forgotPasswordSchema } from '../../../utils/ValidatorSchema';
import useStyles from '../PublicStyles';

const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const { apiStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // validation
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormEmailInputs>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(forgotPasswordSchema),
  });

  // const redirectToResetPassword = useCallback(
  //   (token: any) => {
  //     navigate(PublicRoute.RESETPASSWORD, {
  //       state: {
  //         token,
  //       },
  //     });
  //   },
  //   [navigate],
  // );

  const submitForgotPassword = useCallback(
    async (data: ForgotPasswordFormInputs) => {
      setLoading(true);
      const forgotPasswordResp = await apiStore.userApi.forgotPassword(data);
      if (forgotPasswordResp.isOk()) {
        enqueueSnackbar(forgotPasswordResp.value, SnackBarConfig('s'));
        // redirectToResetPassword(forgotPasswordResp.value);
        navigate(PublicRoute.LOGIN);
      } else {
        enqueueSnackbar(
          String(forgotPasswordResp.error.message),
          SnackBarConfig('e'),
        );
        setLoading(false);
      }
    },
    [apiStore.userApi, enqueueSnackbar, navigate],
  );

  const [input1Ref, setInput1Focus] = UseFocus();

  useMountEffect(setInput1Focus);

  return (
    <Grid
      container
      spacing={0}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      className={classes.sliderHome}>
      <Grid item margin={'auto'}>
        <div className={classes.rectangle}>
          <img src={logoNav2} className={classes.logoNav2} />
          <form
            style={{
              width: '100%',
            }}
            onSubmit={handleSubmit(submitForgotPassword)}>
            <Grid
              sx={{
                marginTop: '31px',
              }}>
              <FormControl error={false} fullWidth>
                <Grid container>
                  <Box className={classes.labels}>Email</Box>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name="email"
                      render={({
                        field: { onChange, value, name, ref },
                        formState,
                      }) => {
                        return (
                          <InputText
                            isFormSubmittedOnce={!!formState.submitCount}
                            inputRefObj={input1Ref}
                            errors={errors.email?.message || ''}
                            name={name}
                            isRefreshValue={true}
                            value={value}
                            onChangeText={onChange}
                            isEditable={false}
                            className={classes.inputStyles}
                            type={'email'}
                            variant="filled"
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Button
              disabled={loading}
              tabIndex={0}
              type="submit"
              variant="contained"
              fullWidth
              className={classes.buttonStyles}>
              {loading ? (
                <Loader
                  className={'flex-centering mt-1em mb-1em'}
                  style={{ marginBottom: 16, marginTop: 16 }}
                  color="primary"
                />
              ) : (
                'Send Password Reset'
              )}
            </Button>
            <Link to="/login" tabIndex={0} className={classes.backtoLogin}>
              Back to Login
            </Link>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(ForgotPassword);
