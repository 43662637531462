import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  '&.MuiTable-root': {
    backgroundColor: '#fafafb',
  },

  activeSwitch: {
    marginRight: '30px',
  },

  addEditVideo: {
    margin: '10px 20px',
  },

  addVideoTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '18px !important',
    lineHeight: '26px',
    marginLeft: '25px',
  },
  bottomAction: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px !important',
    padding: '0 10px',
    width: '100%',
  },
  cancelBtn: {
    '&:hover': {
      backgroundColor: '#D1D3D4',
      color: '#FFFFFF !important',
    },
    backgroundColor: '#D1D3D4',
    color: '#FFFFFF !important',
    cursor: 'pointer !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '15px !important',
    letterSpacing: '0.94px !important',
    lineHeight: '22px',
    marginRight: '5px',
    padding: '10px 20px 10px 20px',
    textTransform: 'uppercase',
  },
  combinedTooltip: {
    backgroundColor: '#241A2E',
    borderRadius: '8px',
    boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
    height: '122.9px',
    width: '207px',
  },
  customQuizCheck: {
    '& .MuiSvgIcon-MuiPaper-elevation': {
      marginLeft: '0.5rem !important',
    },
    borderRadius: '6px',
    height: '28px',
    width: '28px',
  },

  customViolationCheck: {
    '& .MuiSvgIcon-MuiPaper-elevation': {
      marginLeft: '0.5rem !important',
    },
    height: '35px',
    marginLeft: '12px',
    paddingLeft: 'unset !important',
    width: '35px',
  },

  dflexJA: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: 'px',
    },
  },

  flagIconCell: {
    '& .icon': {
      width: '1rem',
    },
    textAlign: 'center',
  },

  formLabelClass: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Regular !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },

  goBackLink: {
    '& .back-icon': {
      fontSize: '0.9rem',
    },
    '& span': {
      textDecoration: 'underline',
    },
    alignItems: 'center',
    color: '#777777',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium',
    fontSize: '12px',
    fontWeight: 400,
    height: '17px',
    letterSpacing: 0,
    lineHeight: '17px',
  },

  gridContainer: {
    '&.MuiGrid-container': {
      padding: '0 !important',
    },
  },

  infoTitle: {
    color: '#241A2E !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },

  infodis: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
  },

  inputStyles: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#f3f3f3 !important',
      borderRadius: '7px !important',
      color: '#241A2E !important',
      fontFamily: 'FiraSans-Medium !important',
      fontSize: '14px !important',
      fontWeight: ' 500 !important',
      letterSpacing: '0 !important',
      // opacity: '0.43 !important',
    },
    '& .MuiFilledInput-root:after': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:before': {
      borderBottom: 0,
    },
    '& .MuiFilledInput-root:hover': {
      border: 'none',
      outline: 'none',
    },
    '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& Mui-error': {
      height: 0,
    },
    '& input': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Medium',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '20px',
      padding: '11px',
    },
    '& label': {
      color: '#241A2E',
      fontFamily: 'FiraSans-Regular',
      fontSize: 12,
      height: 24,
      letterSpacing: 0,
    },
  },

  loginAsAdmin: {
    color: '#2573BF',
    fontFamily: 'FiraSans-Bold',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '22px',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'right',
  },

  makeVideoActiveSwitch: {
    '& .title': {
      color: '#241A2E',
      display: 'inline-flex',
      fontFamily: 'FiraSans-Regular',
      fontSize: '12px',
      fontWeight: 400,
      height: '24px',
      letterSpacing: 0,
      lineHeight: '24px',
      marginRight: '8px',
    },
    alignItems: 'center',
    display: 'flex',
    marginRight: '30px',
  },

  nameHeader: {
    paddingLeft: '0 !important',
    paddingRight: '32px',
  },

  optionsClassName: {
    backgroundColor: '#241A2E !important',
    color: '#FFFFFF',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },

  pxy: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },

  reciaddbox: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  reciadditem: {
    alignItems: 'center',
    backgroundColor: '#DEC330',
    borderRadius: '20px',
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
    marginBottom: '0.5rem !important',
    marginRight: '.5rem !important',
    minWidth: '96px',
    padding: '1rem',
  },
  reciclose: {
    color: '#000000',
    cursor: 'pointer',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '14px !important',
    opacity: '0.2',
    paddingLeft: '.5rem',
    transform: 'scale(1.2)',
  },
  recitext: {
    color: '#FFFFFF !important',
    fontFamily: 'FiraSans-Bold !important',
    fontSize: '1rem !important',
  },
  rectangleTooltip: {
    backgroundColor: '#37414F',
    height: '32px',
    width: '207px',
  },
  rightSidebtn: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  rowHeight: {
    height: '80px',
  },
  saveBtn: {
    '&:hover': {
      backgroundColor: '#0DA728',
      color: '#FFFFFF !important',
    },
    backgroundColor: '#0DA728',
    color: '#FFFFFF !important',
    cursor: 'pointer !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '15px !important',
    letterSpacing: '0.94px !important',
    lineHeight: '22px',
    marginRight: '5px',
    padding: '10px 30px 10px 30px',
    textTransform: 'uppercase',
  },
  scroll: {
    overflow: 'hidden',
    position: 'relative',
  },
  selectClassName: {
    '& .MuiInput-root': {
      height: '44px !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: '8.1px 8px !important',
    },
    '& .MuiSvgIcon-root': {
      backgroundColor: '#F8F8FA !important',
    },
    backgroundColor: '#F8F8FA',
    border: '1px solid #E8E8E8 !important',
    borderRadius: '7px',
    boxSizing: 'border-box',
    marginBottom: '6px',
    paddingLeft: '10px !important',
  },
  selectIn: {
    '& .Mui-disabled': {
      borderRadius: '7px',
      height: '43px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#F8F8FA !important',
      borderRadius: '7px !important',
      color: '#241A2E !important',
      fontFamily: 'FiraSans-Medium !important',
      fontSize: '0.875rem !important',
      fontWeight: ' 500 !important',
      height: '43px',
      letterSpacing: '0 !important',
    },
  },
  selectQuizDropdown: {
    paddingLeft: '0px !important',
  },
  selectW: {
    '& .MuiSelectUnstyled-popper': {
      left: '-10% !important',
    },
    '& .MuiSelectUnstyled-root': {
      margin: 'unset !important',
      minWidth: '100% !important',
    },
  },
  submit: {
    '&:hover': {
      backgroundColor: '#241A2E',
      color: '#FFFFFF !important',
    },
    alignItems: 'center',
    backgroundColor: '#241A2E',
    borderRadius: '4px',
    color: '#ffffff !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '48px',
    justifyContent: 'center',
    letterSpacing: '0.94px',
    marginLeft: '1rem !important',
    minWidth: '100px',
    padding: '12px 27px 12px 27px',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  submited: {
    boxShadow: 'unset',
  },

  table: {
    minWidth: 650,
  },

  thumbnailCard: {
    '& .card-content': {
      padding: '5px 10px 5px 10px',
    },
    alignItems: 'flexStart',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0',
  },

  uploadPreviewContainer: {
    alignItems: 'center',
    display: 'flex',
  },

  uploadThumbnail: {
    '&:hover': {
      backgroundColor: '#241A2E',
      color: '#FFFFFF !important',
    },
    alignItems: 'center',
    backgroundColor: '#241A2E',
    borderRadius: '4px',
    color: '#ffffff !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '48px',
    justifyContent: 'center',
    letterSpacing: '0.94px',
    lineHeight: '22px',
    marginLeft: '10px',
    padding: '12px 22px 12px 22px',
    textAlign: 'right',
    textTransform: 'uppercase',
    width: '170px',
  },

  uploadVideo: {
    '&:hover': {
      backgroundColor: '#241A2E',
      color: '#FFFFFF !important',
    },
    alignItems: 'center',
    backgroundColor: '#241A2E',
    borderRadius: '4px',
    color: '#ffffff !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '0.93rem !important',
    height: '48px',
    justifyContent: 'center',
    letterSpacing: '0.94px',
    lineHeight: '22px',
    padding: '12px 30px 12px 30px',
    textAlign: 'right',
    textTransform: 'uppercase',
    width: '170px',
  },
  videoForm: {
    padding: '0rem 2rem',
    width: '100%',
  },
  videoThumbNailCard: {
    '. MuiPaper-elevation > .MuiCard-root': {
      '&.videoThumbnailContainer': {
        padding: '0 !important',
      },
    },
  },
  videoTitleInput: {
    paddingLeft: '0px !important',
  },
  violationCodesList: {
    '& .more': {
      cursor: 'default',
    },
    color: '#241A2E',
    fontFamily: 'FiraSans-Light',
    fontSize: '14px',
    height: '17px',
    letterSpacing: 0,
    lineHeight: '19px',
  },
  violationMoreCodesList: {
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.8rem',
    lineHeight: '1rem',
  },
}));

export default useStyles;
