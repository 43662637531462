import { styled } from '@mui/material/styles';

const Img = styled('img')({
  display: 'block',
  // margin: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
});
const ContentDiv = styled('div')({
  display: 'flex',
  padding: '12px 0px 0px 10px',
});
const Label = styled('div')({
  color: '#241a2e',
  fontSize: '13px',
  fontWeight: '600',
});

const Values = styled('div')({
  color: '#241a2e',
  fontSize: '13px',
});
const Button = styled('button')({
  backgroundColor: ' #e9e9e9',
  border: 'unset',
  borderRadius: '16px',
  color: '#6a666d',
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: '500',
  height: '32px',
  width: '115px',
});

export { Img, ContentDiv, Label, Button, Values };
