import { Card, CardContent, Grid, Typography } from '@mui/material';
// import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useCustomStore } from '../../../hooks';
import { DriverProfileDetails } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import useStyles from './driverProfileStyles';

const initialDetails: DriverProfileDetails = {
  accessType: '',
  accountName: '',
  address: '',
  city: '',
  contactId: '',
  dob: '',
  email: '',
  firstName: '',
  lastName: '',
  licenseEndorsements: '',
  licenseExpirationDate: '',
  licenseNumber: '',
  licenseOnFile: false,
  licenseState: '',
  licenseType: '',
  medicalCardExpiryDate: '',
  medicalCardIssueDate: '',
  medicalCardOnFile: false,
  medicalExaminerName: '',
  middleName: '',
  name: '',
  nationalRegistryNumber: '',
  phoneNumber: '',
  ssn: '',
  state: '',
  zip: '',
};

const DriverProfile: React.FC = () => {
  const { driverStore } = useCustomStore();
  const classes = useStyles();
  const [driverDetails, setDriverDetails] =
    React.useState<DriverProfileDetails | null>({
      ...initialDetails,
    });
  const { enqueueSnackbar } = useSnackbar();
  const { id }: any = useParams();

  React.useEffect(() => {
    getDriverDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDriverDetails = React.useCallback(async () => {
    const response: any = await driverStore.getDriverDetailByIndividual(id);
    if (response.isOk()) {
      const { value } = response;
      if (value && value.contactId) {
        setDriverDetails({ ...value });
      } else {
        setDriverDetails({ ...initialDetails });
      }
    }

    if (response.isErr()) {
      let message: any = "Error while getting driver's detail";
      if (response && response.error && response.error.message) {
        message = response.error.message;
      }
      setDriverDetails({ ...initialDetails });
      enqueueSnackbar(
        String(message || "Error while getting driver's detail"),
        SnackBarConfig('e'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TransformSsn = (valueAssigned: any) => {
    if (!valueAssigned) {
      return '';
    }
    const valueAssignedLength: number = valueAssigned.length;
    const valueStr: any = valueAssigned
      .replace(/([0-9]{0,3})-?([0-9]{0,2})-?([0-9]{0,4})/gim, '***-**-$3')
      .substr(0, valueAssignedLength);
    return valueStr;
  };

  //   const DateCheckStatus = (datevalue:any) => {
  //       if(!datevalue || !moment(datevalue).isValid()){
  //           return ''
  //       }
  //       return moment(datevalue).format('M/DD/YYYY')
  //   }

  return (
    <div className="driver-profile">
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        style={{ marginTop: '20px' }}>
        <Grid item lg={8} md={12}>
          <Card>
            <CardContent>
              <Grid container style={{ padding: '0px !important' }}>
                <Grid item md={12}>
                  <Typography
                    component={'div'}
                    className={classes.profileTitle}>
                    Profile Information
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="headerplace">
                <Grid md={4}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Driver First Name
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.firstName || '-'}
                  </Typography>
                </Grid>
                <Grid md={4}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Driver Middle Name
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.middleName || '-'}
                  </Typography>
                </Grid>
                <Grid md={4}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Driver Last Name
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.lastName || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="headerplace">
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Phone Number
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.phoneNumber || '-'}
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Email Address
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.email || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="headerplace">
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    DOB
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.dob || '-'}
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    SSN
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {TransformSsn(driverDetails?.ssn) || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="headerplace">
                <Grid md={3}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Address
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.address || '-'}
                  </Typography>
                </Grid>
                <Grid md={3}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    City
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.city || '-'}
                  </Typography>
                </Grid>
                <Grid md={3}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    State
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.state || '-'}
                  </Typography>
                </Grid>
                <Grid md={3}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    ZIP
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.zip || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="headerplace">
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Driver License
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.licenseNumber || '-'}
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Driver License State
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.licenseState || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="headerplace">
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Drivers License Type
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.licenseType || '-'}
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Drivers License Endorsement
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.licenseEndorsements || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="headerplace">
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Drivers License Expiration
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.licenseExpirationDate || '-'}
                  </Typography>
                </Grid>
                <Grid md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Category
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.accessType || '-'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        style={{ marginBottom: '50px', marginTop: '20px' }}>
        <Grid item lg={8} md={12}>
          <Card>
            <CardContent>
              <Grid container className="headerplace">
                <Grid item md={12}>
                  <Typography
                    component={'div'}
                    className={classes.profileTitle}>
                    Medical Card
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="headerplace">
                <Grid item md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Issue Date
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.medicalCardIssueDate || '-'}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Expiration Date
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.medicalCardExpiryDate || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="headerplace">
                <Grid item md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    Medical Examiner Name
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.medicalExaminerName || '-'}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography component={'div'} className={classes.infoTitle}>
                    National Registry
                  </Typography>
                  <Typography component={'div'} className={classes.infoContent}>
                    {driverDetails?.nationalRegistryNumber || '-'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default DriverProfile;
