import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Switch from 'react-switch';

const { v4: uuidv4 } = require('uuid');

import percentage from '../../../../assets/images/percentage.svg';
import trashIcon from '../../../../assets/images/trash-circle.svg';
import { InputText } from '../../../../components/Forms/InputText';
import { useCustomStore } from '../../../../hooks';
import {
  QuizDetails,
  QuizQuestionAnswerOptions,
  QuizQuestions,
  // QuizDetails,
} from '../../../../types/index';
// import { RadioInput } from '../../../../components/Forms/radioInput';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import { QuizSchema } from '../../../../utils/ValidatorSchema';
import useStyles from './QuizzesListStyle';

const QuizAddEdit = (props: any): React.ReactElement => {
  // defaults members
  const defaultQuestionCard = (order: number): QuizQuestions => {
    return {
      _uiid: uuidv4(),
      answerOptions: [
        {
          _uiid: uuidv4(),
          isCorrect: false,
          option: '',
          optionOrder: 1,
        },
        {
          _uiid: uuidv4(),
          isCorrect: false,
          option: '',
          optionOrder: 2,
        },
      ],
      question: '',
      questionOrder: order,
      questionTitle: '',
    };
  };
  const defaultAnswerOptions = (
    optionOrder: number,
  ): QuizQuestionAnswerOptions => {
    return {
      _uiid: uuidv4(),
      isCorrect: false,
      option: '',
      optionOrder: optionOrder,
    };
  };

  // props, style, loaders and other UI state variables
  const [showPageLoader, setShowPageLoader]: any = useState(true);
  const { isView = false } = props;
  const { id }: any = useParams();
  const { quizzesStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [makeQuizActive, setMakeQuizActive]: any = useState(false);
  const [questions, setQuestions]: any = useState([]);
  const [isFormUpdated, setisFormUpdated]: any = useState(false);
  const [isQuizSaving, setIsQuizSaving]: any = useState(false);
  const [deletedQuesIds, setDeletedQuesIds]: any = useState([]);
  const [deletedAnsOptIds, setDeletedAnsOptIds]: any = useState([]);
  const [showQuesLoader, setShowQuesLoader]: any = useState(false);

  // quiz form schema & provider
  const {
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    reset: resetForm,
    trigger,
  } = useForm<{
    correctAnswersPercentage: string;
    title: string;
    active: boolean;
    questions: QuizQuestions[];
    videoTitle: string;
  }>({
    criteriaMode: 'all',
    defaultValues: {
      active: false,
      correctAnswersPercentage: '',
      questions: [defaultQuestionCard(1)],
      title: '',
      videoTitle: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(QuizSchema),
  });

  // setting view and form data on initial load functionality
  const setFormData = useCallback(() => {
    const { correctAnswersPercentage, questions, status, title, videoTitle } =
      quizzesStore.SelectedQuiz;
    setShowQuesLoader(true);
    const _questions = questions.map((q: QuizQuestions) => {
      q._uiid = uuidv4();
      q.answerOptions = q.answerOptions.map((aO: QuizQuestionAnswerOptions) => {
        aO._uiid = uuidv4();
        return aO;
      });
      return q;
    });
    _questions.sort((a: QuizQuestions, b: QuizQuestions) => {
      const key1 = a.questionOrder,
        key2 = b.questionOrder;
      if (key1 < key2) return -1;
      if (key1 > key2) return 1;
      return 0;
    });
    setValue('title', title);
    setValue('correctAnswersPercentage', `${correctAnswersPercentage}`);
    setValue('questions', _questions);
    setValue('active', status === 'active' ? true : false);
    setValue('videoTitle', videoTitle || 'No Training');
    setMakeQuizActive(status === 'active' ? true : false);
    setQuestions(_questions);
    setisFormUpdated(false);
    setShowQuesLoader(false);
    setShowPageLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedQuiz = useCallback(
    async (quizId: string) => {
      quizzesStore.setSelectedQuizReset();
      const quizResp = await quizzesStore.getQuizDetails(quizId);
      if (quizResp.isErr()) {
        enqueueSnackbar('Quiz not found', SnackBarConfig('e'));
      } else if (quizResp.isOk()) {
        quizzesStore.setSelectedQuiz(quizResp.value);
        setFormData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (id) {
      getSelectedQuiz(id);
    } else {
      setQuestions([defaultQuestionCard(1)]);
      quizzesStore.setSelectedQuizReset();
      setShowPageLoader(false);
    }
  }, [getSelectedQuiz, id, quizzesStore]);

  // answer options functionalities
  const addAnswerOptionRow = (question: QuizQuestions) => {
    const _questions = getValues('questions');
    for (let i = 0; i < _questions.length; i++) {
      if (question.questionOrder === _questions[i].questionOrder) {
        const defaultAnsOpt = defaultAnswerOptions(
          _questions[i].answerOptions.length + 1,
        );
        if (id && !isView) {
          defaultAnsOpt.id = -1;
        }
        _questions[i].answerOptions.push(defaultAnsOpt);
      }
    }
    setQuestions(_questions);
    setisFormUpdated(!isFormUpdated);
  };

  const removeAnswerOption = (
    question: QuizQuestions,
    selectedAnswerOption: any,
  ) => {
    const _questions = getValues('questions');
    const deletedAOIds = [];
    for (let i = 0; i < _questions.length; i++) {
      if (_questions[i].questionOrder === question.questionOrder) {
        let _answerOptions = [];
        for (let j = 0; j < _questions[i].answerOptions.length; j++) {
          if (
            _questions[i].answerOptions[j].optionOrder !==
            selectedAnswerOption.optionOrder
          ) {
            _answerOptions.push(_questions[i].answerOptions[j]);
          } else {
            if (
              id &&
              !isView &&
              _questions[i].answerOptions[j].id &&
              _questions[i].answerOptions[j].id !== -1
            ) {
              deletedAOIds.push(_questions[i].answerOptions[j].id);
            }
          }
        }
        _answerOptions = _answerOptions.map((ao: any, i: number) => {
          ao.optionOrder = i + 1;
          return ao;
        });
        _questions[i].answerOptions = _answerOptions;
        setValue(`questions.${i}.answerOptions`, _answerOptions);
      }
    }
    setValue('questions', _questions);
    setQuestions([..._questions]);
    if (id && !isView) {
      setDeletedAnsOptIds([...deletedAnsOptIds, ...deletedAOIds]);
    }
    setisFormUpdated(!isFormUpdated);
  };

  const handleCorrectAnswerChange = (
    question: any,
    selectedAnswerOption: any,
  ) => {
    const _questions = getValues('questions');
    for (let i = 0; i < _questions.length; i++) {
      if (_questions[i].questionOrder === question.questionOrder) {
        for (let j = 0; j < _questions[i].answerOptions.length; j++) {
          if (
            _questions[i].answerOptions[j].optionOrder ===
            selectedAnswerOption.optionOrder
          ) {
            _questions[i].answerOptions[j].isCorrect = true;
          } else {
            _questions[i].answerOptions[j].isCorrect = false;
          }
        }
      }
    }
    setValue('questions', _questions);
    setQuestions([..._questions]);
    setisFormUpdated(!isFormUpdated);
  };

  // question card functionalities
  const addQuestionCard = () => {
    setQuestions([]);
    const _questions = getValues('questions');
    const defaultQuestion = defaultQuestionCard(
      getValues('questions').length + 1,
    );
    if (id && !isView) {
      defaultQuestion.id = -1;
    }
    setValue('questions', [..._questions, defaultQuestion]);
    setQuestions([..._questions, defaultQuestion]);
  };

  const removeQuestion = (question: QuizQuestions) => {
    const deletedQIds: any[] = [];
    const deletedAOIds: any[] = [];
    const _questions = getValues('questions').filter((q: QuizQuestions) => {
      if (q.questionOrder !== question.questionOrder) {
        return true;
      } else {
        if (id && !isView) {
          for (let i = 0; i < q.answerOptions.length; i++) {
            if (q.answerOptions[i].id) deletedAOIds.push(q.answerOptions[i].id);
          }
          if (q.id) deletedQIds.push(q.id);
        }
        return false;
      }
    });
    for (let i = 0; i < _questions.length; i++) {
      _questions[i].questionOrder = i + 1;
    }
    setValue('questions', _questions);
    setQuestions(_questions);
    if (id && !isView) {
      setDeletedAnsOptIds([...deletedAnsOptIds, ...deletedAOIds]);
      setDeletedQuesIds([...deletedQuesIds, ...deletedQIds]);
    }
  };

  const changeQuestionOrder = (
    question: QuizQuestions,
    orderDirection: string,
  ) => {
    setShowQuesLoader(true);
    const _questions = getValues('questions');
    for (let i = 0; i < _questions.length; i++) {
      if (question.questionOrder === _questions[i].questionOrder) {
        let swapIndex = i;
        if (orderDirection === 'UP') {
          if (i === 0) {
            continue;
          }
          --swapIndex;
        } else {
          if (i === _questions.length - 1) {
            continue;
          }
          ++swapIndex;
        }
        const _tempQues = _questions[i];
        _questions[i] = { ..._questions[swapIndex] };
        _questions[swapIndex] = { ..._tempQues };
        break;
      }
    }
    for (let i = 0; i < _questions.length; i++) {
      _questions[i].questionOrder = i + 1;
    }
    setValue('questions', _questions);
    setQuestions([..._questions]);
    setisFormUpdated(!isFormUpdated);
    setShowQuesLoader(false);
  };

  // quiz page functionalities
  const submitQuiz = async () => {
    const formData = getValues();
    let isError = false;
    if (!isValid) {
      trigger();
      enqueueSnackbar(
        String('Please enter required fields'),
        SnackBarConfig('e'),
      );
      isError = true;
    }

    if (formData.questions.length === 0) {
      enqueueSnackbar(
        String('Quiz should have at least 1 question'),
        SnackBarConfig('e'),
      );
      isError = true;
    } else {
      for (let i = 0; i < formData.questions.length; i++) {
        if (formData.questions[i].answerOptions.length < 2) {
          enqueueSnackbar(
            String('Question should have at least 2 answer options'),
            SnackBarConfig('e'),
          );
          isError = true;
        } else {
          let isOptionBlank = false;
          const isCorrectArray: any = formData.questions[i].answerOptions.map(
            (ao: any) => {
              if (ao.option === '') {
                isOptionBlank = true;
              }
              return String(ao.isCorrect);
            },
          );
          if (isOptionBlank) {
            enqueueSnackbar(
              String('Blank Answer option are not allowed'),
              SnackBarConfig('e'),
            );
            isError = true;
          }
          if (!isCorrectArray.includes('true')) {
            enqueueSnackbar(
              String('Please mark one option as correct choice'),
              SnackBarConfig('e'),
            );
            isError = true;
          }
        }
      }
    }
    if (!isError) {
      const _questions = questions.map((q: QuizQuestions) => {
        const _ansOpts = q.answerOptions.map(
          (aO: QuizQuestionAnswerOptions) => {
            const _aO: QuizQuestionAnswerOptions = {
              isCorrect: aO.isCorrect,
              option: aO.option,
              optionOrder: aO.optionOrder,
            };
            if (id && !isView) {
              _aO.id = aO.id || -1;
            }
            return _aO;
          },
        );

        const _question: QuizQuestions = {
          answerOptions: _ansOpts,
          question: q.question,
          questionOrder: q.questionOrder,
          questionTitle: q.questionTitle,
        };
        if (id && !isView) {
          _question.id = q.id || -1;
        }
        return _question;
      });
      // update mode
      if (id && !isView) {
        setIsQuizSaving(true);
        const payload: QuizDetails = {
          correctAnswersPercentage: parseInt(formData.correctAnswersPercentage),
          deleteAnswerOptionIds: deletedAnsOptIds.filter(
            (dAOId: string | number) => {
              if (dAOId && dAOId !== null && dAOId !== -1) {
                return true;
              }
              return false;
            },
          ),
          deleteQuestionIds: deletedQuesIds.filter((dQId: string | number) => {
            if (dQId && dQId !== null && dQId !== -1) {
              return true;
            }
            return false;
          }),
          questions: _questions,
          status: formData.active ? 'active' : 'draft',
          title: formData.title,
        };

        const quizzesUpdateResp = await quizzesStore.updateQuiz(id, payload);
        if (quizzesUpdateResp.isErr()) {
          enqueueSnackbar(
            String(quizzesUpdateResp.error.message),
            SnackBarConfig('e'),
          );
          setIsQuizSaving(false);
        } else if (quizzesUpdateResp.isOk()) {
          setIsQuizSaving(false);
          enqueueSnackbar(String(quizzesUpdateResp.value), SnackBarConfig('s'));
        }
      } else {
        // create mode
        setIsQuizSaving(true);
        const payload: QuizDetails = {
          correctAnswersPercentage: parseInt(formData.correctAnswersPercentage),
          questions: _questions,
          status: formData.active ? 'active' : 'draft',
          title: formData.title,
        };
        // eslint-disable-next-line no-console
        console.log('create', payload);
        const quizzesCreateResp = await quizzesStore.createQuiz(payload);
        if (quizzesCreateResp.isErr()) {
          enqueueSnackbar(
            String(quizzesCreateResp.error.message),
            SnackBarConfig('e'),
          );
          setIsQuizSaving(false);
        } else if (quizzesCreateResp.isOk()) {
          setIsQuizSaving(false);
          enqueueSnackbar(String(quizzesCreateResp.value), SnackBarConfig('s'));
          goBack();
        }
      }
    } else {
      return;
    }
  };

  const cancelQuiz = () => {
    resetForm({
      active: false,
      correctAnswersPercentage: '',
      questions: [],
      title: '',
    });
    // setMakeQuizActive(false);
    navigate('/training/quizzes');
  };

  const goBack = () => {
    navigate('/training/quizzes');
  };

  return (
    <>
      {!showPageLoader && (
        <form className={classes.quizForm}>
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{ padding: '1rem' }}>
            <Grid style={{ alignItems: 'center', display: 'flex' }}>
              <Typography
                noWrap
                className={classes.goBackLink}
                onClick={() => {
                  goBack();
                }}>
                <ArrowBackIosIcon className="back-icon" />
                <span>BACK</span>
              </Typography>
            </Grid>
            <Grid
              justifyContent={'flex-end'}
              display={'flex'}
              alignItems={'center'}>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginRight: '30px',
                }}>
                <Typography
                  noWrap
                  sx={{
                    color: '#241A2E',
                    display: 'inline-flex',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '12px',
                    fontWeight: 400,
                    height: '24px',
                    letterSpacing: 0,
                    lineHeight: '24px',
                    marginRight: '8px',
                  }}>
                  {!isView ? 'Make Quiz Active' : 'Quiz Status'}
                </Typography>
                <Switch
                  onChange={(checked: boolean, e: any, id: string) => {
                    setMakeQuizActive(checked);
                    setValue('active', checked);
                  }}
                  checked={makeQuizActive ? true : false}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor={'#0DA728'}
                  offColor={'#787880'}
                  width={51}
                  height={31}
                  className={'activeSwitch'}
                  disabled={isView ? true : false}
                />
              </div>
              {!isView && (
                <>
                  <Button
                    variant="contained"
                    className={classes.cancelBtn}
                    onClick={() => {
                      cancelQuiz();
                    }}>
                    {'CANCEL'}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isQuizSaving}
                    className={classes.saveBtn}
                    onClick={async () => {
                      await submitQuiz();
                    }}>
                    {isQuizSaving ? (
                      <CircularProgress size={20} sx={{ color: '#DEC330' }} />
                    ) : (
                      'SAVE'
                    )}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent={'center'} alignItems={'space-evenly'}>
            <Grid container justifyContent={'space-evenly'}>
              <Grid
                item
                xs={4}
                md={4}
                lg={4}
                style={{ height: 'max-content', padding: '1rem' }}
                className={'quizDetailsCardContainer'}>
                <Card
                  style={{
                    alignItems: 'flexStart',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                  }}
                  sx={{
                    padding: '0',
                  }}
                  className={'quizDetailsCard'}>
                  <CardContent
                    sx={{ padding: '5px 30px 5px 30px', width: '100%' }}>
                    <Typography
                      component={'div'}
                      sx={{
                        color: '#241A2E',
                        display: 'flex',
                        fontFamily: 'FiraSans-Semibold',
                        fontSize: '18px',
                        letterSpacing: 0,
                        lineHeight: '26px',
                        paddingBottom: '14px',
                      }}>
                      Quiz Details
                    </Typography>
                    <Typography component={'div'}>
                      <div
                        style={{
                          width: '100%',
                        }}>
                        <FormControl style={{ width: '100%' }}>
                          <Grid
                            className={classes.quizInputContainer}
                            style={{ padding: '0px !important' }}>
                            <Grid
                              item
                              md={12}
                              style={{ marginBottom: '.5rem' }}>
                              <Box className={classes.formLabelClass}>
                                Quiz Title{' '}
                                {<span style={{ color: '#A70D0D' }}>*</span>}
                              </Box>
                              <Controller
                                control={control}
                                name="title"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <InputText
                                      isFormSubmittedOnce={
                                        !!formState.submitCount
                                      }
                                      errors={errors.title?.message || ''}
                                      name={name}
                                      isRefreshValue={true}
                                      value={value}
                                      onChangeText={(val) => {
                                        onChange(val);
                                        setValue('title', val);
                                      }}
                                      placeholder="Title"
                                      isEditable={isView}
                                      className={classes.selectIn}
                                      type={'text'}
                                      variant="standard"
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                      </div>
                    </Typography>
                    <Typography component={'div'}>
                      <div
                        style={{
                          width: '100%',
                        }}>
                        <FormControl style={{ width: '100%' }}>
                          <Grid
                            className={classes.quizInputContainer}
                            style={{ padding: '0px !important' }}>
                            <Grid
                              item
                              md={12}
                              style={{ marginBottom: '.5rem' }}>
                              <Box className={classes.formLabelClass}>
                                Correct Answers Needed{' '}
                                {<span style={{ color: '#A70D0D' }}>*</span>}
                              </Box>
                              <Controller
                                control={control}
                                name="correctAnswersPercentage"
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  return (
                                    <>
                                      {!isView && (
                                        <InputText
                                          isFormSubmittedOnce={
                                            !!formState.submitCount
                                          }
                                          errors={
                                            errors.correctAnswersPercentage
                                              ?.message || ''
                                          }
                                          placeholder="Percentage"
                                          name={name}
                                          isRefreshValue={true}
                                          value={value}
                                          onChangeText={(val) => {
                                            if (/^[0-9]*$/.test(val)) {
                                              setValue(
                                                'correctAnswersPercentage',
                                                val,
                                              );
                                              onChange(val);
                                            }
                                          }}
                                          isEditable={isView}
                                          className={classes.selectIn}
                                          type={'text'}
                                          variant="standard"
                                          initIcon={
                                            <InputAdornment position="end">
                                              <img
                                                className={classes.hIcon}
                                                src={percentage}
                                              />
                                            </InputAdornment>
                                          }
                                          iconPosition={'end'}
                                        />
                                      )}
                                      {isView && (
                                        <div style={{ display: 'flex' }}>
                                          <InputText
                                            isFormSubmittedOnce={
                                              !!formState.submitCount
                                            }
                                            errors={
                                              errors.correctAnswersPercentage
                                                ?.message || ''
                                            }
                                            placeholder="Percentage"
                                            name={name}
                                            isRefreshValue={true}
                                            value={value}
                                            onChangeText={(val) => {
                                              if (/^[0-9]*$/.test(val)) {
                                                setValue(
                                                  'correctAnswersPercentage',
                                                  val,
                                                );
                                                onChange(val);
                                              }
                                            }}
                                            isEditable={isView}
                                            className={classes.selectIn}
                                            type={'text'}
                                            variant="standard"
                                          />
                                          <img
                                            className={
                                              classes.percentageDisabledIcon
                                            }
                                            src={percentage}
                                          />
                                        </div>
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                      </div>
                    </Typography>
                    <Typography component={'div'}>
                      <Typography
                        component={'p'}
                        sx={{
                          color: '#241A2E',
                          display: 'flex',
                          fontFamily: 'FiraSans-Regular',
                          fontSize: '12px',
                          letterSpacing: 0,
                          lineHeight: '24px',
                        }}>
                        Attached To Training
                      </Typography>
                      <Typography
                        component={'p'}
                        sx={{
                          color: '#241A2E',
                          display: 'flex',
                          fontFamily: 'FiraSans-Medium',
                          fontSize: '14px',
                          letterSpacing: 0,
                          lineHeight: '20px',
                          paddingBottom: '14px',
                        }}>
                        {getValues('videoTitle')}
                      </Typography>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={8} md={8} lg={8} style={{ padding: '1rem' }}>
                {!showQuesLoader &&
                  questions.map((question: any, index: number) => (
                    <Card
                      sx={{ marginBottom: '10px' }}
                      key={question._uiid}
                      id={question._uiid}>
                      <CardContent>
                        <Grid container style={{ padding: '0 1rem' }}>
                          <Grid item md={12} className={classes.questionTopBar}>
                            <Typography
                              component={'p'}
                              sx={{
                                color: '#241A2E',
                                display: 'flex',
                                fontFamily: 'FiraSans-Bold',
                                fontSize: '18px',
                                letterSpacing: 0,
                                lineHeight: '26px',
                                width: '9%',
                              }}>
                              {index + 1} of {questions.length}
                            </Typography>
                            <Typography
                              component={'div'}
                              sx={{
                                color: '#241A2E',
                                display: 'flex',
                                flexDirection: 'column',
                                fontFamily: 'FiraSans-Regular',
                                fontSize: '12px',
                                letterSpacing: 0,
                                lineHeight: '24px',
                              }}>
                              {!isView && (
                                <>
                                  <KeyboardArrowUpIcon
                                    style={{
                                      color: '#777777',
                                    }}
                                    className={
                                      index !== 0 ? classes.ArrowUpIcon : ''
                                    }
                                    onClick={() => {
                                      changeQuestionOrder(question, 'UP');
                                    }}
                                  />
                                  <KeyboardArrowDownIcon
                                    style={{
                                      color: '#777777',
                                    }}
                                    className={
                                      index !== questions.length - 1
                                        ? classes.ArrowDownIcon
                                        : ''
                                    }
                                    onClick={() => {
                                      changeQuestionOrder(question, 'DOWN');
                                    }}
                                  />
                                </>
                              )}
                            </Typography>
                            {!isView && (
                              <Typography
                                component={'div'}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  width: '100%',
                                }}>
                                <Typography
                                  component={'p'}
                                  sx={{
                                    color: '#241A2E',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    fontFamily: 'FiraSans-Medium',
                                    fontSize: '12px',
                                    letterSpacing: 0,
                                    lineHeight: '17px',
                                    textDecoration: 'underline',
                                  }}
                                  onClick={(e: any) => {
                                    removeQuestion(
                                      getValues(`questions.${index}`),
                                    );
                                  }}>
                                  DELETE
                                </Typography>
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={12}>
                            <FormControl style={{ width: '100%' }}>
                              <Grid
                                className={classes.quizInputContainer}
                                style={{ padding: '0px !important' }}>
                                <Grid
                                  item
                                  md={12}
                                  style={{ marginBottom: '.5rem' }}>
                                  <Box className={classes.formLabelClass}>
                                    Title{' '}
                                    {
                                      <span style={{ color: '#A70D0D' }}>
                                        *
                                      </span>
                                    }
                                  </Box>
                                  <Controller
                                    control={control}
                                    defaultValue={''}
                                    name={`questions.${index}.questionTitle`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                      formState,
                                    }) => {
                                      return (
                                        <InputText
                                          isFormSubmittedOnce={
                                            !!formState.submitCount
                                          }
                                          name={name}
                                          isRefreshValue={true}
                                          value={value}
                                          onChangeText={(val) => {
                                            setValue(
                                              `questions.${index}.questionTitle`,
                                              val,
                                            );
                                            setQuestions(
                                              getValues('questions'),
                                            );
                                          }}
                                          placeholder="Title"
                                          isEditable={isView}
                                          className={classes.selectIn}
                                          type={'text'}
                                          variant="standard"
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </FormControl>
                            <FormControl style={{ width: '100%' }}>
                              <Grid
                                className={classes.quizInputContainer}
                                style={{ padding: '0px !important' }}>
                                <Grid
                                  item
                                  md={12}
                                  style={{ marginBottom: '.5rem' }}>
                                  <Box className={classes.formLabelClass}>
                                    Question{' '}
                                    {
                                      <span style={{ color: '#A70D0D' }}>
                                        *
                                      </span>
                                    }
                                  </Box>
                                  <Controller
                                    control={control}
                                    defaultValue={''}
                                    name={`questions.${index}.question`}
                                    render={({
                                      field: { onChange, value, name, ref },
                                      formState,
                                    }) => {
                                      return (
                                        <InputText
                                          isFormSubmittedOnce={
                                            !!formState.submitCount
                                          }
                                          name={name}
                                          isRefreshValue={true}
                                          value={value}
                                          onChangeText={(val) => {
                                            setValue(
                                              `questions.${index}.question`,
                                              val,
                                            );
                                            if (id && !isView) {
                                              if (
                                                getValues(
                                                  `questions.${index}.id`,
                                                ) &&
                                                getValues(
                                                  `questions.${index}.id`,
                                                ) !== -1
                                              ) {
                                                setDeletedQuesIds([
                                                  ...deletedQuesIds,
                                                  getValues(
                                                    `questions.${index}.id`,
                                                  ),
                                                ]);
                                              }
                                              setValue(
                                                `questions.${index}.id`,
                                                -1,
                                              );
                                              const deletedOptionId = [];
                                              for (
                                                let i = 0;
                                                i <
                                                getValues(
                                                  `questions.${index}.answerOptions`,
                                                ).length;
                                                i++
                                              ) {
                                                if (
                                                  getValues(
                                                    `questions.${index}.answerOptions.${i}.id`,
                                                  ) &&
                                                  getValues(
                                                    `questions.${index}.answerOptions.${i}.id`,
                                                  ) !== -1
                                                ) {
                                                  deletedOptionId.push(
                                                    getValues(
                                                      `questions.${index}.answerOptions.${i}.id`,
                                                    ),
                                                  );
                                                }
                                                setValue(
                                                  `questions.${index}.answerOptions.${i}.id`,
                                                  -1,
                                                );
                                              }
                                              setDeletedAnsOptIds([
                                                ...deletedAnsOptIds,
                                                ...deletedOptionId,
                                              ]);
                                            }
                                            setQuestions(
                                              getValues('questions'),
                                            );
                                          }}
                                          placeholder="Question"
                                          isEditable={isView}
                                          className={classes.selectIn}
                                          type={'text'}
                                          variant="standard"
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </FormControl>
                            <Grid
                              item
                              md={12}
                              className={classes.questionAnswerBar}>
                              <Typography
                                component={'p'}
                                sx={{
                                  color: '#241A2E',
                                  display: 'flex',
                                  fontFamily: 'FiraSans-Medium',
                                  fontSize: '10px',
                                  letterSpacing: 0,
                                  lineHeight: '10px',
                                  width: '9%',
                                }}>
                                {'Correct Answer'}
                              </Typography>
                              <Typography
                                component={'div'}
                                sx={{
                                  color: '#241A2E',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  fontFamily: 'FiraSans-Regular',
                                  fontSize: '12px',
                                  letterSpacing: 0,
                                  lineHeight: '10px',
                                  paddingLeft: '14px',
                                  width: '100%',
                                }}>
                                {'Answer Options'}
                              </Typography>
                              {!isView && (
                                <Typography
                                  component={'div'}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    width: '100%',
                                  }}>
                                  <Typography
                                    component={'p'}
                                    sx={{
                                      color: '#0061FF',
                                      cursor: 'pointer',
                                      display: 'flex',
                                      fontFamily: 'FiraSans-Medium',
                                      fontSize: '12px',
                                      letterSpacing: 0.8,
                                      lineHeight: '17px',
                                      textDecoration: 'underline',
                                    }}
                                    onClick={(e: any) => {
                                      addAnswerOptionRow(
                                        getValues(`questions.${index}`),
                                      );
                                    }}>
                                    + ANSWER OPTION
                                  </Typography>
                                </Typography>
                              )}
                            </Grid>
                            <Grid item md={12}>
                              <FormControl style={{ width: '100%' }}>
                                <Grid
                                  className={classes.quizInputContainer}
                                  style={{ padding: '0px !important' }}>
                                  <Grid
                                    item
                                    md={12}
                                    style={{ marginBottom: '.5rem' }}>
                                    {question.answerOptions.map(
                                      (answerOption: any, i: number) => (
                                        <React.Fragment
                                          key={answerOption._uiid}>
                                          <div
                                            style={{
                                              display: 'flex',
                                              marginBottom: '6px',
                                            }}>
                                            <Radio
                                              checked={answerOption.isCorrect}
                                              onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                                checked: boolean,
                                              ) => {
                                                handleCorrectAnswerChange(
                                                  question,
                                                  answerOption,
                                                );
                                              }}
                                              value={answerOption.isCorrect}
                                              name={`questions.${index}.answerOptions.${i}.isCorrect`}
                                              disabled={isView}
                                              className={
                                                isView && answerOption.isCorrect
                                                  ? classes.correctOptionsMarker
                                                  : classes.radioOutline
                                              }
                                            />
                                            <Controller
                                              control={control}
                                              name={`questions.${index}.answerOptions.${i}.option`}
                                              defaultValue={''}
                                              render={({
                                                field: {
                                                  onChange,
                                                  value,
                                                  name,
                                                  ref,
                                                },
                                                formState,
                                              }) => {
                                                return (
                                                  <>
                                                    <InputText
                                                      name={name}
                                                      isRefreshValue={true}
                                                      value={value}
                                                      onChangeText={(val) => {
                                                        setValue(
                                                          `questions.${index}.answerOptions.${i}.option`,
                                                          val,
                                                        );
                                                        if (id && !isView) {
                                                          if (
                                                            getValues(
                                                              `questions.${index}.answerOptions.${i}.id`,
                                                            ) &&
                                                            getValues(
                                                              `questions.${index}.answerOptions.${i}.id`,
                                                            ) !== -1
                                                          ) {
                                                            setDeletedAnsOptIds(
                                                              [
                                                                ...deletedAnsOptIds,
                                                                getValues(
                                                                  `questions.${index}.answerOptions.${i}.id`,
                                                                ),
                                                              ],
                                                            );
                                                          }
                                                          setValue(
                                                            `questions.${index}.answerOptions.${i}.id`,
                                                            -1,
                                                          );
                                                        }
                                                        setQuestions(
                                                          getValues(
                                                            'questions',
                                                          ),
                                                        );
                                                      }}
                                                      placeholder="Option"
                                                      isEditable={isView}
                                                      className={
                                                        classes.selectIn
                                                      }
                                                      type={'text'}
                                                      variant="standard"
                                                    />
                                                    {!isView && (
                                                      <img
                                                        src={trashIcon}
                                                        style={{
                                                          cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                          removeAnswerOption(
                                                            getValues(
                                                              `questions.${index}`,
                                                            ),
                                                            getValues(
                                                              `questions.${index}.answerOptions.${i}`,
                                                            ),
                                                          );
                                                        }}
                                                      />
                                                    )}
                                                  </>
                                                );
                                              }}
                                            />
                                          </div>
                                        </React.Fragment>
                                      ),
                                    )}
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  ))}
                {showQuesLoader && (
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '80px',
                      marginTop: '80px',
                      width: '100%',
                    }}>
                    <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                  </div>
                )}
                {!isView && (
                  <Button
                    className={classes.submit}
                    onClick={() => {
                      addQuestionCard();
                    }}>
                    {'ADD QUESTIONS'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {showPageLoader && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      )}
    </>
  );
};

export default observer(QuizAddEdit);
