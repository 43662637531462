import { Card, CardContent, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo, useState } from 'react';

import Loader from '../../../components/Loader/Loader';
import { useCustomStore } from '../../../hooks';
import PJSON from './../../../../package.json';
import { BUILD_TIME_STAMP } from './../../../simplex_time';
// import { UpdateAdminInput } from '../../../types';
// import { UpdateAdminFormSchema } from '../../../utils/ValidatorSchema';
import useStyles from './ProfileStyles';

const Profile: React.FC = () => {
  const { authStore, userStore } = useCustomStore();
  const date = useMemo(() => {
    if (BUILD_TIME_STAMP) {
      const date_ = new Date(BUILD_TIME_STAMP * 1000);
      return `${moment(date_).format('MM/DD/YYYY hh:mm A')}`;
    }
  }, []);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { UserData } = userStore;

  const clearSession = () => {
    setLoading(true);
    authStore.logout();
    localStorage.clear();
  };

  return loading ? (
    <Loader
      className={'flex-centering mt-1em mb-1em'}
      style={{ marginBottom: 16, marginTop: 16 }}
      color="primary"
    />
  ) : (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      style={{
        minHeight: '60vh',
      }}>
      <Grid item style={{ paddingTop: '3rem' }}>
        <Card
          variant="outlined"
          style={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
            height: '192px',
            padding: '1rem',
            width: '782px',
          }}>
          <CardContent>
            <Grid container>
              <Grid item md={8}>
                <Typography className={classes.profileTitle}>
                  Profile Information
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ padding: '1rem 0' }}>
              <Grid item md={6}>
                <Typography className={classes.infoTitle}>Name</Typography>
                <Typography className={classes.infodis}>
                  {UserData.name}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography component={'div'} className={classes.infoTitle}>
                  Email
                </Typography>
                <Typography component={'div'} className={classes.infodis}>
                  {UserData.email}
                </Typography>
              </Grid>
            </Grid>
            <Typography onClick={clearSession} className={classes.logoutLink}>
              Log Out
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        style={{ marginTop: '20px' }}>
        <h4>VERSION: {PJSON.version}</h4>
        <br />
        <h5>BUILD MADE ON {date}</h5>
      </Grid>
    </Grid>
  );
};

export default observer(Profile);
