/*
  Web Vitals are a set of useful metrics that aim to capture the user experience of a web page.
  In Create React App, a third-party library is used to measure these metrics (web-vitals).
  To understand more about the object returned to the function when a metric value is calculated,
  refer to the documentation: https://github.com/GoogleChrome/web-vitals/#types
  With the reportWebVitals function, you can send any of results to an analytics
  endpoint to measure and track real user performance on your site.
  For more details : https://create-react-app.dev/docs/measuring-performance
  Common usage is to implement Google Analytics in application.
*/
import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
