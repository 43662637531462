import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import React from 'react';

const CustomSortingIcon: React.FC<{
  sortBY: (src: any) => void;
  filterSortBy?: string;
  sortByName: string;
  filterSortDirection?: 'asc' | 'desc';
}> = ({ sortBY, sortByName, filterSortBy, filterSortDirection }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <ArrowDropUp
        fontSize="small"
        style={{
          color:
            filterSortBy &&
            filterSortBy === sortByName &&
            filterSortDirection == 'desc'
              ? '#DEC330'
              : '#D8D8D8',
          cursor: 'pointer',
          marginBottom: '-14px',
        }}
        onClick={() => sortBY(sortByName)}
      />
      <ArrowDropDown
        fontSize="small"
        style={{
          color:
            filterSortBy &&
            filterSortBy == sortByName &&
            filterSortDirection == 'asc'
              ? '#DEC330'
              : '#D8D8D8',
          cursor: 'pointer',
        }}
        onClick={() => sortBY(sortByName)}
      />
    </div>
  );
};

export default observer(CustomSortingIcon);
