import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    position: 'relative',
  },
  mainContent: {
    flex: 1,
    paddingLeft: '15.3rem',
    position: 'relative',
  },
}));

export default useStyles;
