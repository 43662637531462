import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import {
  CreateTrainingInputs,
  TrainingFileInputs,
  TrainingVideoFilters,
  TrainingViolationCodeInputs,
  TrainingViolationGroupInputs,
  TrainingViolationMappingInputs,
  UpdateTrainingInputs,
} from '../../types';
import { objectClean } from '../../utils/helper';
import { RootStore } from '..';

export class VideoApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  uploadTrainingFile = (payload: TrainingFileInputs) => {
    const {
      UploadTrainingFile: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      data: payload.file,
      method: Method,
      params: {
        type: payload.type,
      },
      url: Endpoint,
    });
  };

  getQuizLookupList = () => {
    const {
      GetQuizLookList: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint,
    });
  };

  createTraining = (payload: CreateTrainingInputs) => {
    const {
      CreateTraining: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      data: payload,
      method: Method,
      url: Endpoint,
    });
  };

  updateTraining = (trainingId: string, payload: UpdateTrainingInputs) => {
    const {
      UpdateTraining: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      data: payload,
      method: Method,
      url: Endpoint.concat(trainingId),
    });
  };

  getAllTrainings = (filters: TrainingVideoFilters) => {
    const {
      GetTrainings: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean({ ...filters }),
      },
      url: Endpoint,
    });
  };

  getTraining = (trainingId: string) => {
    const {
      GetTraining: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint.concat(trainingId),
    });
  };

  archiveTraining = (trainingId: string) => {
    const {
      ArchiveTraining: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint.concat(trainingId),
    });
  };

  getTrainingViolationBasicCategories = () => {
    const {
      GetTrainingViolationBasicCategories: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint,
    });
  };

  getTrainingViolationGroups = (params: TrainingViolationGroupInputs) => {
    const {
      GetTrainingViolationGroups: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(params),
      },
      url: Endpoint,
    });
  };

  getTrainingViolationCodes = (params: TrainingViolationCodeInputs) => {
    const {
      GetTrainingViolationCodes: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(params),
      },
      url: Endpoint,
    });
  };

  getTrainingViolationMappings = (params: TrainingViolationMappingInputs) => {
    const {
      GetTrainingViolationMappings: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(params),
      },
      url: Endpoint,
    });
  };

  getTrainingAssignmentReasons = (params: { showSystemReason?: boolean }) => {
    const {
      GetTrainingAssignmentReasons: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      params: {
        ...objectClean(params),
      },
      url: Endpoint,
    });
  };

  getCertificateTypes = () => {
    const {
      GetCertificateTypes: { Endpoint, Method },
    } = ApiRoutes.Video;
    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint,
    });
  };
}
