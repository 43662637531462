import { useEffect, useRef } from 'react';

export { useCustomStore } from './store';

export const UseFocus = () => {
  const htmlElRef: any = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (fun: any) => useEffect(fun, []);
