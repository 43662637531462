/* eslint-disable @typescript-eslint/restrict-template-expressions */
import NavRoutes from './routes/NavRoutes';

export const PrivateRoute = {
  COMPANIES: `/${NavRoutes.PRIVATE.COMPANIES}`,
  DRIVERPROFILE: `/${NavRoutes.PRIVATE.DRIVERPROFILE}/:id`,
  DRIVERS: `/${NavRoutes.PRIVATE.DRIVERS}`,
  MAPPING: `/${NavRoutes.PRIVATE.TRAINING.TRAINING}/${NavRoutes.PRIVATE.TRAINING.MAPPING}`,
  NOTIFICATIONS: `/${NavRoutes.PRIVATE.NOTIFICATIONS}`,
  QUIZZES: `/${NavRoutes.PRIVATE.TRAINING.TRAINING}/${NavRoutes.PRIVATE.TRAINING.QUIZZES}`,
  QUIZ_ADD: `/${NavRoutes.PRIVATE.TRAINING.TRAINING}/${NavRoutes.PRIVATE.TRAINING.QUIZZES}/${NavRoutes.PRIVATE.TRAINING.ADD}`,
  QUIZ_EDIT: `/${NavRoutes.PRIVATE.TRAINING.TRAINING}/${NavRoutes.PRIVATE.TRAINING.QUIZZES}/${NavRoutes.PRIVATE.TRAINING.EDIT}/:id`,
  QUIZ_VIEW: `/${NavRoutes.PRIVATE.TRAINING.TRAINING}/${NavRoutes.PRIVATE.TRAINING.QUIZZES}/${NavRoutes.PRIVATE.TRAINING.VIEW}/:id`,
  SETTINGS: `/${NavRoutes.PRIVATE.SETTINGS}`,
  TRAINING: `/${NavRoutes.PRIVATE.TRAINING.TRAINING}`,
  TRAININGS: `/${NavRoutes.PRIVATE.TRAINING.TRAINING}/${NavRoutes.PRIVATE.TRAINING.TRAININGS}`,
  TRAINING_ADD: `/${NavRoutes.PRIVATE.TRAINING.TRAINING}/${NavRoutes.PRIVATE.TRAINING.TRAININGS}/${NavRoutes.PRIVATE.TRAINING.ADD}`,
  TRAINING_EDIT: `/${NavRoutes.PRIVATE.TRAINING.TRAINING}/${NavRoutes.PRIVATE.TRAINING.TRAININGS}/${NavRoutes.PRIVATE.TRAINING.EDIT}/:id`,
  USERS: `/${NavRoutes.PRIVATE.USERS}`,
};

export const PublicRoute = {
  CREATEPASSWORD: `/${NavRoutes.PUBLIC.CREATEPASSWORD}`,
  FORGOTPASSWORD: `/${NavRoutes.PUBLIC.FORGOTPASSWORD}`,
  LOGIN: `/${NavRoutes.PUBLIC.LOGIN}`,
  RESETPASSWORD: `/${NavRoutes.PUBLIC.RESETPASSWORD}`,
};
