import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
// import { CompanyOrDriverDTO } from '../../types';
import { RootStore } from '..';

export class GlobalApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getStateList = () => {
    const {
      GetList: { Endpoint, Method },
    } = ApiRoutes.States;

    return this.rootStore.apiStore.call<any>({
      method: Method,
      url: Endpoint,
    });
  };
}
