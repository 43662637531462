import { colors } from '@mui/material';

import { AppStyles } from '../style/variable';

const paletteLight = {
  background: {
    default: AppStyles.background.primary,
    paper: AppStyles.background.secondary,
  },
  border: AppStyles.borderColor.primary,
  common: {
    black: AppStyles.color.black,
    white: AppStyles.color.white,
  },
  divider: colors.grey[200],
  error: {
    contrastText: AppStyles.color.white,
    dark: AppStyles.color.tertiary,
    light: AppStyles.color.tertiary,
    main: AppStyles.color.tertiary,
  },
  icon: AppStyles.iconsBgColor.primary,
  info: {
    contrastText: AppStyles.color.white,
    dark: colors.blue[900],
    light: colors.blue[400],
    main: colors.blue[600],
  },
  primary: {
    contrastText: AppStyles.color.white,
    dark: AppStyles.color.primary,
    light: AppStyles.color.lightBlue,
    main: AppStyles.color.primary,
  },
  secondary: {
    contrastText: AppStyles.color.white,
    dark: colors.blue[900],
    light: colors.blue['A400'],
    main: colors.blue['A400'],
  },
  success: {
    contrastText: AppStyles.color.white,
    dark: AppStyles.color.secondary,
    light: AppStyles.color.secondary,
    main: AppStyles.color.secondary,
  },
  text: {
    disabled: AppStyles.fontColor.tertiary,
    hint: AppStyles.fontColor.lightGray,
    primary: AppStyles.fontColor.primary,
    secondary: AppStyles.fontColor.secondary,
  },
  warning: {
    contrastText: AppStyles.color.white,
    dark: AppStyles.color.tertiary,
    light: AppStyles.color.tertiary,
    main: AppStyles.color.tertiary,
  },
};

export default paletteLight;
