import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  '& .MuiPaper-elevation': {
    padding: '0.5px 0px !important',
  },
  headerSequence: {
    padding: '0.5rem 0 !important',
  },
  infoContent: {
    background: '#F8F8FA !important',
    borderRadius: '5px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    lineHeight: '20px !important',
    marginRight: '10px',
    padding: '11px 7px !important',
  },
  infoTitle: {
    color: '#241A2E !important',
    fontSize: '12px !important',
    lineHeight: '24px !important',
  },
  profileBox: {
    backgroundColor: '#FFFFFF',
    height: '300px',
    maxWidth: '782px',
  },
  profileContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  profileTitle: {
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '20px !important',
  },
}));

export default useStyles;
