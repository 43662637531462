import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';

const Bold = styled('span')(() => ({
  color: 'red !important',
  fontSize: 20,
  fontWeight: 700,
}));
const IdleAlert: React.FC<{ onContinue: () => any; onLogout: () => any }> = (
  props,
) => {
  const [timeLeft, setTimeLeft] = useState(60);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((timeLeft) => (timeLeft > 0 ? timeLeft - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Dialog open={true} id="alert">
      <DialogContent>
        <Alert severity="warning">
          <AlertTitle>Session Expiry Warning</AlertTitle>
          <Typography paragraph>
            You will be logged out in <Bold>{timeLeft}</Bold> seconds. Press
            stay logged in to continue the session.
          </Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Grid
          paddingX={5}
          paddingY={1}
          container
          display="flex"
          justifyContent="space-between">
          <Button variant="contained" color="error" onClick={props.onLogout}>
            LOGOUT
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={props.onContinue}>
            STAY LOGGED IN
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default IdleAlert;
