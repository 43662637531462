import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useCustomStore } from '../../../../hooks';
import useStyles from './mappingStyle';

const MappingList = (): React.ReactElement => {
  const { videoStore } = useCustomStore();
  const classes = useStyles();

  return (
    <>
      {videoStore.ViolationMappings.map((mapping: any, index: number) => (
        <TableRow
          key={index}
          sx={{
            backgroundColor:
              Array.isArray(mapping.videosMapped) &&
              mapping.videosMapped.length > 0
                ? '#FFFFFF'
                : '#F9F9F9',
            boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)',
            height: '80px',
            minHeight: '80px',
            paddingBottom: '20px',
          }}>
          {/* BASIC Category Name */}
          <TableCell>
            <Grid item xs={12} md={12} lg={12}>
              <Typography className={classes.boldText}>
                {mapping.basicCategoryName || '-'}
              </Typography>
            </Grid>
          </TableCell>
          {/* Violation Group Name */}
          <TableCell>
            <Grid item xs={12} md={12} lg={12}>
              <Typography className={classes.boldText}>
                {mapping.violationGroupName || '-'}
              </Typography>
            </Grid>
          </TableCell>
          {/* Violation Code */}
          <TableCell>
            <Grid item xs={12} md={12} lg={12}>
              <Typography className={classes.boldText}>
                {mapping.violationCode || '-'}
              </Typography>
            </Grid>
          </TableCell>
          {/* Violation Description */}
          <TableCell style={{ width: '35%' }}>
            <Grid item xs={12} md={12} lg={12}>
              {mapping.violationDescription || '-'}
            </Grid>
          </TableCell>
          {/* Videos Mapped */}
          <TableCell>
            <Grid item xs={12} md={12} lg={12}>
              {mapping.videosMapped && mapping.videosMapped.length > 0 ? (
                mapping.videosMapped.map((videoName: string, index: number) => {
                  return (
                    <Typography
                      key={`${videoName}+${index}`}
                      sx={{
                        color: '#241A2E',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '16px',
                        fontWeight: '300',
                        letterSpacing: 0,
                        lineHeight: '19px',
                      }}>
                      {videoName}
                    </Typography>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    backgroundColor: '#F9F9F9',
                    color: '#241A2E',
                    fontFamily: 'FiraSans-Regular',
                    fontSize: '16px',
                    fontWeight: '300',
                    letterSpacing: 0,
                    lineHeight: '19px',
                  }}>
                  No Trainings
                </Typography>
              )}
            </Grid>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default observer(MappingList);
