import { PrivateRoute } from '../../config/Constants';
import { AppPage } from '../../types';

export const AdminPages: AppPage[] = [
  {
    aicon: '/assets/images/a-dashboard.svg',
    icon: '/assets/images/n-dashboard.svg',
    route: PrivateRoute.COMPANIES,
    title: 'Companies',
    whiteIcon: '',
  },
  {
    aicon: '/assets/images/a-driver.svg',
    icon: '/assets/images/n-driver.svg',
    route: PrivateRoute.DRIVERS,
    title: 'Drivers',
    whiteIcon: '',
  },
  {
    aicon: '/assets/images/a-notification.svg',
    icon: '/assets/images/n-notification.svg',
    route: PrivateRoute.NOTIFICATIONS,
    title: 'Notifications',
    whiteIcon: '',
  },
  {
    aicon: '/assets/images/a-notification.svg',
    icon: '/assets/images/n-notification.svg',
    route: PrivateRoute.TRAININGS,
    title: 'Training',
    whiteIcon: '',
  },
  // {
  //   aicon: '/assets/images/a-setting.svg',
  //   icon: '/assets/images/n-setting.svg',
  //   route: PrivateRoute.SETTINGS,
  //   title: 'Settings',
  //   whiteIcon: '',
  // },
  {
    aicon: '/assets/images/a-admin.svg',
    icon: '/assets/images/n-admin.svg',
    route: PrivateRoute.USERS,
    title: 'Users',
    whiteIcon: '',
  },
];
