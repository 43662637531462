import { makeAutoObservable, toJS } from 'mobx';

import { Notification } from '../types';
import { RootStore } from '.';

const API_LIMIT: any = 20;
const newNotificationInitialData = {
  description: '',
  isPushNotification: false,
  isSplachNotification: false,
  link: '',
  recipients: [],
  scheduleEndDate: null,
  scheduleStartDate: null,
  title: '',
  unitQuantityMaximum: 0,
  unitQuantityMinimum: 0,
  urlDescription: '',
  yearsInBusinessMaximum: 0,
  yearsInBusinessMinimum: 0,
};

export class NotificationStore {
  private _notificationDetails: any = {
    apiNotifications: [],
    newNotification: {
      ...newNotificationInitialData,
    },
  };

  private _getNotificationParams: any = {
    getCountDetail: 10,
    searchParam: {
      description: '',
      status: '',
      title: '',
    },
    triggerByCount: true,
  };

  private _countDetail: any = 0;
  private _apiTriggerData: any = {
    nextLink: {
      scroll: '',
      search: '',
    },
    triggerApi: false,
  };

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  get getCountDetail(): number {
    return this._countDetail || 0;
  }

  setCountDetail(value: any = 0) {
    this._countDetail = value;
  }

  setApiNotificationDetails(data: any = []) {
    // const previousDataLength: number =
    //   this._notificationDetails.apiNotifications.length;
    // // if (
    // //   previousDataLength === data.length &&
    // //   this._getNotificationParams.getCountDetail > 10
    // // ) {
    // //   this._getNotificationParams.getCountDetail -= 10;
    // // }

    // if (
    //   this._getNotificationParams.getCountDetail !== data.length ||
    //   previousDataLength === data.length
    // ) {
    //   this._getNotificationParams.triggerByCount = false;
    // }
    this._notificationDetails.apiNotifications = data;
  }

  setUpdatedNotificationDetail(templateId: any, details: any) {
    const existedDetails = this.getApiNotifications;
    const findIndexElem: any = existedDetails.findIndex(
      (e: any) => e.templateId === templateId,
    );
    if (findIndexElem !== -1) {
      existedDetails[findIndexElem] = details;
      this.setApiNotificationDetails(existedDetails);
    }
  }

  setCountNotificationDetails() {
    if (this.getNotificationSearchParamCountStatus) {
      this._getNotificationParams.getCountDetail += 10;
      // this._getNotificationParams.triggerByCount =
      //   !this._getNotificationParams.triggerByCount;
    }
  }

  setNotificationSearchDetails(param: any) {
    this.setApiNotificationDetails();
    this.setCountDetail();
    this._getNotificationParams.searchParam = {
      ...this._getNotificationParams.searchParam,
      ...param,
    };
  }

  resetAllSearchParamDetails() {
    this._getNotificationParams = {
      getCountDetail: this._getNotificationParams.getCountDetail || 10,
      searchParam: {
        description: '',
        status: '',
        title: '',
      },
      triggerByCount: true,
    };
  }

  get getNotificationSearchParamCountStatus(): boolean {
    return this._getNotificationParams.triggerByCount;
  }

  get getNotificationLimitDetail(): number {
    return this._getNotificationParams.getCountDetail;
  }

  get getNotificationSearchParamDetails(): any {
    return { ...this._getNotificationParams.searchParam };
  }

  get getNotificationAllSearchParamDetails(): any {
    const searchdata: any = { ...toJS(this._getNotificationParams) };

    const {
      searchParam: { description, status, title },
    } = searchdata;

    return {
      description,
      status,
      title,
    };
  }

  get getTriggerApiStatus(): boolean {
    return this._apiTriggerData.triggerApi;
  }

  get getNextLinkData(): any {
    return { ...toJS(this._apiTriggerData.nextLink) };
  }

  setNextLinkData(data: any) {
    this._apiTriggerData.nextLink = {
      ...this._apiTriggerData.nextLink,
      ...data,
    };
  }

  setNewNotificationDetails(key: any, value: any) {
    const existedData: any = {
      ...toJS(this._notificationDetails.newNotification),
    };
    existedData[key] = value;
    this._notificationDetails.newNotification = existedData;
  }

  // setMobilePushNotifications(key: any, value: any) {
  //   const existedData: any = {
  //     ...toJS(this._notificationDetails.newNotification),
  //   };
  //   existedData[key] = value;
  //   this._notificationDetails.newNotification = existedData;
  // }

  resetNewNotifcation() {
    this._notificationDetails.newNotification = {
      ...newNotificationInitialData,
    };
  }

  get getApiNotifications() {
    return [...toJS(this._notificationDetails.apiNotifications)];
  }

  get getNewNotificationDetails() {
    return { ...toJS(this._notificationDetails.newNotification) };
  }

  async getInitialApiNotifications() {
    const { scroll, search } = this.getNextLinkData;

    // if (!scroll && !search && this.getApiNotifications.length) {
    //   return {
    //     initialApiNotifications: null,
    //   };
    // }
    const {
      // getCountDetail,
      searchParam: { title, description, status },
    } = this._getNotificationParams;

    let initialData: any = null;

    const searchStatus: any = title || description || status;

    if (searchStatus) {
      initialData =
        await this.rootStore.apiStore.notificationApiStore.getNotificationBySearch(
          {
            description,
            limit: API_LIMIT,
            nextLink: search || '',
            status,
            title,
          },
        );
    } else {
      initialData =
        await this.rootStore.apiStore.notificationApiStore.getInitialApiNotification(
          {
            limit: API_LIMIT,
            nextLink: scroll || '',
          },
        );
    }

    if (initialData && initialData.isOk()) {
      const { response, nextLink, count } = initialData.value;
      // this.setApiNotificationDetails(response);
      this.setCountDetail(count);
      if (searchStatus) {
        this.setNextLinkData({
          scroll: '',
          search: nextLink,
        });
      } else {
        this.setNextLinkData({
          scroll: nextLink,
          search: '',
        });
      }

      const oldDatas: any = [...this._notificationDetails.apiNotifications];
      if ((!searchStatus && !search) || (searchStatus && search)) {
        this.setApiNotificationDetails([...oldDatas, ...response]);
      }
      if ((searchStatus && !search) || (!searchStatus && search)) {
        this.setApiNotificationDetails([...response]);
      }
    }

    if (initialData && initialData.isErr()) {
      this.setCountDetail();
      this.setApiNotificationDetails();
    }

    return {
      initialApiNotifications: initialData,
      search,
      searchStatus,
    };
  }

  async createNotifications(data: Notification) {
    const response =
      await this.rootStore.apiStore.notificationApiStore.createNotifications(
        data,
      );
    if (response.isOk()) {
      this.resetNewNotifcation();
    }
    return response;
  }

  async updateNotifications(id: string, data: Notification) {
    const response =
      await this.rootStore.apiStore.notificationApiStore.updateNotification(
        id,
        data,
      );
    if (response.isOk()) {
      this.resetNewNotifcation();
    }
    return response;
  }

  async deleteNotificaion(id: string) {
    const response =
      await this.rootStore.apiStore.notificationApiStore.deleteNotification(id);
    if (response.isOk()) {
      this.resetNewNotifcation();
    }
    return response;
  }
}
