// eslint-disable-next-line simple-import-sort/imports
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import IdleAlert from '../../components/IdleAlert';
import { useCustomStore } from '../../hooks';
import Companies from '../../screens/private/Companies/Companies';
import DriverProfile from '../../screens/private/DriverProfile/driverProfile';
import Drivers from '../../screens/private/Drivers/Drivers';
import Home from '../../screens/private/Home/Home';
import Notifications from '../../screens/private/Notifications/Notifications';
import Profile from '../../screens/private/Profile/Profile';
import Servicerequest from '../../screens/private/ServiceRequest/TaxPermits/Taxsandpermits';
import Mapping from '../../screens/private/Training/Mappings/Mapping';
import QuizzesAddEdit from '../../screens/private/Training/Quizzes/QuizzesAddEdit';
import QuizzesList from '../../screens/private/Training/Quizzes/QuizzesList';
import VideoAddEdit from '../../screens/private/Training/Videos/VideoAddEdit';
import VideoList from '../../screens/private/Training/Videos/VideoList';
import Users from '../../screens/private/Users/Users';
import { PrivateRoute as PrivateRoutesDirectives } from '../Constants';
import { PrivateRoutes, PublicRoutes } from '../index';

const PrivateRoute = (): React.ReactElement => {
  const { authStore } = useCustomStore();

  const hasTokenAndUserId = !!authStore.tokens;
  const {
    DRIVERS,
    COMPANIES,
    USERS,
    NOTIFICATIONS,
    DRIVERPROFILE,
    TRAININGS,
    QUIZZES,
    QUIZ_ADD,
    QUIZ_EDIT,
    QUIZ_VIEW,
    MAPPING,
    TRAINING_ADD,
    TRAINING_EDIT,
  } = PrivateRoutesDirectives;

  const handleContinue = () => {
    authStore.setIdleForLongtimeWarning(false);
    authStore.setIdleTime(0);
  };

  const handleLogout = () => {
    authStore.setIdleForLongtimeWarning(false);
    authStore.logout();
    localStorage.clear();
  };

  return (
    <>
      {authStore.idleForLongtimeWarning && (
        <IdleAlert onLogout={handleLogout} onContinue={handleContinue} />
      )}
      <Routes>
        {/* {hasTokenAndUserId ? ( */}
        {hasTokenAndUserId ? (
          <>
            <Route path={'/'} element={<Navigate to={COMPANIES} />} />
            <Route
              path={COMPANIES}
              element={
                <Home>
                  <Companies />
                </Home>
              }
            />
            <Route
              path={DRIVERS}
              element={
                <Home>
                  <Drivers />
                </Home>
              }
            />
            <Route
              path={NOTIFICATIONS}
              element={
                <Home>
                  <Notifications />
                </Home>
              }
            />
            <Route
              path={MAPPING}
              element={
                <Home>
                  <Mapping />
                </Home>
              }
            />
            <Route
              path={QUIZZES}
              element={
                <Home>
                  <QuizzesList />
                </Home>
              }
            />
            <Route
              path={QUIZ_ADD}
              element={
                <Home>
                  <QuizzesAddEdit />
                </Home>
              }
            />
            <Route
              path={QUIZ_EDIT}
              element={
                <Home>
                  <QuizzesAddEdit />
                </Home>
              }
            />
            <Route
              path={QUIZ_VIEW}
              element={
                <Home>
                  <QuizzesAddEdit isView={true} />
                </Home>
              }
            />
            <Route
              path={TRAININGS}
              element={
                <Home>
                  <VideoList />
                </Home>
              }
            />
            <Route
              path={TRAINING_ADD}
              element={
                <Home>
                  <VideoAddEdit />
                </Home>
              }
            />
            <Route
              path={TRAINING_EDIT}
              element={
                <Home>
                  <VideoAddEdit />
                </Home>
              }
            />
            {/* <Route
            path={SETTINGS}
            element={
              <Home>
                <Settings />
              </Home>
            }
          /> */}
            <Route
              path={USERS}
              element={
                <Home>
                  <Users />
                </Home>
              }
            />
            {/* <Route path={'service-request'} element={<Servicerequest />} />
          <Route path="*" element={<Navigate to="service-request" />} /> */}
            <Route
              path={PrivateRoutes.SERVICEREQUEST.UCR}
              element={
                <Home>
                  <Servicerequest />
                </Home>
              }
            />

            <Route
              path={PrivateRoutes.PROFILE}
              element={
                <Home>
                  <Profile />
                </Home>
              }
            />

            <Route
              path={DRIVERPROFILE}
              element={
                <Home>
                  <DriverProfile />
                </Home>
              }
            />

            {/* <Route path={'/home'} element={<Home />} /> */}
            {/* <Route path="*" element={<Navigate to="/home" />} /> */}
            <Route path="*" element={<Navigate to="/companies" />} />
          </>
        ) : (
          <Navigate
            to={PublicRoutes.LOGIN}
            replace={true}
            state={{ from: location }}
          />
        )}
      </Routes>
    </>
  );
};

export default observer(PrivateRoute);
