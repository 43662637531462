import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { SelectInput } from '../../../../../components/Forms/selectInput';
import { UcrFormInputs } from '../../../../../types';
import { UcrFormSchema } from '../../../../../utils/ValidatorSchema';
import useStyles from './UcrStyles';

/* eslint-disable react/react-in-jsx-scope */
const TaxPermitsUcr = () => {
  const classes = useStyles();

  // validation
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UcrFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      type: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(UcrFormSchema),
  });
  const submit = useCallback((data: UcrFormInputs) => {}, []);
  return (
    <Grid container spacing={0.5} minHeight={'100%'}>
      <Grid item md={12} sm={12} xs={12}>
        <form
          style={{
            width: '100%',
          }}
          onSubmit={handleSubmit(submit)}>
          <FormControl variant="outlined" fullWidth size="small">
            <Box className={classes.formLabelClass}>
              Select the range of units *
            </Box>
            <Controller
              control={control}
              defaultValue=""
              name="type"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <SelectInput
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.type?.message || ''}
                    name={name}
                    isRefreshValue={false}
                    value={value}
                    onChangeText={onChange}
                    isEditable={false}
                    selectClassName={classes.selectClassName}
                    optionsList={
                      <option value="" className={classes.optionsClassName}>
                        Select
                      </option>
                    }
                  />
                );
              }}
            />
          </FormControl>
        </form>
        <Divider className={classes.divider} />
        <Typography component={'div'} className={classes.bottomAction}>
          <Typography component={'span'} className={classes.cancelBtn}>
            cancel
          </Typography>
          <Typography component={'div'} className={classes.rightSidebtn}>
            <Button
              variant="contained"
              className={'cancel-btn d-flex-ja '.concat(classes.draft)}
              style={{ marginRight: '1rem' }}>
              save draft
            </Button>
            <Button
              variant="contained"
              className={'add-btn d-flex-ja '.concat(classes.submited)}>
              submit
            </Button>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TaxPermitsUcr;
