import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import GreenCheck from '../../../../assets//images/green-checkmark.svg';
import LoginBanner from '../../../../assets//images/login-banner.svg';
import SortingIcon from '../../../../components/SortingIcon/SortingIcon';
import VideoArchiveDialog from '../../../../components/VideoArchiveDialog/VideoArchiveDialog';
import VideoStreamDialog from '../../../../components/VideoStreamDialog/VideoStreamDialog';
import { useCustomStore } from '../../../../hooks';
import { decryptFileUrl } from '../../../../utils/helper';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import useStyles from './VideoListStyle';

const VideoList = (): React.ReactElement => {
  const { videoStore } = useCustomStore(); // authStore
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<any>({});
  const [moreTrainingsLoader, setMoreTrainingsLoader] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] =
    useState<boolean>(false);
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  const [isStreamDialogOpen, setIsStreamDialogOpen] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<{
    videoUrl: string;
    videoTitle: string;
    hasDocument: boolean;
  }>({
    hasDocument: false,
    videoTitle: '',
    videoUrl: '',
  });

  const videoHeader: any = [
    { enableSort: false, label: '', name: '' },
    { enableSort: true, label: 'Name', name: 'title' },
    { enableSort: false, label: 'Quiz', name: 'quiz' },
    { enableSort: true, label: 'Accident Flag', name: 'accident' },
    { enableSort: true, label: 'Reason Flag', name: 'reason' },
    { enableSort: false, label: 'Status', name: 'status' },
    { enableSort: false, label: '', name: '' },
  ];

  const getAllTrainings = useCallback(async () => {
    videoStore.setShowLoader(true);
    const trainingsResp = await videoStore.getInitialTrainings();
    if (trainingsResp.isErr()) {
      enqueueSnackbar(String(trainingsResp.error.message), SnackBarConfig('e'));
    }
    videoStore.setShowLoader(false);
  }, [videoStore, enqueueSnackbar]);

  const getMoreTrainings = useCallback(async () => {
    setMoreTrainingsLoader(true);
    const trainingsResp = await videoStore.getTrainings();
    if (trainingsResp.isErr()) {
      enqueueSnackbar(String(trainingsResp.error.message), SnackBarConfig('e'));
    }
    setMoreTrainingsLoader(false);
  }, [videoStore, enqueueSnackbar]);

  useEffect(() => {
    getAllTrainings();
  }, [getAllTrainings]);

  // Sorting
  const sortBY = (e: any) => {
    videoStore.setSortBy(e);
    videoStore.setSortDirection(
      videoStore.SortDirection === 'desc' ? 'asc' : 'desc',
    );
    getAllTrainings();
  };

  // Popover options
  const handleVideoActions = (event: any, videoId: string) => {
    const obj: any = {};

    if (tooltipIsOpen[videoId]) {
      obj[videoId] = false;
      setAnchorEl(null);
    } else {
      obj[videoId] = true;
      setAnchorEl(event.currentTarget);
    }
    setTooltipIsOpen(obj);
  };

  const updateTrainingVideo = (video: any) => {
    const routeId: string = video.id;
    navigate(`/training/trainings/edit/${routeId}`);
  };

  const archiveTrainingVideo = async (confirm: boolean) => {
    if (!confirm || selectedVideoId === null) {
      setIsArchiveDialogOpen(false);
      selectedVideoId !== null && handleVideoActions(null, selectedVideoId);
      return;
    }
    const archiveResp = await videoStore.archiveTraining(selectedVideoId);
    if (archiveResp.isErr()) {
      enqueueSnackbar(String(archiveResp.error.message), SnackBarConfig('e'));
    } else if (archiveResp.isOk()) {
      getAllTrainings();
      enqueueSnackbar(String(archiveResp.value), SnackBarConfig('s'));
    }
    setSelectedVideoId(null);
    setIsArchiveDialogOpen(false);
  };

  const streamVideo = (video: any) => {
    if (!video.fileUrl || !video.title) return;
    setSelectedVideo({
      hasDocument: video.hasDocument,
      videoTitle: video.title,
      videoUrl: decryptFileUrl(video.fileUrl) || '',
    });
    setIsStreamDialogOpen(true);
  };

  const closeStreamVideoDialog = () => {
    setSelectedVideo({
      hasDocument: false,
      videoTitle: '',
      videoUrl: '',
    });
    setIsStreamDialogOpen(false);
  };

  const renderViolationCodes = (training: any): ReactElement => {
    const violationCodes = training?.violationCodes || [];
    let moreCodes = [];
    let codes = '-';
    if (violationCodes.length > 0) {
      codes = violationCodes.slice(0, 6).join(', ');
      moreCodes = violationCodes.slice(6);
    }
    return (
      <Typography className={classes.violationCodesList}>
        {codes}
        {moreCodes.length > 0 && (
          <span>
            <Tooltip
              arrow={true}
              title={
                <React.Fragment>
                  {moreCodes.map((code: string, index: number) => (
                    <span
                      key={code + `${index}`}
                      className={classes.violationMoreCodesList}>
                      {code}
                      <br />
                    </span>
                  ))}
                </React.Fragment>
              }>
              <span className="more">&nbsp;...more</span>
            </Tooltip>
          </span>
        )}
      </Typography>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        px: 6,
      }}>
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          letterSpacing: 0,
          lineHeight: '19px',
          padding: '1rem 1.5rem 2.5rem 1.5rem',
        }}
      />
      <Grid
        className={classes.scroll}
        container
        spacing={2}
        justifyContent="space-between">
        <TableContainer
          sx={{ maxHeight: '85vh' }}
          id="training-trainings-table">
          <InfiniteScroll
            dataLength={
              videoStore.VideosList && videoStore.VideosList.length
                ? videoStore.VideosList.length
                : 0
            }
            style={{ overflow: 'unset' }}
            next={getMoreTrainings}
            hasMore={videoStore.HasMoreVideos}
            scrollableTarget={'training-trainings-table'}
            loader={
              moreTrainingsLoader && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#DEC330', marginTop: 20 }}
                  />
                </div>
              )
            }
            endMessage>
            {videoStore.ShowLoader ? (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '80px',
                  marginTop: '80px',
                  width: '100%',
                }}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            ) : (
              <Table
                className={classes.table}
                sx={{
                  borderCollapse: 'separate !important',
                  borderSpacing: '0px 5px !important',
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {videoHeader.map((header: any, index: number) => (
                      <TableCell
                        key={index}
                        style={{
                          backgroundColor: '#f5f5f5',
                          paddingLeft: header.name === 'name' ? '0px' : '16px',
                        }}
                        className={`${
                          header.name === 'name' ? classes.nameHeader : ''
                        }`}>
                        <Grid item xs={12}>
                          <Typography
                            component={'div'}
                            noWrap
                            style={{ alignItems: 'center', display: 'flex' }}>
                            <Typography
                              component={'div'}
                              sx={{
                                color: '#979598',
                                fontFamily: 'FiraSans-Regular',
                                fontSize: '12px',
                                letterSpacing: 0,
                                lineHeight: '14px',
                                paddingLeft: '0px !important',
                              }}>
                              {header.label}
                            </Typography>

                            {header.enableSort && (
                              <SortingIcon
                                {...{
                                  sortBY: sortBY,
                                  sortByName: header.name,
                                  store: videoStore,
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videoStore.VideosList.length > 0 ? (
                    videoStore.VideosList.map((video: any, index: number) => (
                      <TableRow
                        key={index}
                        className={classes.rowHeight}
                        style={{ minHeight: '80px' }}>
                        <TableCell
                          style={{
                            cursor: 'pointer',
                            paddingBottom: 0,
                            paddingLeft: '10px',
                            paddingTop: 0,
                            width: '0%',
                          }}>
                          <Grid item xs={12} md={12} lg={12}>
                            <img
                              src={
                                decryptFileUrl(video.thumbnailUrl) ===
                                'failed to decrypt'
                                  ? ''
                                  : decryptFileUrl(video.thumbnailUrl) ||
                                    LoginBanner
                              }
                              alt={LoginBanner}
                              height="62"
                              width="100"
                              onClick={() => streamVideo(video)}
                            />
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingTop: 0,
                            width: '50%',
                          }}>
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              noWrap
                              sx={{
                                color: '#241A2E',
                                fontFamily: 'FiraSans-Bold',
                                fontSize: '16px',
                                fontWeight: 600,
                                height: '23px',
                                letterSpacing: 0,
                                lineHeight: '23px',
                              }}>
                              {video.title || '-'}
                            </Typography>
                            {renderViolationCodes(video)}
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            width: '27%',
                          }}>
                          <Grid item xs={12} md={12} lg={12}>
                            <Typography
                              noWrap
                              sx={{
                                color: '#241A2E',
                                fontFamily: 'FiraSans-Light',
                                fontSize: '16px',
                                height: '19px',
                                letterSpacing: 0,
                                lineHeight: '19px',
                              }}>
                              {video.quizTitle || 'N/A'}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                          }}>
                          <Grid item xs={12} className={classes.flagIconCell}>
                            {video.isAccidentsRelated ? (
                              <img
                                src={GreenCheck}
                                alt="Yes"
                                className="icon"
                              />
                            ) : (
                              ''
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                          }}>
                          <Grid item xs={12} className={classes.flagIconCell}>
                            {video.hasReasonMappings ? (
                              <img
                                src={GreenCheck}
                                alt="Yes"
                                className="icon"
                              />
                            ) : (
                              ''
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingRight: '80px',
                            paddingTop: 0,
                          }}>
                          <Grid item xs={12} md={12} lg={12}>
                            {video.status === 'active' && (
                              <Typography
                                noWrap
                                sx={{
                                  color: '#0DA728',
                                  fontFamily: 'FiraSans-Medium',
                                  fontSize: '16px',
                                  height: '23px',
                                  letterSpacing: 0,
                                  lineHeight: '23px',
                                }}>
                                Active
                              </Typography>
                            )}
                            {video.status === 'draft' && (
                              <Typography
                                noWrap
                                sx={{
                                  color: '#979598',
                                  fontFamily: 'FiraSans-Medium',
                                  fontSize: '16px',
                                  height: '23px',
                                  letterSpacing: 0,
                                  lineHeight: '23px',
                                }}>
                                Draft
                              </Typography>
                            )}
                            {video.status === 'archived' && (
                              <Typography
                                noWrap
                                sx={{
                                  color: '#241A2E',
                                  fontFamily: 'FiraSans-Medium',
                                  fontSize: '16px',
                                  height: '23px',
                                  letterSpacing: 0,
                                  lineHeight: '23px',
                                }}>
                                Archived
                              </Typography>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <Grid item style={{ textAlign: 'right' }}>
                            {video.status !== 'archived' && (
                              <>
                                <IconButton
                                  onClick={(event) =>
                                    handleVideoActions(event, video.id)
                                  }>
                                  <MoreVertIcon />
                                </IconButton>
                                <Popover
                                  className="admin-popclass"
                                  open={tooltipIsOpen[video.id]}
                                  onClose={(event) =>
                                    handleVideoActions(event, video.id)
                                  }
                                  anchorEl={anchorEl}
                                  anchorOrigin={{
                                    horizontal: 'center',
                                    vertical: 'bottom',
                                  }}
                                  transformOrigin={{
                                    horizontal: 'right',
                                    vertical: 'top',
                                  }}>
                                  <Box
                                    sx={{
                                      backgroundColor: '#241A2E',
                                      borderRadius: '8px',
                                      boxShadow:
                                        '0 5px 14px 0 rgba(0,0,0,0.22)',
                                      minHeight: '43px',
                                      padding: '0.5rem 0',
                                      position: 'relative',
                                      width: '207px',
                                    }}>
                                    <div className="arrow">.</div>
                                    <Typography
                                      component={'div'}
                                      className="n-pop-btn"
                                      onClick={() =>
                                        updateTrainingVideo(video)
                                      }>
                                      Edit
                                    </Typography>
                                    <Typography
                                      component={'div'}
                                      className="n-pop-btn"
                                      onClick={() => {
                                        setSelectedVideoId(video.id);
                                        setIsArchiveDialogOpen(true);
                                      }}>
                                      Archive
                                    </Typography>
                                  </Box>
                                </Popover>
                              </>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={10}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </InfiniteScroll>
        </TableContainer>
      </Grid>
      {isArchiveDialogOpen && (
        <VideoArchiveDialog
          isOpen={isArchiveDialogOpen}
          closeDialog={(resp: boolean) => archiveTrainingVideo(resp)}
        />
      )}
      {isStreamDialogOpen && (
        <VideoStreamDialog
          isOpen={isStreamDialogOpen}
          videoUrl={selectedVideo.videoUrl}
          videoTitle={selectedVideo.videoTitle}
          hasDocument={selectedVideo.hasDocument}
          closeDialog={() => closeStreamVideoDialog()}
        />
      )}
    </Box>
  );
};

export default observer(VideoList);
