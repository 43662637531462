import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  bottomAction: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '30px !important',
  },
  cancelBtn: {
    color: '#777777 !important',
    cursor: 'pointer !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
    letterSpacing: '0.8px !important',
    textDecoration: 'underline solid #777777 !important',
    textTransform: 'uppercase',
  },
  divider: {
    border: '1px solid #E8E8E8',
    marginTop: '31px !important',
  },
  draft: {
    boxShadow: 'unset',
  },
  formLabelClass: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '12px',
    lineHeight: '24px',
  },
  optionsClassName: {
    backgroundColor: theme.palette.common.white + '!important',
    paddingLeft: '10px !important',
    paddingRight: '27px !important',
    textTransform: 'capitalize',
  },
  rightSidebtn: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectClassName: {
    backgroundColor: '#f3f3f3 !important',
    borderRadius: '7px !important',
    color: '#241A2E !important',
    fontFamily: 'FiraSans-Medium !important',
    fontSize: '14px !important',
    fontWeight: ' 500 !important',
    letterSpacing: '0 !important',
    opacity: '0.43 !important',
  },
  submited: {
    boxShadow: 'unset',
  },
}));

export default useStyles;
