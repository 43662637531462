// import { yupResolver } from '@hookform/resolvers/yup';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material'; //
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import search from '../../../../../assets/images/h-search.svg';
import DialogMigrate from '../../../../../components/Dialog/DialogMigrate';
import { InputText } from '../../../../../components/Forms/InputText';
import CustomSortingIcon from '../../../../../components/SortingIcon/CustomSortingIcon';
import { useCustomStore } from '../../../../../hooks';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import useStyles from './AddViolationDialogueStyle';

const BottomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '44px',
  margin: '0px 18px 10px 18px',
  width: '892px',
}));

type AddViolationDialogType = {
  isOpen: boolean;
  closeDialog: () => void;
};

const AddViolationDialog: React.FC<any> = (props: AddViolationDialogType) => {
  const { isOpen, closeDialog } = props;
  const classes = useStyles();
  const { videoStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedTab, setSelectedTab]: any = useState(1);

  const [selectedCodes, setSelectedCodes] = useState<any[]>([]);

  const [moreViolationGroups, setMoreViolationGroups] =
    useState<boolean>(false);

  const [moreViolationCodes, setMoreViolationCodes] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const goToTab = (tab: number) => {
    videoStore.resetViolationCodeFilters();
    videoStore.resetViolationGroupFilters();
    if (selectedTab < tab && tab === 2) {
      videoStore.setViolationCodesApiReset();
    } else if (selectedTab < tab && tab === 1) {
      videoStore.setViolationGroupsApiReset();
    }
    setSelectedTab(tab);
  };

  const getBasicCategories = useCallback(async () => {
    videoStore.setViolationBasicCategoriesLoader(true);
    const resp = await videoStore.getTrainingViolationBasicCategories();
    if (resp.isErr()) {
      enqueueSnackbar('Unable to fetch BASIC Categories', SnackBarConfig('e'));
    }
    videoStore.setViolationBasicCategoriesLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getViolationGroups = useCallback(async () => {
    videoStore.setViolationGroupsLoader(true);
    const resp = await videoStore.getInitialTrainingViolationGroups();
    if (resp.isErr()) {
      enqueueSnackbar('Unable to fetch Violation Groups', SnackBarConfig('e'));
    }
    videoStore.setViolationGroupsLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getViolationCodes = useCallback(async () => {
    videoStore.setViolationCodesLoader(true);
    const resp = await videoStore.getInitialTrainingViolationCodes();
    if (resp.isErr()) {
      enqueueSnackbar('Unable to fetch Violation Codes', SnackBarConfig('e'));
    }
    videoStore.setViolationCodesLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMoreViolationGroups = useCallback(async () => {
    setMoreViolationGroups(true);
    const groupsResponse = await videoStore.getTrainingViolationGroups();
    if (groupsResponse.isErr()) {
      enqueueSnackbar('Unable to fetch Violation Groups', SnackBarConfig('e'));
    }
    setMoreViolationGroups(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMoreViolationCodes = useCallback(async () => {
    setMoreViolationCodes(true);
    const resp = await videoStore.getTrainingViolationCodes();
    if (resp.isErr()) {
      enqueueSnackbar('Unable to fetch Violation Codes', SnackBarConfig('e'));
    }
    setMoreViolationCodes(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectBasicCategory = (basicCategoryId: number) => {
    videoStore.setSelectedBasicCategoryId(String(basicCategoryId));
    getViolationGroups();
    goToTab(2);
  };

  const onSelectViolationGroup = (violationGroupId: string) => {
    videoStore.setSelectedViolationGroupId(violationGroupId);
    getViolationCodes();
    goToTab(3);
  };

  const isAllViolationCodesSelected = (
    type: 'checked' | 'indeterminate',
  ): boolean => {
    const allCodeIds = videoStore.ViolationCodes.map((v) => v.id);
    const selectedCodeIds = [
      ...videoStore.SelectedViolationCodeIds,
      ...selectedCodes.map((v) => v.id),
    ];
    const allCodesSelected =
      allCodeIds.every((c) => selectedCodeIds.includes(c)) || selectAll;
    const fewCodesSelected = allCodeIds.some((c) =>
      selectedCodeIds.includes(c),
    );
    if (type === 'indeterminate') {
      return !allCodesSelected && fewCodesSelected;
    }
    return allCodesSelected;
  };

  const onSelectAllViolationCodes = (event: any) => {
    if (event && event.target.checked) {
      const selectedCodeIds = [
        ...videoStore.SelectedViolationCodeIds,
        ...selectedCodes.map((v) => v.id),
      ];
      setSelectedCodes([
        ...selectedCodes,
        ...videoStore.ViolationCodes.filter(
          (v) => !selectedCodeIds.includes(v.id),
        ),
      ]);
      setSelectAll(true);
    } else {
      setSelectedCodes([]);
      setSelectAll(false);
    }
  };

  const onSelectViolationCode = (event: any, code: any) => {
    if (event && event.target.checked) {
      setSelectedCodes([...[code], ...selectedCodes]);
    } else {
      const codes = selectedCodes.filter((v) => v.id !== code.id);
      setSelectedCodes(codes);
      setSelectAll(false);
    }
  };

  const onAddViolation = () => {
    videoStore.setSelectedViolationCodes(selectedCodes);
    closeDialog();
  };

  const groupSortBy = (columnName: string) => {
    videoStore.setViolationGroupFilters({ sortBy: columnName });
    videoStore.setViolationGroupFilters({
      sortDirection:
        videoStore.ViolationGroupFilters.sortDirection === 'asc'
          ? 'desc'
          : 'asc',
    });
    getViolationGroups();
  };

  const codeSortBy = (columnName: string) => {
    videoStore.setViolationCodeFilters({ sortBy: columnName });
    videoStore.setViolationCodeFilters({
      sortDirection:
        videoStore.ViolationCodeFilters.sortDirection === 'asc'
          ? 'desc'
          : 'asc',
    });
    getViolationCodes();
  };

  const onGroupSearch = (searchText: string) => {
    if (searchText.length == 0 || searchText.length >= 3) {
      setTimeout(() => {
        videoStore.setViolationGroupFilters({ searchText });
        getViolationGroups();
      }, 500);
    }
  };

  const onCodeSearch = (searchText: string) => {
    if (searchText.length === 0 || searchText.length >= 3) {
      videoStore.setViolationCodeFilters({ searchText });
      getViolationCodes();
    }
  };

  useEffect(() => {
    getBasicCategories();
  }, [getBasicCategories]);

  return (
    <DialogMigrate
      aria-labelledby="training-video-violations"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => closeDialog()}>
      <DialogTitle
        style={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '1.375rem',
          letterSpacing: 0,
          paddingLeft: '20px',
        }}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerContainer}>
            {'Search & Select Violations'}
          </div>
          <Link
            className={classes.cancelLink}
            color="inherit"
            underline="always"
            onClick={() => {
              closeDialog();
            }}>
            <ClearIcon />
          </Link>
        </div>
      </DialogTitle>
      {selectedTab !== 1 && (
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          style={{
            alignItems: 'center',
            display: 'flex',
            paddingBottom: '16px',
            paddingLeft: '16px',
          }}>
          <Typography
            noWrap
            sx={{
              color: '#A4A4A4',
              cursor: 'pointer',
              fontFamily: 'FiraSans-Regular',
              fontSize: '14px',
              height: '24px',
              letterSpacing: 0,
              lineHeight: '24px',
              textDecoration: 'underline',
            }}
            onClick={() => {
              goToTab(1);
            }}>
            {'BASIC Category'}
          </Typography>
          <Typography
            noWrap
            sx={{
              color: '#A4A4A4',
              fontFamily: 'FiraSans-Regular',
              fontSize: '14px',
              height: '24px',
              letterSpacing: 0,
              lineHeight: '24px',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}>
            {'/'}
          </Typography>
          <Typography
            noWrap
            sx={{
              color: '#A4A4A4',
              cursor: 'pointer',
              fontFamily: 'FiraSans-Regular',
              fontSize: '14px',
              height: '24px',
              letterSpacing: 0,
              lineHeight: '24px',
              textDecoration: 'underline',
            }}
            onClick={() => {
              goToTab(2);
            }}>
            {'Group'}
          </Typography>
          {selectedTab == 3 && (
            <>
              <Typography
                noWrap
                sx={{
                  color: '#A4A4A4',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '14px',
                  height: '24px',
                  letterSpacing: 0,
                  lineHeight: '24px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}>
                {'/'}
              </Typography>
              <Typography
                noWrap
                sx={{
                  color: '#A4A4A4',
                  cursor: 'pointer',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '14px',
                  height: '24px',
                  letterSpacing: 0,
                  lineHeight: '24px',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  goToTab(3);
                }}>
                {'Code'}
              </Typography>
            </>
          )}
        </Grid>
      )}
      <Grid
        container
        spacing={1}
        ml={0}
        pl={'15px'}
        pr={'24px'}
        className={classes.headerWrapper}
        sx={{ paddingBottom: '9.5px', width: '100%' }}>
        {selectedTab === 2 && (
          <Grid item md={12} xs={12} style={{ padding: '0' }}>
            <FormControl style={{ width: '98%' }}>
              <InputText
                name="search-group"
                placeholder="Search Group"
                isEditable={false}
                type={'text'}
                variant="standard"
                value={''}
                initIcon={
                  <InputAdornment position="start">
                    <img className={classes.hIcon} src={search} />
                  </InputAdornment>
                }
                className={classes.selectClassName}
                onChangeText={(val) => {
                  onGroupSearch(val);
                }}
              />
            </FormControl>
          </Grid>
        )}
        {selectedTab === 3 && (
          <Grid item md={12} xs={12} style={{ padding: '0' }}>
            <FormControl style={{ width: '98%' }}>
              <InputText
                name="search-code"
                placeholder="Search Code"
                isEditable={false}
                type={'text'}
                variant="standard"
                value={''}
                initIcon={
                  <InputAdornment position="start">
                    <img className={classes.hIcon} src={search} />
                  </InputAdornment>
                }
                className={classes.selectClassName}
                onChangeText={(val) => {
                  onCodeSearch(val);
                }}
              />
            </FormControl>
          </Grid>
        )}
      </Grid>

      <DialogContent id="scroll" style={{ paddingTop: '10px' }}>
        {selectedTab === 1 && (
          <>
            <Box>
              <Grid container spacing={2}>
                {
                  <Grid
                    item
                    xs={6}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      padding: '0 0 0 5px',
                    }}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#A4A4A4',
                        fontFamily: 'FiraSans-Regular',
                        fontSize: '0.75rem',
                        height: '14px',
                        letterSpacing: 0,
                        lineHeight: '14px',
                        width: '89px',
                      }}>
                      {'BASIC Category'}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Box>
            <Grid
              container
              spacing={2}
              style={{
                backgroundColor: '#FFFFFF',
                marginBottom: '10px',
              }}>
              {videoStore.ViolationBasicCategoriesLoader ? (
                <div className={classes.loader}>
                  <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                </div>
              ) : videoStore.ViolationBasicCategories.length > 0 ? (
                videoStore.ViolationBasicCategories.map(
                  (basicCategory: any, index: number) => (
                    <React.Fragment key={index}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingLeft: '0px',
                          paddingTop: '0px',
                        }}>
                        <Grid item xs={11}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Bold',
                              fontSize: '16px',
                              letterSpacing: 0,
                              paddingBottom: '22px',
                              paddingTop: '19px',
                            }}>
                            {basicCategory.value}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{ paddingBottom: '22px', paddingTop: '19px' }}
                          style={{ display: 'flex', justifyContent: 'end' }}>
                          <KeyboardArrowRightIcon
                            style={{
                              cursor: 'pointer',
                              transform: 'rotate(270deg)',
                            }}
                            onClick={() => {
                              onSelectBasicCategory(basicCategory.key);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider className={classes.divider} />
                    </React.Fragment>
                  ),
                )
              ) : (
                <div className={classes.noRecordsFound}>
                  No Category(s) found
                </div>
              )}
              <Divider className={classes.divider} />
            </Grid>
          </>
        )}
        {selectedTab === 2 && (
          <>
            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    padding: '0 0 0 5px',
                  }}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#A4A4A4',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '0.75rem',
                      height: '14px',
                      letterSpacing: 0,
                      lineHeight: '14px',
                      width: '89px',
                    }}>
                    {'Violation Group'}
                  </Typography>
                  <CustomSortingIcon
                    {...{
                      filterSortBy: videoStore.ViolationGroupFilters.sortBy,
                      filterSortDirection:
                        videoStore.ViolationGroupFilters.sortDirection,
                      sortBY: groupSortBy,
                      sortByName: 'name',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <InfiniteScroll
              dataLength={videoStore.ViolationGroups.length}
              style={{ overflow: 'unset' }}
              next={getMoreViolationGroups}
              hasMore={videoStore.HasMoreViolationGroups}
              scrollableTarget={'scroll'}
              loader={
                moreViolationGroups && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Grid
                container
                spacing={2}
                style={{
                  backgroundColor: '#FFFFFF',
                  marginBottom: '10px',
                }}>
                {videoStore.ViolationGroupsLoader ? (
                  <div className={classes.loader}>
                    <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                  </div>
                ) : videoStore.ViolationGroups.length > 0 ? (
                  videoStore.ViolationGroups.map(
                    (violationGroup: any, index: number) => (
                      <React.Fragment key={index}>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingLeft: '0px',
                            paddingTop: '0px',
                          }}>
                          <Grid item xs={11}>
                            <Typography
                              noWrap
                              sx={{
                                color: '#241A2E',
                                fontFamily: 'FiraSans-Bold',
                                fontSize: '0.9375rem',
                                letterSpacing: 0,
                                textTransform: 'capitalize',
                              }}>
                              {violationGroup.name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            sx={{ paddingBottom: '22px', paddingTop: '19px' }}
                            style={{ display: 'flex', justifyContent: 'end' }}>
                            <KeyboardArrowRightIcon
                              style={{
                                cursor: 'pointer',
                                transform: 'rotate(270deg)',
                              }}
                              onClick={() => {
                                onSelectViolationGroup(violationGroup.id);
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                      </React.Fragment>
                    ),
                  )
                ) : (
                  <div className={classes.noRecordsFound}>
                    No Violation Group(s) found
                  </div>
                )}
                <Divider className={classes.divider} />
              </Grid>
            </InfiniteScroll>
          </>
        )}
        {selectedTab === 3 && (
          <>
            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={1}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    padding: '0 0 0 5px',
                  }}>
                  <Checkbox
                    checked={isAllViolationCodesSelected('checked')}
                    indeterminate={isAllViolationCodesSelected('indeterminate')}
                    onChange={(event: any) => {
                      onSelectAllViolationCodes(event);
                    }}
                    color="success"
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    padding: '0 0 0 5px',
                  }}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#A4A4A4',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '0.75rem',
                      height: '14px',
                      letterSpacing: 0,
                      lineHeight: '14px',
                      width: '89px',
                    }}>
                    {'Violation Code'}
                  </Typography>
                  <CustomSortingIcon
                    {...{
                      filterSortBy: videoStore.ViolationCodeFilters.sortBy,
                      filterSortDirection:
                        videoStore.ViolationCodeFilters.sortDirection,
                      sortBY: codeSortBy,
                      sortByName: 'code',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    padding: '0 0 0 5px',
                  }}>
                  <Typography
                    noWrap
                    sx={{
                      color: '#A4A4A4',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '0.75rem',
                      height: '14px',
                      letterSpacing: 0,
                      lineHeight: '14px',
                      width: '89px',
                    }}>
                    {'Description'}
                  </Typography>
                  <CustomSortingIcon
                    {...{
                      filterSortBy: videoStore.ViolationCodeFilters.sortBy,
                      filterSortDirection:
                        videoStore.ViolationCodeFilters.sortDirection,
                      sortBY: codeSortBy,
                      sortByName: 'description',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <InfiniteScroll
              dataLength={videoStore.ViolationCodes.length}
              style={{ overflow: 'unset' }}
              next={getMoreViolationCodes}
              hasMore={videoStore.HasMoreViolationCodes}
              scrollableTarget={'scroll'}
              loader={
                moreViolationCodes && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Grid
                container
                spacing={2}
                style={{
                  backgroundColor: '#FFFFFF',
                  marginBottom: '10px',
                }}>
                {videoStore.ViolationCodesLoader ? (
                  <div className={classes.loader}>
                    <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                  </div>
                ) : videoStore.ViolationCodes.length > 0 ? (
                  videoStore.ViolationCodes.map(
                    (violationCode: any, index: number) => (
                      <React.Fragment key={index}>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingBottom: '10px',
                            paddingLeft: '0px',
                            paddingTop: '10px',
                          }}>
                          <Grid item xs={1}>
                            <Checkbox
                              checked={[
                                ...videoStore.SelectedViolationCodeIds,
                                ...selectedCodes.map((v) => v.id),
                              ].includes(violationCode.id)}
                              onChange={(event: any) => {
                                onSelectViolationCode(event, violationCode);
                              }}
                              color="success"
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              noWrap
                              sx={{
                                color: '#241A2E',
                                fontFamily: 'FiraSans-Bold',
                                fontSize: '16px',
                                letterSpacing: 0,
                                lineHeight: '23px',
                                textTransform: 'capitalize',
                              }}>
                              {violationCode.code}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sx={{ paddingBottom: '10px !important' }}>
                            <Typography
                              noWrap
                              sx={{
                                color: '#241A2E',
                                fontFamily: 'FiraSans-Regular',
                                fontSize: '0.9375rem',
                                letterSpacing: 0,
                                paddingTop: '10px !important',
                                textTransform: 'capitalize',
                              }}>
                              {violationCode.description}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                      </React.Fragment>
                    ),
                  )
                ) : (
                  <div className={classes.noRecordsFound}>
                    No Violations Code(s) found
                  </div>
                )}
                <Divider className={classes.divider} />
              </Grid>
            </InfiniteScroll>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.jxy}>
        <BottomButton
          disabled={selectedCodes.length < 1}
          type={'button'}
          variant="contained"
          sx={{ width: '100%' }}
          onClick={() => onAddViolation()}>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontFamily: 'FiraSans-Medium',
              fontSize: '0.9375rem',
              fontWeight: '500',
              height: '22px',
              letterSpacing: '1px',
              lineHeight: '22px',
              textAlign: 'center',
            }}>
            ADD
            {selectedCodes.length === 0 ? ' ' : ` (${selectedCodes.length}) `}
            VIOLATIONS
          </Typography>
        </BottomButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(AddViolationDialog);
