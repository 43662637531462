import { yupResolver } from '@hookform/resolvers/yup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import CalendarPicker from '@mui/lab/CalendarPicker';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { Box, FormControl, Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
// import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { InputText } from '../../../components/Forms/InputText';
import { MultiSelectDropdown } from '../../../components/Forms/multiSelectDropDown';
// import { SelectInput } from '../../../components/Forms/selectInput';
import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { Notification } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { notificationSchema } from '../../../utils/ValidatorSchema';
import NotificationsList from './NotificationLists';
import useStyles from './NotificationStyle';

const Notifications: React.FC = () => {
  const classes = useStyles();
  const { notificationStore, globalStore, authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  // const [date, setDate] = React.useState<Date | null>(new Date());
  const [loader, setLoader] = React.useState<boolean>(false);
  const [states, setStates] = React.useState<any>([
    { code: '', fullName: 'Select by States' },
  ]);
  const [editNotification, setEditNotification] = React.useState<any>(null);
  const [triggerNotification, setTriggerNotification] =
    React.useState<boolean>(false);

  // validation
  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
    // reset: newFormRest,
    getValues,
    setValue,
    setError,
    trigger: triggerForm,
    watch: watchForm,
    clearErrors,
    reset: resetForm,
  } = useForm<{
    title: string;
    description: string;
    recipients: string[];
    scheduleStartDate: any;
    unitQuantityMinimum: any;
    unitQuantityMaximum: any;
    yearsInBusinessMinimum: any;
    yearsInBusinessMaximum: any;
    isSplachNotification: boolean;
    isPushNotification: boolean;
    scheduleEndDate: any;
    link: string;
    linkTitle: string;
    scheduledStartTime: any;
    scheduledEndTime: any;
    scheduledStartAmPm: any;
    scheduledEndAmPm: any;
  }>({
    criteriaMode: 'all',
    defaultValues: {
      description: '',
      isPushNotification: false,
      isSplachNotification: false,
      link: '',
      linkTitle: '',
      recipients: [''],
      scheduleEndDate: null,
      scheduleStartDate: null,
      scheduledEndAmPm: 'pm',
      scheduledEndTime: null,
      scheduledStartAmPm: 'pm',
      scheduledStartTime: null,
      title: '',
      unitQuantityMaximum: '',
      unitQuantityMinimum: '',
      yearsInBusinessMaximum: '',
      yearsInBusinessMinimum: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(notificationSchema),
  });

  React.useEffect(() => {
    getAllStates();
    return () => {
      notificationStore.setNotificationSearchDetails({
        description: '',
        status: '',
        title: '',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getApiNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triggerNotification,
    // notificationStore.getNotificationSearchParamCountStatus,
    notificationStore.getNotificationSearchParamDetails,
    // notificationStore.getNotificationLimitDetail,
  ]);

  const [input1Ref, setInput1Focus] = UseFocus();

  // const getNotifications = (e: any) => {
  //   const offsetheight: number = e.target.offsetHeight;
  //   const scrolltop: number = e.target.scrollTop;
  //   const scrollheight: number = e.target.scrollHeight;

  //   if (offsetheight + scrolltop === scrollheight) {
  //     notificationStore.setCountNotificationDetails();
  //   }
  // };

  const getApiNotifications = React.useCallback(async () => {
    setLoader(true);
    const { initialApiNotifications, searchStatus, search }: any =
      await notificationStore.getInitialApiNotifications();
    setLoader(false);

    if (
      !authStore.pageAccess ||
      (authStore.pageAccess && !authStore.pageAccess.notifications)
    ) {
      if (notificationStore.getApiNotifications.length === 0) {
        resetForm();
      }
      if (
        notificationStore.getApiNotifications &&
        Array.isArray(notificationStore.getApiNotifications) &&
        notificationStore.getApiNotifications.length &&
        notificationStore.getApiNotifications.length <= 20
      ) {
        selectedNotification(notificationStore.getApiNotifications[0]);
      }
    }
    if (initialApiNotifications && initialApiNotifications.isOk()) {
      if ((searchStatus && !search) || (!searchStatus && search)) {
        document.querySelector('#scrollerNotification')?.scrollTo({
          behavior: 'auto',
          top: 0,
        });
      }

      // const { count } = initialApiNotifications.value;

      // setTotalCount(count || 0);

      //   const ApiData: any = [
      //     // {
      //     //   ...initialApiNotifications.value.response[1],
      //     //   status: 'scheduled',
      //     // },
      //     ...response,
      //   ].map((notification: any) => {
      //     if (notification.description) {
      //       const replacevalue: string = (notification.description || '').replace(
      //         /\n/gim,
      //         '<br>',
      //       );
      //       notification.descriptionHtml = replacevalue;
      //     }
      //     return { ...notification };
      //   });
      //   setApiNotifications(ApiData);
    }
    if (initialApiNotifications && initialApiNotifications.isErr()) {
      // setApiNotifications([]);
      enqueueSnackbar(
        String(initialApiNotifications.error.message),
        SnackBarConfig('e'),
      );
      // const {
      //   error: { message = null },
      // }: any = initialApiNotifications;
      // if (typeof message === 'string') {
      //   enqueueSnackbar(
      //     message || 'Error while processing data',
      //     SnackBarConfig('e'),
      //   );
      // } else if (typeof message === 'object') {
      //   enqueueSnackbar('Validation Error', SnackBarConfig('e'));
      // } else {
      //   enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllStates = React.useCallback(async () => {
    const stateDetails: any = await globalStore.setApiStateDetails();
    if (stateDetails.isOk()) {
      const initialValue: any = { code: '', fullName: 'Select by States' };
      setStates([initialValue, ...stateDetails.value]);
    }

    if (stateDetails.isErr()) {
      enqueueSnackbar(String(stateDetails.error.message), SnackBarConfig('e'));
      // const {
      //   error: { message = null },
      // }: any = stateDetails;
      // if (typeof message === 'string') {
      //   enqueueSnackbar(
      //     message || 'Error while processing data',
      //     SnackBarConfig('e'),
      //   );
      // } else if (typeof message === 'object') {
      //   enqueueSnackbar('Validation Error', SnackBarConfig('e'));
      // } else {
      //   enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formConditions = (): boolean => {
    const datas: any = getValues();
    const { link, linkTitle } = datas;

    let {
      unitQuantityMaximum,
      unitQuantityMinimum,
      yearsInBusinessMaximum,
      yearsInBusinessMinimum,
    } = datas;

    unitQuantityMaximum = Number(unitQuantityMaximum);
    unitQuantityMinimum = Number(unitQuantityMinimum);
    yearsInBusinessMaximum = Number(yearsInBusinessMaximum);
    yearsInBusinessMinimum = Number(yearsInBusinessMinimum);

    const linkStatus: any = link && !linkTitle;
    const linkTitleStatus: any = linkTitle && !link;
    const unitQuantityMaximumStatus: any =
      unitQuantityMaximum && !unitQuantityMinimum;
    const unitQuantityMinimumStatus: any =
      !unitQuantityMaximum && unitQuantityMinimum;
    const yearsInBusinessMaximumStatus: any =
      yearsInBusinessMaximum && !yearsInBusinessMinimum;
    const yearsInBusinessMinimumStatus: any =
      !yearsInBusinessMaximum && yearsInBusinessMinimum;

    const unitQuantityMaxAndMinComparisonDetail =
      unitQuantityMaximum &&
      unitQuantityMinimum &&
      unitQuantityMaximum <= unitQuantityMinimum;

    const yearsInBusinessMaxAndMinComparisonDetail =
      yearsInBusinessMaximum &&
      yearsInBusinessMinimum &&
      yearsInBusinessMaximum <= yearsInBusinessMinimum;

    if (
      linkStatus ||
      linkTitleStatus ||
      unitQuantityMaximumStatus ||
      unitQuantityMinimumStatus ||
      yearsInBusinessMaximumStatus ||
      yearsInBusinessMinimumStatus ||
      yearsInBusinessMaxAndMinComparisonDetail ||
      unitQuantityMaxAndMinComparisonDetail
    ) {
      if (linkStatus) {
        setError('linkTitle', {
          message: 'Required',
          type: 'manual',
        });
      }

      if (linkTitleStatus) {
        setError('link', {
          message: 'Required',
          type: 'manual',
        });
      }

      if (unitQuantityMaxAndMinComparisonDetail) {
        let minMessage: any = 'Not more than maximum';
        let maxMessage: any = 'Not less than minimum';
        if (unitQuantityMaximum == unitQuantityMinimum) {
          minMessage = 'Not equal to maximum';
          maxMessage = 'Not equal to minimum';
        }
        setError('unitQuantityMinimum', {
          message: minMessage,
          type: 'manual',
        });
        setError('unitQuantityMaximum', {
          message: maxMessage,
          type: 'manual',
        });
      }

      if (yearsInBusinessMaxAndMinComparisonDetail) {
        let minMessage: any = 'Not more than maximum';
        let maxMessage: any = 'Not less than minimum';
        if (yearsInBusinessMaximum == yearsInBusinessMinimum) {
          minMessage = 'Not equal to maximum';
          maxMessage = 'Not equal to minimum';
        }
        setError('yearsInBusinessMinimum', {
          message: minMessage,
          type: 'manual',
        });
        setError('yearsInBusinessMaximum', {
          message: maxMessage,
          type: 'manual',
        });
      }

      if (unitQuantityMaximumStatus) {
        compareBetweenMinMaxStages(
          unitQuantityMaximum,
          'unitQuantityMaximum',
          unitQuantityMinimum,
          'unitQuantityMinimum',
        );
      }

      if (unitQuantityMinimumStatus) {
        compareBetweenMinMaxStages(
          unitQuantityMinimum,
          'unitQuantityMinimum',
          unitQuantityMaximum,
          'unitQuantityMaximum',
        );
      }

      if (yearsInBusinessMaximumStatus) {
        compareBetweenMinMaxStages(
          yearsInBusinessMaximum,
          'yearsInBusinessMaximum',
          yearsInBusinessMinimum,
          'yearsInBusinessMinimum',
        );
      }

      if (yearsInBusinessMinimumStatus) {
        compareBetweenMinMaxStages(
          yearsInBusinessMinimum,
          'yearsInBusinessMinimum',
          yearsInBusinessMaximum,
          'yearsInBusinessMaximum',
        );
      }
      return true;
    }
    return false;
  };

  const handleSubmitbtn = async (data: any) => {
    if (formConditions()) {
      return;
    }

    const { link, linkTitle } = data;

    if ((linkTitle && !link) || (!linkTitle && link)) {
      if (linkTitle && !link) {
        urlErrorDesider(linkTitle, link, 'link');
      }

      if (!linkTitle && link) {
        urlErrorDesider(link, linkTitle, 'linkTitle');
      }
      return;
    }

    const {
      description,
      isSplachNotification,
      isPushNotification,
      recipients,
      scheduleEndDate,
      scheduleStartDate,
      scheduledEndAmPm,
      scheduledEndTime,
      scheduledStartAmPm,
      scheduledStartTime,
      title,
      unitQuantityMaximum = 0,
      unitQuantityMinimum = 0,
      yearsInBusinessMaximum = 0,
      yearsInBusinessMinimum = 0,
    } = data;

    let startDateHours: any = moment(scheduledStartTime).hours();
    const startDateminutes: any = moment(scheduledStartTime).minutes();

    let endDateHours: any = moment(scheduledEndTime).hours();
    const endDateminutes: any = moment(scheduledEndTime).minutes();

    if (scheduledStartAmPm === 'pm') {
      startDateHours = Number(startDateHours) + 12;
    }
    if (scheduledEndAmPm === 'pm') {
      endDateHours = Number(endDateHours) + 12;
    }
    const startSequence: any = moment(scheduleStartDate).set({
      hour: startDateHours,
      minute: startDateminutes,
      second: 0,
    });
    const endSequence: any = moment(scheduleEndDate).set({
      hour: endDateHours,
      minute: endDateminutes,
      second: 0,
    });

    const startSequenceDate: any = moment(startSequence).format();
    const endSequenceDate: any = moment(endSequence).format();

    const ApiData: Notification = {
      description,
      endTime: endSequenceDate,
      hyperLink: link,
      hyperLinkText: linkTitle,
      isPushNotification: isPushNotification,
      isSplashNotification: isSplachNotification,
      recipients: (recipients || []).filter((e: any) => e),
      startTime: startSequenceDate,
      title,
      // unitsLowerLimit: unitQuantityMinimum || 0,
      // unitsUpperLimit: unitQuantityMaximum || 0,
      // yearsLowerLimit: yearsInBusinessMinimum || 0,
      // yearsUpperLimit: yearsInBusinessMaximum || 0,
    };

    if ((unitQuantityMinimum && unitQuantityMaximum) || editNotification) {
      ApiData.unitsLowerLimit = unitQuantityMinimum || null;
      ApiData.unitsUpperLimit = unitQuantityMaximum || null;
    }

    if (
      (yearsInBusinessMinimum && yearsInBusinessMaximum) ||
      editNotification
    ) {
      ApiData.yearsLowerLimit = yearsInBusinessMinimum || null;
      ApiData.yearsUpperLimit = yearsInBusinessMaximum || null;
    }

    let ApiResponse: any = null;

    if (editNotification) {
      ApiResponse = await notificationStore.updateNotifications(
        editNotification.templateId,
        ApiData,
      );
    }

    if (!editNotification) {
      ApiResponse = await notificationStore.createNotifications(ApiData);
    }

    if (ApiResponse && ApiResponse.isOk()) {
      if (!editNotification) {
        resetApiNotificationDetails();
        enqueueSnackbar(String(ApiResponse.value), SnackBarConfig('s'));
        setTriggerNotification((p: boolean) => !p);
      } else {
        const { value } = ApiResponse;
        notificationStore.setUpdatedNotificationDetail(
          editNotification.templateId,
          value,
        );
        enqueueSnackbar(
          'Notification updated successfully',
          SnackBarConfig('s'),
        );
      }
      cancelNotification();
    }

    if (ApiResponse && ApiResponse.isErr()) {
      enqueueSnackbar(String(ApiResponse.error.message), SnackBarConfig('e'));
      // const {
      //   error: { message = null },
      // }: any = ApiResponse;
      // if (typeof message === 'string') {
      //   enqueueSnackbar(
      //     message || 'Error while processing data',
      //     SnackBarConfig('e'),
      //   );
      // } else if (typeof message === 'object') {
      //   enqueueSnackbar('Validation Error', SnackBarConfig('e'));
      // } else {
      //   enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      // }
    }
  };

  const selectedNotification = (notification: any) => {
    setEditNotification(notification);
    const {
      description,
      endTime,
      hyperLink,
      hyperLinkText,
      isSplashNotification,
      isPushNotification,
      // recipients,
      startTime,
      // status,
      // templateId,
      title,
      // type,
      unitsLowerLimit,
      unitsUpperLimit,
      yearsLowerLimit,
      yearsUpperLimit,
    }: any = notification;

    const recipients: string = notification.recipients;

    let spliRecipients: any = [];
    if (recipients) {
      spliRecipients = notification.recipients.split(',');
    } else {
      spliRecipients = [''];
    }

    let startAmPm: any = '';
    let endAmPm: any = '';
    let startMinuteSecond: any = null;
    let startDate: any = null;
    let endMinuteSecond: any = null;
    let endDate: any = null;

    if (startTime) {
      let startdate: any = moment(new Date(startTime));
      startDate = startdate;
      startAmPm = startdate.format('a');
      if (startAmPm === 'pm') {
        startdate = startdate.subtract(12, 'hours');
      }
      startMinuteSecond = startdate;
    }

    if (endTime) {
      let enddate: any = moment(new Date(endTime));
      endDate = enddate;
      endAmPm = enddate.format('a');
      if (endAmPm === 'pm') {
        enddate = enddate.subtract(12, 'hours');
      }
      endMinuteSecond = enddate;
    }

    resetForm({
      description: '',
      isSplachNotification: false,
      link: '',
      linkTitle: '',
      recipients: [''],
      scheduleEndDate: null,
      scheduleStartDate: null,
      scheduledEndAmPm: 'pm',
      scheduledEndTime: null,
      scheduledStartAmPm: 'pm',
      scheduledStartTime: null,
      title: '',
      unitQuantityMaximum: '',
      unitQuantityMinimum: '',
      yearsInBusinessMaximum: '',
      yearsInBusinessMinimum: '',
    });
    setValue('title', title);
    setValue('description', description);
    setValue('recipients', spliRecipients);
    setValue('scheduleStartDate', startDate);
    setValue('scheduledStartTime', startMinuteSecond);
    setValue('scheduledStartAmPm', startAmPm);
    setValue('unitQuantityMinimum', unitsLowerLimit || '');
    setValue('unitQuantityMaximum', unitsUpperLimit || '');
    setValue('yearsInBusinessMinimum', yearsLowerLimit || '');
    setValue('yearsInBusinessMaximum', yearsUpperLimit || '');
    setValue('isSplachNotification', isSplashNotification);
    setValue('isPushNotification', isPushNotification);
    setValue('scheduleEndDate', endDate);
    setValue('scheduledEndTime', endMinuteSecond);
    setValue('scheduledEndAmPm', endAmPm);
    setValue('link', hyperLink || '');
    setValue('linkTitle', hyperLinkText || '');
  };

  const urlErrorDesider = (
    currentField: any,
    anotherField: any,
    anotherFieldKey: any,
    // submitCount: any = false,
  ) => {
    if (!anotherField && currentField) {
      const messageString: any = 'Required';
      setTimeout(() => {
        setError(anotherFieldKey, {
          message: messageString,
          type: 'manual',
        });
      }, 0);
    }

    if (!anotherField && !currentField) {
      clearErrors([anotherFieldKey]);
    }
  };

  const compareBetweenMinMaxStages = (
    currentValue: any,
    currentKey: any,
    setValue: any,
    setKey: any,
  ) => {
    currentValue = Number(currentValue);
    setValue = Number(setValue);
    // if (!setValue || !currentValue) {
    //   clearErrors([currentKey, setKey]);
    //   return;
    // }else{
    // }

    if (currentValue && !setValue) {
      setError(setKey, {
        message: 'Required',
        type: 'manual',
      });
      return;
    } else if (!currentValue && setValue) {
      setTimeout(() => {
        setError(currentKey, {
          message: 'Required',
          type: 'manual',
        });
      }, 0);
      return;
    } else if (!setValue && !currentValue) {
      clearErrors([currentKey, setKey]);
      return;
    }

    function denotar(currentFieldMsg: any, setKeyMsg: any) {
      setTimeout(() => {
        setError(currentKey, {
          message: currentFieldMsg,
          type: 'manual',
        });
        setError(setKey, {
          message: setKeyMsg,
          type: 'manual',
        });
      }, 0);
    }

    if (
      (currentKey === 'unitQuantityMinimum' ||
        currentKey === 'yearsInBusinessMinimum') &&
      (setKey === 'unitQuantityMaximum' || setKey === 'yearsInBusinessMaximum')
    ) {
      // const status:any = ((currentValue > setValue) || (currentValue === setValue));
      const minMaxComparator: any = currentValue > setValue;
      const equalComparator: any = currentValue === setValue;
      if (minMaxComparator) {
        denotar('Not more than maximum', 'Not less than minimum');
      } else if (equalComparator) {
        denotar('Not equal to minimum', 'Not equal to maximum');
      } else {
        clearErrors([currentKey, setKey]);
      }
    } else if (
      (currentKey === 'unitQuantityMaximum' ||
        currentKey === 'yearsInBusinessMaximum') &&
      (setKey === 'unitQuantityMinimum' || setKey === 'yearsInBusinessMinimum')
    ) {
      const minMaxComparator: any = currentValue < setValue;
      const equalComparator: any = currentValue === setValue;
      if (minMaxComparator) {
        denotar('Not less than minimum', 'Not more than maximum');
      } else if (equalComparator) {
        denotar('Not equal to minimum', 'Not equal to maximum');
      } else {
        clearErrors([currentKey, setKey]);
      }
    }
  };

  const recipientsErrorDefiner = () => {
    const error: any = { ...errors };
    if (error && error.recipients && error.recipients.message) {
      return error.recipients.message;
    }
    return '';
  };

  const removeState = (ids: string) => {
    const existedDetails: any = [...getValues('recipients')].filter(
      (id: any) => id !== ids,
    );
    if (existedDetails) {
      setValue('recipients', existedDetails);
    }
    setTimeout(() => {
      if (!getValues('recipients').length) {
        setValue('recipients', ['']);
      }
    }, 0);
  };

  const cancelNotification = () => {
    setEditNotification(null);
    resetForm({
      description: '',
      isSplachNotification: false,
      link: '',
      linkTitle: '',
      recipients: [''],
      scheduleEndDate: null,
      scheduleStartDate: null,
      scheduledEndAmPm: 'pm',
      scheduledEndTime: null,
      scheduledStartAmPm: 'pm',
      scheduledStartTime: null,
      title: '',
      unitQuantityMaximum: '',
      unitQuantityMinimum: '',
      yearsInBusinessMaximum: '',
      yearsInBusinessMinimum: '',
    });
    notificationStore.resetNewNotifcation();
  };

  const resetApiNotificationDetails = () => {
    notificationStore.setNextLinkData({
      scroll: '',
      search: '',
    });
    notificationStore.setApiNotificationDetails();
    notificationStore.setCountDetail();
  };

  const deleteNotifications = async () => {
    const deleteresponse: any = await notificationStore.deleteNotificaion(
      editNotification.templateId,
    );
    if (deleteresponse && deleteresponse.isOk()) {
      notificationStore.setUpdatedNotificationDetail(
        editNotification.templateId,
        deleteresponse.value,
      );
      enqueueSnackbar('Notification deleted successfully', SnackBarConfig('s'));
      cancelNotification();
      // setTriggerNotification((p: boolean) => !p);
      // resetApiNotificationDetails();
    }

    if (deleteresponse && deleteresponse.isErr()) {
      enqueueSnackbar(
        String(deleteresponse.error.message),
        SnackBarConfig('e'),
      );
      // const {
      //   error: { message = null },
      // }: any = deleteresponse;
      // if (typeof message === 'string') {
      //   enqueueSnackbar(
      //     message || 'Error while processing data',
      //     SnackBarConfig('e'),
      //   );
      // } else if (typeof message === 'object') {
      //   enqueueSnackbar('Validation Error', SnackBarConfig('e'));
      // } else {
      //   enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      // }
    }
  };

  const disableStatus = () => {
    // if (loader) {
    //   // return editNotification;
    //   return false;
    // }
    if (authStore.pageAccess && authStore.pageAccess.notifications) {
      const status: boolean =
        editNotification && editNotification?.status !== 'scheduled';
      return status;
    } else {
      return true;
    }
  };
  const setTimeDialgueModification = () => {
    setTimeout(() => {
      const dialogueElement: any = document.querySelectorAll(
        '[role="dialog"] .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium',
      );
      if (dialogueElement && dialogueElement.length) {
        dialogueElement.forEach((element: any) => {
          element.style.display = 'none';
        });
      }
    }, 0);
  };

  // loader ? (
  //   <Loader
  //     className={'flex-centering mt-1em mb-1em'}
  //     style={{ marginBottom: 16, marginTop: 16 }}
  //     color="primary"
  //   />
  // ) :

  useMountEffect(setInput1Focus);

  const triggerDateTimeStatus = () => {
    if (submitCount) {
      setTimeout(() => {
        const startTime: any = getValues('scheduledStartTime');
        const endTime: any = getValues('scheduledEndTime');
        if (startTime && endTime) {
          triggerForm('scheduledStartTime');
          triggerForm('scheduledEndTime');
        }
      }, 0);
    }
  };

  const HasMoreResults = () => {
    setTriggerNotification((p: boolean) => !p);
  };

  // if (
  //   !authStore.pageAccess ||
  //   (authStore.pageAccess && !authStore.pageAccess.notifications)
  // ) {
  //   return (
  //     <Grid container>
  //       <Grid item md={12} sm={12} xs={12} sx={{ padding: '2rem 1.3rem' }}>
  //         <NotificationsList
  //           {...{
  //             HasMoreResults,
  //             backgroundColor: '#ffffff',
  //             classes,
  //             editNotification,
  //             height: 'auto',
  //             loader,
  //             loaderPosition: 'center',
  //             margin: 'auto',
  //             notificationStore,
  //             selectedNotification,
  //             width: '630px',
  //           }}
  //         />
  //       </Grid>
  //     </Grid>
  //   );
  // }
  return (
    <Grid container>
      <Grid
        item
        md={3}
        className={
          authStore.pageAccess && authStore.pageAccess.notifications
            ? classes.leftBox
            : ''
        }>
        <div
          {...(authStore.pageAccess && authStore.pageAccess.notifications
            ? { style: {} }
            : { style: { padding: '30px 0' } })}>
          {authStore.pageAccess && authStore.pageAccess.notifications ? (
            <Typography
              component={'div'}
              style={{ borderBottom: '1px solid #EAEAEA', padding: '1.5rem' }}>
              <Typography
                component={'div'}
                className={classes.createBtn}
                onClick={() => {
                  cancelNotification();
                }}>
                Create New Notification
              </Typography>
            </Typography>
          ) : (
            ''
          )}
          <Typography
            component={'div'}
            {...(authStore.pageAccess && authStore.pageAccess.notifications
              ? {}
              : { sx: { backgroundColor: '#ffffff' } })}>
            <NotificationsList
              {...{
                HasMoreResults,
                classes,
                editNotification,
                loader,
                notificationStore,
                selectedNotification,
                ...(authStore.pageAccess && authStore.pageAccess.notifications
                  ? {}
                  : { height: 'enable' }),
              }}
            />
          </Typography>
        </div>
      </Grid>
      <Grid item md={9} className={classes.leftR}>
        <div
          {...(authStore.pageAccess && authStore.pageAccess.notifications
            ? { style: {} }
            : { style: { padding: '30px 0' } })}>
          <form
            style={{
              width: '100%',
            }}
            onSubmit={handleSubmit(handleSubmitbtn, formConditions)}>
            {authStore.pageAccess && authStore.pageAccess.notifications ? (
              <Grid container style={{ padding: '1rem' }}>
                <Grid
                  item
                  md={5}
                  style={{ alignItems: 'center', display: 'flex' }}>
                  {(editNotification === null ||
                    editNotification?.status === 'scheduled') && (
                    <Typography component={'div'} className={classes.addTitle}>
                      {editNotification ? 'Edit' : 'Add New'} Notification
                    </Typography>
                  )}
                </Grid>
                <Grid item md={7}>
                  <Typography component={'div'} className={classes.btnBox}>
                    {editNotification &&
                      (editNotification?.status === 'scheduled' ||
                        editNotification?.status === 'active') && (
                        <Typography
                          component={'div'}
                          className={classes.delete}
                          onClick={() => deleteNotifications()}>
                          delete
                        </Typography>
                      )}
                    <Typography
                      component={'div'}
                      className={classes.cancel}
                      onClick={() => {
                        cancelNotification();
                      }}>
                      cancel
                    </Typography>
                    {(editNotification === null ||
                      editNotification?.status === 'scheduled') && (
                      <button type="submit" className={classes.submit}>
                        {editNotification ? 'SAVE' : 'SUBMIT NEW NOTIFICATION'}
                      </button>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            <Grid container style={{ padding: '0 1rem' }}>
              <Grid item md={7}>
                <Typography component={'div'} className={classes.formBox}>
                  <div
                    style={{
                      width: '100%',
                    }}>
                    <FormControl style={{ width: '100%' }}>
                      <Grid container>
                        <Grid item md={12} style={{ marginBottom: '.5rem' }}>
                          <Box className={classes.formLabelClass}>
                            Title{' '}
                            {!disableStatus() && (
                              <span style={{ color: '#A70D0D' }}>*</span>
                            )}
                          </Box>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="title"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <InputText
                                  isFormSubmittedOnce={!!formState.submitCount}
                                  inputRefObj={input1Ref}
                                  errors={errors.title?.message || ''}
                                  name={name}
                                  isRefreshValue={true}
                                  value={value}
                                  onChangeText={(val) => {
                                    onChange(val);
                                    notificationStore.setNewNotificationDetails(
                                      'title',
                                      val,
                                    );
                                  }}
                                  isEditable={disableStatus()}
                                  className={classes.selectIn}
                                  type={'text'}
                                  variant="standard"
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={12} style={{ marginBottom: '.5rem' }}>
                          <Box className={classes.formLabelClass}>
                            Description{' '}
                            {!disableStatus() && (
                              <span style={{ color: '#A70D0D' }}>*</span>
                            )}
                          </Box>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="description"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <>
                                  <InputText
                                    isFormSubmittedOnce={
                                      !!formState.submitCount
                                    }
                                    inputRefObj={ref}
                                    name={name}
                                    isRefreshValue={true}
                                    value={value}
                                    isTextArea={true}
                                    onChangeText={(val) => {
                                      onChange(val);
                                      notificationStore.setNewNotificationDetails(
                                        'description',
                                        val,
                                      );
                                    }}
                                    isEditable={disableStatus()}
                                    className={classes.selectText}
                                    type={'text'}
                                    variant="standard"
                                  />
                                  <div
                                    className="custom-error"
                                    style={{ marginTop: '.5rem ' }}>
                                    {errors.description?.message || ''}
                                  </div>
                                </>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </div>
                  <Grid container>
                    <Grid item md={12}>
                      <Typography component={'div'}>
                        <Typography component={'div'}>
                          <Grid item md={12} style={{ marginBottom: '.5rem' }}>
                            <Box className={classes.formLabelClass}>Link</Box>
                            <Controller
                              control={control}
                              defaultValue=""
                              name="link"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <InputText
                                    isFormSubmittedOnce={
                                      !!formState.submitCount
                                    }
                                    inputRefObj={ref}
                                    errors={errors.link?.message || ''}
                                    name={name}
                                    isRefreshValue={true}
                                    value={value}
                                    onChangeText={(val) => {
                                      urlErrorDesider(
                                        val,
                                        getValues('linkTitle'),
                                        'linkTitle',
                                        // !!formState.submitCount,
                                      );
                                      onChange(val);
                                      notificationStore.setNewNotificationDetails(
                                        'link',
                                        val,
                                      );
                                      if (!val) {
                                        urlErrorDesider(
                                          getValues('linkTitle'),
                                          val,
                                          'link',
                                          // !!formState.submitCount,
                                        );
                                      }
                                    }}
                                    isEditable={disableStatus()}
                                    className={classes.selectIn}
                                    type={'text'}
                                    variant="standard"
                                  />
                                );
                              }}
                            />
                          </Grid>
                        </Typography>
                        <Typography component={'div'}>
                          <Grid item md={12} style={{ marginBottom: '.5rem' }}>
                            <Box className={classes.formLabelClass}>
                              Link Title
                            </Box>
                            <Controller
                              control={control}
                              defaultValue=""
                              name="linkTitle"
                              render={({
                                field: { onChange, value, name, ref },
                                formState,
                              }) => {
                                return (
                                  <InputText
                                    isFormSubmittedOnce={
                                      !!formState.submitCount
                                    }
                                    inputRefObj={ref}
                                    errors={errors.linkTitle?.message || ''}
                                    name={name}
                                    isRefreshValue={true}
                                    value={value}
                                    onChangeText={(val) => {
                                      urlErrorDesider(
                                        val,
                                        getValues('link'),
                                        'link',
                                        // !!formState.submitCount,
                                      );
                                      onChange(val);
                                      notificationStore.setNewNotificationDetails(
                                        'linkTitle',
                                        val,
                                      );
                                      if (!val) {
                                        urlErrorDesider(
                                          getValues('link'),
                                          val,
                                          'linkTitle',
                                          // !!formState.submitCount,
                                        );
                                      }
                                    }}
                                    isEditable={disableStatus()}
                                    className={classes.selectIn}
                                    type={'text'}
                                    variant="standard"
                                  />
                                );
                              }}
                            />
                          </Grid>
                        </Typography>
                      </Typography>
                      <Typography>
                        <div style={{ alignItems: 'center', display: 'flex' }}>
                          <Controller
                            control={control}
                            defaultValue={false}
                            name="isSplachNotification"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <Checkbox
                                  className={classes.customCheck}
                                  checked={value}
                                  disabled={disableStatus()}
                                  onChange={(event: any) => {
                                    notificationStore.setNewNotificationDetails(
                                      'isSplachNotification',
                                      event.target.checked,
                                    );
                                    onChange(event);
                                  }}
                                />
                              );
                            }}
                          />
                          <label style={{ marginLeft: '0rem' }}>
                            Splash Notification
                          </label>
                        </div>
                      </Typography>

                      <Typography>
                        <div style={{ alignItems: 'center', display: 'flex' }}>
                          <Controller
                            control={control}
                            defaultValue={false}
                            name="isPushNotification"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <Tooltip
                                  title="Select this to send a notification on user’s mobile app."
                                  placement="bottom-start">
                                  <Checkbox
                                    className={classes.customCheck}
                                    checked={value}
                                    disabled={disableStatus()}
                                    onChange={(event: any) => {
                                      notificationStore.setNewNotificationDetails(
                                        'isPushNotification',
                                        event.target.checked,
                                      );
                                      onChange(event);
                                    }}
                                  />
                                </Tooltip>
                              );
                            }}
                          />

                          <label
                            title="notification"
                            style={{ marginLeft: '0rem' }}>
                            Mobile Push Notification
                          </label>
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Grid item md={5}>
                <Typography
                  component={'div'}
                  className={classes.customBox}
                  style={{ paddingLeft: '1rem' }}>
                  <Typography component={'div'} className={classes.customDate}>
                    <Typography
                      component={'div'}
                      className={classes.customDatetitle}>
                      Schedule
                    </Typography>
                    <Grid container className={classes.marY}>
                      <Grid item md={5}>
                        <Typography component={'div'}>
                          <Typography
                            component={'div'}
                            className={classes.formLabelClass}>
                            Start Date{' '}
                            {!disableStatus() && (
                              <span style={{ color: '#A70D0D' }}>*</span>
                            )}
                          </Typography>

                          <Controller
                            control={control}
                            defaultValue={null}
                            name="scheduleStartDate"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <React.Fragment>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}>
                                    {/* <CalendarPicker
                              defaultCalendarMonth={null}
                              // date={date}
                              // onChange={(newDate) => setDate(newDate)}
                              date={value || null}
                              onChange={(dateVal: any) => {
                                onChange(dateVal);
                                notificationStore.setNewNotificationDetails(
                                  'scheduleStartDate',
                                  dateVal,
                                );
                                if(getValues('scheduleEndDate')){
                                  triggerForm('scheduleEndDate')
                                }
                              }}
                            /> */}
                                    <DatePicker
                                      value={value || null}
                                      ref={ref}
                                      minDate={new Date()}
                                      disabled={disableStatus()}
                                      onChange={(newValue: any) => {
                                        onChange(newValue);
                                        notificationStore.setNewNotificationDetails(
                                          'scheduleStartDate',
                                          newValue,
                                        );
                                        triggerDateTimeStatus();
                                        if (getValues('scheduleEndDate')) {
                                          triggerForm('scheduleEndDate');
                                        }
                                      }}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          className={classes.datetime}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {!!formState.submitCount &&
                                    errors.scheduleStartDate?.message && (
                                      <div className="custom-error">
                                        {errors.scheduleStartDate?.message ||
                                          ''}
                                      </div>
                                    )}
                                </React.Fragment>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography component={'div'}>
                          <Typography
                            component={'div'}
                            className={classes.formLabelClass}>
                            Start Time{' '}
                            {!disableStatus() && (
                              <span style={{ color: '#A70D0D' }}>*</span>
                            )}
                          </Typography>

                          <Controller
                            control={control}
                            defaultValue={null}
                            name="scheduledStartTime"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <React.Fragment>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                      ref={ref}
                                      disabled={disableStatus()}
                                      views={['hours', 'minutes']}
                                      openTo={'hours'}
                                      value={value || null}
                                      ampm={true}
                                      onOpen={setTimeDialgueModification}
                                      inputFormat={'HH:mm'}
                                      onChange={(newValue: any) => {
                                        onChange(newValue);
                                        triggerDateTimeStatus();
                                      }}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          className={classes.datetime}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {!!formState.submitCount &&
                                    errors.scheduledStartTime?.message && (
                                      <div className="custom-error">
                                        {errors.scheduledStartTime?.message ||
                                          ''}
                                      </div>
                                    )}
                                </React.Fragment>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          component={'div'}
                          className={classes.period}>
                          <Typography
                            component={'div'}
                            className={
                              watchForm('scheduledStartAmPm') === 'am'
                                ? classes.periodActive
                                : classes.periodNormal
                            }
                            onClick={() => {
                              if (!disableStatus()) {
                                setValue('scheduledStartAmPm', 'am');
                                triggerDateTimeStatus();
                              }
                            }}>
                            AM
                          </Typography>
                          <Typography
                            component={'div'}
                            className={
                              watchForm('scheduledStartAmPm') === 'pm'
                                ? classes.periodActive
                                : classes.periodNormal
                            }
                            onClick={() => {
                              if (!disableStatus()) {
                                setValue('scheduledStartAmPm', 'pm');
                                triggerDateTimeStatus();
                              }
                            }}>
                            PM
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.marY}>
                      <Grid item md={5}>
                        <Typography
                          component={'div'}
                          className={classes.customDate}>
                          <Typography
                            component={'div'}
                            className={classes.formLabelClass}>
                            End Date{' '}
                            {!disableStatus() && (
                              <span style={{ color: '#A70D0D' }}>*</span>
                            )}
                          </Typography>

                          <Controller
                            control={control}
                            defaultValue={null}
                            name="scheduleEndDate"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <React.Fragment>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      ref={ref}
                                      minDate={new Date()}
                                      disabled={disableStatus()}
                                      value={value || null}
                                      onChange={(newValue: any) => {
                                        onChange(newValue);
                                        notificationStore.setNewNotificationDetails(
                                          'scheduleEndDate',
                                          newValue,
                                        );
                                        triggerDateTimeStatus();
                                        if (getValues('scheduleStartDate')) {
                                          triggerForm('scheduleStartDate');
                                        }
                                      }}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          className={classes.datetime}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {!!formState.submitCount &&
                                    errors.scheduleEndDate?.message && (
                                      <div className="custom-error">
                                        {errors.scheduleEndDate?.message || ''}
                                      </div>
                                    )}
                                </React.Fragment>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography component={'div'}>
                          <Typography
                            component={'div'}
                            className={classes.formLabelClass}>
                            End Time{' '}
                            {!disableStatus() && (
                              <span style={{ color: '#A70D0D' }}>*</span>
                            )}
                          </Typography>

                          <Controller
                            control={control}
                            defaultValue={null}
                            name="scheduledEndTime"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <React.Fragment>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                      ref={ref}
                                      disabled={disableStatus()}
                                      views={['hours', 'minutes']}
                                      openTo={'hours'}
                                      value={value || null}
                                      ampm={true}
                                      inputFormat={'HH:mm'}
                                      onOpen={setTimeDialgueModification}
                                      onChange={(newValue: any) => {
                                        onChange(newValue);
                                        triggerDateTimeStatus();
                                      }}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          className={classes.datetime}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {!!formState.submitCount &&
                                    errors.scheduledEndTime?.message && (
                                      <div className="custom-error">
                                        {errors.scheduledEndTime?.message || ''}
                                      </div>
                                    )}
                                </React.Fragment>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          component={'div'}
                          className={classes.period}>
                          <Typography
                            component={'div'}
                            className={
                              watchForm('scheduledEndAmPm') === 'am'
                                ? classes.periodActive
                                : classes.periodNormal
                            }
                            onClick={() => {
                              if (!disableStatus()) {
                                setValue('scheduledEndAmPm', 'am');
                                triggerDateTimeStatus();
                              }
                            }}>
                            AM
                          </Typography>
                          <Typography
                            component={'div'}
                            className={
                              watchForm('scheduledEndAmPm') === 'pm'
                                ? classes.periodActive
                                : classes.periodNormal
                            }
                            onClick={() => {
                              if (!disableStatus()) {
                                setValue('scheduledEndAmPm', 'pm');
                                triggerDateTimeStatus();
                              }
                            }}>
                            PM
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Typography>
                <Typography component={'div'} className={classes.recipent}>
                  {/* <Typography component={'div'} className={classes.reciadd}>
                  Add
                </Typography> */}

                  <Typography component={'div'} className={classes.reciaddbox}>
                    <Typography
                      component={'div'}
                      className={classes.reciaddbox}>
                      <Grid container>
                        <Grid item md={12}>
                          <div className={classes.recititle}>
                            Unit Quantity{' '}
                            <span className={classes.option}> (Optional) </span>
                          </div>
                        </Grid>
                        <Grid item md={6} className={classes.PaRight}>
                          <label
                            htmlFor="firstratings"
                            className={classes.formLabelClass}>
                            Minimum
                          </label>
                          <Controller
                            control={control}
                            defaultValue={''}
                            name="unitQuantityMinimum"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <>
                                  <InputText
                                    isFormSubmittedOnce={
                                      !!formState.submitCount
                                    }
                                    inputRefObj={ref}
                                    name={name}
                                    isRefreshValue={true}
                                    value={value}
                                    isTextArea={true}
                                    onChangeText={(val) => {
                                      if (
                                        new RegExp(/[^\d]/, 'gmi').test(val)
                                      ) {
                                        return;
                                      }
                                      onChange(val);
                                      notificationStore.setNewNotificationDetails(
                                        'unitQuantityMinimum',
                                        val,
                                      );
                                      compareBetweenMinMaxStages(
                                        val,
                                        'unitQuantityMinimum',
                                        getValues('unitQuantityMaximum'),
                                        'unitQuantityMaximum',
                                      );
                                    }}
                                    isEditable={disableStatus()}
                                    className={classes.minmax}
                                    type={'text'}
                                    variant="standard"
                                  />
                                  <div className="custom-error">
                                    {errors.unitQuantityMinimum?.message || ''}
                                  </div>
                                </>
                              );
                            }}
                          />
                        </Grid>

                        <Grid item md={6} className={classes.PaLeft}>
                          <label
                            htmlFor="firstratings"
                            className={classes.formLabelClass}>
                            Maximum
                          </label>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="unitQuantityMaximum"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <>
                                  <InputText
                                    isFormSubmittedOnce={
                                      !!formState.submitCount
                                    }
                                    inputRefObj={ref}
                                    name={name}
                                    isRefreshValue={true}
                                    value={value}
                                    isTextArea={true}
                                    onChangeText={(val) => {
                                      if (
                                        new RegExp(/[^\d]/, 'gmi').test(val)
                                      ) {
                                        return;
                                      }
                                      onChange(val);
                                      notificationStore.setNewNotificationDetails(
                                        'unitQuantityMaximum',
                                        val,
                                      );
                                      // if(getValues('unitQuantityMinimum')){
                                      //   triggerForm('unitQuantityMinimum');
                                      // }
                                      compareBetweenMinMaxStages(
                                        val,
                                        'unitQuantityMaximum',
                                        getValues('unitQuantityMinimum'),
                                        'unitQuantityMinimum',
                                      );
                                    }}
                                    isEditable={disableStatus()}
                                    className={classes.minmax}
                                    type={'text'}
                                    variant="standard"
                                  />
                                  <div className="custom-error">
                                    {errors.unitQuantityMaximum?.message || ''}
                                  </div>
                                </>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item md={12}>
                          <div className={classes.recititle}>
                            Years In Business{' '}
                            <span className={classes.option}> (Optional) </span>
                          </div>
                        </Grid>
                        <Grid item md={6} className={classes.PaRight}>
                          <label
                            htmlFor="firstratings"
                            className={classes.formLabelClass}>
                            Minimum
                          </label>
                          <Controller
                            control={control}
                            defaultValue={''}
                            name="yearsInBusinessMinimum"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <>
                                  <InputText
                                    isFormSubmittedOnce={
                                      !!formState.submitCount
                                    }
                                    inputRefObj={ref}
                                    name={name}
                                    isRefreshValue={true}
                                    value={value}
                                    isTextArea={true}
                                    onChangeText={(val) => {
                                      if (
                                        new RegExp(/[^\d]/, 'gmi').test(val)
                                      ) {
                                        return;
                                      }
                                      onChange(val);
                                      notificationStore.setNewNotificationDetails(
                                        'yearsInBusinessMinimum',
                                        val,
                                      );
                                      compareBetweenMinMaxStages(
                                        val,
                                        'yearsInBusinessMinimum',
                                        getValues('yearsInBusinessMaximum'),
                                        'yearsInBusinessMaximum',
                                      );
                                    }}
                                    isEditable={disableStatus()}
                                    className={classes.minmax}
                                    type={'text'}
                                    variant="standard"
                                  />
                                  <div className="custom-error">
                                    {errors.yearsInBusinessMinimum?.message ||
                                      ''}
                                  </div>
                                </>
                              );
                            }}
                          />
                        </Grid>

                        <Grid item md={6} className={classes.PaLeft}>
                          <label
                            htmlFor="firstratings"
                            className={classes.formLabelClass}>
                            Maximum
                          </label>
                          <Controller
                            control={control}
                            defaultValue=""
                            name="yearsInBusinessMaximum"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <>
                                  <InputText
                                    isFormSubmittedOnce={
                                      !!formState.submitCount
                                    }
                                    inputRefObj={ref}
                                    name={name}
                                    isRefreshValue={true}
                                    value={value}
                                    isTextArea={true}
                                    onChangeText={(val) => {
                                      if (
                                        new RegExp(/[^\d]/, 'gmi').test(val)
                                      ) {
                                        return;
                                      }
                                      onChange(val);
                                      notificationStore.setNewNotificationDetails(
                                        'yearsInBusinessMaximum',
                                        val,
                                      );
                                      compareBetweenMinMaxStages(
                                        val,
                                        'yearsInBusinessMaximum',
                                        getValues('yearsInBusinessMinimum'),
                                        'yearsInBusinessMinimum',
                                      );
                                    }}
                                    isEditable={disableStatus()}
                                    className={classes.minmax}
                                    type={'text'}
                                    variant="standard"
                                  />
                                  <div className="custom-error">
                                    {errors.yearsInBusinessMaximum?.message ||
                                      ''}
                                  </div>
                                </>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Typography>
                  </Typography>
                  <Typography className={classes.recititle}>
                    Select Recipients{' '}
                    <span className={classes.option}> (Optional) </span>
                  </Typography>
                  <div
                    style={{
                      width: '100%',
                    }}>
                    <FormControl style={{ width: '100%' }}>
                      <Grid container>
                        <Grid item md={12} style={{ marginBottom: '.5rem' }}>
                          <Controller
                            control={control}
                            defaultValue={[]}
                            name="recipients"
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              return (
                                <div className={classes.selectW}>
                                  <MultiSelectDropdown
                                    ref={ref}
                                    isDisabled={disableStatus()}
                                    onChange={(values: any) => {
                                      const findStatus: any = values.find(
                                        (e: any) => e,
                                      );
                                      if (findStatus) {
                                        values = values.filter((e: any) => e);
                                      }

                                      if (!values.length) {
                                        values = [''];
                                      }

                                      notificationStore.setNewNotificationDetails(
                                        'recipients',
                                        values,
                                      );

                                      onChange(values);
                                    }}
                                    options={[...states].map((e: any) => ({
                                      label: e.fullName,
                                      value: e.code,
                                    }))}
                                    value={value}
                                  />

                                  {errors.recipients &&
                                    recipientsErrorDefiner() && (
                                      <div>{recipientsErrorDefiner()}</div>
                                    )}
                                </div>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </div>
                  <Typography component={'div'} className={classes.reciaddbox}>
                    {[...watchForm('recipients')]
                      .filter((e: any) => e)
                      .map((ids: any, index: number) => {
                        const details: any = states.find((sta: any) => {
                          return sta.code === ids;
                        });
                        return (
                          <Typography
                            component={'div'}
                            key={index}
                            className={classes.reciadditem}>
                            <Typography className={classes.recitext}>
                              {(details && details.fullName) || ''}
                            </Typography>
                            {!disableStatus() && (
                              <Typography
                                className={classes.reciclose}
                                onClick={() => {
                                  removeState(ids);
                                }}>
                                X
                              </Typography>
                            )}
                          </Typography>
                        );
                      })}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default observer(Notifications);
