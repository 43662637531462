import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Switch from 'react-switch';

import NavFleet from '../../../../assets/images/NavFleet.svg';
import { InputText } from '../../../../components/Forms/InputText';
import { SelectInput } from '../../../../components/Forms/selectInput';
import TrainingActiveDialog from '../../../../components/TrainingActiveDialog/TrainingActiveDialog';
import VideoStreamDialog from '../../../../components/VideoStreamDialog/VideoStreamDialog';
import { useCustomStore } from '../../../../hooks';
import { CreateTrainingInputs, UpdateTrainingInputs } from '../../../../types';
import { decryptFileUrl } from '../../../../utils/helper';
import { SnackBarConfig } from '../../../../utils/SnackBarConfig';
import { VideoSchema } from '../../../../utils/ValidatorSchema';
import AddReasonDialog from './AddReasonDialog/AddReasonDialog';
import AddViolationDialogue from './AddViolationDialogue/AddViolationDialogue';
import ReasonMappingList from './ReasonMappingList';
import useStyles from './VideoListStyle';

const VideoAddEdit = (): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { videoStore } = useCustomStore();
  const classes = useStyles();
  const { id }: any = useParams();
  const navigate = useNavigate();

  const violationHeaders: any = [
    { label: 'BASIC CATEGORY', name: 'basicCategory' },
    { label: 'Violation Group', name: 'violationGroupName' },
    { label: 'Violation Code', name: 'code' },
    { label: 'Description', name: 'description' },
  ];

  const [isViolationsAttached, setIsViolationsAttached] = useState(false);
  const [isAddViolationDialogOpen, setIsAddViolationDialogOpen]: any =
    useState(false);
  const [isAddReasonDialogOpen, setIsAddReasonDialogOpen]: any =
    useState(false);

  const [thumbnailFile, setThumbnailFile] = useState<any>(null);
  const [thumbnailFileBuffer, setThumbnailFileBuffer] = useState<any>(null);
  const [videoFile, setVideoFile] = useState<any>(null);
  const [loadingQuizList, setLoadingQuizList] = useState<boolean>(true);
  const [loadingCertTypesList, setLoadingCertTypesList] =
    useState<boolean>(true);
  const [quizLookupList, setQuizLookupList] = useState<any[]>([]);
  const [isTrainingSaving, setIsTrainingSaving] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isVideoEditMode, setIsVideoEditMode] = useState<boolean>(false);
  const [isThumbnailEditMode, setIsThumbnailEditMode] =
    useState<boolean>(false);
  const [thumbnailFileUrl, setThumbnailFileUrl] = useState<string>('');
  const [isStreamDialogOpen, setIsStreamDialogOpen] = useState<boolean>(false);
  const [showActiveAlert, setShowActiveAlert] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<{
    thumbnailUrl: string;
    fileUrl: string;
    title: string;
    hasQuiz: boolean;
    quizId: string | null;
    hasViolationMappings: boolean;
    violationIds: string[];
    status: boolean;
    isAccidentsRelated: boolean;
    hasReasonMappings: boolean;
    reasonIds: string[];
    hasDocument: boolean;
    documentTypeId: string;
  }>({
    criteriaMode: 'all',
    defaultValues: {
      documentTypeId: '',
      fileUrl: '',
      hasDocument: false,
      hasQuiz: true,
      hasReasonMappings: false,
      hasViolationMappings: false,
      isAccidentsRelated: false,
      quizId: '',
      reasonIds: [],
      status: false,
      thumbnailUrl: '',
      title: '',
      violationIds: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(VideoSchema),
  });
  const hasReasonMappingsWatch = watch('hasReasonMappings');
  const hasDocumentWatch = watch('hasDocument');
  const hasQuizWatch = watch('hasQuiz');
  const statusWatch = watch('status');

  // Quiz lookup list
  const getQuizLookupList = useCallback(async () => {
    const quizLookupListResp = await videoStore.getQuizLookupList();
    if (quizLookupListResp.isErr()) {
      enqueueSnackbar(
        String(quizLookupListResp.error.message),
        SnackBarConfig('e'),
      );
      setQuizLookupList([]);
    } else if (quizLookupListResp.isOk()) {
      setQuizLookupList(quizLookupListResp.value);
      setValue('quizId', videoStore.SelectedVideo.quizId);
    }
    setLoadingQuizList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTrainingCertificateTypesList = useCallback(async () => {
    if (videoStore.TrainingCertificateTypes.length > 0) {
      setLoadingCertTypesList(false);
      setValue(
        'documentTypeId',
        id ? videoStore.SelectedVideo.documentTypeId : '',
      );
      return;
    }
    const resp = await videoStore.getCertificateTypes();
    if (resp.isErr()) {
      enqueueSnackbar('Unable to fetch certificate types', SnackBarConfig('e'));
      videoStore.setTrainingCertificateTypes([]);
    } else if (resp.isOk()) {
      setValue('documentTypeId', videoStore.SelectedVideo.documentTypeId);
    }
    setLoadingCertTypesList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeViolationsDialog = () => {
    videoStore.setViolationGroupsApiReset();
    videoStore.setViolationCodesApiReset();
    setIsAddViolationDialogOpen(false);
  };

  const closeReasonsDialog = () => {
    videoStore.setAssignmentReasonsApiReset();
    setIsAddReasonDialogOpen(false);
  };

  const onChangeThumbnail = (e: any) => {
    if (typeof e?.preventDefault === 'function') {
      e.preventDefault();
    }
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setThumbnailFile(e?.target?.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e?.target?.files[0]);
    reader.onloadend = () => {
      setThumbnailFileBuffer(reader?.result);
    };
  };

  const onChangeVideo = (e: any) => {
    if (typeof e?.preventDefault === 'function') {
      e.preventDefault();
    }
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const videoFile = e?.target?.files[0];
    setVideoFile(videoFile);
  };

  const removeThumbnail = () => {
    if (isTrainingSaving) {
      return;
    }
    if (isThumbnailEditMode) {
      setValue('thumbnailUrl', '');
      setIsThumbnailEditMode(false);
      setThumbnailFileUrl('');
    }
    setThumbnailFile(null);
    setThumbnailFileBuffer(null);
  };

  const removeVideo = () => {
    if (isTrainingSaving) {
      return;
    }
    if (isVideoEditMode) {
      setValue('fileUrl', '');
      setIsVideoEditMode(false);
    }
    setVideoFile(null);
  };

  const setFormData = useCallback(() => {
    const {
      hasQuiz,
      quizId,
      status,
      title,
      thumbnailUrl,
      fileUrl,
      hasViolationMappings,
      violationMappings,
      hasReasonMappings,
      isAccidentsRelated,
      reasonMappings,
      hasDocument,
      documentTypeId,
    } = videoStore.SelectedVideo;
    setValue('title', title);
    setValue('hasQuiz', hasQuiz);
    setValue('hasViolationMappings', hasViolationMappings);
    setValue('status', status === 'active' ? true : false);
    setValue('quizId', quizId);
    setIsViolationsAttached(hasViolationMappings);
    setValue('thumbnailUrl', thumbnailUrl);
    setValue('fileUrl', fileUrl);
    setValue('hasReasonMappings', hasReasonMappings);
    setValue('isAccidentsRelated', isAccidentsRelated);
    setValue('hasDocument', hasDocument);
    setValue('documentTypeId', documentTypeId);
    setThumbnailFileUrl(decryptFileUrl(thumbnailUrl));
    !isVideoEditMode && setIsVideoEditMode(true);
    !isThumbnailEditMode && setIsThumbnailEditMode(true);
    videoStore.setSelectedViolationCodes(violationMappings, false);
    videoStore.setSelectedReasons(reasonMappings, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Selected Training Video
  const getSelectedTrainingVideo = useCallback(
    async (trainingId: string) => {
      videoStore.setSelectedVideoReset();
      videoStore.resetSelectedViolationCodes();
      videoStore.resetSelectedReasons();
      const trainingResp = await videoStore.getTraining(trainingId);
      if (trainingResp.isErr()) {
        enqueueSnackbar('Training video not found', SnackBarConfig('e'));
      } else if (trainingResp.isOk()) {
        videoStore.setSelectedVideo(trainingResp.value);
        if (!loadingQuizList) {
          setValue('quizId', videoStore.SelectedVideo.quizId);
        }
        if (!loadingCertTypesList) {
          setValue('documentTypeId', videoStore.SelectedVideo.documentTypeId);
        }
        setFormData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getQuizLookupList();
    getTrainingCertificateTypesList();
    if (id) {
      setIsEditMode(true);
      setIsVideoEditMode(true);
      setIsThumbnailEditMode(true);
      getSelectedTrainingVideo(id);
    } else {
      videoStore.setSelectedVideoReset();
      videoStore.resetSelectedViolationCodes();
      videoStore.resetSelectedReasons();
    }
  }, [
    getQuizLookupList,
    getTrainingCertificateTypesList,
    getSelectedTrainingVideo,
    id,
    videoStore,
  ]);

  // Form Submit
  const submitTraining = async () => {
    const formData = getValues();
    const createPayload: CreateTrainingInputs = {
      documentTypeId: formData.documentTypeId,
      fileName: '',
      fileUrl: formData.fileUrl,
      hasDocument: formData.hasDocument,
      hasQuiz: formData.hasQuiz,
      hasReasonMappings: formData.hasReasonMappings,
      hasViolationMappings: formData.hasViolationMappings,
      isAccidentsRelated: formData.isAccidentsRelated,
      quizId: formData.quizId || null,
      reasonIds: videoStore.SelectedReasonIds,
      status: formData.status ? 'active' : 'draft',
      thumbnailName: '',
      thumbnailUrl: formData.thumbnailUrl || '',
      title: formData.title,
      violationIds: videoStore.SelectedViolationCodeIds,
    };
    if (
      thumbnailFile === null &&
      formData.thumbnailUrl == '' &&
      !formData.hasDocument
    ) {
      enqueueSnackbar('Thumbnail Required', SnackBarConfig('e'));
      return;
    }
    if (videoFile === null && formData.fileUrl == '') {
      enqueueSnackbar(
        `${formData.hasDocument ? 'Document' : 'Video'} Required`,
        SnackBarConfig('e'),
      );
      return;
    }

    setIsTrainingSaving(true);
    // Upload Thumbnail Image only if it not document based training
    if (!formData.hasDocument) {
      if (formData.thumbnailUrl === '' && thumbnailFile !== null) {
        const thumbnailFormData = new FormData();
        thumbnailFormData.append('file', thumbnailFile);
        const thumbnailResp = await videoStore.uploadThumbnailImage(
          thumbnailFormData,
        );
        if (thumbnailResp.isErr()) {
          enqueueSnackbar(
            String(thumbnailResp.error.message),
            SnackBarConfig('e'),
          );
          setIsTrainingSaving(false);
          return;
        }
        if (thumbnailResp.isOk()) {
          createPayload.thumbnailUrl = thumbnailResp.value.filePath;
          createPayload.thumbnailName = thumbnailFile?.name || '';
        }
      } else {
        createPayload.thumbnailUrl = videoStore.SelectedVideo.thumbnailPath;
        createPayload.thumbnailName = videoStore.SelectedVideo.thumbnailName;
      }
    }

    // Upload Video/Document
    if (formData.fileUrl === '' && videoFile !== null) {
      const videoFormData = new FormData();
      videoFormData.append('file', videoFile);
      const videoResp = await videoStore.uploadTrainingVideo(
        videoFormData,
        createPayload.hasDocument ? 'document' : 'video',
      );
      if (videoResp.isErr()) {
        enqueueSnackbar(String(videoResp.error.message), SnackBarConfig('e'));
        setIsTrainingSaving(false);
        return;
      }
      if (videoResp.isOk()) {
        createPayload.fileUrl = videoResp.value.filePath;
        createPayload.fileName = videoFile?.name;
      }
    } else {
      createPayload.fileUrl = videoStore.SelectedVideo.filePath;
      createPayload.fileName = videoStore.SelectedVideo.fileName;
    }

    if (!isEditMode) {
      // Training Create mode
      const createTrainingResp = await videoStore.createTraining(createPayload);
      if (createTrainingResp.isErr()) {
        enqueueSnackbar(
          String(createTrainingResp.error.message),
          SnackBarConfig('e'),
        );
        setIsTrainingSaving(false);
      } else if (createTrainingResp.isOk()) {
        enqueueSnackbar(String(createTrainingResp.value), SnackBarConfig('s'));
        navigateToTrainings();
      }
      setIsTrainingSaving(false);
    } else {
      // Training Edit/Update mode
      const createViolationIds: string[] = [];
      const deleteViolationMappingIds: string[] = [];
      if (createPayload.hasViolationMappings) {
        const selectedCodeIds = videoStore.SelectedViolationCodeIds;
        videoStore.SelectedVideo.violationMappings.forEach((mapping) => {
          if (!selectedCodeIds.includes(mapping.id)) {
            deleteViolationMappingIds.push(mapping.trainingViolationId);
          }
        });
        const existingViolationCodeIds =
          videoStore.SelectedVideo.violationMappings.map((v) => v.id);
        selectedCodeIds.forEach((id) => {
          if (!existingViolationCodeIds.includes(id)) {
            createViolationIds.push(id);
          }
        });
      } else {
        const existingViolationMappingIds =
          videoStore.SelectedVideo.violationMappings.map(
            (v) => v.trainingViolationId,
          );
        deleteViolationMappingIds.push(...existingViolationMappingIds);
      }
      // Reason mappings delete or add
      const createReasonIds: string[] = [];
      const deleteReasonMappingIds: string[] = [];
      if (createPayload.hasReasonMappings) {
        const selectedReasonIds = videoStore.SelectedReasonIds;
        videoStore.SelectedVideo.reasonMappings.forEach((mapping) => {
          if (!selectedReasonIds.includes(mapping.id)) {
            deleteReasonMappingIds.push(mapping.trainingReasonId);
          }
        });
        const existingReasonIds = videoStore.SelectedVideo.reasonMappings.map(
          (v) => v.id,
        );
        selectedReasonIds.forEach((id) => {
          if (!existingReasonIds.includes(id)) {
            createReasonIds.push(id);
          }
        });
      } else {
        const existingReasonMappingIds =
          videoStore.SelectedVideo.reasonMappings.map(
            (v) => v.trainingReasonId,
          );
        deleteReasonMappingIds.push(...existingReasonMappingIds);
      }

      const updatePayload: UpdateTrainingInputs = {
        createReasonIds,
        createViolationIds,
        deleteReasonMappingIds,
        deleteViolationMappingIds,
        documentTypeId: createPayload.documentTypeId,
        fileName: createPayload.fileName,
        fileUrl: createPayload.fileUrl,
        hasDocument: createPayload.hasDocument,
        hasQuiz: createPayload.hasQuiz,
        hasReasonMappings: createPayload.hasReasonMappings,
        hasViolationMappings: createPayload.hasViolationMappings,
        isAccidentsRelated: createPayload.isAccidentsRelated,
        quizId: createPayload.quizId,
        status: createPayload.status,
        thumbnailName: createPayload.thumbnailName,
        thumbnailUrl: createPayload.thumbnailUrl,
        title: createPayload.title,
      };
      const updateTrainingResp = await videoStore.updateTraining(
        id,
        updatePayload,
      );
      if (updateTrainingResp.isErr()) {
        enqueueSnackbar(
          String(updateTrainingResp.error.message),
          SnackBarConfig('e'),
        );
      } else if (updateTrainingResp.isOk()) {
        enqueueSnackbar(String(updateTrainingResp.value), SnackBarConfig('s'));
        navigateToTrainings();
      }
      setIsTrainingSaving(false);
    }
    return;
  };

  const navigateToTrainings = () => {
    navigate('/training/trainings');
  };

  const resetTrainingVideoForm = () => {
    // Redirecting to trainings page when cancel
    navigateToTrainings();
    // Commented below code because we are not resetting form when cancel
    // if (!isEditMode) {
    //   resetForm();
    //   setThumbnailFile(null);
    //   setThumbnailFileBuffer(null);
    //   setVideoFile(null);
    //   setIsViolationsAttached(false);
    // } else {
    //   setFormData();
    // }
  };

  const onChangeHasViolationMappings = (event: any) => {
    const isChecked = event.target.checked;
    setValue('hasViolationMappings', isChecked);
    setIsViolationsAttached(isChecked);
  };

  const onChangeTrainingType = (value: string): void => {
    setValue('hasDocument', value === 'true' ? true : false);
    setValue('hasQuiz', value === 'true' ? false : true);
    setValue('quizId', '');
    removeVideo();
  };

  const disableField = useMemo(() => {
    if (
      isTrainingSaving ||
      (statusWatch && videoStore.SelectedVideo.status !== 'draft' && isEditMode)
    ) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, statusWatch]);

  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(submitTraining)}
        className={classes.videoForm}>
        <Grid
          className={classes.addEditVideo}
          container
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Grid style={{ alignItems: 'center', display: 'flex' }}>
            <Typography
              noWrap
              className={classes.goBackLink}
              onClick={() => {
                navigateToTrainings();
              }}>
              <ArrowBackIosIcon className="back-icon" />
              <span>BACK</span>
            </Typography>
            <Typography className={classes.addVideoTitle}>
              {isEditMode ? 'Update ' : 'Add '}
              Training Details
            </Typography>
          </Grid>
          <Grid
            justifyContent={'flex-end'}
            display={'flex'}
            alignItems={'center'}
            style={{ marginRight: '30px' }}>
            <div className={classes.makeVideoActiveSwitch}>
              <Typography noWrap className="title">
                {'Make Training Active'}
              </Typography>
              <Controller
                control={control}
                defaultValue={false}
                name="status"
                render={({ field: { name } }) => {
                  return (
                    <Switch
                      onChange={(checked: boolean) => {
                        setValue('status', checked);
                        if (
                          checked &&
                          (!isEditMode ||
                            (isEditMode &&
                              videoStore.SelectedVideo.status === 'draft'))
                        ) {
                          setShowActiveAlert(true);
                        }
                      }}
                      name={name}
                      checked={getValues('status')}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      disabled={disableField}
                      onColor={'#0DA728'}
                      offColor={'#787880'}
                      width={51}
                      height={31}
                      className={'activeSwitch'}
                    />
                  );
                }}
              />
            </div>
            <Button
              variant="contained"
              className={classes.cancelBtn}
              disabled={isTrainingSaving}
              onClick={() => {
                resetTrainingVideoForm();
              }}>
              {'CANCEL'}
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isTrainingSaving}
              className={classes.saveBtn}>
              {isTrainingSaving ? (
                <CircularProgress size={20} sx={{ color: '#DEC330' }} />
              ) : (
                'SAVE'
              )}
            </Button>
          </Grid>
        </Grid>
        {/* Training details section */}
        <Grid
          container
          justifyContent={'space-between'}
          style={{ marginTop: '20px' }}>
          {/* Video/Document and training details section */}
          <Grid item xs={8} md={8} lg={8} style={{ padding: '1rem' }}>
            <Card>
              <CardContent>
                <Grid container style={{ padding: '0 1rem' }}>
                  <Grid item md={12}>
                    <Grid
                      container
                      justifyContent="space-between"
                      className={classes.gridContainer}>
                      {/* Training Type */}
                      <Grid item xs={5}>
                        <Typography
                          sx={{
                            color: '#241A2E !important',
                            fontFamily: 'FiraSans-Regular !important',
                            fontSize: '12px !important',
                            lineHeight: '24px !important',
                          }}>
                          Select Training Type *
                        </Typography>
                        <FormControl style={{ width: '100%' }}>
                          <Grid style={{ padding: '0px !important' }}>
                            <Grid
                              item
                              md={12}
                              style={{ marginBottom: '.5rem' }}>
                              <Grid item md={12}>
                                <Controller
                                  control={control}
                                  name="hasDocument"
                                  render={({
                                    field: { name, ref, value },
                                    formState,
                                  }) => {
                                    return (
                                      <SelectInput
                                        name={name}
                                        value={value ? 'true' : 'false'}
                                        isRefreshValue={true}
                                        isFormSubmittedOnce={
                                          !!formState.submitCount
                                        }
                                        errors={
                                          errors.hasDocument?.message || ''
                                        }
                                        inputRefObj={ref}
                                        size="small"
                                        onChangeText={onChangeTrainingType}
                                        isEditable={
                                          isEditMode || isTrainingSaving
                                        }
                                        selectClassName={
                                          classes.selectClassName
                                        }
                                        placeHolder={'Select Training Type'}
                                        optionsList={
                                          <>
                                            <option
                                              value="false"
                                              className={
                                                classes.optionsClassName
                                              }>
                                              Video
                                            </option>
                                            <option
                                              value="true"
                                              className={
                                                classes.optionsClassName
                                              }>
                                              Document
                                            </option>
                                          </>
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                      {/* Training Certificate Type */}
                      <Grid item xs={6.7}>
                        <Typography
                          sx={{
                            color: '#241A2E !important',
                            fontFamily: 'FiraSans-Regular !important',
                            fontSize: '12px !important',
                            lineHeight: '24px !important',
                          }}>
                          Select Certificate Type *
                        </Typography>
                        <FormControl style={{ width: '100%' }}>
                          <Grid style={{ padding: '0px !important' }}>
                            <Grid
                              item
                              md={12}
                              style={{ marginBottom: '.5rem' }}>
                              <Grid item md={12}>
                                <Controller
                                  control={control}
                                  name="documentTypeId"
                                  render={({
                                    field: { name, ref, value, onChange },
                                    formState,
                                  }) => {
                                    return (
                                      <SelectInput
                                        name={name}
                                        value={value}
                                        isRefreshValue={true}
                                        isFormSubmittedOnce={
                                          !!formState.submitCount
                                        }
                                        errors={
                                          errors.documentTypeId?.message || ''
                                        }
                                        inputRefObj={ref}
                                        size="small"
                                        onChangeText={onChange}
                                        isEditable={
                                          isTrainingSaving ||
                                          loadingCertTypesList
                                        }
                                        selectClassName={
                                          classes.selectClassName
                                        }
                                        placeHolder={'Select Certificate Type'}
                                        optionsList={
                                          <>
                                            <option
                                              value=""
                                              key="certificate-type-select"
                                              disabled={true}
                                              className={
                                                classes.optionsClassName
                                              }>
                                              Select Certificate Type
                                            </option>
                                            {videoStore.TrainingCertificateTypes.map(
                                              (cert: any) => (
                                                <option
                                                  value={cert.documentId}
                                                  key={cert.documentId}
                                                  className={
                                                    classes.optionsClassName
                                                  }>
                                                  {cert.documentName}
                                                </option>
                                              ),
                                            )}
                                          </>
                                        }
                                      />
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* Training Title */}
                    <Typography component={'div'}>
                      <div
                        style={{
                          width: '100%',
                        }}>
                        <FormControl style={{ width: '100%' }}>
                          <Grid
                            className={classes.videoTitleInput}
                            style={{ padding: '0px !important' }}>
                            <Grid
                              item
                              md={12}
                              style={{ marginBottom: '.5rem' }}>
                              <Box className={classes.formLabelClass}>
                                Training Title *
                              </Box>
                              <Controller
                                control={control}
                                defaultValue="input"
                                name="title"
                                render={({
                                  field: { value, name, onChange },
                                  formState,
                                }) => {
                                  return (
                                    <InputText
                                      isFormSubmittedOnce={
                                        !!formState.submitCount
                                      }
                                      errors={errors.title?.message || ''}
                                      name={name}
                                      isRefreshValue={true}
                                      value={value}
                                      isEditable={false}
                                      onChangeText={(val: string) => {
                                        onChange(val.replace(/[^\w\s-]/g, ''));
                                      }}
                                      className={classes.selectIn}
                                      type={'text'}
                                      variant="standard"
                                      placeholder="Training Title"
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                      </div>
                    </Typography>
                    {/* Quiz */}
                    <Typography
                      component={'div'}
                      style={{ display: 'flex', minHeight: '79.19px' }}>
                      {/* Quiz Required? */}
                      <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Controller
                          control={control}
                          defaultValue={false}
                          name="hasQuiz"
                          render={({ field: { name, ref } }) => {
                            return (
                              <Checkbox
                                className={classes.customQuizCheck}
                                name={name}
                                checked={getValues('hasQuiz')}
                                inputRef={ref}
                                disabled={disableField}
                                onChange={(e: any) => {
                                  setValue('hasQuiz', e.target.checked);
                                  setValue(
                                    'quizId',
                                    isEditMode
                                      ? videoStore.SelectedVideo.quizId || ''
                                      : '',
                                  );
                                }}
                                color="success"
                              />
                            );
                          }}
                        />
                        <label
                          style={{
                            color: '#241A2E',
                            fontFamily: 'FiraSans-Medium',
                            fontSize: '15px',
                            lineHeight: '22px',
                            marginLeft: '0rem',
                          }}>
                          Quiz Required?
                        </label>
                      </div>
                      {/* Quiz List */}
                      {hasQuizWatch && (
                        <div
                          style={{
                            marginLeft: '27px',
                            width: '60%',
                          }}>
                          <Typography
                            sx={{
                              color: '#241A2E !important',
                              fontFamily: 'FiraSans-Regular !important',
                              fontSize: '12px !important',
                              lineHeight: '24px !important',
                            }}>
                            Select Quiz{' '}
                          </Typography>
                          <FormControl style={{ width: '100%' }}>
                            <Grid style={{ padding: '0px !important' }}>
                              <Grid
                                item
                                md={12}
                                style={{ marginBottom: '.5rem' }}>
                                <Grid item md={12}>
                                  <Controller
                                    control={control}
                                    name="quizId"
                                    render={({
                                      field: { name, ref, value, onChange },
                                      formState,
                                    }) => {
                                      return (
                                        <SelectInput
                                          name={name}
                                          value={value}
                                          isRefreshValue={true}
                                          isFormSubmittedOnce={
                                            !!formState.submitCount
                                          }
                                          errors={errors.quizId?.message || ''}
                                          inputRefObj={ref}
                                          size="small"
                                          onChangeText={onChange}
                                          isEditable={
                                            loadingQuizList || disableField
                                          }
                                          selectClassName={
                                            classes.selectClassName
                                          }
                                          placeHolder={'Select Quiz'}
                                          optionsList={
                                            <>
                                              <option
                                                value=""
                                                key="quiz-select"
                                                disabled={true}
                                                className={
                                                  classes.optionsClassName
                                                }>
                                                Select Quiz
                                              </option>
                                              {quizLookupList.map(
                                                (quiz: any) => (
                                                  <option
                                                    value={quiz.quizId}
                                                    key={quiz.quizId}
                                                    disabled={quiz.isAttached}
                                                    className={
                                                      classes.optionsClassName
                                                    }>
                                                    {quiz.quizTitle}
                                                  </option>
                                                ),
                                              )}
                                            </>
                                          }
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </FormControl>
                        </div>
                      )}
                    </Typography>
                    {/* Upload Document */}
                    {hasDocumentWatch && (
                      <Grid
                        style={{
                          marginTop: '0.5rem',
                        }}>
                        <Typography
                          component={'div'}
                          sx={{
                            color: '#241A2E',
                            display: 'flex',
                            fontFamily: 'FiraSans-Regular',
                            fontSize: '12px',
                            height: '24px',
                            letterSpacing: 0,
                            lineHeight: '24px',
                          }}>
                          Upload Document<sup>*</sup>
                        </Typography>
                        {((videoFile && videoFile.name) || isVideoEditMode) && (
                          <Grid
                            item
                            xs={9}
                            md={9}
                            lg={9}
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                            <Typography
                              component={'div'}
                              sx={{
                                color: '#241A2E',
                                display: 'flex',
                                fontFamily: 'FiraSans-Medium',
                                fontSize: '15px',
                                height: '24px',
                                letterSpacing: 0,
                                lineHeight: '24px',
                              }}
                              style={{
                                cursor: isVideoEditMode ? 'pointer' : 'unset',
                              }}
                              onClick={() =>
                                isVideoEditMode && setIsStreamDialogOpen(true)
                              }>
                              {isVideoEditMode
                                ? videoStore.SelectedVideo.fileName
                                : videoFile.name}
                            </Typography>
                            <Typography
                              component={'div'}
                              sx={{
                                color: '#241A2E',
                                display: 'flex',
                                fontFamily: 'FiraSans-Medium',
                                fontSize: '15px',
                                height: '24px',
                                letterSpacing: 0,
                                lineHeight: '24px',
                              }}>
                              {!isVideoEditMode
                                ? `${(
                                    Number(videoFile.size) /
                                    1024 /
                                    1024
                                  ).toFixed(2)}mb`
                                : ''}
                              <Typography
                                component={'div'}
                                sx={{
                                  color: '#979598',
                                  cursor: 'pointer',
                                  display: 'flex',
                                  fontFamily: 'FiraSans-Regular',
                                  fontSize: '13px',
                                  height: '13px',
                                  letterSpacing: 0,
                                  lineHeight: '24px',
                                  marginLeft: '10px',
                                }}
                                onClick={removeVideo}>
                                {<ClearIcon />}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                        {!videoFile && !isVideoEditMode && (
                          <>
                            <label
                              htmlFor={'uploadDocument'}
                              className={classes.uploadVideo}>
                              {'UPLOAD'}
                            </label>
                            <input
                              type="file"
                              id="uploadDocument"
                              name="uploadDocument"
                              accept=".pdf"
                              onChange={onChangeVideo}
                              hidden
                            />
                          </>
                        )}
                      </Grid>
                    )}
                    {/* Upload Video */}
                    {!hasDocumentWatch && (
                      <Grid
                        style={{
                          marginTop: '0.5rem',
                        }}>
                        <Typography
                          component={'div'}
                          sx={{
                            color: '#241A2E',
                            display: 'flex',
                            fontFamily: 'FiraSans-Regular',
                            fontSize: '12px',
                            height: '24px',
                            letterSpacing: 0,
                            lineHeight: '24px',
                          }}>
                          Upload Video<sup>*</sup>
                        </Typography>
                        {((videoFile && videoFile.name) || isVideoEditMode) && (
                          <Grid
                            item
                            xs={9}
                            md={9}
                            lg={9}
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                            <Typography
                              component={'div'}
                              sx={{
                                color: '#241A2E',
                                display: 'flex',
                                fontFamily: 'FiraSans-Medium',
                                fontSize: '15px',
                                height: '24px',
                                letterSpacing: 0,
                                lineHeight: '24px',
                              }}
                              style={{
                                cursor: isVideoEditMode ? 'pointer' : 'unset',
                              }}
                              onClick={() =>
                                isVideoEditMode && setIsStreamDialogOpen(true)
                              }>
                              {isVideoEditMode
                                ? videoStore.SelectedVideo.fileName
                                : videoFile.name}
                            </Typography>
                            <Typography
                              component={'div'}
                              sx={{
                                color: '#241A2E',
                                display: 'flex',
                                fontFamily: 'FiraSans-Medium',
                                fontSize: '15px',
                                height: '24px',
                                letterSpacing: 0,
                                lineHeight: '24px',
                              }}>
                              {!isVideoEditMode
                                ? `${(
                                    Number(videoFile.size) /
                                    1024 /
                                    1024
                                  ).toFixed(2)}mb`
                                : ''}
                              <Typography
                                component={'div'}
                                sx={{
                                  color: '#979598',
                                  cursor: 'pointer',
                                  display: 'flex',
                                  fontFamily: 'FiraSans-Regular',
                                  fontSize: '13px',
                                  height: '13px',
                                  letterSpacing: 0,
                                  lineHeight: '24px',
                                  marginLeft: '10px',
                                }}
                                onClick={removeVideo}>
                                {<ClearIcon />}
                              </Typography>
                            </Typography>
                          </Grid>
                        )}
                        {!videoFile && !isVideoEditMode && (
                          <>
                            <label
                              htmlFor={'uploadVideo'}
                              className={classes.uploadVideo}>
                              {'UPLOAD'}
                            </label>
                            <input
                              type="file"
                              id="uploadVideo"
                              name="uploadVideo"
                              accept="video/*"
                              onChange={onChangeVideo}
                              hidden
                            />
                          </>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* Video Thumbnail section */}
          {!hasDocumentWatch && (
            <Grid
              item
              xs={4}
              md={4}
              lg={4}
              style={{ padding: '1rem' }}
              className={'videoThumbNailCard'}>
              <Card
                className={clsx(
                  classes.thumbnailCard,
                  'videoThumbnailContainer',
                )}>
                <CardContent className="card-content">
                  <Typography
                    component={'div'}
                    sx={{
                      color: '#241A2E',
                      display: 'flex',
                      fontFamily: 'FiraSans-Regular',
                      fontSize: '12px',
                      height: '24px',
                      letterSpacing: 0,
                      lineHeight: '24px',
                    }}>
                    Upload Thumbnail<sup>*</sup>
                  </Typography>
                  <Grid
                    className={'uploadPreviewContainer'}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                    }}>
                    <Typography component={'div'} style={{}}>
                      {!thumbnailFileBuffer && !thumbnailFileUrl && (
                        <Typography
                          component={'div'}
                          style={{
                            alignItems: 'center',
                            backgroundColor: '#F8F8FA',
                            borderRadius: '5px',
                            display: 'flex',
                            height: '175px',
                            justifyContent: 'center',
                            marginBottom: '10px',
                            width: '345px',
                          }}>
                          <img src={NavFleet} />
                        </Typography>
                      )}
                      {(thumbnailFileBuffer || thumbnailFileUrl) && (
                        <Typography
                          component={'div'}
                          style={{
                            cursor: isThumbnailEditMode ? 'pointer' : 'unset',
                          }}
                          onClick={() =>
                            isThumbnailEditMode && setIsStreamDialogOpen(true)
                          }>
                          <img
                            src={
                              isThumbnailEditMode
                                ? thumbnailFileUrl
                                : thumbnailFileBuffer
                            }
                            alt="thumbnail"
                            height="175"
                          />
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </CardContent>
                {thumbnailFile === null && !isThumbnailEditMode && (
                  <>
                    <label
                      htmlFor={'uploadThumbnail'}
                      className={classes.uploadThumbnail}>
                      {'UPLOAD'}
                    </label>
                    <input
                      type="file"
                      id="uploadThumbnail"
                      name="uploadThumbnail"
                      accept="image/png, image/jpeg"
                      onChange={onChangeThumbnail}
                      hidden
                    />
                  </>
                )}
                {(thumbnailFile !== null || isThumbnailEditMode) && (
                  <Grid
                    style={{
                      alignItems: 'flexStart',
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '10px',
                      paddingTop: '10px',
                    }}>
                    <Typography
                      noWrap
                      sx={{
                        color: '#241A2E',
                        display: 'inline-flex',
                        fontFamily: 'FiraSans-Medium',
                        fontSize: '15px',
                        height: '22px',
                        letterSpacing: 0,
                        lineHeight: '22px',
                        marginRight: '8px',
                      }}>
                      {isThumbnailEditMode
                        ? videoStore.SelectedVideo.thumbnailName
                        : thumbnailFile?.name}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{
                        color: '#979598',
                        cursor: 'pointer',
                        display: 'inline-flex',
                        fontFamily: 'FiraSans-Medium',
                        fontSize: '12px',
                        height: '17px',
                        letterSpacing: 0,
                        lineHeight: '17px',
                        marginRight: '8px',
                        textDecoration: 'underline',
                      }}
                      onClick={removeThumbnail}>
                      {'Remove Thumbnail Image'}
                    </Typography>
                  </Grid>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
        {/* Has Violations and Reasons section */}
        <Grid style={{ alignItems: 'center', display: 'flex' }}>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Controller
              control={control}
              defaultValue={false}
              name="hasViolationMappings"
              render={({ field: { name, ref } }) => {
                return (
                  <Checkbox
                    className={classes.customViolationCheck}
                    name={name}
                    checked={getValues('hasViolationMappings')}
                    inputRef={ref}
                    onChange={onChangeHasViolationMappings}
                    color="success"
                  />
                );
              }}
            />
            <label
              style={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Medium',
                fontSize: '15px',
                lineHeight: '22px',
                marginLeft: '0rem',
              }}>
              Related to Violations?
            </label>
          </div>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Controller
              control={control}
              defaultValue={false}
              name="isAccidentsRelated"
              render={({ field: { name, ref, onChange, value } }) => {
                return (
                  <Checkbox
                    className={classes.customViolationCheck}
                    name={name}
                    checked={value}
                    inputRef={ref}
                    onChange={onChange}
                    color="success"
                  />
                );
              }}
            />
            <label
              style={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Medium',
                fontSize: '15px',
                lineHeight: '22px',
                marginLeft: '0rem',
              }}>
              Related to Accidents?
            </label>
          </div>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <Controller
              control={control}
              defaultValue={false}
              name="hasReasonMappings"
              render={({ field: { name, ref, onChange, value } }) => {
                return (
                  <Checkbox
                    className={classes.customViolationCheck}
                    name={name}
                    checked={value}
                    inputRef={ref}
                    onChange={onChange}
                    color="success"
                  />
                );
              }}
            />
            <label
              style={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Medium',
                fontSize: '15px',
                lineHeight: '22px',
                marginLeft: '0rem',
              }}>
              Related to Reasons?
            </label>
          </div>
        </Grid>
        {/* Violation list section */}
        {isViolationsAttached && (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              flexGrow: 1,
              overflow: 'hidden',
              padding: '10px 20px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}>
            <Grid
              item
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '15px',
                padding: '0',
              }}>
              <Typography
                noWrap
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Bold',
                  fontSize: '16px',
                  fontWeight: 600,
                  height: '23px',
                  letterSpacing: 0,
                  lineHeight: '23px',
                }}>
                Violations
              </Typography>
              <Button
                className={classes.submit}
                onClick={() => {
                  setIsAddViolationDialogOpen(!isAddViolationDialogOpen);
                }}>
                ADD VIOLATION
              </Button>
            </Grid>
            <Grid
              className={classes.scroll}
              container
              spacing={2}
              style={{ padding: '0', paddingLeft: '10px' }}
              justifyContent="space-between">
              <Table
                className={classes.table}
                sx={{
                  borderCollapse: 'separate !important',
                  borderSpacing: '0px 5px !important',
                  minWidth: 650,
                }}
                aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {violationHeaders.map((header: any, index: number) => (
                      <TableCell key={index}>
                        <Grid item xs={12}>
                          <Typography
                            component={'div'}
                            noWrap
                            style={{ alignItems: 'center', display: 'flex' }}>
                            <Typography
                              component={'div'}
                              sx={{
                                color:
                                  header.name === 'name'
                                    ? '#241A2E'
                                    : '#979598',
                                fontFamily: 'FiraSans-Regular',
                                fontSize: '12px',
                                letterSpacing: 0,
                                lineHeight: '14px',
                              }}>
                              {header.label}
                            </Typography>
                          </Typography>
                        </Grid>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videoStore.SelectedViolationCodes.length > 0 ? (
                    videoStore.SelectedViolationCodes.map(
                      (violation: any, index: number) => (
                        <TableRow
                          key={index}
                          className={classes.rowHeight}
                          style={{ minHeight: '80px' }}>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography
                                noWrap
                                sx={{
                                  color: '#241A2E',
                                  fontFamily: 'FiraSans-Bold',
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  height: '23px',
                                  letterSpacing: 0,
                                  lineHeight: '23px',
                                }}>
                                {violation.basicCategoryName || '-'}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography
                                noWrap
                                sx={{
                                  color: '#241A2E',
                                  fontFamily: 'FiraSans-Bold',
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  height: '23px',
                                  letterSpacing: 0,
                                  lineHeight: '23px',
                                }}>
                                {violation.violationGroupName || '-'}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography
                                noWrap
                                sx={{
                                  color: '#241A2E',
                                  fontFamily: 'FiraSans-Bold',
                                  fontSize: '16px',
                                  height: '19px',
                                  letterSpacing: 0,
                                  lineHeight: '19px',
                                }}>
                                {violation.code || '-'}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography
                                sx={{
                                  color: '#241A2E',
                                  fontFamily: 'FiraSans-Light',
                                  fontSize: '16px',
                                  height: '19px',
                                  letterSpacing: 0,
                                  lineHeight: '19px',
                                }}>
                                {violation.description || '-'}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <Grid item xs={12} md={12} lg={12}>
                              <Typography
                                noWrap
                                sx={{
                                  color: '#979598',
                                  cursor: 'pointer',
                                  fontFamily: 'FiraSans-Light',
                                  fontSize: '16px',
                                  height: '19px',
                                  letterSpacing: 0,
                                  lineHeight: '19px',
                                }}
                                onClick={() => {
                                  videoStore.removeSelectedViolationCode(
                                    violation.id,
                                  );
                                }}>
                                {<ClearIcon />}
                              </Typography>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ),
                    )
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={5}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Regular',
                              fontSize: '16px',
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            {hasDocumentWatch ? 'Document' : 'Video'} not
                            related to any Violation(s)
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Box>
        )}
        {/* Reasons list section */}
        {hasReasonMappingsWatch && (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              flexGrow: 1,
              overflow: 'hidden',
              padding: '10px 20px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}>
            <Grid
              item
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '15px',
                padding: '0',
              }}>
              <Typography
                noWrap
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Bold',
                  fontSize: '16px',
                  fontWeight: 600,
                  height: '23px',
                  letterSpacing: 0,
                  lineHeight: '23px',
                }}>
                Reasons
              </Typography>
              <Button
                className={classes.submit}
                onClick={async () => {
                  await videoStore.getReasonList();
                  setIsAddReasonDialogOpen(!isAddReasonDialogOpen);
                }}>
                ADD REASON
              </Button>
            </Grid>
            <ReasonMappingList hasDocument={hasDocumentWatch} />
          </Box>
        )}
      </form>
      {isAddViolationDialogOpen && (
        <AddViolationDialogue
          isOpen={isAddViolationDialogOpen}
          closeDialog={closeViolationsDialog}
        />
      )}
      {isAddReasonDialogOpen && (
        <AddReasonDialog
          isOpen={isAddReasonDialogOpen}
          closeDialog={closeReasonsDialog}
        />
      )}
      {isStreamDialogOpen && (
        <VideoStreamDialog
          isOpen={isStreamDialogOpen}
          videoUrl={decryptFileUrl(videoStore.SelectedVideo.fileUrl)}
          videoTitle={videoStore.SelectedVideo.title}
          closeDialog={() => setIsStreamDialogOpen(false)}
          hasDocument={videoStore.SelectedVideo.hasDocument}
        />
      )}
      {showActiveAlert && (
        <TrainingActiveDialog
          isOpen={showActiveAlert}
          closeDialog={(response: boolean) => {
            setShowActiveAlert(false);
            if (!response) {
              setValue('status', false);
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default observer(VideoAddEdit);
