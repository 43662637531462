import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from '../../../components/Loader/Loader';

type NotificationListprops = {
  loader: boolean | any;
  notificationStore: any;
  HasMoreResults: any;
  classes: any;
  editNotification: any;
  selectedNotification: (src: any) => void;
  width?: string;
  height?: string;
  margin?: string;
  backgroundColor?: string;
  loaderPosition?: string;
};
const NotificationsList: React.FC<NotificationListprops> = (
  props: NotificationListprops,
) => {
  const {
    loader,
    notificationStore,
    HasMoreResults,
    classes,
    editNotification,
    selectedNotification,
    width,
    height,
    backgroundColor,
    margin,
    loaderPosition,
  } = props;

  const formatDate = (date: any) => {
    if (!date) {
      return '';
    }
    return moment(date).format('MM/DD/YY @hh:mma');
  };

  return (
    <InfiniteScroll
      // dataLength={apiNotifications.length}
      dataLength={(notificationStore.getApiNotifications || []).length}
      // height={windowHeight}
      style={{ overflow: 'unset' }}
      next={HasMoreResults}
      // hasMore={totalCount !== apiNotifications.length}
      hasMore={
        notificationStore.getCountDetail !==
        (notificationStore.getApiNotifications || []).length
      }
      scrollableTarget={'scrollerNotification'}
      loader={
        loader && (
          <div style={{ textAlign: 'center', width: '100%' }}>
            {/* <CircularProgress
              size={4}
              sx={{ color: '#DEC330', marginTop: 20 }}
            /> */}
          </div>
        )
      }
      endMessage>
      <Typography
        id="scrollerNotification"
        component={'div'}
        className={classes.scrollB}
        sx={{
          height: height ? 'calc(100vh - 140px)' : 'calc(100vh - 173px)',
          width: width ? width : 'auto',
          ...(margin ? { margin: margin } : {}),
          ...(backgroundColor ? { backgroundColor: backgroundColor } : {}),
        }}
        // onScroll={getNotifications}
      >
        {(notificationStore.getApiNotifications || []).length !== 0 &&
          (notificationStore.getApiNotifications || []).map(
            (notification: any, index: number) => {
              let applyclass: any = classes.notDate;

              let selectedNotificationclass: any = classes.notDetails;
              let statusStr: any = '';
              let endStatusStr: any = '';
              if (notification.status === 'scheduled') {
                // applyclass = clsx(classes.notDate, classes.scheduleStatusColor);
                statusStr = 'Scheduled';
                endStatusStr = 'Ends:';
                applyclass = classes.notDate;
              } else if (notification.status === 'active') {
                // applyclass = clsx(classes.notDate, classes.scheduleStatusColor);
                statusStr = 'Active';
                endStatusStr = 'Ends:';
                applyclass = classes.notactiveDate;
              } else if (notification.status === 'deleted') {
                // applyclass = clsx(classes.notDate);
                statusStr = 'Deleted';
                endStatusStr = '-';
                applyclass = classes.notDisableDate;
              } else if (notification.status === 'inactive') {
                // applyclass = clsx(classes.notDate, classes.scheduleStatusColor);
                statusStr = 'Expired';
                endStatusStr = 'Ended:';
                applyclass = classes.notDisableDate;
              }

              if (['deleted', 'inactive'].includes(notification.status)) {
                selectedNotificationclass = classes.notDisable;
              }

              if (
                editNotification &&
                editNotification.templateId &&
                notification.templateId === editNotification.templateId
              ) {
                selectedNotificationclass = clsx(
                  classes.notActive,
                  // classes.notDisable,
                );
              }
              return (
                <Typography
                  component={'div'}
                  className={selectedNotificationclass}
                  key={index}
                  onClick={() => selectedNotification(notification)}>
                  <Typography component={'div'} className={classes.notTitle}>
                    {notification.title}
                  </Typography>
                  <Typography
                    component={'div'}
                    className={classes.notDisc}
                    dangerouslySetInnerHTML={{
                      __html: notification.descriptionHtml,
                    }}>
                    {/* {notification.description} */}
                  </Typography>
                  {notification.hyperLink && notification.hyperLinkText && (
                    <Typography
                      component={'span'}
                      className={classes.notLink}
                      onClick={(e: any) => {
                        if (notification.hyperLink) {
                          window.open(notification.hyperLink, '_blank');
                        }
                        e.stopPropagation();
                      }}>
                      {notification.hyperLinkText}
                    </Typography>
                  )}
                  <Typography component={'div'} className={applyclass}>
                    {`${String(statusStr)}: ${formatDate(
                      notification.startTime,
                    )} ${String(endStatusStr)} ${formatDate(
                      notification.endTime,
                    )}`}
                  </Typography>
                  {notification.isSplashNotification && (
                    <Typography component={'div'} className={classes.splas}>
                      Splash Notification
                    </Typography>
                  )}
                </Typography>
              );
            },
          )}
        {(notificationStore.getApiNotifications || []).length === 0 &&
          (loader ? (
            <Typography
              component={'div'}
              {...(loaderPosition === 'center'
                ? {
                    sx: {
                      left: '55%',
                      position: 'fixed',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                    },
                  }
                : {})}>
              <Loader
                className={'flex-centering mt-1em mb-1em'}
                style={{ marginBottom: 16, marginTop: 16 }}
                color="primary"
              />
            </Typography>
          ) : (
            <div
              style={{
                padding: '2rem 0',
                textAlign: 'center',
              }}>
              No record(s) found
            </div>
          ))}
      </Typography>
    </InfiniteScroll>
  );
};

export default observer(NotificationsList);
