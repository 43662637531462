import Button, { ButtonProps } from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import React from 'react';

import DialogMigrate from '../Dialog/DialogMigrate';
import useStyles from './TrainingActiveDialogStyles';

const ArchiveButton = styled(Button)<ButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DEC330',
  },
  backgroundColor: '#DEC330',
  borderRadius: '4px',
  color: theme.palette.getContrastText('#DEC330'),
  height: '48px',
  width: 'fit-content',
}));

type TrainingActiveDialogProps = {
  isOpen: boolean;
  closeDialog: (response: boolean) => void;
};

const VideoArchiveDialog: React.FC<TrainingActiveDialogProps> = (
  props: TrainingActiveDialogProps,
) => {
  const classes = useStyles();
  const { isOpen = false, closeDialog } = props;

  return (
    <DialogMigrate
      aria-labelledby="training-active-dialog"
      className={classes.dialog}
      maxWidth="md"
      open={isOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {
        closeDialog(false);
      }}>
      <DialogTitle className={classes.dialogTitle}>
        <div className="title">Activate Training?</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className="sub-title">
          Once you activate the training, you will not be able to make changes
          (except adding new violations and reasons) to it. Are you sure you
          want to activate this?
        </div>
      </DialogContent>
      <Divider className={classes.divider} />
      <DialogActions className={classes.dialogAction}>
        <Link
          className={classes.cancelLink}
          color="inherit"
          underline="always"
          onClick={() => {
            closeDialog(false);
          }}>
          NO, CANCEL
        </Link>
        <ArchiveButton
          type={'button'}
          variant="contained"
          onClick={() => {
            closeDialog(true);
          }}>
          <Typography className={classes.confirmBtn}>YES, ACTIVATE</Typography>
        </ArchiveButton>
      </DialogActions>
    </DialogMigrate>
  );
};

export default observer(VideoArchiveDialog);
