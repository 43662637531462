import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useCustomStore } from '../../../hooks';
import { DriverSearchFormInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import DriverHeader from './DriverHeader';
import DriverLists from './DriverLists';
import useStyles from './driverStyle';

const Drivers: React.FC = () => {
  const { driverStore, authStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [getMoreLoader, setGetMoreLoader] = useState(false);

  const [searchDrivers, setSearchDrivers] = useState<DriverSearchFormInputs>({
    companyName: driverStore.Filters.companyName,
    driverType: driverStore.Filters.driverType,
    name: driverStore.Filters.name,
    simplexId: driverStore.Filters.simplexId,
  });

  const driverHeader: any = [
    { lable: 'Driver Name/ID', name: 'name' },
    { lable: 'Company Name', name: 'companyName' },
    { lable: 'Driver Type', name: 'driverType' },
    { lable: 'Hire Date', name: 'hireDate' },
    { lable: 'Termination Date', name: 'terminationDate' },
    '',
  ];

  const getDriversLists = useCallback(
    async (isReset: boolean) => {
      driverStore.setShowLoader(true);
      if (isReset) {
        setSearchDrivers({
          companyName: '',
          driverType: '',
          name: '',
          simplexId: '',
        });
        driverStore.setFilters({
          companyName: '',
          driverType: '',
          limit: 0,
          name: '',
          nextLink: '',
          simplexId: '',
        });
      }

      const getDriversResp = await driverStore.fetchDriversBySorting();
      if (getDriversResp.isOk()) {
        driverStore.setShowLoader(false);
      }
      if (getDriversResp.isErr()) {
        driverStore.setShowLoader(false);
        enqueueSnackbar(
          String(getDriversResp.error.message),
          SnackBarConfig('e'),
        );
      }
    },
    [driverStore, enqueueSnackbar],
  );

  const getSearchBasedDrivers = useCallback(
    async (searchParams: DriverSearchFormInputs) => {
      if (
        !searchParams.name &&
        !searchParams.simplexId &&
        !searchParams.companyName &&
        !searchParams.driverType
      ) {
        driverStore.setDriverApiReset();
        getDriversLists(true);
        return;
      }
      setGetMoreLoader(true);
      const getEquipmentsResp = await driverStore.fetchDriversBySearchBased(
        searchParams,
      );
      if (getEquipmentsResp.isErr()) {
        enqueueSnackbar(
          String(getEquipmentsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      setGetMoreLoader(false);
      // driverStore.setShowLoader(false);
    },
    [driverStore, getDriversLists, enqueueSnackbar],
  );

  const getMoreDrivers = useCallback(async () => {
    if (
      searchDrivers.name ||
      searchDrivers.simplexId ||
      searchDrivers.companyName ||
      searchDrivers.driverType
    ) {
      getSearchBasedDrivers(searchDrivers);
      return;
    }

    setGetMoreLoader(true);
    const getDriversResp = await driverStore.fetchDrivers();
    if (getDriversResp.isErr()) {
      enqueueSnackbar(
        String(getDriversResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [driverStore, enqueueSnackbar, getSearchBasedDrivers, searchDrivers]);

  const getActiveUnits = () => {
    return (
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          letterSpacing: 0,
          lineHeight: '19px',
          padding: '1rem 1.5rem 2.5rem 1.5rem',
        }}>
        {driverStore.Count &&
          `${driverStore.Count}  ${driverStore.Status} Drivers `}{' '}
      </Typography>
    );
  };

  useEffect(() => {
    getDriversLists(true);
  }, [getDriversLists]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {!driverStore.ShowLoader && <Grid>{getActiveUnits()}</Grid>}
      <Grid
        className={classes.scroll}
        container
        spacing={2}
        justifyContent="space-between"
        style={{ paddingLeft: '40px', paddingRight: '40px' }}>
        <TableContainer sx={{ maxHeight: '85vh' }} id="driver-table">
          <InfiniteScroll
            dataLength={
              driverStore.DriverList && driverStore.DriverList.length
                ? driverStore.DriverList.length
                : 0
            }
            style={{ overflow: 'unset' }}
            next={getMoreDrivers}
            hasMore={driverStore.HasMoreResults}
            scrollableTarget={'driver-table'}
            loader={
              getMoreLoader && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#DEC330', marginTop: 20 }}
                  />
                </div>
              )
            }
            endMessage>
            {driverStore.ShowLoader ? (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '80px',
                  marginTop: '80px',
                  width: '100%',
                }}>
                <CircularProgress
                  size={30}
                  sx={{ color: '#DEC330', marginTop: 20 }}
                />
              </div>
            ) : (
              <Table
                className={classes.table}
                stickyHeader
                sx={{
                  borderCollapse: 'separate !important',
                  borderSpacing: '0px 5px !important',
                }}
                aria-label="driver table">
                <TableHead>
                  <DriverHeader {...{ driverHeader, driverStore }} />
                </TableHead>
                <TableBody>
                  {driverStore.DriverList &&
                  driverStore.DriverList.length > 0 ? (
                    <DriverLists
                      {...{
                        Status: driverStore.Status,
                        authStore,
                        driverLists: driverStore.DriverList,
                        driverStore,
                        enqueueSnackbar,
                      }}
                    />
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={8}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No record(s) found
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </InfiniteScroll>
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default observer(Drivers);
