import axios, { AxiosInstance } from 'axios';

import { Config } from '../config';
import { AdminUsersStore } from './adminUsers';
import { ApiStore } from './api';
import { AuthStore } from './auth';
import { CompanyStore } from './company';
import { DriverStore } from './driver';
import { GlobalStore } from './global';
import { NotificationStore } from './notification';
import { QuizzesStore } from './quizzes';
import { UserStore } from './user';
import { VideoStore } from './video';

export class RootStore {
  public apiStore: ApiStore;
  public authStore: AuthStore;
  public axiosInstance!: AxiosInstance;
  public globalStore: GlobalStore;
  public companyStore: CompanyStore;
  public driverStore: DriverStore;
  public adminUsersStore: AdminUsersStore;
  public userStore: UserStore;
  public notificationStore: NotificationStore;
  public videoStore: VideoStore;
  public quizzesStore: QuizzesStore;

  constructor() {
    this.initialize();
    this.apiStore = new ApiStore(this);
    this.authStore = new AuthStore(this);
    this.companyStore = new CompanyStore(this);
    this.driverStore = new DriverStore(this);
    this.adminUsersStore = new AdminUsersStore(this);
    this.userStore = new UserStore(this);
    this.globalStore = new GlobalStore(this);
    this.notificationStore = new NotificationStore(this);
    this.videoStore = new VideoStore(this);
    this.quizzesStore = new QuizzesStore(this);
  }

  initialize = () => {
    this.axiosInstance = axios.create({
      baseURL: Config.API_URL,
    });
  };
}

export const rootStore = new RootStore();
