import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  archiveBtn: {
    color: '#FFFFFF',
    fontFamily: 'FiraSans-Medium',
    fontSize: '0.9375rem',
    fontWeight: '500',
    height: '22px',
    letterSpacing: '1px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  cancelLink: {
    color: '#979797',
    cursor: 'pointer',
    fontSize: '0.9rem',
    letterSpacing: '0',
  },
  center: {
    textAlign: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      '@media screen and (max-width: 600px)': {
        width: '100%',
      },
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
      padding: '0.5rem 1rem 1rem 1rem !important',
      width: '32%',
    },
  },
  dialogAction: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '1rem',
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    border: '1px solid #E8E8E8',
    boxSizing: 'border-box',
    height: '1px',
  },
  subTitle: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '0.9rem',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '22px',
    textAlign: 'center',
    width: '80%',
  },
  title: {
    color: '#241A2E',
    fontFamily: 'FiraSans-Regular',
    fontSize: '1.1rem',
    fontWeight: 600,
    letterSpacing: '1.25px',
    lineHeight: '26px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
export default useStyles;
