import { Grid, Menu } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { API_LIMIT } from '../../config';
import { PrivateRoute } from '../../config/Constants';
import { useCustomStore } from '../../hooks';
import {
  CompanySearchFormInputs,
  DriverSearchFormInputs,
  NotificationSearchParams,
  SearchPages,
  UsersSearchFormInputs,
} from '../../types';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import useStyles from './SearchFilterStyles';
import AdminSearchForm from './SearchForm/AdminSearchForm';
import CompanySearchForm from './SearchForm/CompanySearchForm';
import DriverSearchForm from './SearchForm/DriverSearchForm';
import NotificationSearchForm from './SearchForm/NotificationSearchForm';
import TrainingQuizzesSearchForm from './SearchForm/TrainingQuizzesSearchForm';
import TrainingVideosSearchForm from './SearchForm/TrainingVideosSearchForm';
import TrainingViolationMappingsSearchForm from './SearchForm/TrainingViolationMappingsSearchForm';

type MenuProps = {
  open: any;
  anchorEl: any;
  filter: any;
  searchPage: any;
  handleClose: () => void;
  setFilters: (src: any) => void;
};

const SearchFilter = (props: MenuProps): React.ReactElement => {
  const { open, anchorEl, filter, handleClose, searchPage, setFilters } = props;
  const location = useLocation();

  const classes = useStyles();
  const { companyStore, driverStore, notificationStore, adminUsersStore } =
    useCustomStore();
  const { enqueueSnackbar } = useSnackbar();

  // Training Routes
  const TrainingRoute = {
    MAPPING: PrivateRoute.MAPPING,
    QUIZ: PrivateRoute.QUIZZES,
    VIDEOS: PrivateRoute.TRAININGS,
  };

  const {
    control: companyController,
    handleSubmit: companySubmit,
    reset: companyReset,
  } = useForm<CompanySearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      dotNumber: '',
      name: '',
      simplexId: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    // resolver: yupResolver(companySearchSchema),
  });

  const {
    control: driverController,
    handleSubmit: driverSubmit,
    reset: driverReset,
  } = useForm<DriverSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      companyName: '',
      driverType: '',
      name: '',
      simplexId: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    control: adminController,
    handleSubmit: adminSubmit,
    reset: adminReset,
  } = useForm<UsersSearchFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      name: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    control: notificationController,
    handleSubmit: notificationSubmit,
    reset: notificationReset,
  } = useForm<NotificationSearchParams>({
    criteriaMode: 'all',
    defaultValues: {
      description: '',
      // nextLink: '',
      status: '',
      title: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const resetFilter = async () => {
    if (searchPage === SearchPages.Drivers) {
      driverStore.setShowLoader(true);
      setFilters((pre: any) => ({
        ...pre,
        companyName: '',
        driverType: '',
        limit: API_LIMIT,
        name: '',
        nextLink: '',
        simplexId: '',
      }));

      driverStore.setFilters({
        companyName: '',
        driverType: '',
        limit: API_LIMIT,
        name: '',
        nextLink: '',
        simplexId: '',
      });
      driverReset();
      driverStore.setDriverApiReset();
      await driverStore.fetchDrivers();
      driverStore.setShowLoader(false);
    }

    if (searchPage === SearchPages.Companies) {
      companyStore.setShowLoader(true);
      setFilters((pre: any) => ({
        ...pre,
        dotNumber: '',
        limit: API_LIMIT,
        name: '',
        nextLink: '',
        simplexId: '',
      }));

      companyStore.setFilters({
        dotNumber: '',
        limit: API_LIMIT,
        name: '',
        nextLink: '',
        simplexId: '',
      });
      companyReset();
      companyStore.setCompanyApiReset();
      await companyStore.fetchCompanies();
      companyStore.setShowLoader(false);
    }

    if (searchPage === SearchPages.Notifications) {
      notificationStore.setNextLinkData({
        scroll: '',
        search: '',
      });
      setFilters((pre: any) => ({
        ...pre,
        description: '',
        // nextLink: '',
        status: '',
        title: '',
      }));
      notificationReset();
      // notificationStore.resetAllSearchParamDetails();
      submitNotificationFilter({
        description: '',
        status: '',
        title: '',
      });
    }

    if (searchPage === SearchPages.Users) {
      adminUsersStore.setShowLoader(true);
      setFilters((pre: any) => ({
        ...pre,
        limit: API_LIMIT,
        name: '',
        nextLink: '',
      }));

      adminUsersStore.setFilters({
        limit: API_LIMIT,
        name: '',
        nextLink: '',
      });
      adminReset();
      adminUsersStore.setAdminApiReset();
      await adminUsersStore.fetchAdmins();
      adminUsersStore.setShowLoader(false);
    }
  };

  const submitCompanyFilter = useCallback(
    async (data: CompanySearchFormInputs) => {
      companyStore.setShowLoader(true);
      companyStore.setCompanyApiReset();
      companyStore.setFilters(filter);

      const params: any = {};
      data.name != '' ? (params['name'] = data.name) : null;
      data.simplexId != '' ? (params['simplexId'] = data.simplexId) : null;
      data.dotNumber != '' ? (params['dotNumber'] = data.dotNumber) : null;

      const searchRes = await companyStore.fetchCompaniesBySearchBased(params);
      handleClose();

      if (searchRes.isErr()) {
        enqueueSnackbar(String(searchRes.error.message), SnackBarConfig('e'));
      }
      companyStore.setShowLoader(false);
    },
    [companyStore, enqueueSnackbar, filter, handleClose],
  );

  const submitDriverFilter = useCallback(
    async (data: DriverSearchFormInputs) => {
      driverStore.setDriverApiReset();
      driverStore.setShowLoader(true);
      driverStore.setFilters(filter);
      const params: any = {};
      data.name != '' ? (params['name'] = data.name) : null;
      data.companyName != ''
        ? (params['companyName'] = data.companyName)
        : null;
      data.simplexId != '' ? (params['simplexId'] = data.simplexId) : null;
      data.driverType != '' ? (params['driverType'] = data.driverType) : null;

      const searchRes = await driverStore.fetchDriversBySearchBased(params);
      handleClose();

      if (searchRes.isErr()) {
        enqueueSnackbar(String(searchRes.error.message), SnackBarConfig('e'));
      }
      driverStore.setShowLoader(false);
    },
    [driverStore, enqueueSnackbar, filter, handleClose],
  );

  const submitAdminFilter = useCallback(
    async (data: UsersSearchFormInputs) => {
      adminUsersStore.setShowLoader(true);
      adminUsersStore.setAdminApiReset();
      adminUsersStore.setFilters(filter);
      const params: any = {};
      data.name != '' ? (params['name'] = data.name) : null;

      const searchRes = await adminUsersStore.fetchAdminsBySearchBased(params);
      handleClose();

      if (searchRes.isErr()) {
        enqueueSnackbar(String(searchRes.error.message), SnackBarConfig('e'));
      }
      adminUsersStore.setShowLoader(false);
    },
    [adminUsersStore, enqueueSnackbar, filter, handleClose],
  );

  const submitNotificationFilter = (data: NotificationSearchParams) => {
    notificationStore.setNextLinkData({
      scroll: '',
      search: '',
    });
    notificationStore.setNotificationSearchDetails({ ...data });
  };

  return (
    <Menu
      id={searchPage}
      tabIndex={0}
      className="filter"
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        handleClose();
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}>
      <Grid style={{ padding: '1rem' }}>
        {searchPage === SearchPages.Companies && (
          <CompanySearchForm
            {...{
              classes,
              companyController,
              companyStore,
              companySubmit,
              filter,
              reset: companyReset,
              resetFilter,
              searchPage,
              setFilters,
              submitCompanyFilter,
            }}
          />
        )}

        {searchPage === SearchPages.Drivers && (
          <DriverSearchForm
            {...{
              classes,
              driverController,
              driverStore,
              driverSubmit,
              filter,
              reset: driverReset,
              resetFilter,
              searchPage,
              setFilters,
              submitDriverFilter,
            }}
          />
        )}

        {searchPage === SearchPages.Notifications && (
          <NotificationSearchForm
            {...{
              classes,
              filter,
              notificationController,
              notificationSubmit,
              reset: notificationReset,
              resetFilter,
              searchPage,
              setFilters,
              submitNotificationFilter,
            }}
          />
        )}

        {searchPage === SearchPages.Users && (
          <AdminSearchForm
            {...{
              adminController,
              adminSubmit,
              adminUsersStore,
              classes,
              filter,
              reset: adminReset,
              resetFilter,
              searchPage,
              setFilters,
              submitAdminFilter,
            }}
          />
        )}
        {searchPage === SearchPages.Training &&
          location.pathname === TrainingRoute.VIDEOS && (
            <TrainingVideosSearchForm classes={classes} onClose={handleClose} />
          )}
        {searchPage === SearchPages.Training &&
          location.pathname === TrainingRoute.QUIZ && (
            <TrainingQuizzesSearchForm
              classes={classes}
              onClose={handleClose}
            />
          )}
        {searchPage === SearchPages.Training &&
          location.pathname === TrainingRoute.MAPPING && (
            <TrainingViolationMappingsSearchForm
              classes={classes}
              onClose={handleClose}
            />
          )}
      </Grid>
    </Menu>
  );
};

export default SearchFilter;
