import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import CustomSortingIcon from '../../../../components/SortingIcon/CustomSortingIcon';
import { VideoStore } from '../../../../store/video';
import useStyles from './mappingStyle';

const MappingHeader: React.FC<{
  videoStore: VideoStore;
  violationMappingSortBy: (columnName: string) => void;
}> = ({ videoStore, violationMappingSortBy }) => {
  const classes = useStyles();
  // Grid Header
  const headerColumns = [
    { label: 'BASIC Category', name: 'basicCategoryName' },
    { label: 'Violation Group', name: 'violationGroupName' },
    { label: 'Violation Code', name: 'code' },
    { label: 'Description', name: 'description' },
    { label: 'Training Mapped', name: 'videosMapped' },
  ];
  // Sorting enable columns
  const sortColumns = ['violationGroupName', 'code', 'description'];

  return (
    <TableRow>
      {headerColumns.map((header: any, index: number) => (
        <TableCell
          key={index}
          className={classes.tableHeaderBg}
          sx={header.styles}>
          <Grid item xs={12}>
            <Typography
              component={'div'}
              noWrap
              style={{
                alignItems: 'center',
                display: 'flex',
                whiteSpace: 'unset',
              }}>
              <Typography
                component={'div'}
                sx={{
                  color: '#979598',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '12px',
                  letterSpacing: 0,
                  lineHeight: '14px',
                }}>
                {header.label}
              </Typography>

              {sortColumns.includes(header.name) && (
                <CustomSortingIcon
                  {...{
                    filterSortBy: videoStore.ViolationMappingFilters.sortBy,
                    filterSortDirection:
                      videoStore.ViolationMappingFilters.sortDirection,
                    sortBY: violationMappingSortBy,
                    sortByName: header.name,
                  }}
                />
              )}
            </Typography>
          </Grid>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default observer(MappingHeader);
