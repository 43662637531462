import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  // Paper,
  // Table,
  // TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useCustomStore } from '../../../hooks';
import useStyles from './companyStyle';
import CompanyUserList from './CompanyUserList';

type ListPropsTypes = {
  companyLists: any;
  expandState: any;
  onInvite: (id: string) => unknown;
  showUserLoader: boolean;
  handleEpandRow: (event: any, companyId: string) => void;
  enqueueSnackbar: any;
  getMoreCompanyUsers: (companyId: string) => void;
};

const CompanyLists = (props: ListPropsTypes): React.ReactElement => {
  const {
    companyLists,
    expandState,
    handleEpandRow,
    onInvite,
    showUserLoader,
    enqueueSnackbar,
    getMoreCompanyUsers,
  } = props;
  const { authStore, companyStore } = useCustomStore();
  const { CompanyUsersList } = companyStore;
  const classes = useStyles();
  return companyLists.map((company: any, index: number) => (
    <>
      <TableRow
        key={index}
        className={classes.rowHeight}
        style={{ minHeight: '80px' }}>
        {/* container spacing={2} justifyContent="space-between" sx={{ mt: 0 }} */}
        <TableCell>
          <Grid item xs={12}>
            <Typography
              noWrap
              sx={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Regular',
                fontSize: '16px',
                fontWeight: 'bold',
                letterSpacing: 0,
                lineHeight: '23px',
              }}>
              {company.name || '-'}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid item xs={12}>
            <Typography
              noWrap
              sx={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Regular',
                fontSize: '16px',
                fontWeight: '300',
                letterSpacing: 0,
                lineHeight: '19px',
              }}>
              {company.simplexId || '-'}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid item xs={12}>
            <Typography
              noWrap
              sx={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Regular',
                fontSize: '16px',
                fontWeight: '300',
                letterSpacing: 0,
                lineHeight: '19px',
              }}>
              {company.dotNumber || '-'}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid item xs={12}>
            <Typography
              noWrap
              sx={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Regular',
                fontSize: '16px',
                fontWeight: '300',
                letterSpacing: 0,
                lineHeight: '19px',
              }}>
              {company.totalActiveDrivers || '-'}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid item xs={12}>
            <Typography
              noWrap
              sx={{
                color: '#241A2E',
                fontFamily: 'FiraSans-Regular',
                fontSize: '16px',
                fontWeight: '300',
                letterSpacing: 0,
                lineHeight: '19px',
              }}>
              {company.totalActiveUnits || '-'}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid item xs={12}>
            <Button
              color="secondary"
              onClick={() => onInvite(company.companyId)}>
              Invite
            </Button>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(event) => handleEpandRow(event, company.companyId)}>
              {expandState[company.companyId] ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </Grid>
        </TableCell>
      </TableRow>
      {expandState[company.companyId] && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse
              in={expandState[company.companyId]}
              timeout="auto"
              unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {showUserLoader && !CompanyUsersList.length ? (
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <CircularProgress size={30} sx={{ color: '#DEC330' }} />
                  </div>
                ) : CompanyUsersList && CompanyUsersList.length ? (
                  <CompanyUserList
                    usersList={CompanyUsersList}
                    isLast={false}
                    enqueueSnackbar={enqueueSnackbar}
                    authStore={authStore}
                    companyId={company.companyId}
                    getMoreCompanyUsers={getMoreCompanyUsers}
                  />
                ) : (
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <Typography
                      component={'span'}
                      color={'#241A2E'}
                      fontSize={'14px'}
                      fontFamily={'FiraSans-Medium'}
                      fontWeight={500}>
                      No users found
                    </Typography>
                  </div>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  ));
};

export default observer(CompanyLists);
