export enum AuthErrorType {
  InvalidKeyErrorType = 'AuthErrorTypeInvalidKey',
  InvalidUserType = 'AuthErrorTypeInvalidUserType',
  NotFoundErrorType = 'AuthErrorTypeNotFound',
  MissingParamsErrorType = 'AuthErrorTypeMissingParams',
  InvalidPassword = 'AuthErrorTypeInvalidPassword',
  TokenExpiredType = 'AuthErrorTypeTokenExpired',
  AppHasNoNotificationSetup = 'AuthErrorTypeAppHasNoNotificationSetup',
  NotPartOfApp = 'AuthErrorTypeNotPartOfApp',
  InvalidWebhookKey = 'AuthErrorTypeInvalidWebhookKey',
  NoAccessToUser = 'AuthErrorTypeNoAccessToUser',
  ServerNoAccessToUser = 'AuthErrorTypeServerNoAccessToUser',
  IncorrectPassword = 'AuthErrorTypeIncorrectPassword',
  NoSamePassword = 'AuthErrorTypeNoSamePassword',
  NoAppAccessToUser = 'AuthErrorTypeNoAppAccessToUser',
  TokenNotStarted = 'AuthErrorTypeTokenNotStarted',
  AccountAlreadyExists = 'AuthErrorTypeAccountAlreadyExists',
  UserNotFound = 'AuthErrorTypeUserNotFound',
  InvalidToken = 'AuthErrorTypeInvalidToken',
  MissingAuthHeaderField = 'AuthErrorTypeMissingAuthHeaderField',
  InvalidCredentials = 'AuthErrorTypeInvalidCredentials',
  HashGeneration = 'AuthErrorTypeHashGeneration',
  HashComparison = 'AuthErrorTypeHashComparison',
  RefreshTokenAlreadyExchanged = 'AuthErrorTypeRefreshTokenAlreadyExchanged',
  RefreshTokenNotFound = 'AuthErrorTypeRefreshTokenNotFound',
  UserDisabled = 'AuthErrorTypeUserDisabled',
  UserInactive = 'AuthErrorTypeUserInactive',
  InvalidUserRole = 'AuthErrorInvalidUserRole',
  InvitationPermissionDenied = 'AuthErrorInvitationPermissionDenied',
  PasswordMismatchError = 'AuthErrorPasswordMismatchError',
  InvitationCodeNotFound = 'AuthErrorTypeInvitationCodeNotFound',
  InvalidInvitationCode = 'AuthErrorTypeInvalidInvitationCode',
  InvitationCodeExpired = 'AuthErrorTypeInvitationCodeExpired',
  InvitationCodeRedeemed = 'AuthErrorTypeInvitationCodeRedeemed',
}

export enum FileErrorType {
  SizeLimitExceeded = 'FileErrorTypeSizeLimitExceeded',
  NoFileSentType = 'FileErrorTypeNoFileSentType',
  InvalidFieldNameForFile = 'FileErrorTypeInvalidFieldNameForFile',
  InvalidMetadata = 'FileErrorTypeInvalidMetadata',
  FileSizeLimitExceeded = 'FileErrorTypeFileSizeLimitExceeded',
  InvalidFileName = 'FileErrorTypeInvalidFileName',
  NotSent = 'FileErrorTypeNotSent',
  NotFound = 'FileErrorTypeNotFound',
  NoThumbnailFound = 'FileErrorTypeNoThumbnailFound',
  ImageNotForConversation = 'FileErrorTypeImageNotForConversation',
  ImageNotForUser = 'FileErrorTypeImageNotForUser',
  FileNotForMessage = 'FileErrorTypeFileNotForMessage',
  SizeNotFound = 'FileErrorTypeSizeNotFound',
  NotForApp = 'FileErrorTypeNotForApp',
  IncorrectFileType = 'FileErrorTypeIncorrectFileType',
}

export enum UserErrorType {
  NoUsersFound = 'UserErrorTypeNoUsersFound',
}

export enum ServerErrorType {
  UnknownError = 'UnknownError',
  InternalServerError = 'InternalServerError',
}

export enum GeneralErrorType {
  MissingQueryParams = 'GeneralErrorTypeMissingQueryParams',
  RouteNotFound = 'GeneralErrorTypeRouteNotFound',
  LimitNotValid = 'GeneralErrorTypeLimitNotValid',
  OffsetNotValid = 'GeneralErrorTypeOffsetNotValid',
}

export enum DateErrorType {
  InvalidFromDateError = 'DateErrorTypeInvalidFromDateError',
  InvalidToDateError = 'DateErrorTypeInvalidToDateError',
}

// Payload actions
export interface ErrorResponseActions {
  authFailed?: boolean;
  exchange?: boolean;
}
