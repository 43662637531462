import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useCustomStore } from '../../../hooks';
import { UsersSearchFormInputs } from '../../../types';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import ComingSoon from '../ComingSoon';
import UsersHeader from './UsersHeader';
import UsersLists from './UsersLists';
import useStyles from './UsersStyle';

const Users: React.FC = () => {
  const { adminUsersStore, authStore } = useCustomStore();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [getMoreLoader, setGetMoreLoader] = useState(false);

  const [searchAdmins, setSearchAdmins] = useState<UsersSearchFormInputs>({
    name: adminUsersStore.Filters.name,
  });

  const [comingSoon] = useState(false);

  const getAdminsLists = useCallback(
    async (isReset: boolean) => {
      adminUsersStore.setShowLoader(true);
      if (isReset) {
        setSearchAdmins({
          name: '',
        });
        adminUsersStore.setFilters({
          limit: 0,
          name: '',
          nextLink: '',
        });
      }
      const getAdminsResp = await adminUsersStore.fetchAdminsBySorting();
      if (getAdminsResp.isOk()) {
        adminUsersStore.setShowLoader(false);
      }
      if (getAdminsResp.isErr()) {
        adminUsersStore.setShowLoader(false);
        enqueueSnackbar(
          String(getAdminsResp.error.message),
          SnackBarConfig('e'),
        );
      }
    },
    [adminUsersStore, enqueueSnackbar],
  );

  const getSearchBasedAdmins = useCallback(
    async (searchParams: UsersSearchFormInputs) => {
      if (!searchParams.name) {
        adminUsersStore.setAdminApiReset();
        getAdminsLists(true);
        return;
      }
      adminUsersStore.setShowLoader(true);
      const getEquipmentsResp = await adminUsersStore.fetchAdminsBySearchBased(
        searchParams,
      );
      if (getEquipmentsResp.isErr()) {
        enqueueSnackbar(
          String(getEquipmentsResp.error.message),
          SnackBarConfig('e'),
        );
      }
      adminUsersStore.setShowLoader(false);
    },
    [adminUsersStore, enqueueSnackbar, getAdminsLists],
  );

  const getMoreAdmins = useCallback(async () => {
    if (searchAdmins.name) {
      getSearchBasedAdmins(searchAdmins);
      return;
    }
    setGetMoreLoader(true);
    const getEquipmentsResp = await adminUsersStore.fetchAdmins();
    if (getEquipmentsResp.isErr()) {
      enqueueSnackbar(
        String(getEquipmentsResp.error.message),
        SnackBarConfig('e'),
      );
    }
    setGetMoreLoader(false);
  }, [adminUsersStore, enqueueSnackbar, getSearchBasedAdmins, searchAdmins]);

  useEffect(() => {
    getAdminsLists(true);
  }, [getAdminsLists]);

  const userHeader: any = [
    {
      lable: 'Name',
      name: 'name',
      show: adminUsersStore.Status === 'Users' ? true : false,
    },
    {
      lable: 'Role',
      name: '',
      show: adminUsersStore.Status === 'Users' ? true : false,
    },

    {
      lable: 'Role Name',
      name: 'name',
      show: adminUsersStore.Status === 'Roles' ? true : false,
    },
    {
      lable: 'Impersonate Admin',
      name: '',
      show: adminUsersStore.Status === 'Roles' ? true : false,
    },
    {
      lable: 'Impersonate Drivers',
      name: '',
      show: adminUsersStore.Status === 'Roles' ? true : false,
    },
    {
      lable: 'Add Notifications',
      name: '',
      show: adminUsersStore.Status === 'Roles' ? true : false,
    },
    {
      lable: 'Manage Users',
      name: '',
      show: adminUsersStore.Status === 'Roles' ? true : false,
    },
    {
      lable: 'Manage Trainings',
      name: '',
      show: adminUsersStore.Status === 'Roles' ? true : false,
    },
    '',
  ];

  const getActiveUnits = () => {
    return (
      <Typography
        noWrap
        sx={{
          color: '#241A2E',
          fontFamily: 'FiraSans-Regular',
          fontSize: '16px',
          letterSpacing: 0,
          lineHeight: '19px',
          padding: '1rem 1.5rem 2.5rem 1.5rem',
        }}
      />
    );
  };

  return comingSoon ? (
    <ComingSoon />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        px: 6,
      }}>
      {!adminUsersStore.ShowLoader && <Grid>{getActiveUnits()}</Grid>}
      <Grid
        className={classes.scroll}
        container
        spacing={2}
        justifyContent="space-between"
        style={{ paddingLeft: '40px', paddingRight: '40px' }}>
        <TableContainer sx={{ maxHeight: '85vh' }} id="user-table">
          <InfiniteScroll
            dataLength={
              adminUsersStore.AdminList && adminUsersStore.AdminList.length
                ? adminUsersStore.AdminList.length
                : 0
            }
            style={{ overflow: 'unset' }}
            next={getMoreAdmins}
            hasMore={adminUsersStore.HasMoreResults}
            scrollableTarget={'user-table'}
            loader={
              getMoreLoader && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <CircularProgress
                    size={30}
                    sx={{ color: '#DEC330', marginTop: 20 }}
                  />
                </div>
              )
            }
            endMessage>
            {adminUsersStore.ShowLoader ? (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '80px',
                  marginTop: '80px',
                  width: '100%',
                }}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            ) : (
              <Table
                sx={{
                  borderCollapse: 'separate !important',
                  borderSpacing: '0px 5px !important',
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="simple table">
                <TableHead>
                  <UsersHeader {...{ adminUsersStore, userHeader }} />
                </TableHead>
                <TableBody>
                  {adminUsersStore.AdminList &&
                  adminUsersStore.AdminList.length > 0 ? (
                    <UsersLists
                      {...{
                        adminUsersStore: adminUsersStore,
                        authStore,
                        getAdminsListCall: getAdminsLists,
                        usersLists: adminUsersStore.AdminList,
                      }}
                    />
                  ) : (
                    <TableRow
                      className={classes.rowHeight}
                      style={{ minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={10}>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No record(s) found
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </InfiniteScroll>
        </TableContainer>
      </Grid>
    </Box>
  );
};

export default observer(Users);
