import moment from 'moment';
import * as yup from 'yup';

const StartEndTimeValidationFn: any = (val: any, parent: any) => {
  const {
    scheduleStartDate,
    scheduleEndDate,
    scheduledStartAmPm,
    scheduledEndAmPm,
    scheduledEndTime,
    scheduledStartTime,
  } = parent.parent;
  if (
    scheduleEndDate &&
    scheduleStartDate &&
    moment(scheduleStartDate).isValid() &&
    moment(scheduleEndDate).isValid() &&
    val
  ) {
    let starthours: any = moment(scheduledStartTime).hours();
    const startminutes: any = moment(scheduledStartTime).minutes();
    let endhours: any = moment(scheduledEndTime).hours();
    const endminutes: any = moment(scheduledEndTime).minutes();
    if (scheduledStartAmPm === 'pm') {
      starthours += 12;
    }
    if (scheduledEndAmPm === 'pm') {
      endhours += 12;
    }

    const startDate: any = moment(scheduleStartDate).set({
      hour: starthours,
      minute: startminutes,
      second: 0,
    });
    const endDate: any = moment(scheduleEndDate).set({
      hour: endhours,
      minute: endminutes,
      second: 0,
    });
    if (startDate.isSame(endDate, 'day')) {
      const status = startDate.isBefore(endDate);
      return status;
    }
  }
  return true;
};

export const loginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Email should have correct format')
      .required('Required'),
    password: yup.string().required('Required'),
  })
  .required();

export const notificationSchema = yup
  .object()
  .shape({
    description: yup
      .string()
      .required('Required')
      .test('manual', 'Please enter valid description', (value: any) => {
        return !(
          /[0-9a-zA-Z](\n|\r|\n\r|\s)+$/gi.test(value) ||
          /^(\s|\n\r|\n)+[9a-zA-Z]+/gi.test(value)
        );
      }),
    isSplachNotification: yup.boolean().optional(),
    link: yup.string().url().optional(),
    linkTitle: yup
      .string()
      .when('link', {
        is: (val: any) => !!val,
        then: yup.string().required(),
      })
      .optional()
      .test('manual', 'Please enter valid description', (value: any) => {
        return !(
          /[0-9a-zA-Z](\n|\r|\n\r|\s)+$/gi.test(value) ||
          /^(\s|\n\r|\n)+[9a-zA-Z]+/gi.test(value)
        );
      }),
    recipients: yup.array().optional(),
    scheduleEndDate: yup
      .date()
      .typeError('Invalid date')
      .nullable()
      // .min(yup.ref('scheduleStartDate'), 'min date exceed')
      .test({
        name: 'custome predictor',
        test: (value: any, parent: any) => {
          if (value && moment(value).isValid()) {
            const endDateFormatted: any = moment(value);
            const beforeStatus: any = moment().isSameOrBefore(
              endDateFormatted,
              'day',
            );
            if (!beforeStatus) {
              return parent.createError({
                message: 'Invalid date',
                path: 'scheduleEndDate',
              });
            } else {
              const {
                parent: { scheduleStartDate },
              } = parent;
              if (scheduleStartDate && moment(scheduleStartDate).isValid()) {
                const checkStatus: any = moment(
                  scheduleStartDate,
                ).isSameOrBefore(endDateFormatted, 'day');

                if (!checkStatus) {
                  return parent.createError({
                    message: 'min date exceed',
                    path: 'scheduleEndDate',
                  });
                }
              }
            }
          }
          return true;
        },
      })
      .required('Required'),
    scheduleStartDate: yup
      .date()
      .typeError('Invalid date')
      .nullable()
      // .max(yup.ref('scheduleEndDate'), 'max date exceeds')
      .test({
        name: 'custome predictor',
        test: (value: any, parent: any) => {
          if (value && moment(value).isValid()) {
            const startDateFormatted: any = moment(value);
            const beforeStatus: any = moment().isSameOrBefore(
              startDateFormatted,
              'day',
            );
            if (!beforeStatus) {
              return parent.createError({
                message: 'Invalid date',
                path: 'scheduleStartDate',
              });
            } else {
              const {
                parent: { scheduleEndDate },
              } = parent;
              if (scheduleEndDate && moment(scheduleEndDate).isValid()) {
                const checkStatus: any = moment(scheduleEndDate).isSameOrAfter(
                  startDateFormatted,
                  'day',
                );
                if (!checkStatus) {
                  return parent.createError({
                    message: 'min date exceed',
                    path: 'scheduleStartDate',
                  });
                }
              }
            }
          }
          return true;
        },
      })
      .required('Required'),
    scheduledEndAmPm: yup.string().required('Required'),
    scheduledEndTime: yup
      .string()
      .nullable()
      .required('Required')
      .test('exceeds', 'Not less than start time', StartEndTimeValidationFn),
    scheduledStartAmPm: yup.string().required('Required'),
    scheduledStartTime: yup
      .string()
      .nullable()
      .required('Required')
      .test('exceeds', 'Not more than end time', StartEndTimeValidationFn),
    title: yup
      .string()
      .required('Required')
      .matches(
        /(?=(?:^\w))[a-zA-Z0-9\s`~!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+(?<=[^\s])$/gim,
        'Please Enter valid title',
      ),
    unitQuantityMaximum: yup.string().nullable().optional(),
    unitQuantityMinimum: yup.string().nullable().optional(),
    yearsInBusinessMaximum: yup.string().nullable().optional(),
    yearsInBusinessMinimum: yup.string().nullable().optional(),
  })
  .required();

export const VideoSchema = yup
  .object()
  .shape({
    documentTypeId: yup
      .string()
      .nullable()
      .required('Certificate Type Required'),
    hasDocument: yup.boolean().required('Training Type Required'),
    hasQuiz: yup.boolean().optional(),
    hasViolationMappings: yup.boolean().optional(),
    quizId: yup.string().when('hasQuiz', {
      is: true,
      otherwise: yup.string().nullable().optional(),
      then: yup.string().nullable().required('Quiz Required'),
    }),
    status: yup.boolean().required('Status Required'),
    title: yup.string().required('Training Title Required'),
    violationIds: yup.array().optional(),
  })
  .required();

export const QuizSchema = yup
  .object()
  .shape({
    active: yup.boolean().required('Status Required'),
    correctAnswersPercentage: yup
      .string()
      .required('Correct Answers Percentage Required')
      .matches(/^([0-9]|[1-9][0-9]|100)$/g, {
        excludeEmptyString: false,
        message: 'Percentage should not exceed 100',
      }),
    questions: yup.array().required('Questions Required'),
    title: yup.string().required('Quiz Title Required'),
    videoTitle: yup.string().optional(),
  })
  .required();

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email should have correct format')
    .required('Required'),
});

export const verifyOtpSchema = yup.object().shape({
  otp: yup
    .number()
    .required('Required') // optional
    .typeError('Required') // optional as well
    .test(
      'len',
      'Otp needs to be excatly 6 digits',
      (val) => val!.toString().length === 6,
    ),
});

export const CreatePasswordSchema = yup.object().shape({
  password: yup.string().required('Required'),
});

export const ResetPasswordSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .when('password', {
      is: (p: string) => !!p,
      then: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
    })
    .required('Required'),
  password: yup.string().required('Required'),
});

export const SignUpSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .when('password', {
      is: (p: string) => !!p,
      then: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
    })
    .required('Confirm Password is required'),
  email: yup
    .string()
    .email('Email should have correct format')
    .required('Required'),
  firstName: yup
    .string()
    .required('Required')
    .matches(/^[A-Za-z ]*$/, 'Please enter valid first name')
    .max(40),
  lastName: yup
    .string()
    .required('Required')
    .matches(/^[A-Za-z ]*$/, 'Please enter valid last name')
    .max(40),
  password: yup.string().required('Password is required'),
});

export const UcrFormSchema = yup.object().shape({
  type: yup.string().required('Required'),
});

export const companySearchSchema = yup.object().shape({
  dotNumber: yup.string().required('Required'),
  name: yup.string().required('Required'),
  simplexId: yup.string().required('Required'),
});

export const UpdateAdminFormSchema = yup.object().shape({
  email: yup.string().required('Required'),
  name: yup.string().required('Required'),
});

export const AdminUsersSearchSchema = yup.object().shape({
  email: yup.string().optional(),
  firstName: yup.string().optional(),
  lastName: yup.string().optional(),
});

export const AdminRoleSchema = yup.object().shape({
  roleId: yup.string().required('Required'),
});

export const AdminRolesSchema = yup.object().shape({
  addNotifications: yup.string().optional(),
  impersonateAdmin: yup.string().optional(),
  impersonateDrivers: yup.string().optional(),
  manageAdmins: yup.string().optional(),
  manageTrainings: yup.string().optional(),
  name: yup.string().required('Required'),
});

export const AdminGrandPortalSchema = yup.object().shape({
  password: yup.string().required('Required'),
  roleId: yup.string().optional(),
  simplexId: yup
    .string()
    .nullable()
    .required(
      'Simplex id is required. please contact your simplex administrator',
    ),
});
